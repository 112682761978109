import StoreInventoryProductsList from "../components/inventory-products/StoreInventoryProductsList";

const StoreInventoryProductListPage = () => {
  return (
    <>
      <StoreInventoryProductsList />
    </>
  );
};

export default StoreInventoryProductListPage;
