import React, { useCallback, useEffect, useState } from "react";
import Color from "../../models/color";
import Errors from "../../models/errors";
import Product from "../../models/product";
import ProductSelect from "../product/ProductSelect";
import SingleColorSelect from "../color/SingleColorSelect";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";
import { CInput } from "@coreui/react";
import { getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { emptyValueOnUndefined } from "../../utils/fields";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Variant from "../../models/variant";
import {
  DeliveryOrderFormItem,
  DeliveryOrderFormItemSize,
} from "../../utils/delivery-orders";

interface DeliveryOrderItemFormProps {
  value?: DeliveryOrderFormItem;
  initialValue?: DeliveryOrderFormItem;
  initialErrors?: Errors;
  sizesColumns: string[];
  errors: Errors;
  onChange: (deliveryOrderItem: DeliveryOrderFormItem) => void | Promise<void>;
}

const FIXED_COLUMNS = [
  "Prenda",
  "Color",
  "Precio Unit.",
  "Subtotal",
  "Cant. Total",
  "Acciones",
];

const DeliveryOrderItemForm: React.FC<DeliveryOrderItemFormProps> = ({
  value,
  initialErrors,
  sizesColumns,
  initialValue,
  onChange,
  errors,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);

  const [editingItem, setEditingItem] = useState<DeliveryOrderFormItem>(
    value ? value : {}
  );
  const [product, setProduct] = useState<Product | undefined>(
    initialValue?.clothingProduct ? initialValue?.clothingProduct : undefined
  );
  const [color, setColor] = useState<Color | undefined>(
    initialValue?.color ? initialValue.color : undefined
  );
  const [sizeItems, setSizeItems] = useState<DeliveryOrderFormItemSize[]>(
    initialValue?.sizes ? initialValue.sizes : []
  );

  const [totalClothesAmount, setTotalClothesAmount] = useState<number>(0);

  const onProductChange = useCallback(
    (newProduct: Product | null) => {
      const newItem = {
        ...editingItem,
        companyId: company.id,
        clothingProductId: newProduct?.id,
        clothingProduct: newProduct !== null ? newProduct : undefined,
        productCodeIdentifier: newProduct?.codeIdentifier,
        productName: newProduct?.name,
        unitPrice: newProduct?.price,
        colorName: undefined,
        color: undefined,
        colorId: undefined,
      };
      setEditingItem(newItem);
      setProduct(newProduct !== null ? newProduct : undefined);
      setColor(undefined);
      setSizeItems([]);
      onChange(newItem);
    },
    [editingItem, company.id, onChange]
  );

  const onColorChange = useCallback(
    (newColor: Color | null) => {
      const newItem = {
        ...editingItem,
        companyId: company.id,
        colorName: newColor !== null ? newColor.color : undefined,
        color: newColor !== null ? newColor : undefined,
        colorId: newColor !== null ? newColor.id : undefined,
      };
      setEditingItem(newItem);
      setColor(newColor !== null ? newColor : undefined);
      if (product === undefined) {
        setSizeItems([]);
        onChange(newItem);
        return;
      }
      if (newColor === null) {
        setSizeItems([]);
        onChange(newItem);
        return;
      }
      fetchAvailableVariants(product, newColor);
      onChange(newItem);
    },
    [editingItem, company.id, onChange, product]
  );

  const onUnitPriceChange = useCallback(
    (newUnitPrice: number | undefined) => {
      const newItem = {
        ...editingItem,
        companyId: company.id,
        unitPrice: newUnitPrice,
        totalPrice: newUnitPrice
          ? newUnitPrice * totalClothesAmount
          : undefined,
      };
      setEditingItem(newItem);
      onChange(newItem);
    },
    [editingItem, company.id, onChange, totalClothesAmount]
  );

  const onSizeAmountChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      sizeItem: DeliveryOrderFormItemSize | undefined
    ) => {
      if (sizeItem === undefined) {
        onChange(editingItem);
        return;
      }
      const newAmountNumber = Number(e.target.value);
      const newSizeItems = [...sizeItems];
      const index = newSizeItems.findIndex((item) => {
        return item.sizeId === sizeItem.sizeId;
      });
      if (index === -1) {
        setSizeItems(newSizeItems);
      }
      const itemCopy = newSizeItems[index];
      itemCopy.amountDelivered = newAmountNumber;
      newSizeItems[index] = itemCopy;
      setSizeItems(newSizeItems);

      const newTotalRequiredClothesAmount = newSizeItems.reduce(
        (total, item) => total + (item.amountDelivered || 0),
        0
      );
      setTotalClothesAmount(newTotalRequiredClothesAmount);
      const currentPriceToMultiply = editingItem.unitPriceNoDiscount;
      if (currentPriceToMultiply === undefined) {
        onChange(editingItem);
        return;
      }
      const newEditingItem = {
        ...editingItem,
        companyId: company.id,
        totalPriceNoDiscount:
          newTotalRequiredClothesAmount * currentPriceToMultiply,
        sizes: newSizeItems,
      };
      setEditingItem(newEditingItem);
      onChange(newEditingItem);
    },
    [editingItem, sizeItems, company.id, onChange]
  );

  useEffect(() => {
    if (initialValue !== undefined && initialValue.sizes !== undefined) {
      const newTotalClothesAmount = initialValue.sizes.reduce(
        (total, item) => total + (item.amountDelivered || 0),
        0
      );
      setTotalClothesAmount(newTotalClothesAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    if (value !== undefined && value.sizes !== undefined) {
      const newTotalClothesAmount = value.sizes.reduce(
        (total, item) => total + (item.amountDelivered || 0),
        0
      );
      setTotalClothesAmount(newTotalClothesAmount);
      setSizeItems(value.sizes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const fetchAvailableVariants = async (product: Product, color: Color) => {
    const productIdStr = product.id?.toString();
    const colorIdStr = color.id?.toString();
    const limit = 200;
    const offset = 0;
    const additional = new Map();
    additional.set("clothing_product_id", productIdStr);
    additional.set("color_id", colorIdStr);

    try {
      const sizesStatus = await getList<Variant>(
        "/product_variants/with_related/",
        limit,
        offset,
        additional
      );

      if (sizesStatus.status === SUCCESS && sizesStatus.data !== undefined) {
        const newSizes = sizesStatus.data.items.map((variant) => ({
          size: variant.sizeObject,
          sizeId: variant.sizeId,
          sizeName: variant.size,
          variant: variant,
          variantId: variant.id,
        }));

        setSizeItems(newSizes);
        setEditingItem((editingItem) => {
          return {
            ...editingItem,
            sizes: newSizes,
            totalPrice: undefined,
          };
        });
      } else {
        setSizeItems([]);
      }
    } catch (error) {
      console.error("Error fetching sizes:", error);
      setSizeItems([]);
    }
  };

  return (
    <tr style={{ verticalAlign: "middle", overflow: "hidden" }}>
      <td style={{ padding: 0, minWidth: "200px" }}>
        <ProductSelect
          value={product ? product : null}
          onChange={onProductChange}
          disabled={true}
        ></ProductSelect>
      </td>
      <td style={{ padding: 0, width: "120px" }}>
        <SingleColorSelect
          value={color ? color : null}
          onChange={onColorChange}
          productId={product?.id}
          key={product ? product.id : 0}
          disabled={true}
        ></SingleColorSelect>
      </td>
      <td style={{ padding: 0, width: "100px" }}>
        <CurrencyField
          currency={PYG}
          value={
            editingItem
              ? editingItem.unitPriceNoDiscount ?? editingItem.unitPrice
              : undefined
          }
          onChange={onUnitPriceChange}
          disabled={true}
        ></CurrencyField>
      </td>
      <td style={{ padding: 0, width: "100px" }}>
        <CurrencyField
          disabled
          currency={PYG}
          value={
            editingItem
              ? editingItem.totalPriceNoDiscount ?? editingItem.totalPrice
              : undefined
          }
        ></CurrencyField>
      </td>
      <td style={{ padding: 0, width: "70px" }}>
        <CInput disabled value={totalClothesAmount}></CInput>
      </td>
      {sizesColumns.map((column, ix) => {
        if (FIXED_COLUMNS.includes(column)) {
          return <></>;
        }
        const matchSize = sizeItems.find((item) => item.sizeName === column);
        const disabled =
          matchSize === undefined || matchSize.maxAmountAllowed === 0;
        return (
          <td key={ix} style={{ padding: 0, width: "70px" }}>
            <CInput
              type="number"
              max={matchSize ? matchSize.maxAmountAllowed : undefined}
              min={0}
              value={
                disabled
                  ? ""
                  : matchSize
                  ? emptyValueOnUndefined(matchSize.amountDelivered)
                  : ""
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onSizeAmountChange(e, matchSize)
              }
              disabled={disabled}
            ></CInput>
          </td>
        );
      })}
    </tr>
  );
};

const propsAreEqual = (
  prevItemProps: DeliveryOrderItemFormProps,
  nextItemProps: DeliveryOrderItemFormProps
): boolean => {
  const areCommonPropsEqual =
    prevItemProps.initialValue === nextItemProps.initialValue &&
    prevItemProps.errors === nextItemProps.errors &&
    prevItemProps.sizesColumns === nextItemProps.sizesColumns &&
    prevItemProps.onChange === nextItemProps.onChange;

  if (!areCommonPropsEqual) {
    return false;
  }

  const prevSizes = prevItemProps.value?.sizes || [];
  const nextSizes = nextItemProps.value?.sizes || [];

  if (prevSizes.length !== nextSizes.length) {
    return false;
  }

  for (let i = 0; i < prevSizes.length; i++) {
    const prevSize = prevSizes[i];
    const nextSize = nextSizes[i];

    if (
      prevSize.sizeId !== nextSize.sizeId ||
      prevSize.amountDelivered !== nextSize.amountDelivered
    ) {
      return false;
    }
  }

  return true;
};

export default React.memo(DeliveryOrderItemForm, propsAreEqual);
