import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Color from "../../models/color";
import CashRegisterOperation from "../../models/cash-register-operation";
import { DATEDDMMYYY, formatDate } from "../../utils/dates";

const RECORS_PER_SEARCH = 20;

type CashRegisterSelectProps = {
  value: CashRegisterOperation | null;
  disabled?: boolean;
  onChange: (_: Color | null) => void;
};

const CashRegisterSelect: React.FC<CashRegisterSelectProps> = ({
  value,
  disabled,
  onChange,
}) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );
  const loadOptions = async (
    s: string,
    currentOpts: readonly (
      | CashRegisterOperation
      | { options: CashRegisterOperation[]; label?: string }
    )[]
  ): Promise<
    Response<
      CashRegisterOperation,
      { options: CashRegisterOperation[]; label?: string },
      any
    >
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    const dataPaginated = await getList<CashRegisterOperation>(
      "/cash_register_operations/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: CashRegisterOperation[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Operacion de caja"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: CashRegisterOperation) =>
        `${
          option.openingDatetime
            ? formatDate(new Date(option.openingDatetime), DATEDDMMYYY)
            : ""
        } - ${option.storeName} - ${option.username}`
      }
      getOptionValue={(option: CashRegisterOperation) => `${option.id}`}
      value={value}
      isDisabled={disabled}
      isClearable
    />
  );
};

export default CashRegisterSelect;
