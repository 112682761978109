import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { deleteItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import Discount from "../../models/discount";

interface DiscountDeleteModalProps {
  show: boolean;
  discount?: Discount;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const DiscountDeleteModal: React.FC<DiscountDeleteModalProps> = ({
  show,
  discount,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = discount
    ? `Está seguro de que quiere eliminar el descuento: ${discount.name}`
    : "";

  const onDeleteClick = async () => {
    if (discount !== undefined) {
      setSubmitting(true);
      const discountStatus = await deleteItem<Discount>(
        `/discounts/${discount.id}/`,
        discount
      );

      if (discountStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (discountStatus.detail !== undefined) {
          message = discountStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Eliminar</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <h3 className="text-danger">Precaución</h3>
              <h5>
                Eliminar descuentos hara que todos los que esten activos con el
                mismop dejen de aplicarse
              </h5>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onDeleteClick} color="danger">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Eliminando..." : "Eliminar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default DiscountDeleteModal;
