import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getList, updateItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, warningAlert } from "../utils/messages";
import ProductionOrder, {
  ProductionOrderSize,
} from "../../models/production-order";
import ProductionStage from "../../models/production-stage";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  UniqueListItem,
  newUniqueListItem,
  uniqueListToListItems,
} from "../../models/unique-list-item";
import ProductionSizeEntryForm from "./ProductionSizeEntryForm";
import Errors, { getFieldErrors } from "../../models/errors";

interface ProductionSizesModalProps {
  show: boolean;
  productionOrder?: ProductionOrder;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ProductionSizesModal: React.FC<ProductionSizesModalProps> = ({
  show,
  productionOrder,
  onCancel,
  onSuccess,
}) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );
  const [submitting, setSubmitting] = useState(false);
  const [stage, setStage] = useState<ProductionStage | null>(null);
  const [cutStage, setCutStage] = useState<ProductionStage | null>(null);
  const [cutSizes, setCutSizes] = useState<
    UniqueListItem<ProductionOrderSize>[]
  >([]);

  const [errors, setErrors] = useState<Errors>({});

  const message = productionOrder
    ? `Formulario de corte de proceso de producción #${productionOrder.id}, ${productionOrder.productCodeIdentifier} - ${productionOrder.productName}`
    : "";

  const getConfectionStage = async () => {
    const limit = 100;
    const offset = 0;
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());
    const stagesStatus = await getList<ProductionStage>(
      `/production_stages/`,
      limit,
      offset,
      additionalParams
    );
    if (stagesStatus.status === SUCCESS) {
      if (stagesStatus.data !== undefined) {
        const newStage = stagesStatus.data.items.find(
          (item) => item.isConfection
        );
        setStage(newStage !== undefined ? newStage : null);
      }
    } else {
      setStage(null);
    }
  };

  const getCutStage = async () => {
    const limit = 100;
    const offset = 0;
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());
    const stagesStatus = await getList<ProductionStage>(
      `/production_stages/`,
      limit,
      offset,
      additionalParams
    );
    if (stagesStatus.status === SUCCESS) {
      if (stagesStatus.data !== undefined) {
        const newStage = stagesStatus.data.items.find((item) => item.isCut);
        setCutStage(newStage !== undefined ? newStage : null);
      }
    } else {
      setCutStage(null);
    }
  };

  const onAddRowClick = () => {
    setCutSizes((cutSizes) => {
      const newCutSizes = [
        ...cutSizes,
        newUniqueListItem<ProductionOrderSize>({}),
      ];
      return newCutSizes;
    });
  };

  const onRowEntryChange = (cutSize: UniqueListItem<ProductionOrderSize>) => {
    setCutSizes((cutSizes) => {
      const newCutSizes = [...cutSizes];
      const index = newCutSizes.findIndex((item) => {
        return item.uuid === cutSize.uuid;
      });

      if (index === -1) {
        return newCutSizes;
      }

      newCutSizes[index] = cutSize;

      return newCutSizes;
    });
  };

  const onRowEntryDelete = (entry: UniqueListItem<ProductionOrderSize>) => {
    setCutSizes((cutSizes) => {
      const newCutSizes = [...cutSizes];

      const index = newCutSizes.findIndex((item) => {
        return item.uuid === entry.uuid;
      });

      if (index === -1) {
        return newCutSizes;
      }

      newCutSizes.splice(index, 1);

      return newCutSizes;
    });
  };

  useEffect(() => {
    getCutStage();
    getConfectionStage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionOrder]);

  const onSubmitClick = async () => {
    if (productionOrder !== undefined) {
      setSubmitting(true);

      let toSendSizes = uniqueListToListItems(cutSizes);
      if (toSendSizes.length <= 0) {
        warningAlert(
          "Se debe seleccionar al menos una tamaño y una cantidad cortada"
        );
        setSubmitting(false);
        return;
      }

      toSendSizes = toSendSizes.map((item) => ({
        ...item,
        orderId: productionOrder.id,
        size: undefined,
      }));

      const totalAmount: number = toSendSizes.reduce(
        (sum, item) => sum + (item.amount || 0),
        0
      );

      let toSendOrder: ProductionOrder = {
        ...productionOrder,
        cutAmounts: toSendSizes,
        amountProduced: totalAmount,
        cutOrConfectionAmount: totalAmount,
        currentStageId: stage !== null ? stage.id : undefined,
        currentStage: stage !== null ? stage.name : undefined,
        currentStageAllowRelatedAmounts:
          stage !== null ? stage.allowRelatedAmounts : undefined,
        currentStageIsConfection: true,
        currentStageIsCut: false,
        stageAmount: totalAmount,
        originStageId: cutStage !== null ? cutStage.id : undefined,
      };

      const orderStatus = await updateItem<ProductionOrder>(
        `/production/${productionOrder.id}/advance/`,
        toSendOrder
      );

      if (orderStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (orderStatus.errors !== undefined) {
          setErrors(orderStatus.errors);
        }
        if (orderStatus.detail !== undefined) {
          message = orderStatus.detail;
        }
        errorAlert(message);
      } else {
        setErrors({});
        onSuccess();
        setStage(null);
      }

      setSubmitting(false);
    }
  };

  useEffect(() => {
    setStage(null);
    setCutSizes([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionOrder]);

  const cutAmountsErrors = getFieldErrors("cutAmounts", errors) as Errors[];

  return (
    <CModal show={show} className="modal-primary" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Formulario de Corte</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <p>Indique las cantidades por tamaño que fueron cortadas</p>
            </CCol>
          </CRow>
          {cutSizes.map((cutSize, ix) => {
            return (
              <CRow key={cutSize.uuid}>
                <CCol>
                  <ProductionSizeEntryForm
                    productId={productionOrder?.productId}
                    value={cutSize.item}
                    onDelete={() => onRowEntryDelete(cutSize)}
                    onChange={(item) =>
                      onRowEntryChange({
                        uuid: cutSize.uuid,
                        item: item,
                      })
                    }
                    errors={
                      ix < cutAmountsErrors.length ? cutAmountsErrors[ix] : {}
                    }
                  />
                </CCol>
              </CRow>
            );
          })}
          {cutSizes.length === 0 ? (
            <CRow>
              <CCol>
                Agregue nuevos tamaños cortados con el boton + de la derecha
              </CCol>
            </CRow>
          ) : (
            <></>
          )}
          <CRow className="mb-1">
            <CCol>
              <CButton
                className="btn btn-primary float-right"
                onClick={onAddRowClick}
              >
                <i className="fa fa-plus" />
              </CButton>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onSubmitClick} color="primary">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Guardando..." : "Guardar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ProductionSizesModal;
