import InventoryInputBatchCrud from "../components/inventory-input-batches/InventoryInputBatchCrud";

const InventoryInputBatchCrudPage = () => {
  return (
    <>
      <InventoryInputBatchCrud />
    </>
  );
};

export default InventoryInputBatchCrudPage;
