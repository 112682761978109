import SalesHistory from "../components/sales-history/SalesHistory";

const SalesHistoryPage = () => {
  return (
    <>
      <SalesHistory />
    </>
  );
};

export default SalesHistoryPage;