import { CCol, CFormGroup, CRow } from "@coreui/react";
import React, { useCallback, useState } from "react";
import { FieldErrors } from "../form/FieldErrors";
import Errors, { errorsAreSame, getFieldErrors } from "../../models/errors";

import ProductionOrderLaborPrice from "../../models/production-labor-price";
import ProductionStage from "../../models/production-stage";
import ProductionStageSelect from "./StageSelect";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";

interface ProductionLaborPriceFormProps {
  value: ProductionOrderLaborPrice;
  initialStage?: ProductionStage;
  initialPrice?: number;
  onChange: (_: ProductionOrderLaborPrice) => void;
  errors: Errors;
}

const ProductionLaborPriceForm: React.FC<ProductionLaborPriceFormProps> = ({
  value,
  initialStage,
  initialPrice,
  onChange,
  errors,
}) => {
  const [editingItem, setEditingItem] =
    useState<ProductionOrderLaborPrice>(value);
  const [stage, setStage] = useState<ProductionStage | null>(
    initialStage !== undefined ? initialStage : null
  );
  const [, setPrice] = useState<number | undefined>(initialPrice);

  const onPriceChange = useCallback(
    (newPrice: number | undefined) => {
      setPrice(newPrice);
      const newItem = {
        ...editingItem,
        price: newPrice,
      };
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onStageChange = useCallback(
    (newStage: ProductionStage | null) => {
      setStage(newStage);
      setPrice(undefined);
      const newItem = {
        ...editingItem,
        prdoctionStage: newStage !== null ? newStage : undefined,
        stageId: newStage !== null ? newStage.id : undefined,
        price: undefined,
      };
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  return (
    <div className="section border rounded mb-2 p-2">
      <CFormGroup>
        <CRow>
          <CCol md={2} className={"mt-2"}>
            Etapa de producción:
          </CCol>
          <CCol md={3}>
            {stage !== null ? (
              <ProductionStageSelect
                value={stage}
                onChange={onStageChange}
                isDisabled={true}
              ></ProductionStageSelect>
            ) : (
              <p className={"mt-2"}>{value.subject}</p>
            )}
            <FieldErrors
              errors={getFieldErrors("stage", errors) as string[]}
            ></FieldErrors>
          </CCol>
          <CCol md={1} className={"mt-2"}>
            Precio:
          </CCol>
          <CCol md={2}>
            <CurrencyField
              currency={PYG}
              value={editingItem.price}
              onChange={onPriceChange}
              placeholder="Precio de la etapa"
            ></CurrencyField>
            <FieldErrors
              errors={getFieldErrors("price", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
    </div>
  );
};

const propsAreEqual = (
  prevItemProps: ProductionLaborPriceFormProps,
  nextItemProps: ProductionLaborPriceFormProps
): boolean => {
  const areEqual =
    prevItemProps.value.id === nextItemProps.value.id &&
    prevItemProps.value.orderId === nextItemProps.value.orderId &&
    prevItemProps.value.price === nextItemProps.value.price &&
    prevItemProps.value.isActive === nextItemProps.value.isActive &&
    prevItemProps.value.subject === nextItemProps.value.subject &&
    prevItemProps.value.productionStage ===
      nextItemProps.value.productionStage &&
    prevItemProps.value.stageId === nextItemProps.value.stageId;
  errorsAreSame(prevItemProps.errors, nextItemProps.errors);

  return areEqual;
};

export default React.memo(ProductionLaborPriceForm, propsAreEqual);
