import InputMaterialCrud from "../components/input-material/InputMaterialCrud";

const InputMaterialCrudPage = () => {
  return (
    <>
      <InputMaterialCrud />
    </>
  );
};

export default InputMaterialCrudPage;
