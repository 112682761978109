import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import ProductionForm from "./ProductionForm";
import ProductionOrder, { newProductionOrder } from "../../models/production-order";

interface SupplierUpdateModalProps {
  show: boolean;
  productionOrder?: ProductionOrder;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const SupplierUpdateModal: React.FC<SupplierUpdateModalProps> = ({
  show,
  productionOrder,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialOrder, setInitialOrder] = useState<ProductionOrder>(productionOrder ? productionOrder : newProductionOrder());

  const modalTitle = productionOrder
    ? `Editar/Ver Detalles Orden de producción #${productionOrder.id}: ${productionOrder.productCodeIdentifier} - ${productionOrder.productName}`
    : `Crear Orden de producción`;

  const onClose = () => {
    setInitialErrors({});
    setInitialOrder(newProductionOrder());
    onCancel();
  };
  useEffect(() => {
    setInitialOrder(productionOrder ? productionOrder : newProductionOrder());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionOrder]);

  return (
    <CModal show={show} onClose={onClose} closeOnBackdrop={false} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <ProductionForm
            initialProductionOrder={initialOrder}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default SupplierUpdateModal;
