import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getList, updateItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import ProductionOrder from "../../models/production-order";
import ProductionStage from "../../models/production-stage";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface ProductionOrderStartModalProps {
  show: boolean;
  productionOrder?: ProductionOrder;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ProductionOrderStartModal: React.FC<ProductionOrderStartModalProps> = ({
  show,
  productionOrder,
  onCancel,
  onSuccess,
}) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );
  const [submitting, setSubmitting] = useState(false);
  const [stage, setStage] = useState<ProductionStage | null>(null);

  const message = productionOrder
    ? `Está seguro de que quiere iniciar la produccion #${productionOrder.id}, ${productionOrder.productCodeIdentifier} - ${productionOrder.productName}`
    : "";

  const getCutStage = async () => {
    const limit = 100;
    const offset = 0;
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());
    const stagesStatus = await getList<ProductionStage>(
      `/production_stages/`,
      limit,
      offset,
      additionalParams
    );
    if (stagesStatus.status === SUCCESS) {
      if (stagesStatus.data !== undefined) {
        const newStage = stagesStatus.data.items.find((item) => item.isCut);
        setStage(newStage !== undefined ? newStage : null);
      }
    } else {
      setStage(null);
    }
  };

  useEffect(() => {
    getCutStage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionOrder]);

  const onSubmitClick = async () => {
    if (productionOrder !== undefined) {
      if (stage === null) {
        errorAlert("Debe seleccionar una etapa para iniciar");
        return;
      }
      setSubmitting(true);

      let toSendOrder: ProductionOrder = {
        ...productionOrder,
        currentStage: stage !== null ? stage.name : undefined,
        currentStageId: stage !== null ? stage.id : undefined,
        currentStageAllowRelatedAmounts:
          stage !== null ? stage.allowRelatedAmounts : undefined,
        currentStageIsCut: true,
        currentStageIsConfection: false,
        orderStatus: "ongoing",
        stageAmount: productionOrder.amountProduced,
      };

      const orderStatus = await updateItem<ProductionOrder>(
        `/production/${productionOrder.id}/start/`,
        toSendOrder
      );

      if (orderStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (orderStatus.errors !== undefined) {
        }
        if (orderStatus.detail !== undefined) {
          message = orderStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
        setStage(null);
      }

      setSubmitting(false);
    }
  };

  useEffect(() => {
    setStage(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionOrder]);

  return (
    <CModal show={show} className="modal-primary" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Iniciar Producción</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <p> Se iniciará la producción en la etapa de corte</p>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onSubmitClick} color="primary">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Iniciando..." : "Iniciar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ProductionOrderStartModal;
