import BranchOffice from "./branch-office";

interface InventoryLocation {
  id?: number;
  companyId?: number;
  identifier?: string;
  storeId?: number;
  branchOffice?: BranchOffice;
  inventoryValue?: number;
  inventorySellValue?: number;
  inventorySellValueWholesale?: number;
  isFactory?: boolean;
  type?: string;
}

export interface InventoryLocationValueLog {
  id?: number;
  companyId?: number;
  storeName?: string;
  storeId?: number;
  locationId?: number;
  previousValue?: number;
  amount?: number;
  date?: string;
  obs?: string;
  manualEntry?: boolean;
}

export const INVENTORY_LOCATION_TYPE_PRODUCTS = "product";
export const INVENTORY_LOCATION_TYPE_PRODUCTS_LABEL = "Modelos";
export const INVENTORY_LOCATION_TYPE_INPUT_MATERIAL = "input_material";
export const INVENTORY_LOCATION_TYPE_INPUT_MATERIAL_LABEL = "Materia Prima";

export const INVENTORY_LOCATION_TYPE_CHOICES: Map<string, string> = new Map([
  [INVENTORY_LOCATION_TYPE_PRODUCTS, INVENTORY_LOCATION_TYPE_PRODUCTS_LABEL],
  [
    INVENTORY_LOCATION_TYPE_INPUT_MATERIAL,
    INVENTORY_LOCATION_TYPE_INPUT_MATERIAL_LABEL,
  ],
]);

export const newInventoryLocation = (): InventoryLocation => {
  return {};
};

export default InventoryLocation;
