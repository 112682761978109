import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
  CBadge,
  CSelect,
} from "@coreui/react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { createItem, getItem, getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, successAlert, warningAlert } from "../utils/messages";

import StoreRequest, {
  getStoreRequestStatusClass,
  getStoreRequestStatusLabel,
} from "../../models/store-requests";
import StoreRequestDeleteModal from "./StoreRequestDeleteModal";
import StoreRequestUpdateModal from "./StoreRequestUpdateModal";

import { formatDateTime } from "../../utils/dates";

import { defaultValueOnUndefined } from "../../utils/fields";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
import SingleStoreSelect from "../store/SingleStoreSelect";
import Store from "../../models/store";
import ManualAddProductModal from "./ManualAddProductModal";
import Variant from "../../models/variant";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { getFieldErrors } from "../../models/errors";
import InventoryLocation from "../../models/inventory-location";

const ITEMS_PER_PAGE = 100;

const StoreRequestList = () => {
  const fields = [
    {
      key: "requestDate",
      _classes: ["text-center"],
      label: "Fecha de pedido",
    },
    {
      key: "storeName",
      _classes: ["text-center"],
      label: "Sucursal",
    },
    {
      key: "variantName",
      _classes: ["text-center"],
      label: "Producto",
    },
    {
      key: "amount",
      _classes: ["text-center"],
      label: "Cantidad Solicitada",
    },
    {
      key: "fulfilledAmount",
      _classes: ["text-center"],
      label: "Cantidad Enviada",
    },
    {
      key: "status",
      _classes: ["text-center"],
      label: "Estado",
    },
    {
      key: "variantPrice",
      _classes: ["text-center"],
      label: "Precio Unitario",
    },
    {
      key: "totalValue",
      _classes: ["text-center"],
      label: "Valor de pedido",
    },
    {
      key: "requestedBy",
      _classes: ["text-center"],
      label: "Solicitado por",
    },
    {
      key: "transferId",
      _classes: ["text-center"],
      label: "Transferencia",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [storeRequest, setStoreRequest] = useState<StoreRequest | undefined>(
    undefined
  );
  const [search, setSearch] = useState<string>("");
  const [startDate, setStartDate] = useState<string | moment.Moment>("");
  const [endDate, setEndDate] = useState<string | moment.Moment>("");
  const [requestStatus, setRequestStatus] = useState<string>("");
  const [filterStore, setFilterStore] = useState<Store | undefined>(undefined);

  const [mainTotal, setMainTotal] = useState<number>(0);
  const [pendingTotal, setPendingTotal] = useState<number>(0);
  const [issuedTotal, setIssuedTotal] = useState<number>(0);

  const [loading, setLoading] = useState(true);
  const [storeRequests, setStoreRequests] = useState<StoreRequest[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const userEmail = useSelector((state: RootState) => state.user.email);

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let requestStatus = urlParams.get("request_status");
    let storeId = urlParams.get("store_id") ? urlParams.get("store_id") : "";
    let startDate = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : "";
    let endDate = urlParams.get("end_date") ? urlParams.get("end_date") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    if (requestStatus !== null && requestStatus !== "")
      map.set("request_status", requestStatus);
    if (startDate !== "") map.set("start_date", startDate);
    if (endDate !== "") map.set("end_date", endDate);
    if (storeId !== "") map.set("store_id", storeId);
    return map;
  };

  const fetchStoreRequests = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const colorsStatus = await getList<StoreRequest>(
      "/store_requests/",
      limit,
      offset,
      urlParams
    );
    if (colorsStatus.status === SUCCESS) {
      if (colorsStatus.data !== undefined) {
        const count = colorsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setStoreRequests(colorsStatus.data.items);
        setMainTotal(calculateMainTotal(colorsStatus.data.items));
        setPendingTotal(calculatePendingTotal(colorsStatus.data.items));
        setIssuedTotal(calculateIssuedTotal(colorsStatus.data.items));
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = colorsStatus.detail
        ? colorsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    if (previousParams.get("request_status")) {
      urlParams.set("request_status", previousParams.get("request_status"));
    }
    if (previousParams.get("start_date")) {
      urlParams.set("start_date", previousParams.get("start_date"));
    }
    if (previousParams.get("end_date")) {
      urlParams.set("end_date", previousParams.get("end_date"));
    }
    if (previousParams.get("store_id")) {
      urlParams.set("store_id", previousParams.get("store_id"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const calculateMainTotal = (requests: StoreRequest[]): number => {
    const total = requests.reduce((acc, req) => {
      const toAddPrice =
        req.status === "pending" ? req.totalValue : req.fulfilledValue;
      const price = toAddPrice ?? 0;
      return acc + price;
    }, 0);

    return total;
  };

  const calculatePendingTotal = (requests: StoreRequest[]): number => {
    const total = requests
      .filter((req) => req.status === "pending")
      .reduce((acc, sale) => {
        const price = sale.totalValue ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const calculateIssuedTotal = (requests: StoreRequest[]): number => {
    const total = requests
      .filter((req) => req.status === "issued")
      .reduce((acc, sale) => {
        const price = sale.fulfilledValue ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const onUpdateClick = (storeRequest: StoreRequest) => {
    setStoreRequest(storeRequest);
    setShowEditModal(true);
  };

  const onDeleteClick = (storeRequest: StoreRequest) => {
    setStoreRequest(storeRequest);
    setShowDeleteModal(true);
  };

  const onEditCancel = () => {
    setStoreRequest(undefined);
    setShowEditModal(false);
  };

  const onEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowEditModal(false);
    setStoreRequests([]);
    setLoading(true);
    fetchStoreRequests();
  };

  const onDeleteCancel = () => {
    setStoreRequest(undefined);
    setShowDeleteModal(false);
  };

  const onDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowDeleteModal(false);
    setStoreRequests([]);
    setLoading(true);
    fetchStoreRequests();
  };

  const onAddClick = () => {
    setShowAddModal(true);
  };

  const onAddCancel = () => {
    setShowAddModal(false);
  };

  const onAddSuccess = async (
    variant: Variant,
    storeLocation: Store,
    amount: number
  ) => {
    setShowAddModal(false);
    await createNewStoreRequest(variant, storeLocation, amount);
    setStoreRequests([]);
    setLoading(true);
    fetchStoreRequests();
  };

  const fetchStoreInventoryLocation = async (id: number) => {
    if (id === 0) return undefined;
    const locationStatus = await getItem<InventoryLocation>(
      `/inventory_locations/from_store/${id}/`
    );
    if (locationStatus.status === SUCCESS) {
      if (locationStatus.data !== undefined) {
        return locationStatus.data;
      }
    }
    return undefined;
  };

  const createNewStoreRequest = async (
    variant: Variant,
    storeLocation: Store,
    amount: number
  ) => {
    if (storeLocation.storeRequestsCredit === undefined) {
      warningAlert(
        "No se pudo obtener el valor del crédito disponible. No se puede realizar pedidos. Reinicie e intente de nuevo."
      );
      return;
    }

    const inventoryLocation = await fetchStoreInventoryLocation(
      storeLocation.id || 0
    );

    if (!inventoryLocation) {
      warningAlert(
        "No se pudo obtener el valor del crédito disponible. No se puede realizar pedidos. Reinicie e intente de nuevo."
      );
      return;
    }

    const totalValue = (variant.wholesalePrice || variant.price || 0) * amount;
    let toSendStoreRequest: StoreRequest = {
      id: undefined,
      companyId: variant.companyId,
      storeId: storeLocation.id,
      storeName: storeLocation.name,
      locationId: inventoryLocation?.id,
      clothingProductId: variant.productId,
      clothingProduct: variant.product,
      productVariantId: variant.id,
      productVariant: variant,
      productCodeIdentifier: variant.productCodeIdentifier,
      productName: variant.productName,
      variantName: variant.name,
      colorName: variant.color,
      color: variant.colorObject,
      colorId: variant.colorId,
      sizeName: variant.size,
      size: variant.sizeObject,
      sizeId: variant.sizeId,
      requestDate: new Date().toISOString(),
      requestedBy: userEmail,
      amount: amount,
      fulfilledAmount: 0,
      transferId: undefined,
      status: "pending",
      variantPrice: variant.wholesalePrice || variant.price || 0,
      totalValue: totalValue,
      fulfilledValue: 0,
    };

    const requestPromise = createItem<StoreRequest>(
      `/store_requests/create/`,
      toSendStoreRequest
    );

    const storeRequestStatus = await requestPromise;

    if (storeRequestStatus.status !== SUCCESS) {
      if (storeRequestStatus.errors !== undefined) {
        const errors = getFieldErrors(
          "total_value",
          storeRequestStatus.errors
        ) as string[];
        errors.forEach((element) => {
          errorAlert(element);
        });
      }
      return;
    }

    successAlert("Datos guardados con éxito!");
  };

  const onFilterStoreChange = (newStore: Store | null) => {
    setFilterStore(newStore !== null ? newStore : undefined);
  };

  const onStartDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setStartDate("");
      return;
    }
    setStartDate(value);
  };

  const onEndDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setEndDate("");
      return;
    }
    setEndDate(value);
  };

  const onStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRequestStatus(e.target.value);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    if (requestStatus !== null && requestStatus !== "") {
      urlParams.set("request_status", requestStatus);
    } else {
      urlParams.delete("request_status");
    }
    if (moment.isMoment(startDate)) {
      urlParams.set("start_date", startDate.toISOString());
    } else if (startDate !== undefined && startDate !== "") {
      urlParams.set("start_date", startDate);
    } else {
      urlParams.delete("start_date");
    }
    if (moment.isMoment(endDate)) {
      urlParams.set("end_date", endDate.toISOString());
    } else if (endDate !== undefined && endDate !== "") {
      urlParams.set("end_date", endDate);
    } else {
      urlParams.delete("end_date");
    }
    if (filterStore !== undefined && filterStore.id !== undefined) {
      urlParams.set("store_id", filterStore.id.toString());
    } else {
      urlParams.delete("store_id");
    }
    urlParams.set("search", search);
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchStoreRequests();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("storeRequestList")) {
        return;
      }
      fetchStoreRequests();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Pedidos de Sucursales</h3>
              </div>
              <div className="p-2 ">
                <CButton
                  color="primary"
                  className="float-right"
                  onClick={onAddClick}
                >
                  <BsFillPlusCircleFill />
                  &nbsp; Agregar Nuevo
                </CButton>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Desde:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onStartDatehange}
                    value={startDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Hasta:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onEndDatehange}
                    value={endDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Sucursal:</CLabel>
                </CCol>
                <CCol>
                  <SingleStoreSelect
                    value={filterStore ? filterStore : null}
                    onChange={onFilterStoreChange}
                  ></SingleStoreSelect>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Estado:</CLabel>
                </CCol>
                <CCol>
                  <CSelect
                    type="text"
                    onChange={onStatusChange}
                    value={requestStatus}
                  >
                    <option value={""}>-----</option>
                    <option value={"pending"}>Pendiente</option>
                    <option value={"issued"}>Atendido</option>
                  </CSelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <p>
                    <b>Referencias:</b>
                  </p>
                  <ul>
                    <li>
                      El monto mostrado en la columna valor de pedido varia
                      dependiendo del estado del pedido.
                    </li>
                    <li>
                      Si el pedido está <CBadge color="info">Pendiente</CBadge>{" "}
                      se muestra el valor del credito de tienda que se está
                      utilizando para hacer el pedido. Si el pedido ya está{" "}
                      <CBadge color="success">Atendido</CBadge> se muestra el
                      valor de la mercadería recibida.
                    </li>
                    <li>
                      En caso de haber recibido menos mercadería de la
                      solicitada, la diferencia de crédito se devuelve a la
                      sucursal para seguir usándolo. En caso de haber recibido
                      más mercadería de la solicitada se muestra el valor de la
                      misma pero el crédito se descuenta de la sucursal sólo
                      hasta el valor pedido inicialmente.
                    </li>
                  </ul>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={storeRequests}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    requestDate: (item: StoreRequest) => {
                      return (
                        <td className="text-center">
                          {item.requestDate
                            ? formatDateTime(new Date(item.requestDate))
                            : "-"}
                        </td>
                      );
                    },
                    transferId: (item: StoreRequest) => {
                      return (
                        <td className="text-center">
                          {item.transferId ? "#" : ""}
                          {defaultValueOnUndefined("-", item.transferId)}
                        </td>
                      );
                    },
                    variantPrice: (item: StoreRequest) => {
                      return (
                        <td className="text-center">
                          {item.variantPrice
                            ? formatToCurrency(item.variantPrice, PYG)
                            : "-"}
                        </td>
                      );
                    },
                    totalValue: (item: StoreRequest) => {
                      const valueToShow =
                        item.status === "issued"
                          ? item.fulfilledValue
                          : item.totalValue;
                      return (
                        <td className="text-center">
                          {valueToShow
                            ? formatToCurrency(valueToShow, PYG)
                            : "-"}
                        </td>
                      );
                    },
                    status: (item: StoreRequest) => {
                      return (
                        <td className="text-center">
                          <CBadge
                            color={
                              item.status
                                ? getStoreRequestStatusClass(item.status)
                                : undefined
                            }
                          >
                            {item.status
                              ? getStoreRequestStatusLabel(item.status)
                              : undefined}
                          </CBadge>
                        </td>
                      );
                    },
                    actions: (item: StoreRequest) => {
                      const editButton = (
                        <CButton
                          className="text-white"
                          color="warning"
                          onClick={() => {
                            onUpdateClick(item);
                          }}
                        >
                          <i className="fa fa-pencil"></i>
                        </CButton>
                      );
                      const deleteButton = (
                        <CButton
                          className="text-white"
                          color="danger"
                          onClick={() => {
                            onDeleteClick(item);
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </CButton>
                      );

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {item.status === "pending" ? editButton : <></>}
                            {item.status === "pending" ? deleteButton : "-"}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
              <CRow className={"mt-2"}>
                <CCol className={"border border-dark"}>
                  <CRow className={"mt-2"}>
                    <CCol>
                      <h5>Totales:</h5>
                    </CCol>
                  </CRow>
                  <CRow className={"mt-2"}>
                    <CCol md={2}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        TOTAL GENERAL:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {mainTotal ? formatToCurrency(mainTotal, PYG) : "-"}
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        TOTAL PENDIENTES:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {pendingTotal
                          ? formatToCurrency(pendingTotal, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        TOTAL ATENDIDOS:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {issuedTotal ? formatToCurrency(issuedTotal, PYG) : "-"}
                      </CLabel>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <ManualAddProductModal
        show={showAddModal}
        onCancel={onAddCancel}
        onSuccess={onAddSuccess}
        isRequest
      ></ManualAddProductModal>
      <StoreRequestDeleteModal
        show={showDeleteModal}
        storeRequest={storeRequest}
        onCancel={onDeleteCancel}
        onSuccess={onDeleteSuccess}
      ></StoreRequestDeleteModal>
      <StoreRequestUpdateModal
        storeRequest={storeRequest}
        show={showEditModal}
        onCancel={onEditCancel}
        onSuccess={onEditSuccess}
      ></StoreRequestUpdateModal>
    </>
  );
};

export default StoreRequestList;
