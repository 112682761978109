import {
  CBadge,
  CButton,
  CButtonGroup,
  CCard,
  CCol,
  CDataTable,
  CFormGroup,
  CListGroup,
  CListGroupItem,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";

import { Spinner } from "react-bootstrap";
import { getList } from "../../api/generics";

import { SUCCESS } from "../../utils/constants/tags";

import { RootState } from "../../store";
import { useSelector } from "react-redux";

import StoreSale, {
  StoreSaleItem,
  calculateItemIVA,
  getStoreSaleItemTaxType,
} from "../../models/store-sale";

import { formatDateTime } from "../../utils/dates";
import { defaultValueOnUndefined } from "../../utils/fields";
import { getPaymentTypeLabel } from "../../models/client";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
const lungorLogo = require("../../assets/img/lungorlogo.jpg");

interface SaleDetailProps {
  storeSale?: StoreSale;
  onCancel: () => void | Promise<void>;
}

const SaleDetail: React.FC<SaleDetailProps> = ({ storeSale, onCancel }) => {
  const fields = [
    {
      key: "amount",
      _classes: ["text-center"],
      label: "Cant. Total",
    },
    {
      key: "returnedAmount",
      _classes: ["text-center"],
      label: "Cant. Devolución",
    },
    {
      key: "name",
      _classes: ["text-center"],
      label: "Concepto",
    },
    {
      key: "taxType",
      _classes: ["text-center"],
      label: "Tipo IVA",
    },
    {
      key: "unitPrice",
      _classes: ["text-center"],
      label: "Precio unitario",
    },
    {
      key: "ivaTotal",
      _classes: ["text-center"],
      label: "IVA Total",
    },
    {
      key: "totalPrice",
      _classes: ["text-center"],
      label: "Subtotal",
    },
  ];

  const company = useSelector((state: RootState) => state.company.data.company);
  const [saleItems, setSaleItems] = useState<StoreSaleItem[]>([]);

  const [loading, setLoading] = useState(false);

  const getSaleItems = async () => {
    if (storeSale === undefined || storeSale.id === undefined) {
      setSaleItems([]);
      return;
    }
    const saleIdStr = storeSale.id.toString();
    if (saleIdStr === undefined) {
      setSaleItems([]);
      return;
    }
    const limit = 2000;
    const offset = 0;
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();
    additionalParams.set("company_id", company.id!.toString());
    additionalParams.set("store_sale_id", saleIdStr);
    setLoading(true);
    const saleItemsStatus = await getList<StoreSaleItem>(
      `/store_sales/items/`,
      limit,
      offset,
      additionalParams
    );
    if (saleItemsStatus.status === SUCCESS) {
      if (saleItemsStatus.data !== undefined) {
        setSaleItems(saleItemsStatus.data.items);
      } else {
        setSaleItems([]);
        setLoading(false);
        return;
      }
    } else {
      setSaleItems([]);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setSaleItems([]);
  };

  useEffect(() => {
    getSaleItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeSale]);

  if (loading) {
    return (
      <Spinner
        animation="grow"
        style={{
          height: "17px",
          width: "17px",
          marginTop: "auto",
          marginBottom: "auto",
          marginRight: "10px",
        }}
      />
    );
  }

  return (
    <>
      <fieldset disabled={loading}>
        <CRow className={"justify-content-center"}>
          <CCol lg="12">
            <CCard>
              <CRow>
                <CCol lg="12" className={"d-flex justify-content-around"}>
                  <div className="p-3 align-middle">
                    <img
                      src={lungorLogo}
                      alt="logo"
                      style={{ height: "100%", maxHeight: "100px" }}
                    />
                  </div>
                  <div className="p-3 d-flex align-items-center">
                    <h2>
                      Detalle de{" "}
                      {storeSale?.isReturnOperation ? "Devolución" : "Venta"}
                    </h2>
                    {storeSale?.cancelled ? (
                      <CBadge color={"danger"} className={"ml-2"}>
                        Operación Anulada
                      </CBadge>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="p-3 d-flex align-items-center">
                    <h5>
                      {storeSale?.saleDate
                        ? formatDateTime(new Date(storeSale.saleDate))
                        : ""}
                    </h5>
                  </div>
                </CCol>
              </CRow>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="12">
            <CRow>
              <CCol lg="6">
                <CListGroup>
                  <CListGroupItem>
                    {`Sucursal: ${defaultValueOnUndefined(
                      "-",
                      storeSale?.storeName
                    )}`}
                  </CListGroupItem>
                  <CListGroupItem>
                    {`Cliente: ${defaultValueOnUndefined(
                      "SIN NOMBRE",
                      storeSale?.clientName
                    )}`}
                  </CListGroupItem>
                  <CListGroupItem>
                    {`Razón Social: ${defaultValueOnUndefined(
                      "-",
                      storeSale?.clientLegalName
                    )}`}
                  </CListGroupItem>
                  <CListGroupItem>
                    {`RUC: ${defaultValueOnUndefined(
                      "-",
                      storeSale?.clientRuc
                    )}`}
                  </CListGroupItem>
                  <CListGroupItem>
                    {`Nro de documento: ${defaultValueOnUndefined(
                      "-",
                      storeSale?.clientDocument
                    )}`}
                  </CListGroupItem>
                </CListGroup>
              </CCol>
              <CCol lg="6">
                <CListGroup>
                  <CListGroupItem>
                    {`Total Venta: ${
                      storeSale?.discountedPrice
                        ? formatToCurrency(storeSale.discountedPrice, PYG)
                        : "-"
                    }`}
                  </CListGroupItem>
                  <CListGroupItem>
                    {`IVA: ${
                      storeSale?.iva
                        ? formatToCurrency(storeSale.iva, PYG)
                        : "-"
                    }`}
                  </CListGroupItem>
                  <CListGroupItem>
                    {`Nro de factura: ${
                      storeSale?.documentNumber
                        ? `${storeSale.establishment}-${storeSale.dispatchPoint}-${storeSale.documentNumber}`
                        : "-"
                    }`}
                  </CListGroupItem>
                  <CListGroupItem>
                    {`Tipo de Pago: ${
                      storeSale?.paymentType
                        ? getPaymentTypeLabel(storeSale)
                        : "-"
                    }`}
                  </CListGroupItem>
                  {storeSale?.discount !== undefined &&
                  storeSale?.discount !== 0 ? (
                    <CListGroupItem>
                      {`Descuento realizado: ${formatToCurrency(
                        storeSale.discount,
                        PYG
                      )}`}
                    </CListGroupItem>
                  ) : (
                    <></>
                  )}
                </CListGroup>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <div>
          <CDataTable
            noItemsView={<h2 className="text-center">Sin Resultados</h2>}
            addTableClasses={"table-fixed"}
            fields={fields}
            items={saleItems}
            striped
            border
            loading={loading}
            responsive
            scopedSlots={{
              returnedAmount: (item: StoreSaleItem) => {
                return (
                  <td className="text-center">
                    {item.returned ? item.returnedAmount : "-"}
                  </td>
                );
              },
              taxType: (item: StoreSaleItem) => {
                return (
                  <td className="text-center">
                    {item.taxType ? getStoreSaleItemTaxType(item) : "-"}
                  </td>
                );
              },
              name: (item: StoreSaleItem) => {
                return (
                  <td className="text-center">
                    {defaultValueOnUndefined("No encontrado", item.name)}
                  </td>
                );
              },
              unitPrice: (item: StoreSaleItem) => {
                return (
                  <td className="text-center">
                    {item.unitPrice
                      ? formatToCurrency(item.unitPrice, PYG)
                      : "-"}
                  </td>
                );
              },
              ivaTotal: (item: StoreSaleItem) => {
                return (
                  <td className="text-center">
                    {formatToCurrency(calculateItemIVA(item), PYG)}
                  </td>
                );
              },
              totalPrice: (item: StoreSaleItem) => {
                return (
                  <td className="text-center">
                    {item.totalPrice
                      ? formatToCurrency(item.totalPrice, PYG)
                      : "-"}
                  </td>
                );
              },
            }}
          />
        </div>

        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            {/*storeSale?.isReturnOperation?<></>:<CButton type="submit" color="primary" onClick={onSave}>
                {loading ? (
                  <Spinner
                    animation="grow"
                    style={{
                      height: "17px",
                      width: "17px",
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginRight: "10px",
                    }}
                  />
                ) : (
                  <></>
                )}
                {loading ? "Procesando..." : "Devolución"}
              </CButton>*/}
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default SaleDetail;
