import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { deleteItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import SaleOrder from "../../models/sale-order";

interface SaleOrderDeleteModalProps {
  show: boolean;
  saleOrder?: SaleOrder;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const SaleOrderDeleteModal: React.FC<SaleOrderDeleteModalProps> = ({
  show,
  saleOrder,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = saleOrder
    ? `Está seguro de que quiere eliminar la orden de venta: ${saleOrder.id}`
    : "";

  const onDeleteClick = async () => {
    if (saleOrder !== undefined) {
      setSubmitting(true);
      const saleOrderStatus = await deleteItem<SaleOrder>(`/sale_orders/${saleOrder.id}/`, saleOrder);

      if (saleOrderStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (saleOrderStatus.detail !== undefined) {
          message = saleOrderStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Eliminar</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onDeleteClick} color="danger">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Eliminando..." : "Eliminar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default SaleOrderDeleteModal;
