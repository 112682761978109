import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import ProductionStage from "../../models/production-stage";

const RECORS_PER_SEARCH = 20;

type ProductionStageSelectProps = {
  value: ProductionStage | null;
  onChange: (_: ProductionStage | null) => void;
  isDisabled?:boolean;
  omitSpecialStages?:boolean;
};

const ProductionStageSelect: React.FC<ProductionStageSelectProps> = ({ value, onChange, isDisabled, omitSpecialStages }) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );
  const loadOptions = async (
    s: string,
    currentOpts: readonly (ProductionStage | { options: ProductionStage[]; label?: string })[]
  ): Promise<
    Response<ProductionStage, { options: ProductionStage[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    if (omitSpecialStages!==undefined && omitSpecialStages===true){
      additionalParams.set("omit_special", "true");
    }

    const dataPaginated = await getList<ProductionStage>(
      "/production_stages/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: ProductionStage[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione una etapa"}
      isClearable={true}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadOptions}
      isDisabled={isDisabled}
      getOptionLabel={(option: ProductionStage) => `${option.name}`}
      getOptionValue={(option: ProductionStage) => `${option.id}`}
      value={value}
    />
  );
};

export default ProductionStageSelect;
