import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { deleteItem } from "../../api/generics";
import Size from "../../models/size";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";

interface SizeDeleteModalProps {
  show: boolean;
  size?: Size;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const SizeDeleteModal: React.FC<SizeDeleteModalProps> = ({
  show,
  size,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = size
    ? `Está seguro de que quiere eliminar el size: ${size.size}`
    : "";

  const onDeleteClick = async () => {
    if (size !== undefined) {
      setSubmitting(true);
      const sizeStatus = await deleteItem<Size>(`/sizes/${size.id}/`, size);

      if (sizeStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (sizeStatus.detail !== undefined) {
          message = sizeStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Eliminar</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <h3 className="text-danger">Precaución</h3>
              <h5>
                No se recomienda borrar talles. Si hay prendas con este talle
                asignado podrían crearse inconsistencias o fallas con estas
                prendas, sus ventas, envíos o o su inventario
              </h5>
              <br />
              <h5>Podrían perderse datos.</h5>
              <br />
              <h5>
                Si ya no va utilizar no es necesario borrar. Puede quedar como
                un registro.
              </h5>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onDeleteClick} size="danger">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Eliminando..." : "Eliminar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default SizeDeleteModal;
