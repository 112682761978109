import Client from "./client";
import Color from "./color";
import Product from "./product";
import Size from "./size";
import Variant from "./variant";

export interface SaleOrderFormItemSize {
  savedId?: number;
  sizeName?: string;
  size?: Size;
  sizeId?: number;
  variantId?: number;
  variant?: Variant;
  amountRequired?: number;
}

export interface SaleOrderFormItem {
  id?: number;
  companyId?: number;
  saleOrderId?: number;
  clothingProductId?: number;
  clothingProduct?: Product;
  productCodeIdentifier?: string;
  productName?: string;
  colorName?: string;
  colorId?: number;
  color?: Color;
  sizes?: SaleOrderFormItemSize[];
  unitPrice?: number;
  totalPrice?: number;
  hasCustomPrice?: boolean;
  totalCodeAmount?: number;
  skipProcessing?: boolean;
}

export interface SaleOrderItem {
  id?: number;
  companyId?: number;
  saleOrderId?: number;
  clothingProductId?: number;
  clothingProduct?: Product;
  productVariantId?: number;
  productVariant?: Variant;
  productCodeIdentifier?: string;
  productName?: string;
  colorName?: string;
  color?: Color;
  colorId?: number;
  sizeName?: string;
  size?: Size;
  sizeId?: number;
  amountRequired?: number;
  amountDelivered?: number;
  unitPrice?: number;
  totalPrice?: number;
}

interface SaleOrder {
  id?: number;
  companyId?: number;
  clientId?: number;
  client?: Client;
  clientName?: string;
  orderDate?: string;
  status?: string;
  deliveryStatus?: string;
  paymentStatus?: string;
  lastDeliveryDate?: string;
  lastPaymentDate?: string;
  totalPrice?: number;
  pendingPrice?: number;
  paidPrice?: number;
  obs?: string;
  saleOrderItems?: SaleOrderItem[];
  reportUrl?: string;
}

export const newSaleOrder = (): SaleOrder => {
  return {};
};

export const saleOrderTableFormatToAPIReceived = (
  items: SaleOrderFormItem[]
): SaleOrderItem[] => {
  const result: SaleOrderItem[] = [];

  items.forEach((item) => {
    item.sizes?.forEach((size) => {
      if (size.amountRequired !== undefined && size.amountRequired !== 0) {
        const saleOrderItem: SaleOrderItem = {
          id: size.savedId,
          companyId: item.companyId,
          saleOrderId: item.saleOrderId,
          clothingProductId: item.clothingProductId,
          clothingProduct: item.clothingProduct,
          productVariantId: size.variantId,
          productVariant: size.variant,
          productCodeIdentifier: item.productCodeIdentifier,
          productName: item.productName,
          colorName: item.colorName,
          colorId: item.colorId,
          color: item.color,
          sizeName: size.sizeName,
          size: size.size,
          sizeId: size.sizeId,
          amountRequired: size.amountRequired,
          amountDelivered: 0,
          unitPrice: item.unitPrice,
          totalPrice: item.unitPrice
            ? item.unitPrice * size.amountRequired
            : undefined,
        };
        result.push(saleOrderItem);
      }
    });
  });

  return result;
};

export const saleOrderAPIReceivedToTableFormat = (
  items: SaleOrderItem[]
): SaleOrderFormItem[] => {
  const groupedItems: Map<string, SaleOrderItem[]> = new Map();

  items.forEach((item) => {
    const key = `${item.productCodeIdentifier}-${item.colorName}`;

    if (!groupedItems.has(key)) {
      groupedItems.set(key, []);
    }

    groupedItems.get(key)?.push(item);
  });
  const result: SaleOrderFormItem[] = Array.from(groupedItems.values()).map(
    (group) => {
      const firstItem = group[0];
      let hasCustomPrice: boolean | undefined = true;

      if (firstItem.unitPrice) {
        if (firstItem.clothingProduct) {
          if (
            firstItem.clothingProduct.wholesalePrice &&
            firstItem.clothingProduct.price
          ) {
            if (
              firstItem.unitPrice ===
                firstItem.clothingProduct.wholesalePrice ||
              firstItem.unitPrice === firstItem.clothingProduct.price
            ) {
              hasCustomPrice = undefined;
            }
          }
        }
      }

      const saleOrderFormItem: SaleOrderFormItem = {
        companyId: firstItem.companyId,
        saleOrderId: firstItem.saleOrderId,
        clothingProductId: firstItem.clothingProductId,
        clothingProduct: firstItem.clothingProduct,
        productCodeIdentifier: firstItem.productCodeIdentifier,
        productName: firstItem.productName,
        colorName: firstItem.colorName,
        color: firstItem.color,
        colorId: firstItem.colorId,
        sizes: group.map((item) => ({
          savedId: item.id,
          sizeName: item.sizeName,
          size: item.size,
          sizeId: item.sizeId,
          variantId: item.productVariantId,
          variant: item.productVariant,
          amountRequired: item.amountRequired,
        })),
        unitPrice: firstItem.unitPrice,
        totalPrice: group.reduce(
          (sum, item) => sum + (item.totalPrice ?? 0),
          0
        ),
        hasCustomPrice: hasCustomPrice,
        skipProcessing: true,
      };

      return saleOrderFormItem;
    }
  );

  return result;
};

interface StatusData {
  class: string;
  name: string;
}

interface StatusesMap {
  [index: string]: StatusData;
}

export const SALEORDERSTATUSES: StatusesMap = {
  pending: {
    class: "info",
    name: "Pendiente",
  },
  ongoing: {
    class: "primary",
    name: "En curso",
  },
  completed: {
    class: "success",
    name: "Finalizado",
  },
  cancelled: {
    class: "danger",
    name: "Cancelado",
  },
};

export const getStatusLabel = (key: string) => {
  return SALEORDERSTATUSES[key].name;
};

export const getStatusClass = (key: string) => {
  return SALEORDERSTATUSES[key].class;
};

type OrderMaps = { [key: string]: string };

const SALEORDERDELIVERYSTATUSES: OrderMaps = {
  pending: "Pendiente",
  partial: "Entregado Parcialmente",
  completed: "Completado",
};

export const getDeliveryStatusLabel = (key: string) => {
  return SALEORDERDELIVERYSTATUSES[key];
};

const SALEORDERPAYMENTSTATUSES: OrderMaps = {
  pending: "Pendiente",
  partial: "Pagado Parcialmente",
  up_to_date: "Al día",
  paid: "Pagado",
};

export const getPaymentStatusLabel = (key: string) => {
  return SALEORDERPAYMENTSTATUSES[key];
};

export default SaleOrder;
