import BatchAmountLogList from "../components/batches-amount/BatchAmountLogList";

const BatchAmountLogsPage = () => {
  return (
    <>
      <BatchAmountLogList />
    </>
  );
};

export default BatchAmountLogsPage;
