import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { updateItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import Payment from "../../models/delivery-order";

interface PaymentRevertModalProps {
  show: boolean;
  payment?: Payment;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const PaymentRevertModal: React.FC<PaymentRevertModalProps> = ({
  show,
  payment,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = payment
    ? `Está seguro de que quiere revertir/anular el pago #${payment.id}, ${payment.clientName}`
    : "";

  const onSubmitClick = async () => {
    if (payment !== undefined) {
      setSubmitting(true);

      let toSendPayment: Payment = {
        ...payment,
      };
      const orderStatus = await updateItem<Payment>(
        `/payments/${payment.id}/revert/`,
        toSendPayment
      );

      if (orderStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (orderStatus.errors !== undefined) {
        }
        if (orderStatus.detail !== undefined) {
          message = orderStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-warning" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Revertir/anular Pago</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <p>
                {" "}
                Al revertir/anular el pago se actualizara el estado de cuenta
                del cliente.
              </p>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onSubmitClick} color="warning">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Revirtiendo..." : "Revertir"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default PaymentRevertModal;
