import DiscountCrud from "../components/discounts/DiscountCrud";

const DiscountCrudPage = () => {
  return (
    <>
      <DiscountCrud />
    </>
  );
};

export default DiscountCrudPage;
