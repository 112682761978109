import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { deleteItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import Category from "../../models/category";

interface CategoryDeleteModalProps {
  show: boolean;
  category?: Category;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const CategoryDeleteModal: React.FC<CategoryDeleteModalProps> = ({
  show,
  category,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = category
    ? `Está seguro de que quiere eliminar la categoria ${category.name}`
    : "";

  const onDeleteClick = async () => {
    if (category !== undefined) {
      setSubmitting(true);
      const categoryStatus = await deleteItem<Category>(
        `/categories/${category.id}/`,
        category
      );

      if (categoryStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (categoryStatus.detail !== undefined) {
          message = categoryStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Eliminar</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onDeleteClick} color="danger">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Eliminando..." : "Eliminar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default CategoryDeleteModal;
