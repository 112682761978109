import SaleOrderCrud from "../components/sale-orders/SaleOrderCrud";

const SaleOrderCrudPage = () => {
  return (
    <>
      <SaleOrderCrud />
    </>
  );
};

export default SaleOrderCrudPage;
