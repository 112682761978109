import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CSelect,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { createItem, ItemRequestStatus } from "../../api/generics";
import Client, {
  ClientPayment,
  PAYMENT_TYPE_CHOICES,
  newClientPayment,
} from "../../models/client";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert, warningAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";
import PaymentDeliverOrderSelect from "../payments/PaymentDeliverOrderSelect";
import DeliveryOrder from "../../models/delivery-order";
import ClientSelect from "../client/ClientSelect";
import BankSelect from "./BankSelect";
import Bank from "../../models/bank";
import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";

interface PaymentFormProps {
  initialClient?: Client;
  initialErrors?: Errors;
  initialPayment?: ClientPayment;
  renderContent: boolean;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  renderContent,
  initialClient,
  initialErrors,
  initialPayment,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingClientPayment, setEditingClientPayment] =
    useState<ClientPayment>(
      initialPayment ? initialPayment : newClientPayment()
    );
  const [client, setClient] = useState<Client | undefined>(undefined);
  const [deliveryOrders, setDeliveryOrders] = useState<
    readonly DeliveryOrder[]
  >([]);
  const [paymentDate, setPaymentDate] = useState<string | moment.Moment>("");
  const [checkDate, setCheckDate] = useState<string | moment.Moment>("");
  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);
  const [, setPaymentLimit] = useState<number>(0);

  const [bank, setBank] = useState<Bank | null>(null);
  const [showCheckFields, setShowCheckFields] = useState(false);
  const [markOrdersAsFinished, setMarkOrdersAsFinished] = useState(false);

  const onAmountChange = (newAmount: number | undefined) => {
    setEditingClientPayment({
      ...editingClientPayment,
      amountPaid: newAmount,
    });
  };

  const onDiscountChange = (newDiscount: number | undefined) => {
    setEditingClientPayment({
      ...editingClientPayment,
      discountInDebt: newDiscount,
    });
  };

  const onClientChange = (newClient: Client | null) => {
    setEditingClientPayment((editingItem) => {
      return {
        ...editingItem,
        clientId: newClient !== null ? newClient.id : undefined,
        clientName: newClient !== null ? newClient.name : undefined,
      };
    });
    setClient(newClient !== null ? newClient : undefined);
    setPaymentLimit(
      newClient !== null ? newClient.clientPaymentAccount?.totalPending! : 0
    );
  };

  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingClientPayment({
      ...editingClientPayment,
      obs: e.target.value,
    });
  };

  const onDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }
    setPaymentDate(value);
    if (moment.isMoment(value)) {
      setEditingClientPayment({
        ...editingClientPayment,
        date: value.toISOString(),
      });
    } else if (value !== undefined && value !== "") {
      setEditingClientPayment({
        ...editingClientPayment,
        date: value,
      });
    }
  };

  const onCheckDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }
    setCheckDate(value);
    if (moment.isMoment(value)) {
      setEditingClientPayment({
        ...editingClientPayment,
        checkDate: value.toISOString(),
      });
    } else if (value !== undefined && value !== "") {
      setEditingClientPayment({
        ...editingClientPayment,
        checkDate: value,
      });
    }
  };

  const onTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    const newEditingValue = {
      ...editingClientPayment,
      paymentType: newValue,
    };
    if (newValue !== "check") {
      newEditingValue.checkDate = undefined;
      newEditingValue.checkNumber = undefined;
      newEditingValue.bankName = undefined;

      setBank(null);
      setShowCheckFields(false);
      setCheckDate("");
    } else {
      setShowCheckFields(true);
    }

    setEditingClientPayment(newEditingValue);
  };

  const onCheckNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingClientPayment({
      ...editingClientPayment,
      checkNumber: e.target.value,
    });
  };

  const onBankChange = (stage: Bank | null) => {
    setBank(stage);
  };

  const onMarkAsFinishedChange = (newValue: boolean) => {
    setMarkOrdersAsFinished(newValue);
    setEditingClientPayment({
      ...editingClientPayment,
      markOrdersAsFinished: newValue,
    });
  };

  const onSave = async () => {
    setSubmitting(true);
    if (client === undefined) {
      setErrors({ client: ["Seleccione un cliente"] });
      warningAlert("Seleccione un cliente");
      setSubmitting(false);
      return;
    }
    const mutableOrders: DeliveryOrder[] = Array.from(deliveryOrders);
    let toSendClientPayment: ClientPayment = {
      ...editingClientPayment,
      companyId: company.id,
      clientId: client.id,
      clientName: client.name,
      clientRuc: client.ruc,
      status: "paid",
      clientAccountId: client.clientPaymentAccount?.id,
      orders: mutableOrders,
    };

    if (
      editingClientPayment.paymentType !== undefined &&
      editingClientPayment.paymentType === "check"
    ) {
      const errorMessages: Record<string, string[]> = {};

      if (bank === null) {
        errorMessages.bankName = ["Seleccione un banco"];
      }
      if (
        toSendClientPayment.checkDate === undefined ||
        toSendClientPayment.checkDate === ""
      ) {
        errorMessages.checkDate = ["Seleccione una fecha de cheque"];
      }
      if (
        toSendClientPayment.checkNumber === undefined ||
        toSendClientPayment.checkNumber === ""
      ) {
        errorMessages.checkNumber = ["Ingrese el número de cheque"];
      }

      if (Object.keys(errorMessages).length > 0) {
        setErrors(errorMessages);
        setSubmitting(false);
        return;
      }
      toSendClientPayment.bankName = bank!.name;
    } else {
      toSendClientPayment.bankName = undefined;
      toSendClientPayment.checkDate = undefined;
      toSendClientPayment.checkNumber = undefined;
    }

    let requestPromise: Promise<ItemRequestStatus<ClientPayment>>;
    requestPromise = createItem<ClientPayment>(
      "/payments/create/",
      toSendClientPayment
    );

    const clientPaymentStatus = await requestPromise;

    if (clientPaymentStatus.status !== SUCCESS) {
      if (clientPaymentStatus.errors !== undefined) {
        setErrors(clientPaymentStatus.errors);
        console.log(clientPaymentStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (clientPaymentStatus.detail !== undefined) {
        message = clientPaymentStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingClientPayment(newClientPayment());
    setDeliveryOrders([]);
    setClient(undefined);
    setPaymentDate("");
    setPaymentLimit(0);
    setBank(null);
    setShowCheckFields(false);
    setMarkOrdersAsFinished(false);
  };

  useEffect(() => {
    setEditingClientPayment(newClientPayment());
    setClient(initialClient);
    setPaymentDate("");
    setBank(null);
    setShowCheckFields(false);
    setMarkOrdersAsFinished(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialClient]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  useEffect(() => {
    if (renderContent) {
      setPaymentDate("");
      setClient(initialClient ? initialClient : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderContent]);

  if (!renderContent) {
    return <></>;
  }

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow className={"mt-2"}>
            <CCol md={3}>
              <CLabel className={"mt-2"}>Fecha:</CLabel>
            </CCol>
            <CCol>
              <Datetime
                className="pl-0"
                onChange={onDatehange}
                value={paymentDate}
                locale="es/PY"
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                closeOnSelect={true}
              ></Datetime>
              <FieldErrors
                errors={getFieldErrors("date", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>Cliente:</CLabel>
            </CCol>
            <CCol>
              <ClientSelect
                value={client ? client : null}
                onChange={onClientChange}
                disabled={initialClient !== undefined}
              ></ClientSelect>
              <FieldErrors
                errors={getFieldErrors("clientId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>

        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>Deuda Pendiente:</CLabel>
            </CCol>
            <CCol>
              <CurrencyField
                disabled={true}
                currency={PYG}
                value={
                  client?.clientPaymentAccount?.totalPending
                    ? client.clientPaymentAccount?.totalPending
                    : 0
                }
              ></CurrencyField>
              <FieldErrors
                errors={getFieldErrors("pending", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>

        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>Este pago:</CLabel>
            </CCol>
            <CCol>
              <CurrencyField
                currency={PYG}
                value={editingClientPayment.amountPaid}
                onChange={onAmountChange}
              ></CurrencyField>
              <FieldErrors
                errors={getFieldErrors("amountPaid", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>Descuento en deuda (Opcional):</CLabel>
            </CCol>
            <CCol>
              <CurrencyField
                currency={PYG}
                value={editingClientPayment.discountInDebt}
                onChange={onDiscountChange}
              ></CurrencyField>
              <FieldErrors
                errors={getFieldErrors("discountInDebt", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>Medio de pago:</CLabel>
            </CCol>
            <CCol>
              <CSelect
                type="text"
                defaultValue={
                  editingClientPayment.paymentType
                    ? editingClientPayment.paymentType
                    : ""
                }
                value={emptyValueOnUndefined(editingClientPayment.paymentType)}
                onChange={onTypeChange}
              >
                <option value={""} disabled>
                  -----
                </option>
                {Array.from(PAYMENT_TYPE_CHOICES.entries()).map((entry) => {
                  return (
                    <option key={entry[0]} value={entry[0]}>
                      {entry[1]}
                    </option>
                  );
                })}
              </CSelect>
              <FieldErrors
                errors={getFieldErrors("paymentType", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        {showCheckFields ? (
          <>
            <CFormGroup>
              <CRow>
                <CCol md={3}>
                  <CLabel>Número de cheque:</CLabel>
                </CCol>
                <CCol md={3}>
                  <CInput
                    type="text"
                    value={emptyValueOnUndefined(
                      editingClientPayment.checkNumber
                    )}
                    onChange={onCheckNumberChange}
                    placeholder="Número de cheque"
                  ></CInput>
                  <FieldErrors
                    errors={getFieldErrors("checkNumber", errors) as string[]}
                  ></FieldErrors>
                </CCol>
                <CCol md={3}>
                  <CLabel>Fecha de cheque:</CLabel>
                </CCol>
                <CCol md={3}>
                  <Datetime
                    className="pl-0"
                    onChange={onCheckDatehange}
                    value={checkDate}
                    locale="es/PY"
                    utc
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                  <FieldErrors
                    errors={getFieldErrors("checkDate", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={3}>
                  <CLabel>Banco:</CLabel>
                </CCol>
                <CCol>
                  <BankSelect value={bank} onChange={onBankChange}></BankSelect>
                  <FieldErrors
                    errors={getFieldErrors("bankName", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
          </>
        ) : (
          <></>
        )}
        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>Observación:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingClientPayment.obs)}
                onChange={onObsChange}
                placeholder="Observación"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("obs", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>{"Vincular con Orden (Opcional):"}</CLabel>
            </CCol>
            <CCol>
              <PaymentDeliverOrderSelect
                clientId={client?.id!}
                value={deliveryOrders}
                onChange={setDeliveryOrders}
                key={client?.id ? client?.id : 0}
              ></PaymentDeliverOrderSelect>
              <FieldErrors
                errors={getFieldErrors("orders", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Marcar órdenes selccionadas como finalizadas:</CLabel>
            </CCol>
            <CCol md={2}>
              <CRow>
                <AnimatedCheckbox
                  value={markOrdersAsFinished}
                  onChange={onMarkAsFinishedChange}
                ></AnimatedCheckbox>
              </CRow>
            </CCol>
          </CRow>
        </CFormGroup>

        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default PaymentForm;
