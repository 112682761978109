import Color from "../models/color";
import Product from "../models/product";
import Size from "../models/size";
import Variant, { VariantTableEntry } from "../models/variant";

export interface BarcodeFormItemSize {
  savedId?: number;
  sizeName?: string;
  size?: Size;
  sizeId?: number;
  variantId?: number;
  variant?: Variant;
  amount?: number;
}

export interface BarcodeFormItem {
  id?: number;
  companyId?: number;
  inventoryLocationTransferId?: number;
  clothingProductId?: number;
  clothingProduct?: Product;
  productCodeIdentifier?: string;
  productName?: string;
  colorName?: string;
  colorId?: number;
  color?: Color;
  sizes?: BarcodeFormItemSize[];
}

export interface BarcodeData {
  code: string;
  quantity: number;
  label?: string;
}

export const convertToBarcodeData = (
  inputItems: BarcodeFormItem[],
  alphanumeric: boolean
): BarcodeData[] => {
  const outputItems: BarcodeData[] = [];

  inputItems.forEach((item) => {
    if (item.sizes) {
      item.sizes.forEach((size) => {
        if (
          size.variant &&
          size.amount !== undefined &&
          size.variant.barcode &&
          size.variant.barcode2
        ) {
          const barcodeData: BarcodeData = {
            code: alphanumeric ? size.variant.barcode2 : size.variant.barcode,
            quantity: size.amount,
            label: `${size.variant.color} - ${size.variant.size} - ${size.variant.productName}`,
          };
          outputItems.push(barcodeData);
        }
      });
    }
  });

  return outputItems;
};

export const convertToBarcodeDataFromProduction = (
  inputItems: VariantTableEntry[]
): BarcodeData[] => {
  const outputItems: BarcodeData[] = [];

  inputItems.forEach((item) => {
    if (item.sizes) {
      item.sizes.forEach((size) => {
        if (size.amount !== undefined && size.barcodeBatch) {
          const barcodeData: BarcodeData = {
            code: size.barcodeBatch,
            quantity: size.amount,
            label: `${item.colorName} - ${size.sizeName} - ${item.productName}`,
          };
          outputItems.push(barcodeData);
        }
      });
    }
  });

  return outputItems;
};
