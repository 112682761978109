import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
  CBadge,
  CSelect,
  CTooltip,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getItem, getList } from "../../api/generics";
import ProductionOrder, {
  PRODUCTIONSTATUSES,
  getStatusClass,
  getStatusLabel,
} from "../../models/production-order";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, successAlert, warningAlert } from "../utils/messages";
import ProductionOrderDeleteModal from "./ProductionDeleteModal";
import ProductionOrderUpdateModal from "./ProductionUpdateModal";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
import { DATEDDMMYYY, formatDate } from "../../utils/dates";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import {
  orderCanAdvance,
  orderCanEnd,
  orderHasFinishedProducts,
  orderIsEditable,
} from "../../utils/production-orders";
import ProductionOrderStartModal from "./ProductionStartModal";
import ProductionOrderEndModal from "./ProductionEndModal";
import ProductionOrderStageModal from "./ProductionStageModal";
import ProductionSizesModal from "./ProductionSizesModal";
import ProductionColorsModal from "./ProductionColorsModal";
import { getObjectMinioUrl } from "../../minio/urls";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import {
  PRODUCTION_CREATE,
  PRODUCTION_DESTROY,
  PRODUCTION_RETRIEVE,
  PRODUCTION_UPDATE,
} from "../../auth/permissions";
import ProductionPartialEndModal from "./ProductionPartialEndModal";
import ProductionPartialFailedModal from "./ProductionPartialFailedModal";
import ProductionBarcodeModal from "./ProductionBarcodeModal";

const ITEMS_PER_PAGE = 10;

const ProductionOrderList = () => {
  const fields = [
    {
      key: "id",
      _classes: ["text-center"],
      label: "N° de Orden",
    },
    {
      key: "productCodeIdentifier",
      _classes: ["text-center"],
      label: "Prenda",
    },
    {
      key: "amountProduced",
      _classes: ["text-center"],
      label: "Cantidad Total",
    },
    {
      key: "orderStatus",
      _classes: ["text-center"],
      label: "Estado",
    },
    {
      key: "cutOrConfectionAmount",
      _classes: ["text-center"],
      label: "En corte/confección",
    },
    {
      key: "laundryAmount",
      _classes: ["text-center"],
      label: "En lavanderia",
    },
    {
      key: "backFromLaundryAmount",
      _classes: ["text-center"],
      label: "Retornó de lavandería",
    },
    {
      key: "finishedAmount",
      _classes: ["text-center"],
      label: "Finalizados + Fallados",
    },
    {
      key: "startDate",
      _classes: ["text-center"],
      label: "Fecha Inicio",
    },
    {
      key: "inStageFrom",
      _classes: ["text-center"],
      label: "Fecha Fin",
    },
    {
      key: "totalCostPrice",
      _classes: ["text-center"],
      label: "Costo Total Unitario",
    },
    {
      key: "productReferencePrice",
      _classes: ["text-center"],
      label: "Precio de referencia",
    },
    {
      key: "expectedProfit",
      _classes: ["text-center"],
      label: "Ganancia",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showProductionOrderEditModal, setShowProductionOrderEditModal] =
    useState(false);
  const [showProductionOrderDeleteModal, setShowProductionOrderDeleteModal] =
    useState(false);
  const [showProductionOrderStartModal, setShowProductionOrderStartModal] =
    useState(false);
  const [showProductionOrderFinishModal, setShowProductionOrderFinishModal] =
    useState(false);
  const [showAddToInventoryModal, setShowAddToInventoryModal] = useState(false);
  const [showAddToFailModal, setShowAddToFailModal] = useState(false);
  const [showBarcodeModal, setShowBarcodeModal] = useState(false);
  const [showProductionOrderStageModal, setShowProductionOrderStageModal] =
    useState(false);
  const [showProductionSizesModal, setShowProductionSizesModal] =
    useState(false);
  const [showProductionColorsModal, setShowProductionColorsModal] =
    useState(false);

  const [productionOrder, setProductionOrder] = useState<
    ProductionOrder | undefined
  >(undefined);
  const [search, setSearch] = useState<string>("");
  const [startDate, setStartDate] = useState<string | moment.Moment>("");
  const [endDate, setEndDate] = useState<string | moment.Moment>("");
  const [status, setStatus] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [productionOrders, setProductionOrders] = useState<ProductionOrder[]>(
    []
  );
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let orderStatus = urlParams.get("order_status")
      ? urlParams.get("order_status")
      : "";
    let orderStage = urlParams.get("order_stage")
      ? urlParams.get("order_stage")
      : "";
    let startDate = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : "";
    let endDate = urlParams.get("end_date") ? urlParams.get("end_date") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    if (orderStatus !== "") map.set("order_status", orderStatus);
    if (orderStage !== "") map.set("order_stage", orderStage);
    if (startDate !== "") map.set("start_date", startDate);
    if (endDate !== "") map.set("end_date", endDate);
    return map;
  };

  const fetchProductionOrders = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const requestStatus = await getList<ProductionOrder>(
      "/production/",
      limit,
      offset,
      urlParams
    );
    if (requestStatus.status === SUCCESS) {
      if (requestStatus.data !== undefined) {
        const count = requestStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setProductionOrders(requestStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = requestStatus.detail
        ? requestStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    if (previousParams.get("order_stage")) {
      urlParams.set("order_stage", previousParams.get("order_stage"));
    }
    if (previousParams.get("order_status")) {
      urlParams.set("order_status", previousParams.get("order_status"));
    }
    if (previousParams.get("start_date")) {
      urlParams.set("start_date", previousParams.get("start_date"));
    }
    if (previousParams.get("end_date")) {
      urlParams.set("end_date", previousParams.get("end_date"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setProductionOrder(undefined);
    setShowProductionOrderEditModal(true);
  };

  const onUpdateClick = (productionOrder: ProductionOrder) => {
    setProductionOrder(productionOrder);
    setShowProductionOrderEditModal(true);
  };

  const onDeleteClick = (productionOrder: ProductionOrder) => {
    setProductionOrder(productionOrder);
    setShowProductionOrderDeleteModal(true);
  };

  const onStartClick = (productionOrder: ProductionOrder) => {
    setProductionOrder(productionOrder);
    setShowProductionOrderStartModal(true);
  };

  const onFinishClick = (productionOrder: ProductionOrder) => {
    setProductionOrder(productionOrder);
    setShowProductionOrderFinishModal(true);
  };

  const onAddToInventoryClick = (productionOrder: ProductionOrder) => {
    setProductionOrder(productionOrder);
    setShowAddToInventoryModal(true);
  };

  const onAddToFailClick = (productionOrder: ProductionOrder) => {
    setProductionOrder(productionOrder);
    setShowAddToFailModal(true);
  };

  const onBarcodeClick = (productionOrder: ProductionOrder) => {
    setProductionOrder(productionOrder);
    setShowBarcodeModal(true);
  };

  const onStageClick = (productionOrder: ProductionOrder) => {
    setProductionOrder(productionOrder);
    setShowProductionOrderStageModal(true);
  };

  const onSizesClick = (productionOrder: ProductionOrder) => {
    setProductionOrder(productionOrder);
    setShowProductionSizesModal(true);
  };

  const onColorssClick = (productionOrder: ProductionOrder) => {
    setProductionOrder(productionOrder);
    setShowProductionColorsModal(true);
  };

  const onProductionOrderEditCancel = () => {
    setProductionOrder(undefined);
    setShowProductionOrderEditModal(false);
  };

  const onProductionOrderEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowProductionOrderEditModal(false);
    setProductionOrders([]);
    setLoading(true);
    fetchProductionOrders();
  };

  const onProductionOrderDeleteCancel = () => {
    setProductionOrder(undefined);
    setShowProductionOrderDeleteModal(false);
  };

  const onProductionOrderStartCancel = () => {
    setProductionOrder(undefined);
    setShowProductionOrderStartModal(false);
  };

  const onProductionOrderFinishCancel = () => {
    setProductionOrder(undefined);
    setShowProductionOrderFinishModal(false);
  };

  const onAddToInventoryCancel = () => {
    setProductionOrder(undefined);
    setShowAddToInventoryModal(false);
  };

  const onAddToFailCancel = () => {
    setProductionOrder(undefined);
    setShowAddToFailModal(false);
  };

  const onBarcodeCancel = () => {
    setProductionOrder(undefined);
    setShowBarcodeModal(false);
  };

  const onProductionOrderStageCancel = () => {
    setProductionOrder(undefined);
    setShowProductionOrderStageModal(false);
  };

  const onProductionSizesCancel = () => {
    setProductionOrder(undefined);
    setShowProductionSizesModal(false);
  };

  const onProductionColorsCancel = () => {
    setProductionOrder(undefined);
    setShowProductionColorsModal(false);
  };

  const onProductionOrderDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowProductionOrderDeleteModal(false);
    setProductionOrders([]);
    setLoading(true);
    fetchProductionOrders();
  };

  const onProductionOrderStartSuccess = () => {
    successAlert("Produccion iniciada con éxito!");
    setShowProductionOrderStartModal(false);
    setProductionOrders([]);
    setLoading(true);
    fetchProductionOrders();
  };

  const onProductionOrderFinishSuccess = () => {
    successAlert("Produccion Finalizada con éxito!");
    setShowProductionOrderFinishModal(false);
    setProductionOrders([]);
    setLoading(true);
    fetchProductionOrders();
  };

  const onAddToInventorySuccess = () => {
    successAlert("Productos añadidos con éxito!");
    setShowAddToInventoryModal(false);
    setProductionOrders([]);
    setLoading(true);
    fetchProductionOrders();
  };

  const onAddToFailSuccess = () => {
    successAlert("Productos marcados como fallados!");
    setShowAddToFailModal(false);
    setProductionOrders([]);
    setLoading(true);
    fetchProductionOrders();
  };

  const onBarcodeSuccess = () => {
    successAlert("Productos marcados como fallados!");
    setShowBarcodeModal(false);
    setProductionOrders([]);
    setLoading(true);
    fetchProductionOrders();
  };

  const onProductionOrderStageSuccess = () => {
    successAlert("Etapa de produccion cambiada con éxito!");
    setShowProductionOrderStageModal(false);
    setProductionOrders([]);
    setLoading(true);
    fetchProductionOrders();
  };

  const onProductionSizesSuccess = () => {
    successAlert("Etapa de corte finalizada con éxito!");
    setShowProductionSizesModal(false);
    setProductionOrders([]);
    setLoading(true);
    fetchProductionOrders();
  };

  const onProductionColorsSuccess = () => {
    successAlert("Etapa de corte finalizada con éxito!");
    setShowProductionColorsModal(false);
    setProductionOrders([]);
    setLoading(true);
    fetchProductionOrders();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onStartDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setStartDate("");
      return;
    }
    setStartDate(value);
  };

  const onEndDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setEndDate("");
      return;
    }
    setEndDate(value);
  };

  const onStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    if (search !== "") {
      urlParams.set("search", search);
    } else {
      urlParams.delete("search");
    }
    if (status !== "") {
      urlParams.set("order_status", status);
    } else {
      urlParams.delete("order_status");
    }
    if (moment.isMoment(startDate)) {
      urlParams.set("start_date", startDate.toISOString());
    } else if (startDate !== undefined && startDate !== "") {
      urlParams.set("start_date", startDate);
    } else {
      urlParams.delete("start_date");
    }
    if (moment.isMoment(endDate)) {
      urlParams.set("end_date", endDate.toISOString());
    } else if (endDate !== undefined && endDate !== "") {
      urlParams.set("end_date", endDate);
    } else {
      urlParams.delete("end_date");
    }
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const onPDFClick = async (order: ProductionOrder) => {
    const pdfState = await getItem<{ url: string }>(
      `/production/${order.id}/pdf/`
    );

    if (pdfState.status !== SUCCESS || pdfState.data === undefined) {
      errorAlert("Error al tratar de obtener el PDF");
      return;
    }
    window.open(getObjectMinioUrl(pdfState.data.url), "_blank");
  };

  useEffect(() => {
    fetchProductionOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("production")) {
        return;
      }
      fetchProductionOrders();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Producción de Prendas - Procesos de producción</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent permissionName={PRODUCTION_CREATE}>
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow>
                <CCol></CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Desde:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onStartDatehange}
                    value={startDate}
                    locale="es/PY"
                    utc
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Hasta:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onEndDatehange}
                    value={endDate}
                    locale="es/PY"
                    utc
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Estado:</CLabel>
                </CCol>
                <CCol md={4}>
                  <CSelect
                    type="text"
                    defaultValue={status !== null ? status : ""}
                    onChange={onStatusChange}
                  >
                    <option value={""}>-----</option>
                    {Object.entries(PRODUCTIONSTATUSES).map(
                      ([key, entry], ix) => {
                        return (
                          <option key={ix} value={key}>
                            {entry.name}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                </CCol>
              </CRow>
              <CRow className={"mb-4"}>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda (código de prenda, nombre, color o talle)"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Filtrar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>

              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={productionOrders}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    id: (item: ProductionOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.id ? `#${item.id}` : "-"}
                          </span>
                        </td>
                      );
                    },
                    actions: (item: ProductionOrder) => {
                      const startButton = (
                        <PermissionRequiredComponent
                          permissionName={PRODUCTION_UPDATE}
                        >
                          <CTooltip content={"Iniciar Producción"}>
                            <CButton
                              className="text-white"
                              color="primary"
                              onClick={() => {
                                onStartClick(item);
                              }}
                            >
                              <i className="fa fa-arrow-right"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const endButton = (
                        <PermissionRequiredComponent
                          permissionName={PRODUCTION_UPDATE}
                        >
                          <CTooltip content={"Finalizar Producción"}>
                            <CButton
                              className="text-white"
                              color="primary"
                              onClick={() => {
                                onFinishClick(item);
                              }}
                            >
                              <i className="fa fa-flag-checkered"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const invButton = (
                        <PermissionRequiredComponent
                          permissionName={PRODUCTION_UPDATE}
                        >
                          <CTooltip
                            content={"Finalizacion parcial. Mover a inventario"}
                          >
                            <CButton
                              className="text-white"
                              color="success"
                              onClick={() => {
                                onAddToInventoryClick(item);
                              }}
                            >
                              <i className="fa fa-truck-ramp-box"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const failButton = (
                        <PermissionRequiredComponent
                          permissionName={PRODUCTION_UPDATE}
                        >
                          <CTooltip
                            content={
                              "Finalizacion fallada. Marcar items como fallados"
                            }
                          >
                            <CButton
                              className="text-white"
                              color="danger"
                              onClick={() => {
                                onAddToFailClick(item);
                              }}
                            >
                              <i className="fa fa-truck-ramp-box"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const barcodeButton = (
                        <PermissionRequiredComponent
                          permissionName={PRODUCTION_UPDATE}
                        >
                          <CTooltip
                            content={"Imprimir códigos de barra de Lote"}
                          >
                            <CButton
                              className="text-white"
                              color="primary"
                              onClick={() => {
                                onBarcodeClick(item);
                              }}
                            >
                              <i className="fa fa-barcode"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const changeStageButton = (
                        <PermissionRequiredComponent
                          permissionName={PRODUCTION_UPDATE}
                        >
                          <CTooltip content={"Mover Cantidades entre etapas"}>
                            <CButton
                              className="text-white"
                              color="info"
                              onClick={() => {
                                onStageClick(item);
                              }}
                            >
                              <i className="fa fa-person-walking-arrow-right"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const cutButton = (
                        <PermissionRequiredComponent
                          permissionName={PRODUCTION_UPDATE}
                        >
                          <CTooltip content={"Formulario de corte"}>
                            <CButton
                              className="text-white"
                              color="info"
                              onClick={() => {
                                onSizesClick(item);
                              }}
                            >
                              <i className="fa fa-scissors"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const confectionButton = (
                        <PermissionRequiredComponent
                          permissionName={PRODUCTION_UPDATE}
                        >
                          <CTooltip content={"Formulario de confección"}>
                            <CButton
                              className="text-white"
                              color="info"
                              onClick={() => {
                                onColorssClick(item);
                              }}
                            >
                              <i className="fa fa-ruler"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      let advanceButton;
                      if (
                        item.currentStageIsCut !== undefined &&
                        item.currentStageIsCut === true
                      ) {
                        advanceButton = cutButton;
                      } else if (
                        item.currentStageIsConfection !== undefined &&
                        item.currentStageIsConfection === true
                      ) {
                        advanceButton = confectionButton;
                      } else {
                        advanceButton = changeStageButton;
                      }
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={PRODUCTION_UPDATE}
                        >
                          <CTooltip content={"Editar"}>
                            <CButton
                              className="text-white"
                              color="warning"
                              onClick={() => {
                                onUpdateClick(item);
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const detailButton = (
                        <PermissionRequiredComponent
                          permissionName={PRODUCTION_RETRIEVE}
                        >
                          <CTooltip content={"Ver detalles"}>
                            <CButton
                              className="text-white"
                              color="warning"
                              onClick={() => {
                                onUpdateClick(item);
                              }}
                            >
                              <i className="fa fa-eye"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const deleteButton = (
                        <PermissionRequiredComponent
                          permissionName={PRODUCTION_DESTROY}
                        >
                          <CTooltip content={"Eliminar"}>
                            <CButton
                              className="text-white"
                              color="danger"
                              onClick={() => {
                                onDeleteClick(item);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );

                      let pdfButton = null;
                      if (item.reportUrl !== undefined) {
                        pdfButton = (
                          <PermissionRequiredComponent
                            permissionName={PRODUCTION_RETRIEVE}
                          >
                            <CTooltip content="PDF">
                              <CButton
                                className="text-white"
                                color="danger"
                                onClick={() => {
                                  onPDFClick(item);
                                }}
                              >
                                <i className="fa fa-file-pdf"></i>
                              </CButton>
                            </CTooltip>
                          </PermissionRequiredComponent>
                        );
                      }

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {pdfButton}
                            {orderIsEditable(item) ? editButton : detailButton}
                            {orderIsEditable(item) ? startButton : <></>}
                            {orderHasFinishedProducts(item) ? (
                              barcodeButton
                            ) : (
                              <></>
                            )}
                            {orderCanAdvance(item) ? advanceButton : <></>}
                            {orderCanEnd(item) ? invButton : <></>}
                            {orderCanEnd(item) ? failButton : <></>}
                            {orderCanEnd(item) ? endButton : <></>}
                            {orderIsEditable(item) ? deleteButton : <></>}
                          </CButtonGroup>
                        </td>
                      );
                    },
                    orderStatus: (item: ProductionOrder) => {
                      return (
                        <td className="text-center">
                          <CBadge
                            color={
                              item.orderStatus
                                ? getStatusClass(item.orderStatus)
                                : undefined
                            }
                          >
                            {item.orderStatus
                              ? getStatusLabel(item.orderStatus)
                              : undefined}
                          </CBadge>
                        </td>
                      );
                    },
                    currentStage: (item: ProductionOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.currentStage ? item.currentStage : "-"}
                          </span>
                        </td>
                      );
                    },
                    cutOrConfectionAmount: (item: ProductionOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.cutOrConfectionAmount
                              ? item.cutOrConfectionAmount
                              : "-"}
                          </span>
                        </td>
                      );
                    },
                    laundryAmount: (item: ProductionOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.laundryAmount ? item.laundryAmount : "-"}
                          </span>
                        </td>
                      );
                    },
                    backFromLaundryAmount: (item: ProductionOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.backFromLaundryAmount
                              ? item.backFromLaundryAmount
                              : "-"}
                          </span>
                        </td>
                      );
                    },
                    finishedAmount: (item: ProductionOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.finishedAmount ? item.finishedAmount : "-"}
                          </span>
                        </td>
                      );
                    },
                    stagePending: (item: ProductionOrder) => {
                      const pending = item.stageAmountPending
                        ? item.stageAmountPending
                        : "-";
                      const total = item.stageAmountTotal
                        ? item.stageAmountTotal
                        : "-";
                      return (
                        <td className="text-center">
                          <span className="">{`${pending}/${total}`}</span>
                        </td>
                      );
                    },
                    amountProduced: (item: ProductionOrder) => {
                      const total = item.amountProduced
                        ? item.amountProduced
                        : "-";
                      const realProduced = item.amountLost
                        ? item.amountProduced! - item.amountLost
                        : item.amountProduced!;
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.orderStatus !== "finished"
                              ? total
                              : `${realProduced}/${total}`}
                          </span>
                        </td>
                      );
                    },
                    totalCostPrice: (item: ProductionOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.totalCostPrice
                              ? formatToCurrency(item.totalCostPrice, PYG)
                              : ""}
                          </span>
                        </td>
                      );
                    },
                    expectedProfit: (item: ProductionOrder) => {
                      const expectedProfit = item.expectedProfit
                        ? formatToCurrency(item.expectedProfit, PYG)
                        : "";
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.expectedProfit ? expectedProfit : "-"}
                          </span>
                        </td>
                      );
                    },
                    productReferencePrice: (item: ProductionOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.productReferencePrice
                              ? formatToCurrency(
                                  item.productReferencePrice,
                                  PYG
                                )
                              : ""}
                          </span>
                        </td>
                      );
                    },
                    startDate: (item: ProductionOrder) => {
                      return (
                        <td className="text-center">
                          {item.startDate
                            ? formatDate(new Date(item.startDate), DATEDDMMYYY)
                            : "-"}
                        </td>
                      );
                    },
                    inStageFrom: (item: ProductionOrder) => {
                      return (
                        <td className="text-center">
                          {item.endDate
                            ? formatDate(new Date(item.endDate), DATEDDMMYYY)
                            : "-"}
                        </td>
                      );
                    },
                    productCodeIdentifier: (item: ProductionOrder) => {
                      return (
                        <td className="text-center">
                          {item.productCodeIdentifier
                            ? `${item.productCodeIdentifier} ${item.productName}`
                            : "-"}
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <ProductionOrderUpdateModal
        show={showProductionOrderEditModal}
        onCancel={onProductionOrderEditCancel}
        onSuccess={onProductionOrderEditSuccess}
        productionOrder={productionOrder}
      />
      <ProductionOrderDeleteModal
        show={showProductionOrderDeleteModal}
        onCancel={onProductionOrderDeleteCancel}
        onSuccess={onProductionOrderDeleteSuccess}
        productionOrder={productionOrder}
      />
      <ProductionOrderStartModal
        show={showProductionOrderStartModal}
        onCancel={onProductionOrderStartCancel}
        onSuccess={onProductionOrderStartSuccess}
        productionOrder={productionOrder}
      />
      <ProductionOrderEndModal
        show={showProductionOrderFinishModal}
        onCancel={onProductionOrderFinishCancel}
        onSuccess={onProductionOrderFinishSuccess}
        productionOrder={productionOrder}
      />
      <ProductionPartialEndModal
        show={showAddToInventoryModal}
        onCancel={onAddToInventoryCancel}
        onSuccess={onAddToInventorySuccess}
        productionOrder={productionOrder}
      />
      <ProductionPartialFailedModal
        show={showAddToFailModal}
        onCancel={onAddToFailCancel}
        onSuccess={onAddToFailSuccess}
        productionOrder={productionOrder}
      />
      <ProductionBarcodeModal
        show={showBarcodeModal}
        onCancel={onBarcodeCancel}
        onSuccess={onBarcodeSuccess}
        productionOrder={productionOrder}
      />
      <ProductionOrderStageModal
        show={showProductionOrderStageModal}
        onCancel={onProductionOrderStageCancel}
        onSuccess={onProductionOrderStageSuccess}
        productionOrder={productionOrder}
      />
      <ProductionSizesModal
        show={showProductionSizesModal}
        onCancel={onProductionSizesCancel}
        onSuccess={onProductionSizesSuccess}
        productionOrder={productionOrder}
      />
      <ProductionColorsModal
        show={showProductionColorsModal}
        onCancel={onProductionColorsCancel}
        onSuccess={onProductionColorsSuccess}
        productionOrder={productionOrder}
      />
    </>
  );
};

export default ProductionOrderList;
