import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
  CSelect,
  CTooltip,
  CBadge,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getItem, getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import { formatDateTime } from "../../utils/dates";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import CashRegisterSelect from "../cash-register/CashRegisterSelect";
import CashRegisterOperation from "../../models/cash-register-operation";
import StoreSale, {
  STORESALETYPES,
  getStoreSaleOperationType,
} from "../../models/store-sale";
import { getPaymentTypeLabel } from "../../models/client";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
import { defaultValueOnUndefined } from "../../utils/fields";
import SaleDetailModal from "./SaleDetailModal";
import generateSalesReportPDF from "../../utils/storeSalesPdfGenerator";
import SingleStoreSelect from "../store/SingleStoreSelect";
import Store from "../../models/store";
import SaleNullifyModal from "./SaleNullifyModal";

const ITEMS_PER_PAGE = 100;
const ITEMS_PER_PAGE_FOR_REPORT = -1; // Basically No limit

const SaleOrderList = () => {
  const fields = [
    {
      key: "documentNumber",
      _classes: ["text-center"],
      label: "Nro de factura",
    },
    {
      key: "orderDate",
      _classes: ["text-center"],
      label: "Fecha",
    },
    {
      key: "storeName",
      _classes: ["text-center"],
      label: "Sucursal",
    },
    {
      key: "clientName",
      _classes: ["text-center"],
      label: "Cliente",
    },
    {
      key: "clientRuc",
      _classes: ["text-center"],
      label: "RUC",
    },
    {
      key: "isReturnOperation",
      _classes: ["text-center"],
      label: "Tipo",
    },
    {
      key: "cancelled",
      _classes: ["text-center"],
      label: "Anulado",
    },
    {
      key: "paymentType",
      _classes: ["text-center"],
      label: "Forma de pago",
    },
    {
      key: "noTaxed",
      _classes: ["text-center"],
      label: "Exentas",
    },
    {
      key: "taxed",
      _classes: ["text-center"],
      label: "Gravadas",
    },
    {
      key: "discountedPrice",
      _classes: ["text-center"],
      label: "Venta total",
    },
    {
      key: "iva",
      _classes: ["text-center"],
      label: "IVA",
    },
    {
      key: "saleProfit",
      _classes: ["text-center"],
      label: "Ganancia",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];
  const [filterCashRegisterOperation, setFilterCashRegisterOperation] =
    useState<CashRegisterOperation | undefined>(undefined);

  const [filterStore, setFilterStore] = useState<Store | undefined>(undefined);

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showNullifyModal, setShowNullifyModal] = useState(false);

  const [startDate, setStartDate] = useState<string | moment.Moment>("");
  const [endDate, setEndDate] = useState<string | moment.Moment>("");
  const [filterType, setFilterType] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [storeSale, setStoreSale] = useState<StoreSale | undefined>(undefined);
  const [storeSales, setStoreSales] = useState<StoreSale[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const [enableReportButton, setEnableReportButton] = useState(false);
  const [storeSalesReport, setStoreSalesReport] = useState<StoreSale[]>([]);

  const [pageTotalPrice, setPageTotalPrice] = useState<number | undefined>(0);
  const [pageTotalProfit, setPageTotalProfit] = useState<number | undefined>(0);
  const [pageTotalCost, setPageTotalCost] = useState<number | undefined>(0);
  const [queryTotalPrice, setQueryTotalPrice] = useState<number | undefined>(
    undefined
  );
  const [queryTotalProfit, setQueryTotalProfit] = useState<number | undefined>(
    undefined
  );
  const [queryTotalCost, setQueryTotalCost] = useState<number | undefined>(
    undefined
  );

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let isReturnal = urlParams.get("is_returnal")
      ? urlParams.get("is_returnal")
      : "";
    let cashRegisterOperationId = urlParams.get("cash_register_operation_id")
      ? urlParams.get("cash_register_operation_id")
      : "";
    let storeId = urlParams.get("store_id") ? urlParams.get("store_id") : "";
    let startDate = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : "";
    let endDate = urlParams.get("end_date") ? urlParams.get("end_date") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    if (isReturnal !== "") map.set("is_returnal", isReturnal);
    if (startDate !== "") map.set("start_date", startDate);
    if (endDate !== "") map.set("end_date", endDate);
    if (cashRegisterOperationId !== "")
      map.set("cash_register_operation_id", cashRegisterOperationId);
    if (storeId !== "") map.set("store_id", storeId);
    return map;
  };

  const fetchStoreSales = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const storeSalesStatus = await getList<StoreSale>(
      "/store_sales/",
      limit,
      offset,
      urlParams
    );
    if (storeSalesStatus.status === SUCCESS) {
      if (storeSalesStatus.data !== undefined) {
        const count = storeSalesStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setPageTotalPrice(calculatePageTotal(storeSalesStatus.data.items));
        setPageTotalProfit(
          calculatePageTotalProfit(storeSalesStatus.data.items)
        );
        setPageTotalCost(calculatePageTotalCost(storeSalesStatus.data.items));
        setStoreSales(storeSalesStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = storeSalesStatus.detail
        ? storeSalesStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const calculatePageTotal = (sales: StoreSale[]): number => {
    const total = sales
      .filter((sale) => !sale.cancelled)
      .reduce((acc, sale) => {
        const price = sale.discountedPrice ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const calculatePageTotalProfit = (sales: StoreSale[]): number => {
    const total = sales
      .filter((sale) => !sale.cancelled)
      .reduce((acc, sale) => {
        const price = sale.saleProfit ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const calculatePageTotalCost = (sales: StoreSale[]): number => {
    const total = sales
      .filter((sale) => !sale.cancelled)
      .reduce((acc, sale) => {
        const price = sale.saleProductCosts ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const fetchTotals = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    urlParams.set("limit", limit);
    urlParams.set("offset", offset);
    const totalsStatus = await getItem<{
      fullCost?: number;
      fullTotal?: number;
      fullProfit?: number;
    }>("/store_sales/totals/", urlParams);
    if (totalsStatus.status === SUCCESS) {
      if (totalsStatus.data !== undefined) {
        setQueryTotalPrice(totalsStatus.data.fullTotal);
        setQueryTotalProfit(totalsStatus.data.fullProfit);
        setQueryTotalCost(totalsStatus.data.fullCost);
      }
    } else {
      const message = totalsStatus.detail
        ? totalsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const fetchStoreSalesReport = async () => {
    setEnableReportButton(false);
    const urlParams = getURLParams();
    urlParams.set("descending", "false");
    const limit = ITEMS_PER_PAGE_FOR_REPORT;
    const offset = 0;
    const storeSalesStatus = await getList<StoreSale>(
      "/store_sales/",
      limit,
      offset,
      urlParams
    );
    if (storeSalesStatus.status === SUCCESS) {
      if (storeSalesStatus.data !== undefined) {
        setStoreSalesReport(storeSalesStatus.data.items);
        setEnableReportButton(true);
      }
    } else {
      setEnableReportButton(false);
    }
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    if (previousParams.get("is_returnal")) {
      urlParams.set("is_returnal", previousParams.get("is_returnal"));
    }
    if (previousParams.get("cash_register_operation_id")) {
      urlParams.set(
        "cash_register_operation_id",
        previousParams.get("cash_register_operation_id")
      );
    }
    if (previousParams.get("store_id")) {
      urlParams.set("store_id", previousParams.get("store_id"));
    }
    if (previousParams.get("start_date")) {
      urlParams.set("start_date", previousParams.get("start_date"));
    }
    if (previousParams.get("end_date")) {
      urlParams.set("end_date", previousParams.get("end_date"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onStartDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setStartDate("");
      return;
    }
    setStartDate(value);
  };

  const onEndDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setEndDate("");
      return;
    }
    setEndDate(value);
  };

  const onFilterTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterType(e.target.value);
  };

  const onCashRegisterOperationChange = (
    cashRegisterOperation: CashRegisterOperation | null
  ) => {
    setFilterCashRegisterOperation(
      cashRegisterOperation !== null ? cashRegisterOperation : undefined
    );
  };

  const onFilterStoreChange = (newStore: Store | null) => {
    setFilterStore(newStore !== null ? newStore : undefined);
  };

  const onDetailClick = (storeSale: StoreSale) => {
    setStoreSale(storeSale);
    setShowDetailModal(true);
  };

  const onDetailModalCancel = () => {
    setStoreSale(undefined);
    setShowDetailModal(false);
  };

  const onNullifyClick = (storeSale: StoreSale) => {
    setStoreSale(storeSale);
    setShowNullifyModal(true);
  };

  const onNullifyCancel = () => {
    setStoreSale(undefined);
    setShowNullifyModal(false);
  };

  const onNullifySuccess = () => {
    successAlert("Venta anulada con éxito!");
    setShowNullifyModal(false);
    setStoreSales([]);
    setLoading(true);
    fetchStoreSales();
    fetchTotals();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    if (search !== "") {
      urlParams.set("search", search);
    } else {
      urlParams.delete("search");
    }
    if (filterType === "returnOperation") {
      urlParams.set("is_returnal", "true");
    } else if (filterType === "saleOperation") {
      urlParams.set("is_returnal", "false");
    } else {
      urlParams.delete("is_returnal");
    }
    if (
      filterCashRegisterOperation !== undefined &&
      filterCashRegisterOperation.id !== undefined
    ) {
      urlParams.set(
        "cash_register_operation_id",
        filterCashRegisterOperation.id.toString()
      );
    } else {
      urlParams.delete("cash_register_operation_id");
    }
    if (filterStore !== undefined && filterStore.id !== undefined) {
      urlParams.set("store_id", filterStore.id.toString());
    } else {
      urlParams.delete("store_id");
    }
    if (moment.isMoment(startDate)) {
      urlParams.set("start_date", startDate.toISOString());
    } else if (startDate !== undefined && startDate !== "") {
      urlParams.set("start_date", startDate);
    } else {
      urlParams.delete("start_date");
    }
    if (moment.isMoment(endDate)) {
      urlParams.set("end_date", endDate.toISOString());
    } else if (endDate !== undefined && endDate !== "") {
      urlParams.set("end_date", endDate);
    } else {
      urlParams.delete("end_date");
    }
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const onReportClick = async () => {
    setEnableReportButton(false);
    if (storeSalesReport.length <= 0) {
      warningAlert("No se han seleccionado ventas");
      setEnableReportButton(true);
      return;
    }

    const urlParams = getURLParams();
    const titlesRight: string[] = generateTitlesRight(urlParams);

    const filteredTitlesRight = titlesRight.filter((title) => title !== "");

    await generateSalesReportPDF(
      storeSalesReport,
      [
        "Reporte de Ventas",
        "",
        "Reporte generado el:",
        formatDateTime(new Date()),
      ],
      filteredTitlesRight,
      undefined
    );
    setEnableReportButton(true);
  };

  const generateTitlesRight = (map: Map<any, any>): string[] => {
    const titlesRight: string[] = ["Filtros Utilizados: "];

    map.forEach((value, key) => {
      const stringKey = typeof key === "string" ? key : "";
      const stringValue = typeof value === "string" ? value : "";
      const description = getFilterDescription(stringKey, stringValue);
      titlesRight.push(description);
    });

    return titlesRight;
  };

  const getFilterDescription = (key: string, value: string) => {
    switch (key) {
      case "search":
        if (value !== "") {
          return `Búsqueda: ${value}`;
        } else {
          return "";
        }
      case "is_returnal":
        if (value === "true") {
          return "Tipo de Operación: Devolución";
        } else if (value === "false") {
          return "Tipo de Operación: Devolución";
        } else {
          return "";
        }
      case "cash_register_operation_id":
        return `Corresponde a ciclo de caja: Si`;
      case "start_date":
        return `Fecha de inicio: ${formatDateTime(new Date(value))}`;
      case "end_date":
        return `Fecha de fin: ${formatDateTime(new Date(value))}`;
      default:
        return "";
    }
  };

  useEffect(() => {
    fetchStoreSales();
    fetchStoreSalesReport();
    fetchTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("salesHistory")) {
        return;
      }
      fetchStoreSales();
      fetchStoreSalesReport();
      fetchTotals();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Registro de ventas / Gestión de devoluciones</h3>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CButton
                    color="danger"
                    className="fa-solid fa-file-pdf"
                    onClick={onReportClick}
                    disabled={!enableReportButton || loading}
                  ></CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Caja:</CLabel>
                </CCol>
                <CCol>
                  <CashRegisterSelect
                    value={
                      filterCashRegisterOperation
                        ? filterCashRegisterOperation
                        : null
                    }
                    onChange={onCashRegisterOperationChange}
                  ></CashRegisterSelect>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Venta/devolucion:</CLabel>
                </CCol>
                <CCol>
                  <CSelect
                    type="text"
                    defaultValue={filterType !== null ? filterType : ""}
                    onChange={onFilterTypeChange}
                  >
                    <option value={""}>-----</option>
                    {Object.entries(STORESALETYPES).map(([key, entry], ix) => {
                      return (
                        <option key={ix} value={key}>
                          {entry}
                        </option>
                      );
                    })}
                  </CSelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol></CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Desde:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onStartDatehange}
                    value={startDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Hasta:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onEndDatehange}
                    value={endDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Sucursal:</CLabel>
                </CCol>
                <CCol>
                  <SingleStoreSelect
                    value={filterStore ? filterStore : null}
                    onChange={onFilterStoreChange}
                  ></SingleStoreSelect>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda (factura/cliente)"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Filtrar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={storeSales}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    documentNumber: (item: StoreSale) => {
                      return (
                        <td className="text-center">
                          {item.documentNumber
                            ? `${item.establishment}-${item.dispatchPoint}-${item.documentNumber}`
                            : "-"}
                        </td>
                      );
                    },

                    cancelled: (item: StoreSale) => {
                      return (
                        <td className="text-center">
                          {item.cancelled ? (
                            <CBadge color={"danger"}>Operación Anulada</CBadge>
                          ) : (
                            "-"
                          )}
                        </td>
                      );
                    },

                    orderDate: (item: StoreSale) => {
                      return (
                        <td className="text-center">
                          {item.saleDate
                            ? formatDateTime(new Date(item.saleDate))
                            : "-"}
                        </td>
                      );
                    },
                    clientName: (item: StoreSale) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined(
                            "SIN NOMBRE",
                            item.clientName
                          )}
                        </td>
                      );
                    },
                    storeName: (item: StoreSale) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.storeName)}
                        </td>
                      );
                    },
                    clientRuc: (item: StoreSale) => {
                      let valueToShow: string;
                      if (item.clientRuc !== undefined) {
                        valueToShow = item.clientRuc;
                      } else if (item.clientDocument !== undefined) {
                        valueToShow = item.clientDocument;
                      } else {
                        valueToShow = "-";
                      }
                      return <td className="text-center">{valueToShow}</td>;
                    },
                    paymentType: (item: StoreSale) => {
                      return (
                        <td className="text-center">
                          {item.paymentType ? getPaymentTypeLabel(item) : "-"}
                        </td>
                      );
                    },
                    isReturnOperation: (item: StoreSale) => {
                      return (
                        <td className="text-center">
                          {getStoreSaleOperationType(item)}
                        </td>
                      );
                    },
                    noTaxed: (item: StoreSale) => {
                      const show = item.isReturnOperation
                        ? !item.isReturnOperation
                        : true;

                      return (
                        <td className="text-center">
                          {show
                            ? item.noTaxed
                              ? formatToCurrency(item.noTaxed, PYG)
                              : "-"
                            : "-"}
                        </td>
                      );
                    },

                    taxed: (item: StoreSale) => {
                      const show = item.isReturnOperation
                        ? !item.isReturnOperation
                        : true;
                      return (
                        <td className="text-center">
                          {show
                            ? item.taxed
                              ? formatToCurrency(item.taxed, PYG)
                              : "-"
                            : "-"}
                        </td>
                      );
                    },
                    discountedPrice: (item: StoreSale) => {
                      return (
                        <td className="text-center">
                          {item.discountedPrice
                            ? formatToCurrency(item.discountedPrice, PYG)
                            : "-"}
                        </td>
                      );
                    },

                    iva: (item: StoreSale) => {
                      const show = item.isReturnOperation
                        ? !item.isReturnOperation
                        : true;
                      return (
                        <td className="text-center">
                          {show
                            ? item.iva
                              ? formatToCurrency(item.iva, PYG)
                              : "-"
                            : "-"}
                        </td>
                      );
                    },

                    saleProfit: (item: StoreSale) => {
                      const show = item.isReturnOperation
                        ? !item.isReturnOperation
                        : true;
                      return (
                        <td className="text-center">
                          {show
                            ? item.saleProfit
                              ? formatToCurrency(item.saleProfit, PYG)
                              : "-"
                            : "-"}
                        </td>
                      );
                    },

                    actions: (item: StoreSale) => {
                      const detailButton = (
                        <CTooltip content={"Ver Detalles"}>
                          <CButton
                            className="text-white"
                            color="primary"
                            onClick={() => {
                              onDetailClick(item);
                            }}
                          >
                            <i className="fa fa-eye"></i>
                          </CButton>
                        </CTooltip>
                      );

                      const nullifyButton = (
                        <CTooltip content={"Anular Venta"}>
                          <CButton
                            className="text-white"
                            color="danger"
                            onClick={() => {
                              onNullifyClick(item);
                            }}
                          >
                            <i className="fa fa-circle-xmark"></i>
                          </CButton>
                        </CTooltip>
                      );

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {detailButton}
                            {!item.isReturnOperation && !item.cancelled
                              ? nullifyButton
                              : null}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
              <CRow className={"mt-2"}>
                <CCol className={"border border-dark"}>
                  <CRow className={"mt-2"}>
                    <CCol>
                      <h5>Valores Totales de selección:</h5>
                    </CCol>
                  </CRow>
                  <CRow className={"mt-2"}>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Venta total de la pagina visualizada:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {pageTotalPrice
                          ? formatToCurrency(pageTotalPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Venta total todas las páginas:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {queryTotalPrice
                          ? formatToCurrency(queryTotalPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                  </CRow>
                  <CRow className={"mt-2"}>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Costo total de productos vendidos de la pagina
                        visualizada:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {pageTotalCost
                          ? formatToCurrency(pageTotalCost, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Costo total todas las páginas:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {queryTotalCost
                          ? formatToCurrency(queryTotalCost, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                  </CRow>
                  <CRow className={"mt-2"}>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Ganancia total de la pagina visualizada:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {pageTotalProfit
                          ? formatToCurrency(pageTotalProfit, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Ganancia total todas las páginas:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {queryTotalProfit
                          ? formatToCurrency(queryTotalProfit, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <SaleDetailModal
        show={showDetailModal}
        storeSale={storeSale}
        onCancel={onDetailModalCancel}
      ></SaleDetailModal>
      <SaleNullifyModal
        show={showNullifyModal}
        storeSale={storeSale}
        onCancel={onNullifyCancel}
        onSuccess={onNullifySuccess}
      ></SaleNullifyModal>
    </>
  );
};

export default SaleOrderList;
