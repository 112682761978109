interface Store {
  id?: number;
  name?: string;
  accessKey?: string;
  companyId?: number;
  inventoryValue?: number;
  storeRequestsCredit?: number;
  storeRequestCreditPercentage?: number;
  storeRequestLimitEnabled?: boolean;
}

export const newStore = (): Store => {
  return {};
};

export default Store;
