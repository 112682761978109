import InventoryTransferCrud from "../components/inventory-transfers/InventoryTransferCrud";

const InventoryTransferCrudPage = () => {
  return (
    <>
      <InventoryTransferCrud />
    </>
  );
};

export default InventoryTransferCrudPage;
