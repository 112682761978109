import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import InputMaterial, {
  getInputMaterialTypeLabel,
  inputMaterialNeedsReStock,
} from "../../models/input-material";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import InputMaterialDeleteModal from "./InputMaterialDeleteModal";
import InputMaterialUpdateModal from "./InputMaterialUpdateModal";

import {
  INPUT_MATERIALS_CREATE,
  INPUT_MATERIALS_DESTROY,
  INPUT_MATERIALS_RETRIEVE,
  INPUT_MATERIALS_UPDATE,
} from "../../auth/permissions";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import {
  defaultValueOnUndefined,
  showNumberWithDecimalPoint,
} from "../../utils/fields";
import InputMaterialAmountModal from "./InputMaterialAmountModal";

const ITEMS_PER_PAGE = 10;

const InputMaterialList = () => {
  const fields = [
    {
      key: "identifier",
      _classes: ["text-center"],
      label: "Identificador",
    },
    {
      key: "currentTotal",
      _classes: ["text-center"],
      label: "Disponible",
    },
    {
      key: "measurementUnit",
      _classes: ["text-center"],
      label: "Unidad de medida",
    },
    {
      key: "reStock",
      _classes: ["text-center"],
      label: "Falta reponer",
    },
    {
      key: "type",
      _classes: ["text-center"],
      label: "Tipo",
    },
    {
      key: "description",
      _classes: ["text-center"],
      label: "Descripción",
    },
    {
      key: "obs",
      _classes: ["text-center"],
      label: "Observaciones",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showInputMaterialEditModal, setShowInputMaterialEditModal] =
    useState(false);
  const [showInputMaterialDeleteModal, setShowInputMaterialDeleteModal] =
    useState(false);
  const [showInputMaterialAmountModal, setShowInputMaterialAmountModal] =
    useState(false);

  const [inputMaterial, setInputMaterial] = useState<InputMaterial | undefined>(
    undefined
  );
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [inputMaterials, setInputMaterials] = useState<InputMaterial[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    return map;
  };

  const fetchInputMaterials = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const inputMaterialsStatus = await getList<InputMaterial>(
      "/input_materials/",
      limit,
      offset,
      urlParams
    );
    if (inputMaterialsStatus.status === SUCCESS) {
      if (inputMaterialsStatus.data !== undefined) {
        const count = inputMaterialsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setInputMaterials(inputMaterialsStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = inputMaterialsStatus.detail
        ? inputMaterialsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setShowInputMaterialEditModal(true);
  };

  const onUpdateClick = (inputMaterial: InputMaterial) => {
    setInputMaterial(inputMaterial);
    setShowInputMaterialEditModal(true);
  };

  const onDeleteClick = (inputMaterial: InputMaterial) => {
    setInputMaterial(inputMaterial);
    setShowInputMaterialDeleteModal(true);
  };

  const onAmountClick = (inputMaterial: InputMaterial) => {
    setInputMaterial(inputMaterial);
    setShowInputMaterialAmountModal(true);
  };

  const onInputMaterialEditCancel = () => {
    setInputMaterial(undefined);
    setShowInputMaterialEditModal(false);
  };

  const onInputMaterialEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowInputMaterialEditModal(false);
    setInputMaterials([]);
    setLoading(true);
    fetchInputMaterials();
  };

  const onInputMaterialDeleteCancel = () => {
    setInputMaterial(undefined);
    setShowInputMaterialDeleteModal(false);
  };

  const onInputMaterialDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowInputMaterialDeleteModal(false);
    setInputMaterials([]);
    setLoading(true);
    fetchInputMaterials();
  };

  const onInputMaterialAmountCancel = () => {
    setInputMaterial(undefined);
    setShowInputMaterialAmountModal(false);
  };

  const onInputMaterialAmountSuccess = () => {
    successAlert("Stock de materia prima modificado con éxito!");
    setShowInputMaterialAmountModal(false);
    setInputMaterials([]);
    setLoading(true);
    fetchInputMaterials();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("search", search);
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchInputMaterials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("inputMaterials")) {
        return;
      }
      fetchInputMaterials();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Materias Primas</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent
                  permissionName={INPUT_MATERIALS_CREATE}
                >
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={inputMaterials}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    type: (item: InputMaterial) => {
                      return (
                        <td className="text-center">
                          {getInputMaterialTypeLabel(item)}
                        </td>
                      );
                    },
                    description: (item: InputMaterial) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.description)}
                        </td>
                      );
                    },
                    obs: (item: InputMaterial) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.obs)}
                        </td>
                      );
                    },
                    measurementUnit: (item: InputMaterial) => {
                      return (
                        <td className="text-center">
                          {item.measurementUnit ? item.measurementUnit : "-"}
                        </td>
                      );
                    },
                    reStock: (item: InputMaterial) => {
                      return (
                        <td className="text-center">
                          {inputMaterialNeedsReStock(item) ? "Si" : "No"}
                        </td>
                      );
                    },
                    currentTotal: (item: InputMaterial) => {
                      return (
                        <td className="text-center">
                          {item.currentTotal
                            ? showNumberWithDecimalPoint(item.currentTotal, 2)
                            : 0}
                        </td>
                      );
                    },
                    actions: (item: InputMaterial) => {
                      const stockButton = (
                        <PermissionRequiredComponent
                          permissionName={INPUT_MATERIALS_RETRIEVE}
                        >
                          <CButton
                            className="text-white"
                            color="primary"
                            onClick={() => {
                              onAmountClick(item);
                            }}
                          >
                            <i className="fa fa-truck-ramp-box"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={INPUT_MATERIALS_UPDATE}
                        >
                          <CButton
                            className="text-white"
                            color="warning"
                            onClick={() => {
                              onUpdateClick(item);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );
                      const deleteButton = (
                        <PermissionRequiredComponent
                          permissionName={INPUT_MATERIALS_DESTROY}
                        >
                          <CButton
                            className="text-white"
                            color="danger"
                            onClick={() => {
                              onDeleteClick(item);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {stockButton}
                            {editButton}
                            {deleteButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <InputMaterialUpdateModal
        show={showInputMaterialEditModal}
        onCancel={onInputMaterialEditCancel}
        onSuccess={onInputMaterialEditSuccess}
        inputMaterial={inputMaterial}
      />
      <InputMaterialDeleteModal
        show={showInputMaterialDeleteModal}
        onCancel={onInputMaterialDeleteCancel}
        onSuccess={onInputMaterialDeleteSuccess}
        inputMaterial={inputMaterial}
      />
      <InputMaterialAmountModal
        show={showInputMaterialAmountModal}
        onCancel={onInputMaterialAmountCancel}
        onSuccess={onInputMaterialAmountSuccess}
        inputMaterial={inputMaterial}
      />
    </>
  );
};

export default InputMaterialList;
