import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Size from "../../models/size";

const RECORS_PER_SEARCH = 20;

type SizesSelectProps = {
  value: readonly Size[];
  onChange: (React.Dispatch<React.SetStateAction<readonly Size[]>>);
  isDisabled?:boolean;
};

const SizesSelect: React.FC<SizesSelectProps> = ({
  value,
  onChange,
  isDisabled,
}) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );
  const loadOptions = async (
    s: string,
    currentOpts: readonly (
      | Size
      | { options: Size[]; label?: string }
    )[]
  ): Promise<
    Response<
      Size,
      { options: Size[]; label?: string },
      any
    >
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    const dataPaginated = await getList<Size>(
      "/sizes/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: Size[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder="Selecciona los talles para esta prenda"
      onChange={onChange}
      isMulti={true}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: Size) => `${option.size}`}
      getOptionValue={(option: Size) => `${option.id}`}
      value={value}
      isDisabled={isDisabled}
    />
  );
};

export default SizesSelect;
