import { getList } from "../../api/generics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

import { ClientPayment, PaymentRelatedOrder } from "../../models/client";
import { SUCCESS } from "../../utils/constants/tags";
import { warningAlert } from "../utils/messages";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

type RelatedOrdersProps = {
  payment: ClientPayment;
};

const RelatedOrders: React.FC<RelatedOrdersProps> = ({ payment }) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<PaymentRelatedOrder[]>([]);

  const fetchRelatedOrders = async () => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();
    additionalParams.set("company_id", companyId!.toString());
    additionalParams.set("payment_id", payment!.id!.toString());
    setLoading(true);
    const ordersStatus = await getList<PaymentRelatedOrder>(
      "/payments/related_orders/",
      1000,
      0,
      additionalParams
    );
    if (ordersStatus.status === SUCCESS) {
      if (ordersStatus.data !== undefined) {
        setOrders(ordersStatus.data.items);
      }
    } else {
      const message = ordersStatus.detail
        ? ordersStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRelatedOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);
  if (loading) {
    return (
      <Spinner
        animation="grow"
        style={{
          height: "5px",
          width: "5px",
          marginTop: "auto",
          marginBottom: "auto",
          marginRight: "auto",
        }}
      />
    );
  }
  return (
    <>
      {orders.length > 0
        ? orders.map((order, index) =>
            index === orders.length - 1
              ? `#${order.orderId}`
              : `#${order.orderId}, `
          )
        : "-"}
    </>
  );
};

export default RelatedOrders;
