import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import StoreSale, { getStoreSaleOperationType } from "../models/store-sale";
import { formatDateTime } from "./dates";
import { defaultValueOnUndefined } from "./fields";
import { getPaymentTypeLabel } from "../models/client";
import CashRegisterOperation from "../models/cash-register-operation";

const lungorLogo = require("../assets/img/lungorlogo.jpg");

interface SalesPDFEntry {
  documentNumber: string;
  date: string;
  client: string;
  ruc: string;
  operationType: string;
  paymentType: string;
  cancelled: string;
  notTaxed: string;
  taxed: string;
  totalAmount: string;
  tax: string;
}

const convertStoreSaleToPDFEntries = (
  storeSales: StoreSale[]
): SalesPDFEntry[] => {
  return storeSales.map((sale) => {
    let rucToShow: string;
    if (sale.clientRuc !== undefined) {
      rucToShow = sale.clientRuc;
    } else if (sale.clientDocument !== undefined) {
      rucToShow = sale.clientDocument;
    } else {
      rucToShow = "-";
    }
    return {
      documentNumber:
        sale.documentNumber !== undefined
          ? `${sale.establishment}-${sale.dispatchPoint}-${sale.documentNumber}`
          : "-",
      date: sale.saleDate ? formatDateTime(new Date(sale.saleDate)) : "-",
      client: defaultValueOnUndefined("SIN NOMBRE", sale.clientName),
      ruc: rucToShow,
      operationType: getStoreSaleOperationType(sale),
      paymentType: getPaymentTypeLabel(sale),
      cancelled: sale.cancelled ? "Anulado" : "-",
      notTaxed: sale.noTaxed ? sale.noTaxed.toLocaleString() : "-",
      taxed: sale.taxed ? sale.taxed.toLocaleString() : "-",
      totalAmount: sale.discountedPrice
        ? sale.discountedPrice.toLocaleString()
        : "-",
      tax: sale.iva ? sale.iva.toLocaleString() : "-",
    };
  });
};
type FontStyle = "normal" | "bold" | "italic";
const generateSalesReportPDF = async (
  storeSales: StoreSale[],
  titlesMiddle: string[],
  titlesRight: string[],
  cashRegisterOperation?: CashRegisterOperation
) => {
  const doc = new jsPDF({
    orientation: "portrait",
    format: "a4",
  });
  const entries = convertStoreSaleToPDFEntries(storeSales);

  const totals = calculateTotals(storeSales);

  const entriesPerPage = 30;
  const totalPages = Math.ceil(entries.length / entriesPerPage);

  const headerStyles = {
    fontSize: 5,
    font: "helvetica",
    fontStyle: "bold" as FontStyle,
    fillColor: "#ffffff",
    textColor: "#000000",
    lineWidth: 0.1,
  };

  const bodyStyles = {
    fontSize: 5,
    font: "helvetica",
  };

  for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
    if (currentPage > 1) {
      doc.addPage();
    }

    doc.setFontSize(10);
    generateHeader(doc, titlesMiddle, titlesRight);

    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = Math.min(startIndex + entriesPerPage, storeSales.length);
    const pageEntries = entries.slice(startIndex, endIndex);

    if (currentPage === totalPages) {
      pageEntries.push(totals);
    }

    autoTable(doc, {
      theme: "grid",
      startY: 45,
      head: [
        [
          "Nro de Factura",
          "Fecha",
          "Cliente",
          "RUC",
          "Tipo",
          "Anulado",
          "Forma de Pago",
          "Exentas",
          "Gravadas",
          "Total",
          "IVA",
        ],
      ],
      body: pageEntries.map((entry) => Object.values(entry)),
      headStyles: headerStyles,
      bodyStyles: bodyStyles,
    });

    if (currentPage === totalPages && cashRegisterOperation !== undefined) {
      doc.setFontSize(7);
      doc.text(`Detalles:`, 14, doc.internal.pageSize.height - 35);
      doc.text(
        `Total facturado efectivo: ${
          cashRegisterOperation.cashAmount
            ? cashRegisterOperation.cashAmount.toLocaleString()
            : "-"
        } Gs`,
        14,
        doc.internal.pageSize.height - 32
      );
      doc.text(
        `Efectivo en Apertura: ${
          cashRegisterOperation.openingMoney
            ? cashRegisterOperation.openingMoney.toLocaleString()
            : "-"
        } Gs`,
        80,
        doc.internal.pageSize.height - 29
      );
      doc.text(
        `Efectivo al Cierre: ${
          cashRegisterOperation.closingMoney &&
          cashRegisterOperation.closingDatetime
            ? cashRegisterOperation.closingMoney.toLocaleString() + "Gs"
            : "-"
        } `,
        140,
        doc.internal.pageSize.height - 29
      );
      doc.text(
        `Total facturado Tarjeta de Crédito: ${
          cashRegisterOperation.creditAmount
            ? cashRegisterOperation.creditAmount.toLocaleString()
            : "-"
        } Gs`,
        14,
        doc.internal.pageSize.height - 29
      );
      doc.text(
        `Total facturado Tarjeta de Débito: ${
          cashRegisterOperation.debitAmount
            ? cashRegisterOperation.debitAmount.toLocaleString()
            : "-"
        } Gs
      `,
        14,
        doc.internal.pageSize.height - 26
      );
      doc.text(
        `Total facturado Pago QR: ${
          cashRegisterOperation.qrAmount
            ? cashRegisterOperation.qrAmount.toLocaleString()
            : "-"
        } Gs
      `,
        14,
        doc.internal.pageSize.height - 23
      );
      doc.text(
        `Total facturado transferencia bancaria: ${
          cashRegisterOperation.transferAmount
            ? cashRegisterOperation.transferAmount.toLocaleString()
            : "-"
        } Gs
      `,
        14,
        doc.internal.pageSize.height - 20
      );
      doc.text(
        `Total devoluciones: ${
          cashRegisterOperation.returnAmount
            ? cashRegisterOperation.returnAmount.toLocaleString()
            : "-"
        } Gs
      `,
        14,
        doc.internal.pageSize.height - 17
      );
      doc.text(
        `Usuario: ${
          cashRegisterOperation.username ? cashRegisterOperation.username : "-"
        }
      `,
        80,
        doc.internal.pageSize.height - 17
      );
      doc.text(
        `TOTAL GENERAL FACTURADO: ${
          cashRegisterOperation.totalAmount
            ? cashRegisterOperation.totalAmount.toLocaleString()
            : "-"
        } Gs
      `,
        14,
        doc.internal.pageSize.height - 14
      );
      doc.text(
        `Hora apertura: ${
          cashRegisterOperation.openingDatetime
            ? formatDateTime(new Date(cashRegisterOperation.openingDatetime))
            : "-"
        }
      `,
        80,
        doc.internal.pageSize.height - 14
      );
      doc.text(
        `Hora Cierre: ${
          cashRegisterOperation.closingDatetime
            ? formatDateTime(new Date(cashRegisterOperation.closingDatetime))
            : "-"
        }
      `,
        140,
        doc.internal.pageSize.height - 14
      );
    }
    doc.setFontSize(5);
    doc.text(
      `Página ${currentPage} de ${totalPages}`,
      14,
      doc.internal.pageSize.height - 10
    );
  }

  const blob = doc.output("blob");
  const url = URL.createObjectURL(blob);
  window.open(url, "_blank");
};

const calculateTotals = (storeSales: StoreSale[]): SalesPDFEntry => {
  const notTaxedTotal: number = storeSales.reduce(
    (sum, item) =>
      item.isReturnOperation === undefined || item.isReturnOperation === false
        ? sum + (item.noTaxed || 0)
        : sum,
    0
  );

  const taxedTotal: number = storeSales.reduce(
    (sum, item) =>
      item.isReturnOperation === undefined || item.isReturnOperation === false
        ? sum + (item.taxed || 0)
        : sum,
    0
  );

  const priceTotal: number = storeSales.reduce(
    (sum, item) => sum + (item.discountedPrice || 0),
    0
  );

  const ivaTotal: number = storeSales.reduce(
    (sum, item) =>
      item.isReturnOperation === undefined || item.isReturnOperation === false
        ? sum + (item.iva || 0)
        : sum,
    0
  );

  return {
    documentNumber: "",
    date: "",
    client: "",
    ruc: "",
    operationType: "",
    paymentType: "",
    cancelled: "",
    notTaxed: notTaxedTotal.toLocaleString(),
    taxed: taxedTotal.toLocaleString(),
    totalAmount: priceTotal.toLocaleString(),
    tax: ivaTotal.toLocaleString(),
  };
};

const generateHeader = (
  doc: jsPDF,
  titlesMiddle: string[],
  titlesRight: string[]
) => {
  const x = 14;
  const y = 10;
  const width = doc.internal.pageSize.width - 28;
  const height = 30;
  const middleX = x + width / 2;

  doc.rect(x, y, width, height);

  const img = new Image();
  img.src = lungorLogo;
  doc.addImage(img, "JPEG", x + 5, y + 5, 35, 20);

  const totalTitlesMiddle = titlesMiddle.length;
  const totalTitlesRight = titlesRight.length;
  const totalTitles = Math.max(totalTitlesMiddle, totalTitlesRight);

  const verticalSpace = height / (totalTitles + 1);

  let middleTitleY = y + verticalSpace;

  titlesMiddle.forEach((title) => {
    const middleTitleWidth = doc.getTextWidth(title);
    const middleTitleX = middleX - middleTitleWidth / 2;
    doc.text(title, middleTitleX, middleTitleY);
    middleTitleY += verticalSpace;
  });

  doc.setFontSize(6);
  let rightTitleY = y + verticalSpace;

  const rightX = x + width;
  titlesRight.forEach((title) => {
    const rightTitleWidth = doc.getTextWidth(title);
    const rightTitleX = rightX - rightTitleWidth - 5;
    doc.text(title, rightTitleX, rightTitleY);
    rightTitleY += verticalSpace;
  });
};
export default generateSalesReportPDF;
