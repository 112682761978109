import {
  CButton,
  CCol,
  CContainer,
  CDataTable,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getList, updateItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import ProductionOrder, {
  ProductionOrderStatusAmount,
} from "../../models/production-order";
import ProductionStage from "../../models/production-stage";
import ProductionStageSelect from "./StageSelect";
import Errors, { getFieldErrors } from "../../models/errors";
import { FieldErrors } from "../form/FieldErrors";

interface ProductionOrderStageModalProps {
  show: boolean;
  productionOrder?: ProductionOrder;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ProductionOrderStageModal: React.FC<ProductionOrderStageModalProps> = ({
  show,
  productionOrder,
  onCancel,
  onSuccess,
}) => {
  const fields = [
    {
      key: "stageName",
      _classes: ["text-center", "align-middle"],
      label: "Etapa",
    },
    {
      key: "totalAmount",
      _classes: ["text-center", "align-middle"],
      label: "Cantidad",
    },
  ];
  const [submitting, setSubmitting] = useState(false);
  const [stage, setStage] = useState<ProductionStage | null>(null);
  const [destinationStage, setDestinationStage] =
    useState<ProductionStage | null>(null);
  const [amount, setAmount] = useState<number | undefined>();
  const [errors, setErrors] = useState<Errors>({});
  const [statusesAmounts, setStatusesAmounts] = useState<
    ProductionOrderStatusAmount[]
  >([]);
  const [matchStatusAmount, setMatchStatusAmount] = useState<
    ProductionOrderStatusAmount | undefined
  >(undefined);
  const [enoughExistences, setEnoughExistences] = useState(false);
  const message = productionOrder
    ? `Está seguro de que Enviar productos de produccion ${productionOrder.id}, ${productionOrder.productName}`
    : "";

  const onStageChange = useCallback(
    (newStage: ProductionStage | null) => {
      setStage(newStage);
      setEnoughExistences(true);
      setAmount(0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productionOrder]
  );

  const onDestinationStageChange = useCallback(
    (newStage: ProductionStage | null) => {
      setDestinationStage(newStage);

      setAmount(0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productionOrder]
  );

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = parseInt(e.target.value);
    setAmount(newAmount);

    if (stage !== null) {
      const matchStage = statusesAmounts.find(
        (item) => item.stageId === stage.id
      );
      setMatchStatusAmount(matchStage);
      if (matchStage === undefined) {
        setEnoughExistences(false);
      }
      if (matchStage === undefined) {
        setEnoughExistences(false);
        return;
      }
      if (
        matchStage !== undefined &&
        matchStage.totalAmount !== undefined &&
        newAmount !== undefined
      ) {
        if (matchStage.totalAmount >= newAmount) {
          setEnoughExistences(true);
        } else {
          setEnoughExistences(false);
        }
      }
    }
  };

  const onSubmitClick = async () => {
    if (productionOrder === undefined) {
      return;
    }

    if (stage === null || destinationStage === null) {
      errorAlert("Debe seleccionar una etapa para iniciar");
      return;
    }
    if (stage.id === destinationStage.id) {
      errorAlert("Debe seleccionar etapas diferentes de origen y destino");
      return;
    }

    if (enoughExistences === false) {
      errorAlert(
        `No dispones de suficientes unidades en la etapa de origen para enviar. tienes disponible ${matchStatusAmount?.totalAmount}`
      );
      return;
    }
    if (amount === undefined) {
      errorAlert(`Debes ingresar una cantidad.`);
      return;
    }

    if (amount !== undefined && amount <= 0) {
      errorAlert(`La cantidad debe ser mayor a cero.`);
      return;
    }

    setSubmitting(true);

    let toSendOrder: ProductionOrder = {
      ...productionOrder,
      originStageId: stage !== null ? stage.id : undefined,
      currentStage:
        destinationStage !== null ? destinationStage.name : undefined,
      currentStageId:
        destinationStage !== null ? destinationStage.id : undefined,
      currentStageAllowRelatedAmounts: true,
      currentStageIsConfection: false,
      currentStageIsCut: false,
      currentStageIsLaundry: true,
    };

    toSendOrder.stageAmount = amount;

    const orderStatus = await updateItem<ProductionOrder>(
      `/production/${productionOrder.id}/advance/`,
      toSendOrder
    );

    if (orderStatus.status !== SUCCESS) {
      let message = "Ha ocurrido un error!!";
      if (orderStatus.errors !== undefined) {
        setErrors(orderStatus.errors);
      }
      if (orderStatus.detail !== undefined) {
        message = orderStatus.detail;
      }
      errorAlert(message);
    } else {
      onSuccess();
      setErrors({});
      setStage(null);
      setDestinationStage(null);
      setEnoughExistences(false);
      setMatchStatusAmount(undefined);
      setAmount(0);
    }

    setSubmitting(false);
  };

  const fetchAmounts = async (orderId: number) => {
    const limit = 100;
    const offset = 0;

    try {
      const amountStatus = await getList<ProductionOrderStatusAmount>(
        `/production/${orderId}/stages_amounts/`,
        limit,
        offset
      );
      if (amountStatus.status === SUCCESS) {
        if (amountStatus.data !== undefined) {
          setStatusesAmounts(amountStatus.data.items);
        }
      } else {
        setStatusesAmounts([]);
      }
    } catch (error) {
      console.error("Error fetching variants:", error);

      setStatusesAmounts([]);
    }
  };

  const fetchCurrentAmounts = async (orderId: number) => {
    await fetchAmounts(orderId);
  };

  useEffect(() => {
    if (productionOrder?.id) {
      fetchCurrentAmounts(productionOrder.id);
    }
    setStage(null);
    setDestinationStage(null);
    setEnoughExistences(false);
    setErrors({});
    setAmount(0);
    setMatchStatusAmount(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionOrder]);

  return (
    <CModal show={show} className="modal-info" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Mover unidades a etapa de produccion</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
          <fieldset>
            <CFormGroup>
              <CRow>
                <CCol md={3} className={"mt-2"}>
                  Etapa de producción origen:
                </CCol>
                <CCol md={3}>
                  <ProductionStageSelect
                    value={stage}
                    onChange={onStageChange}
                    isDisabled={false}
                    omitSpecialStages={true}
                  ></ProductionStageSelect>
                  <FieldErrors
                    errors={getFieldErrors("stage") as string[]}
                  ></FieldErrors>
                </CCol>
                <CCol md={3} className={"mt-2"}>
                  Etapa de producción destino:
                </CCol>
                <CCol md={3}>
                  <ProductionStageSelect
                    value={destinationStage}
                    onChange={onDestinationStageChange}
                    isDisabled={false}
                    omitSpecialStages={true}
                  ></ProductionStageSelect>
                  <FieldErrors
                    errors={getFieldErrors("stage") as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={3}>
                  <CLabel>Cantidad a ser movida de etapa:</CLabel>
                </CCol>
                <CCol md={9}>
                  <CInput
                    type="number"
                    placeholder="Cantidad"
                    min={0}
                    max={productionOrder?.amountProduced}
                    value={amount ? amount : 0}
                    onChange={onAmountChange}
                  ></CInput>
                  <FieldErrors
                    errors={getFieldErrors("stageAmount", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
          </fieldset>
          <CRow hidden={statusesAmounts.length <= 0}>
            <CCol>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={statusesAmounts}
                  striped
                  border
                  responsive
                />
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onSubmitClick} color="info">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Confirmando..." : "Confirmar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ProductionOrderStageModal;
