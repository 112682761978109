import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import SaleOrder from "../../models/sale-order";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
import { DATEDDMMYYY, formatDate } from "../../utils/dates";

const RECORS_PER_SEARCH = 20;

type SaleOrderSelectProps = {
  clientId?: number;
  value: SaleOrder | null;
  disabled?:boolean;
  onlyDeliveryPending?:boolean;
  onChange: (_: SaleOrder | null) => void;
};

const SaleOrderSelect: React.FC<SaleOrderSelectProps> = ({ clientId, value, disabled, onlyDeliveryPending, onChange }) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );
  const loadOptions = async (
    s: string,
    currentOpts: readonly (SaleOrder | { options: SaleOrder[]; label?: string })[]
  ): Promise<
    Response<SaleOrder, { options: SaleOrder[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }


    if (clientId!== undefined){
      additionalParams.set("client_id", clientId.toString())
    }
    
    if(onlyDeliveryPending!==undefined && onlyDeliveryPending===true){
      additionalParams.set("only_delivery_pending", "true");
    }
    
    const dataPaginated = await getList<SaleOrder>(
      "/sale_orders/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: SaleOrder[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Orden de venta"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: SaleOrder) => `Orden#${option.id} - ${option.orderDate?formatDate(new Date(option.orderDate ), DATEDDMMYYY):""} - Total - ${option.totalPrice?formatToCurrency(option.totalPrice,PYG):""}`}
      getOptionValue={(option: SaleOrder) => `${option.id}`}
      value={value}
      isDisabled={disabled}
    />
  );
};

export default SaleOrderSelect;
