import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import InventoryInputBatchForm from "./InventoryInputBatchForm";
import InventoryInputBatch from "../../models/inventory-input-batch";

interface InventoryInputBatchUpdateModalProps {
  show: boolean;
  item?: InventoryInputBatch;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const InventoryInputBatchUpdateModal: React.FC<
  InventoryInputBatchUpdateModalProps
> = ({ show, item, onCancel, onSuccess }) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialValue, setInitialValue] = useState<InventoryInputBatch>(
    item ? item : {}
  );

  const modalTitle = item ? `Editar Tanda #${item.id}` : `Crear Tanda nueva`;

  const onClose = () => {
    setInitialErrors({});
    setInitialValue({});
    onCancel();
  };
  useEffect(() => {
    setInitialValue(item ? item : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <InventoryInputBatchForm
            initialInventoryInputBatch={initialValue}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default InventoryInputBatchUpdateModal;
