import Category from "./category";
import Store from "./store";

interface Discount {
  id?: number;
  name?: string;
  discountPercentage?: number;
  firstThreshold?: number | null;
  secondDiscountValue?: number | null;
  secondThreshold?: number | null;
  discountType?: string;
  isActive?: boolean;
  companyId?: number;
  categories?: Category[];
  stores?: Store[];
}

export const newDiscount = (): Discount => {
  return {};
};

type DiscountMaps = { [key: string]: string };

const DISCOUNTTYPËS: DiscountMaps = {
  percentage: "Porcentaje",
  fixed_amount: "Descuento de Monto Fijo",
  set_price: "Fijar Precio",
  two_levels: "Descuento de dos Niveles",
};

export const getDiscountTypeLabel = (key: string) => {
  return DISCOUNTTYPËS[key];
};

export default Discount;
