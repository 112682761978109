import ClientCrud from "../components/client/ClientCrud";

const ClientCrudPage = () => {
  return (
    <>
      <ClientCrud />
    </>
  );
};

export default ClientCrudPage;
