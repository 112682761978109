import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import Category, { newCategory } from "../../models/category";
import CategoryForm from "./CategoryForm";

interface CategoryUpdateModalProps {
  show: boolean;
  category?: Category;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const CategoryUpdateModal: React.FC<CategoryUpdateModalProps> = ({
  show,
  category,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialCategory, setInitialCategory] = useState<Category>(
    category ? category : newCategory()
  );

  const modalTitle = category
    ? `Editar Categoria: ${category.name}`
    : `Crear Categoria nueva`;

  const onClose = () => {
    setInitialErrors({});
    setInitialCategory(newCategory());
    onCancel();
  };
  useEffect(() => {
    setInitialCategory(category ? category : newCategory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CategoryForm
            initialCategory={initialCategory}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default CategoryUpdateModal;
