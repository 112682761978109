import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import Color, { newColor } from "../../models/color";
import ColorForm from "./ColorForm";

interface ColorUpdateModalProps {
  show: boolean;
  color?: Color;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ColorUpdateModal: React.FC<ColorUpdateModalProps> = ({
  show,
  color,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialColor, setInitialColor] = useState<Color>(color ? color : newColor());

  const modalTitle = color
    ? `Editar color: ${color.color}`
    : `Crear color nuevo`;

  const onClose = () => {
    setInitialErrors({});
    setInitialColor(newColor());
    onCancel();
  };
  useEffect(() => {
    setInitialColor(color ? color : newColor());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <ColorForm
            initialColor={initialColor}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default ColorUpdateModal;
