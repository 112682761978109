import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import DeliveryOrder from "../../models/delivery-order";
import { DATEDDMMYYY, formatDate } from "../../utils/dates";

const RECORS_PER_SEARCH = 20;

type PaymentDeliverOrderSelectProps = {
  clientId?: number;
  value: readonly DeliveryOrder[];
  onChange: React.Dispatch<React.SetStateAction<readonly DeliveryOrder[]>>;
};

const PaymentDeliverOrderSelect: React.FC<PaymentDeliverOrderSelectProps> = ({
  clientId,
  value,
  onChange,
}) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );
  const loadOptions = async (
    s: string,
    currentOpts: readonly (
      | DeliveryOrder
      | { options: DeliveryOrder[]; label?: string }
    )[]
  ): Promise<
    Response<DeliveryOrder, { options: DeliveryOrder[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());

    if (clientId !== undefined && clientId !== 0)
      additionalParams.set("client_id", clientId!.toString());

    additionalParams.set("only_pending", "true");

    if (s !== "") {
      additionalParams.set("search", s);
    }
    const dataPaginated = await getList<DeliveryOrder>(
      "/delivery_orders/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: DeliveryOrder[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione las ordenes"}
      onChange={onChange}
      isMulti={true}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: DeliveryOrder) =>
        `#${option.id} - ${
          option.orderDate
            ? formatDate(new Date(option.orderDate), DATEDDMMYYY)
            : ""
        }`
      }
      getOptionValue={(option: DeliveryOrder) => `${option.id}`}
      value={value}
    />
  );
};

export default PaymentDeliverOrderSelect;
