import {
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getList, updateItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, warningAlert } from "../utils/messages";
import ProductionOrder, {
  ProductionOrderItem,
} from "../../models/production-order";
import {
  VariantTableEntry,
  productionOrderItemAPIToTable,
  variantTableFormatToAPIProductionOrderItem,
} from "../../models/variant";
import { emptyValueOnUndefined } from "../../utils/fields";

interface ProductionPartialFailedModalProps {
  show: boolean;
  productionOrder?: ProductionOrder;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const FIXED_COLUMNS = ["Color"];

const ProductionPartialFailedModal: React.FC<
  ProductionPartialFailedModalProps
> = ({ show, productionOrder, onCancel, onSuccess }) => {
  const [submitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState<number>(0);
  const [pendingAmount, setPendingAmount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sizeErrors, setSizeErrors] = useState<string>("");

  const [tableColumns, setTableColumns] = useState<string[]>(FIXED_COLUMNS);

  const [tableEntries, setTableEntries] = useState<VariantTableEntry[]>([]);

  const message = productionOrder
    ? `Está seguro de que quiere marcar como fallados la produccion ${productionOrder.id}, ${productionOrder.productName}`
    : "";

  const onSubmitClick = async () => {
    if (productionOrder !== undefined) {
      setSubmitting(true);

      let toSendItems = variantTableFormatToAPIProductionOrderItem(
        tableEntries,
        true,
        "fail"
      );

      if (toSendItems.length <= 0) {
        warningAlert(
          "Se debe seleccionar al menos una tamaño y una cantidad para marcar fallado"
        );
        setSubmitting(false);
        return;
      }
      const amountToAllow = productionOrder.backFromLaundryAmount
        ? productionOrder.backFromLaundryAmount
        : 0;
      if (amount > amountToAllow) {
        warningAlert(
          "revise las cantidades. no tiene suficientes unidades que hayan vuelto de lavanderia"
        );
        setSubmitting(false);
        return;
      }

      toSendItems = toSendItems.map((item) => ({
        ...item,
        orderId: productionOrder.id,
        size: undefined,
      }));

      let variantErrors: string = "";

      toSendItems.forEach((item) => {
        if (
          item.finishedAmount &&
          item.amount &&
          item.finishedAmount > item.amount
        ) {
          if (variantErrors === "") {
            variantErrors += "Cantidaded erroneas en: ";
          }
          variantErrors += `${item.colorName} ${item.sizeName}. `;
        }
      });

      if (variantErrors !== "") {
        setSizeErrors(variantErrors);
        setSubmitting(false);
        return;
      }
      const achievedProfit = productionOrder.expectedProfit;
      let toSendOrder: ProductionOrder = {
        ...productionOrder,
        achievedProfit: achievedProfit,
        backFromLaundryAmount: productionOrder.backFromLaundryAmount
          ? productionOrder.backFromLaundryAmount - amount
          : 0,
        finishedAmount: productionOrder.finishedAmount
          ? productionOrder.finishedAmount + amount
          : amount,
        amountLost: (productionOrder.amountLost ?? 0) + amount,
        orderItems: toSendItems,
      };

      const orderStatus = await updateItem<ProductionOrder>(
        `/production/${productionOrder.id}/items_to_failed/`,
        toSendOrder
      );

      if (orderStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (orderStatus.detail !== undefined) {
          message = orderStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
        setAmount(0);
        setSizeErrors("");
      }

      setSubmitting(false);
    }
  };

  const generateTableHeaders = (entries: VariantTableEntry[]) => {
    const uniqueSizeNames: string[] = [];

    entries.forEach((item) => {
      if (item.sizes) {
        item.sizes
          .map((size) => size?.sizeName)
          .filter(
            (sizeName) =>
              sizeName !== undefined && !uniqueSizeNames.includes(sizeName)
          )
          .forEach((sizeName) => {
            if (sizeName) {
              uniqueSizeNames.push(sizeName);
            }
          });
      }
    });

    uniqueSizeNames.sort((a, b) => {
      const aFloat = parseFloat(a);
      const bFloat = parseFloat(b);
      return aFloat - bFloat;
    });

    setTableColumns([...FIXED_COLUMNS, ...uniqueSizeNames]);
  };

  const getOrderItems = async () => {
    if (productionOrder === undefined || productionOrder.id === undefined) {
      setTableEntries([]);
      return;
    }
    const productionOrderIdStr = productionOrder.id.toString();
    if (productionOrderIdStr === undefined) {
      setTableEntries([]);
      return;
    }
    const limit = 2000;
    const offset = 0;
    const orderSizesStatus = await getList<ProductionOrderItem>(
      `/production/${productionOrderIdStr}/order_items/`,
      limit,
      offset
    );
    if (orderSizesStatus.status === SUCCESS) {
      if (orderSizesStatus.data !== undefined) {
        const formattedTableEntries = productionOrderItemAPIToTable(
          orderSizesStatus.data.items
        );

        setTableEntries(formattedTableEntries);
        generateTableHeaders(formattedTableEntries);
      } else {
        setTableEntries([]);
        return;
      }
    } else {
      setTableEntries([]);
      return;
    }
  };

  const onSizeAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, variantId: number | undefined) => {
      const newAmountNumber = Number(e.target.value);

      if (variantId !== undefined) {
        const newTableEntries = tableEntries.map((entry) => {
          const updatedSizes = entry.sizes?.map((size) => {
            if (size.variantId === variantId) {
              return {
                ...size,
                amount: newAmountNumber,
              };
            }
            return size;
          });

          return {
            ...entry,
            sizes: updatedSizes,
          };
        });
        setTableEntries(newTableEntries);
        let totalAmount = 0;

        newTableEntries.forEach((entry) => {
          entry.sizes?.forEach((size) => {
            if (size.amount !== undefined) {
              totalAmount += size.amount;
            }
          });
        });

        setAmount(totalAmount);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableEntries]
  );

  const fetchTableEntries = async () => {
    setLoading(true);
    await getOrderItems();
    setLoading(false);
  };

  useEffect(() => {
    if (productionOrder?.amountProduced) {
      if (productionOrder?.finishedAmount) {
        setAmount(
          productionOrder.amountProduced - productionOrder.finishedAmount
        );
        setPendingAmount(
          productionOrder.amountProduced - productionOrder.finishedAmount
        );
      } else {
        setAmount(productionOrder.amountProduced);
        setPendingAmount(productionOrder.amountProduced);
      }
    } else {
      setAmount(0);
      setPendingAmount(0);
    }
    setSizeErrors("");
    fetchTableEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionOrder]);

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Marcar productos como fallados</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
          <fieldset>
            <CFormGroup>
              <CRow>
                <CCol md={5}>
                  <CLabel>Cantidad restante por finalizar:</CLabel>
                </CCol>
                <CCol md={7}>
                  <CInput
                    type="number"
                    placeholder="Cantidad"
                    disabled
                    value={emptyValueOnUndefined(pendingAmount)}
                  ></CInput>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={5}>
                  <CLabel>Cantidad Marcando como fallado:</CLabel>
                </CCol>
                <CCol md={7}>
                  <CInput
                    type="number"
                    placeholder="Cantidad"
                    disabled
                    value={emptyValueOnUndefined(amount)}
                  ></CInput>
                </CCol>
              </CRow>
            </CFormGroup>
            <CRow>
              <CCol md={2}>
                <h5>Cantidades</h5>
              </CCol>
            </CRow>
            {sizeErrors !== "" ? (
              <CRow>
                <CCol md={12}>
                  <p className="text-danger">{sizeErrors}</p>
                </CCol>
              </CRow>
            ) : (
              <></>
            )}

            {!loading ? (
              <CRow>
                <CCol md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered table-fixed">
                      <thead>
                        <tr>
                          {tableColumns.map((title, ix) => {
                            return (
                              <th
                                className="text-center"
                                key={ix}
                                style={{
                                  verticalAlign: "middle",
                                  overflow: "hidden",
                                }}
                              >
                                <div className="d-inline">{title}</div>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tableEntries.map((tableEntry, ix) => {
                          return (
                            <tr
                              style={{
                                verticalAlign: "middle",
                                overflow: "hidden",
                              }}
                              key={ix}
                            >
                              <td
                                className={"text-center"}
                                style={{ padding: 0, width: "120px" }}
                              >
                                <p>{tableEntry.colorName}</p>
                              </td>
                              {tableColumns.map((column, ix) => {
                                if (column === "Color") {
                                  return <></>;
                                }
                                const matchSize = tableEntry.sizes?.find(
                                  (item) => item.sizeName === column
                                );
                                const disabled = matchSize === undefined;
                                return (
                                  <td
                                    key={ix}
                                    style={{ padding: 0, width: "70px" }}
                                  >
                                    <CInput
                                      type="number"
                                      min={0}
                                      max={matchSize?.maxAmountAllowed}
                                      value={
                                        disabled
                                          ? ""
                                          : matchSize
                                          ? emptyValueOnUndefined(
                                              matchSize.amount
                                            )
                                          : ""
                                      }
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) =>
                                        onSizeAmountChange(
                                          e,
                                          matchSize!.variantId
                                        )
                                      }
                                      disabled={disabled}
                                    ></CInput>
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                        {tableEntries.length === 0 ? (
                          <tr>
                            <td colSpan={tableColumns.length}>
                              No hay tamaños disponibles para esta prenda.
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                    <br />
                    <br />
                  </div>
                </CCol>
              </CRow>
            ) : (
              <Spinner
                animation="grow"
                style={{
                  height: "17px",
                  width: "17px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
            )}
          </fieldset>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onSubmitClick} color="danger">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Confirmando..." : "Confirmar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ProductionPartialFailedModal;
