import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import Store, { newStore } from "../../models/store";
import StoreForm from "./StoreForm";

interface StoreUpdateModalProps {
  show: boolean;
  store?: Store;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const StoreUpdateModal: React.FC<StoreUpdateModalProps> = ({
  show,
  store,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialStore, setInitialStore] = useState<Store>(store ? store : newStore());

  const modalTitle = store
    ? `Editar tienda: ${store.name}`
    : `Crear tienda nueva`;

  const onClose = () => {
    setInitialErrors({});
    setInitialStore(newStore());
    onCancel();
  };
  useEffect(() => {
    setInitialStore(store ? store : newStore());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <StoreForm
            initialStore={initialStore}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default StoreUpdateModal;
