import {
    CContainer,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
  } from "@coreui/react";
import { useEffect, useState } from "react";
import SaleDetail from "./SaleDetail";
  
import StoreSale from "../../models/store-sale";
  
  interface SaleDetailModalProps {
    show: boolean;
    storeSale?: StoreSale;
    onCancel: () => void | Promise<void>;
  }
  
  const SaleDetailModal: React.FC<SaleDetailModalProps> = ({
    show,
    storeSale,
    onCancel,
  }) => {
    
    const [initialStoreSale, setInitialStoreSale] = useState<StoreSale>(storeSale ? storeSale : {});
  
    const modalTitle = storeSale?.documentNumber
      ? `Detalle: ${storeSale.documentNumber}`
      : `Detalle`;
  
    const onClose = () => {
      setInitialStoreSale({});
      onCancel();
    };
    useEffect(() => {
      setInitialStoreSale(storeSale ? storeSale : {});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeSale]);
  
    return (
      <CModal show={show} onClose={onClose} size="xl">
        <CModalHeader closeButton>
          <CModalTitle>{modalTitle}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <SaleDetail
            storeSale={initialStoreSale}
            onCancel={onCancel}
            ></SaleDetail>
          </CContainer>
        </CModalBody>
      </CModal>
    );
  };
  
  export default SaleDetailModal;
  