import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { successAlert, warningAlert } from "../utils/messages";
import DeliveryOrderForm from "./DeliveryOrderForm";
import Errors from "../../models/errors";
import { getItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { getRoutePath } from "../../routes";
import { Spinner } from "react-bootstrap";
import DeliveryOrder from "../../models/delivery-order";

const DeliveryOrderFormView = () => {
  const [deliveryOrder, setDeliveryOrder] = useState<DeliveryOrder | undefined>(
    undefined
  );
  const [errors] = useState<Errors>({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const getInitialValues = async () => {
    setLoading(true);
    const deliveryOrderPromise = fetchSaleOrder(Number(id));
    await deliveryOrderPromise;
    setLoading(false);
  };

  const fetchSaleOrder = async (id: number) => {
    const deliveryOrderStatus = await getItem<DeliveryOrder>(
      `/delivery_orders/${id}/`
    );
    if (deliveryOrderStatus.status === SUCCESS) {
      if (deliveryOrderStatus.data !== undefined) {
        setDeliveryOrder(deliveryOrderStatus.data);
      }
    } else {
      const message = deliveryOrderStatus.detail
        ? deliveryOrderStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  useEffect(() => {
    if (id === undefined || isNaN(Number(id))) {
      setLoading(false);
      return;
    }
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeliveryOrderEditCancel = () => {
    setDeliveryOrder(undefined);
  };

  const onDeliveryOrderEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    history.push(getRoutePath("deliveryOrders"));
    setLoading(true);
  };

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol lg="12">
            <CCard>
              <CCardHeader className="d-flex flex-row mb-3">
                <div className="p-2 ">
                  <h3>
                    {deliveryOrder
                      ? `Ver/Editar Orden de envío #${deliveryOrder.id}`
                      : "Crear nueva orden de envío"}
                  </h3>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading ? (
                  <Spinner animation="grow" />
                ) : (
                  <DeliveryOrderForm
                    initialDeliveryOrder={deliveryOrder}
                    initialErrors={errors}
                    disabled={deliveryOrder !== undefined}
                    onCancel={onDeliveryOrderEditCancel}
                    onSuccess={onDeliveryOrderEditSuccess}
                  />
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default DeliveryOrderFormView;
