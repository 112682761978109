import { CButton } from "@coreui/react";
import { useHistory } from "react-router-dom";

const BackButton = () => {
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <CButton color="primary" onClick={handleBack}>
      <i className="fa fa-arrow-left"></i> Atrás
    </CButton>
  );
};

export default BackButton;
