import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Loading from "../indicators/Loading";
import Error from "../error/Error";
import {
  setCompany,
  setCompanyErrorLoading,
  setCompanyLoading,
} from "../../redux/company/company-slice";
import { getItem } from "../../api/generics";
import Company from "../../models/company";
import { SUCCESS } from "../../utils/constants/tags";

interface AppDataLoadedFirstProps {
  children: React.ReactNode;
}

const AppDataLoadedFirst: React.FC<AppDataLoadedFirstProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const loaded = useSelector((state: RootState) => state.company.loaded);

  const loading = useSelector((state: RootState) => state.company.loading);

  const error = useSelector((state: RootState) => state.company.error);

  const loadData = async () => {
    if (loading) {
      return;
    }

    dispatch(setCompanyLoading(true));

    const requestStatus = await getItem<Company>("/companies/default/");

    if (requestStatus.status !== SUCCESS) {
      dispatch(setCompanyErrorLoading(true));
      return;
    }

    dispatch(setCompany(requestStatus.data!));
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return <>{children}</>;
};

export default AppDataLoadedFirst;
