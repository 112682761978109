import Color from "./color";
import ProductionOrderInputMaterial from "./production-input-material";
import ProductionOrderLaborPrice from "./production-labor-price";
import Size from "./size";
import Variant from "./variant";

export interface ProductionOrderSize {
  id?: number;
  orderId?: number;
  sizeId?: number;
  sizeName?: string;
  size?: Size;
  amount?: number;
}

export interface ProductionOrderItem {
  id?: number;
  orderId?: number;
  variantId?: number;
  variant?: Variant;
  colorId?: number;
  colorName?: string;
  color?: Color;
  sizeId?: number;
  sizeName?: string;
  size?: Size;
  amount?: number;
  lostAmount?: number;
  finishedAmount?: number;
  barcodeBatch?: string;
  productCodeIdentifier?: string;
  productName?: string;
}

interface ProductionOrder {
  id?: number;
  companyId?: number;
  productId?: number;
  productName?: string;
  productCodeIdentifier?: string;
  productReferencePrice?: number;
  productReferencePriceWholesale?: number;
  productReferencePriceSuperWholesale?: number;
  variantId?: number;
  colorId?: number;
  color?: string;
  sizeId?: number;
  size?: string;
  orderStatus?: string;
  currentStage?: string;
  totalLaborPrice?: number;
  totalMaterialPrice?: number;
  totalCostPrice?: number;
  expectedProfit?: number;
  expectedProfitWholesale?: number;
  expectedProfitSuperWholesale?: number;
  achievedProfit?: number;
  amountProduced?: number;
  amountLost?: number;
  startDate?: string;
  endDate?: string;
  inStageFrom?: string;
  createdAt?: string;
  updatedAt?: string;
  productionLaborPrices?: ProductionOrderLaborPrice[];
  productionMaterials?: ProductionOrderInputMaterial[];
  currentStageId?: number;
  originStageId?: number;
  inventoryLocationId?: number;
  currentStageAllowRelatedAmounts?: boolean;
  currentStageIsCut?: boolean;
  currentStageIsConfection?: boolean;
  currentStageIsLaundry?: boolean;
  stageAmount?: number;
  stageAmountTotal?: number;
  stageAmountPending?: number;
  finalObs?: string;
  cutAmounts?: ProductionOrderSize[];
  orderItems?: ProductionOrderItem[];
  reportUrl?: string;
  cutOrConfectionAmount?: number;
  laundryAmount?: number;
  backFromLaundryAmount?: number;
  finishedAmount?: number;
}

export interface ProductionOrderStatusAmount {
  id?: number;
  companyId?: number;
  productionOrderId?: number;
  stageId?: number;
  stageName?: string;
  totalAmount?: number;
  pendingAmount?: number;
}

export const newProductionOrder = (): ProductionOrder => {
  return {};
};

interface StatusData {
  class: string;
  name: string;
}

interface StatusesMap {
  [index: string]: StatusData;
}

export const PRODUCTIONSTATUSES: StatusesMap = {
  pending: {
    class: "info",
    name: "Pendiente",
  },
  ongoing: {
    class: "primary",
    name: "En curso",
  },
  finished: {
    class: "success",
    name: "Finalizado",
  },
  cancelled: {
    class: "danger",
    name: "Cancelado",
  },
};

export const getStatusLabel = (key: string) => {
  return PRODUCTIONSTATUSES[key].name;
};

export const getStatusClass = (key: string) => {
  return PRODUCTIONSTATUSES[key].class;
};

export default ProductionOrder;
