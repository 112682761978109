import { CButton, CCol, CFormGroup, CInput, CRow } from "@coreui/react";
import React, { useCallback, useEffect, useState } from "react";
import { FieldErrors } from "../form/FieldErrors";
import Errors, { errorsAreSame, getFieldErrors } from "../../models/errors";
import { emptyValueOnUndefined } from "../../utils/fields";
import InputMaterial, {
  InputMaterialAmount,
} from "../../models/input-material";
import InputBatchSelect from "../inventory-input-batches/InputBatchSelect";
import InventoryInputBatch from "../../models/inventory-input-batch";

interface InputMaterialAmountFormItemProps {
  value: InputMaterialAmount;
  initialInputMaterial?: InputMaterial;
  onDelete: (_: InputMaterialAmount) => void;
  onChange: (_: InputMaterialAmount) => void;
  errors: Errors;
}

const InputMaterialAmountFormItem: React.FC<
  InputMaterialAmountFormItemProps
> = ({ value, initialInputMaterial, onDelete, onChange, errors }) => {
  const [editingItem, setEditingItem] = useState<InputMaterialAmount>({
    ...value,
    inputMaterial: initialInputMaterial,
    inputMaterialId: initialInputMaterial?.id,
    inputMaterialIdentifier: initialInputMaterial?.identifier,
  });
  const [batch, setBatch] = useState<InventoryInputBatch | null>(null);

  const [inputMaterial, setInputMaterial] = useState<InputMaterial | null>(
    initialInputMaterial ? initialInputMaterial : null
  );

  const onDeleteClick = useCallback(() => {
    onDelete(editingItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  const onBatchChange = useCallback(
    (newBatch: InventoryInputBatch | null) => {
      setBatch(newBatch);
      const newItem: InputMaterialAmount = {
        ...editingItem,
        inventoryInputBatch: newBatch !== null ? newBatch : undefined,
        inventoryInputBatchId: newBatch !== null ? newBatch.id : undefined,
        batchIdentifier: newBatch !== null ? newBatch.purchaseDate : undefined,
      };
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(e.target.value);
      const newItem = {
        ...editingItem,
        amount: newValue !== null ? newValue : undefined,
      };
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  useEffect(() => {
    setInputMaterial(initialInputMaterial ? initialInputMaterial : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInputMaterial]);

  return (
    <div className="section border rounded mb-2 p-2">
      <CFormGroup>
        <CRow>
          <CCol md={1} className={"mt-2"}>
            Lote:
          </CCol>
          <CCol md={6}>
            <InputBatchSelect
              value={batch}
              key={inputMaterial !== null ? inputMaterial.id : 0}
              inputMaterialId={
                initialInputMaterial !== undefined
                  ? initialInputMaterial.id
                  : undefined
              }
              onChange={onBatchChange}
            ></InputBatchSelect>
            <FieldErrors
              errors={getFieldErrors("batchId", errors) as string[]}
            ></FieldErrors>
          </CCol>
          <CCol md={1} className={"mt-2"}>
            Cantidad:
          </CCol>
          <CCol md={3}>
            <CInput
              type="number"
              value={emptyValueOnUndefined(editingItem.amount)}
              onChange={onAmountChange}
              placeholder="Cant."
              step="0.1"
            ></CInput>
            <FieldErrors
              errors={getFieldErrors("amount", errors) as string[]}
            ></FieldErrors>
          </CCol>
          <CCol md={1}>
            <CButton
              className="btn btn-danger float-right"
              onClick={onDeleteClick}
            >
              <i className="fa fa-trash"></i>
            </CButton>
          </CCol>
        </CRow>
      </CFormGroup>
    </div>
  );
};

const propsAreEqual = (
  prevItemProps: InputMaterialAmountFormItemProps,
  nextItemProps: InputMaterialAmountFormItemProps
): boolean => {
  const areEqual =
    prevItemProps.value.inventoryInputBatch ===
      nextItemProps.value.inventoryInputBatch &&
    prevItemProps.value.amount === nextItemProps.value.amount &&
    prevItemProps.value.inputMaterial === nextItemProps.value.inputMaterial &&
    prevItemProps.value.inputMaterialId ===
      nextItemProps.value.inputMaterialId &&
    prevItemProps.value.inventoryInputBatchId ===
      nextItemProps.value.inventoryInputBatchId &&
    prevItemProps.value.inputMaterialIdentifier ===
      nextItemProps.value.inputMaterialIdentifier &&
    prevItemProps.value.batchIdentifier ===
      nextItemProps.value.batchIdentifier &&
    errorsAreSame(prevItemProps.errors, nextItemProps.errors);

  return areEqual;
};

export default React.memo(InputMaterialAmountFormItem, propsAreEqual);
