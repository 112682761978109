import ColorCrud from "../components/color/ColorCrud";

const ColorCrudPage = () => {
  return (
    <>
      <ColorCrud />
    </>
  );
};

export default ColorCrudPage;
