import InputMaterialHistoryList from "../components/input-material-history/InputMaterialHistoryList";

const InputMaterialHistoryPage = () => {
  return (
    <>
      <InputMaterialHistoryList />
    </>
  );
};

export default InputMaterialHistoryPage;
