import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
  CLink,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import Client, { newClientPayment } from "../../models/client";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import ClientDeleteModal from "./ClientDeleteModal";
import ClientUpdateModal from "./ClientUpdateModal";
import ClientPaymentModal from "../payments/PaymentModal";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import {
  CLIENTS_CREATE,
  CLIENTS_DESTROY,
  CLIENTS_UPDATE,
  PAYMENTS_CREATE,
  PAYMENTS_RETRIEVE,
} from "../../auth/permissions";
import { defaultValueOnUndefined } from "../../utils/fields";

const ITEMS_PER_PAGE = 10;

const ClientList = () => {
  const fields = [
    {
      key: "name",
      _classes: ["text-center"],
      label: "Nombre",
    },
    {
      key: "ruc",
      _classes: ["text-center"],
      label: "RUC",
    },
    {
      key: "totalAmount",
      _classes: ["text-center"],
      label: "Total en productos",
    },
    {
      key: "debt",
      _classes: ["text-center"],
      label: "Deuda ",
    },
    {
      key: "discountsMade",
      _classes: ["text-center"],
      label: "Descuentos realizados",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showClientEditModal, setShowClientEditModal] = useState(false);
  const [showClientDeleteModal, setShowClientDeleteModal] = useState(false);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [client, setClient] = useState<Client | undefined>(undefined);
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState<Client[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    return map;
  };

  const fetchClients = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const clientsStatus = await getList<Client>(
      "/clients/",
      limit,
      offset,
      urlParams
    );
    if (clientsStatus.status === SUCCESS) {
      if (clientsStatus.data !== undefined) {
        const count = clientsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setClients(clientsStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = clientsStatus.detail
        ? clientsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setShowClientEditModal(true);
  };

  const onUpdateClick = (client: Client) => {
    setClient(client);
    setShowClientEditModal(true);
  };

  const onPayClick = (client: Client) => {
    setClient(client);
    setShowPaymentModal(true);
  };

  const onDeleteClick = (client: Client) => {
    setClient(client);
    setShowClientDeleteModal(true);
  };

  const onClientEditCancel = () => {
    setClient(undefined);
    setShowClientEditModal(false);
  };

  const onClientEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowClientEditModal(false);
    setClients([]);
    setLoading(true);
    fetchClients();
  };

  const onClientDeleteCancel = () => {
    setClient(undefined);
    setShowClientDeleteModal(false);
  };

  const onClientDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowClientDeleteModal(false);
    setClients([]);
    setLoading(true);
    fetchClients();
  };

  const onPayCancel = () => {
    setClient(undefined);
    setShowPaymentModal(false);
  };

  const onPaySuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowPaymentModal(false);
    setClients([]);
    setLoading(true);
    fetchClients();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("search", search);
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("clients")) {
        return;
      }
      fetchClients();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Clientes</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent permissionName={CLIENTS_CREATE}>
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={clients}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    ruc: (item: Client) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.ruc)}
                        </td>
                      );
                    },
                    totalAmount: (item: Client) => {
                      const totalAmount = item.clientPaymentAccount
                        ? item.clientPaymentAccount.totalSpent
                        : 0;
                      const totalAmountFormatted = formatToCurrency(
                        totalAmount ? totalAmount : 0,
                        PYG
                      );
                      return (
                        <td className="text-center">{totalAmountFormatted}</td>
                      );
                    },
                    debt: (item: Client) => {
                      const debtAmount = item.clientPaymentAccount
                        ? item.clientPaymentAccount.totalPending
                        : 0;
                      const debtAmountFormatted = formatToCurrency(
                        debtAmount ? debtAmount : 0,
                        PYG
                      );
                      return (
                        <td className="text-center">{debtAmountFormatted}</td>
                      );
                    },
                    discountsMade: (item: Client) => {
                      const discountAmount = item.clientPaymentAccount
                        ? item.clientPaymentAccount.discountsMade
                        : 0;
                      const discountAmountFormatted = formatToCurrency(
                        discountAmount ? discountAmount : 0,
                        PYG
                      );
                      return (
                        <td className="text-center">
                          {discountAmountFormatted}
                        </td>
                      );
                    },
                    actions: (item: Client) => {
                      const checkPaymentsButton = (
                        <PermissionRequiredComponent
                          permissionName={PAYMENTS_RETRIEVE}
                        >
                          <CLink to={`/client/${item.id}/payments/`}>
                            <CButton className="text-white" color="primary">
                              <i className="fa fa-eye"></i>
                            </CButton>
                          </CLink>
                        </PermissionRequiredComponent>
                      );
                      const paymentButton = (
                        <PermissionRequiredComponent
                          permissionName={PAYMENTS_CREATE}
                        >
                          <CButton
                            className="text-white"
                            color="success"
                            onClick={() => {
                              onPayClick(item);
                            }}
                          >
                            <i className="fa fa-money-bill"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={CLIENTS_UPDATE}
                        >
                          <CButton
                            className="text-white"
                            color="warning"
                            onClick={() => {
                              onUpdateClick(item);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );
                      const deleteButton = (
                        <PermissionRequiredComponent
                          permissionName={CLIENTS_DESTROY}
                        >
                          <CButton
                            className="text-white"
                            color="danger"
                            onClick={() => {
                              onDeleteClick(item);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {checkPaymentsButton}
                            {paymentButton}
                            {editButton}
                            {deleteButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <ClientUpdateModal
        show={showClientEditModal}
        onCancel={onClientEditCancel}
        onSuccess={onClientEditSuccess}
        client={client}
      />
      <ClientDeleteModal
        show={showClientDeleteModal}
        onCancel={onClientDeleteCancel}
        onSuccess={onClientDeleteSuccess}
        client={client}
      />
      <ClientPaymentModal
        show={showPaymentModal}
        onCancel={onPayCancel}
        onSuccess={onPaySuccess}
        client={client}
        payment={newClientPayment()}
      />
    </>
  );
};

export default ClientList;
