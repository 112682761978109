import DeliveryOrderCrud from "../components/delivery-order/DeliveryOrderCrud";

const DeliveryOrderCrudPage = () => {
  return (
    <>
      <DeliveryOrderCrud />
    </>
  );
};

export default DeliveryOrderCrudPage;
