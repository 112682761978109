import Color from "./color";
import Product from "./product";
import Size from "./size";
import Variant from "./variant";

interface StoreRequest {
  id?: number;
  companyId?: number;
  storeId?: number;
  storeName?: string;
  locationId?: number;
  clothingProductId?: number;
  clothingProduct?: Product;
  productVariantId?: number;
  productVariant?: Variant;
  productCodeIdentifier?: string;
  productName?: string;
  variantName?: string;
  colorName?: string;
  color?: Color;
  colorId?: number;
  sizeName?: string;
  size?: Size;
  sizeId?: number;
  requestDate?: string;
  obs?: string;
  requestedBy?: string;
  amount?: number;
  fulfilledAmount?: number;
  transferId?: number;
  status?: string;
  variantPrice?: number;
  totalValue?: number;
  fulfilledValue?: number;
}

export const newStoreRequest = (): StoreRequest => {
  return {};
};

export interface StatusData {
  class: string;
  name: string;
}

interface StatusesMap {
  [index: string]: StatusData;
}

export const STOREREQUESTSTATUSES: StatusesMap = {
  pending: {
    class: "info",
    name: "Pendiente",
  },
  issued: {
    class: "success",
    name: "Atendido",
  },
};

export const getStoreRequestStatusLabel = (key: string) => {
  return STOREREQUESTSTATUSES[key].name;
};

export const getStoreRequestStatusClass = (key: string) => {
  return STOREREQUESTSTATUSES[key].class;
};

export default StoreRequest;
