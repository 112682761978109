import InventoryProductsList from "../components/inventory-products/InventoryProductsList";

const InventoryProductListPage = () => {
  return (
    <>
      <InventoryProductsList />
    </>
  );
};

export default InventoryProductListPage;
