interface Size {
  id?: number;
  companyId?: number,
  size?: string;
  isKidSize?:boolean;
}

export const newSize = (): Size => {
  return {
  };
};

export default Size;
