import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import userReducer from "./redux/reducers/user";
import DashboardReducer from "./redux/reducers/dashboard";
import CompanyReducer from "./redux/company/company-slice";
import transfersReducer from "./redux/inventory-transfer/inventory-transfer-slice";

const rootReducer = combineReducers({
  user: userReducer,
  dashboard: DashboardReducer,
  company: CompanyReducer,
  transfers: transfersReducer,
});

const middleware = [thunk];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export type RootState = ReturnType<typeof rootReducer>;
export default store;
