import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import Product, { newProduct } from "../../models/product";
import ProductForm from "./ProductForm";

interface ProductUpdateModalProps {
  show: boolean;
  product?: Product;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ProductUpdateModal: React.FC<ProductUpdateModalProps> = ({
  show,
  product,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialProduct, setInitialProduct] = useState<Product>(
    product ? product : newProduct()
  );

  const modalTitle = product
    ? `Editar Prenda: ${product.name}`
    : `Crear Prenda nueva`;

  const onClose = () => {
    setInitialErrors({});
    setInitialProduct(newProduct());
    onCancel();
  };
  useEffect(() => {
    setInitialProduct(product ? product : newProduct());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <ProductForm
            initialProduct={initialProduct}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default ProductUpdateModal;
