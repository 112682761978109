import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
  CBadge,
  CSelect,
  CLink,
  CTooltip,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getItem, getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, successAlert, warningAlert } from "../utils/messages";
import DeliveryOrder, {
  getStatusClass,
  getStatusLabel,
} from "../../models/delivery-order";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
import { DATEDDMMYYY, formatDate } from "../../utils/dates";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { DELIVERYORDERPAYMENTSTATUSES } from "../../models/delivery-order";
import { getObjectMinioUrl } from "../../minio/urls";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import { DELIVERY_ORDERS_CREATE } from "../../auth/permissions";
import DeliveryOrderRevertModal from "./DeliveryOrderRevertModal";
import { deliveryOrderIsReversible } from "../../utils/delivery-orders";

const ITEMS_PER_PAGE = 20;

const DeliveryOrderList = () => {
  const fields = [
    {
      key: "id",
      _classes: ["text-center"],
      label: "N° de Orden",
    },
    {
      key: "orderDate",
      _classes: ["text-center"],
      label: "Fecha de Orden",
    },
    {
      key: "clientName",
      _classes: ["text-center"],
      label: "Cliente",
    },
    {
      key: "paymentStatus",
      _classes: ["text-center"],
      label: "Estado de pago",
    },
    {
      key: "estimatedDate",
      _classes: ["text-center"],
      label: "Fecha estimada de pago",
    },
    {
      key: "saleOrderId",
      _classes: ["text-center"],
      label: "N° Orden de venta",
    },
    {
      key: "totalPriceNoDiscount",
      _classes: ["text-center"],
      label: "Monto total(sin Descuento)",
    },
    {
      key: "totalPrice",
      _classes: ["text-center"],
      label: "Monto total(con Descuento)",
    },
    {
      key: "paidPrice",
      _classes: ["text-center"],
      label: "Monto Pagado",
    },
    {
      key: "obs",
      _classes: ["text-center"],
      label: "Observaciones",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showDeliveryOrderRevertModal, setShowDeliveryOrderRevertModal] =
    useState(false);

  const [deliveryOrder, setDeliveryOrder] = useState<DeliveryOrder | undefined>(
    undefined
  );
  const [startDate, setStartDate] = useState<string | moment.Moment>("");
  const [endDate, setEndDate] = useState<string | moment.Moment>("");
  const [statuses, setStatuses] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [deliveryOrders, setDeliveryOrders] = useState<DeliveryOrder[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [pageTotalPrice, setPageTotalPrice] = useState<number | undefined>(0);
  const [pageCostPrice, setPageCostPrice] = useState<number | undefined>(0);
  const [pageProfitPrice, setPageProfitPrice] = useState<number | undefined>(0);
  const [queryTotalPrice, setQueryTotalPrice] = useState<number | undefined>(
    undefined
  );
  const [queryCostPrice, setQueryCostPrice] = useState<number | undefined>(
    undefined
  );
  const [queryProfitPrice, setQueryProfitPrice] = useState<number | undefined>(
    undefined
  );

  const [pagePaidPrice, setPagePaidPrice] = useState<number | undefined>(0);
  const [pagePendingPrice, setPagePendingPrice] = useState<number | undefined>(
    0
  );
  const [pageDiscountedPrice, setPageDiscountedPrice] = useState<
    number | undefined
  >(0);
  const [queryPaidPrice, setQueryPaidPrice] = useState<number | undefined>(
    undefined
  );
  const [queryPendingPrice, setQueryPendingPrice] = useState<
    number | undefined
  >(undefined);
  const [queryDiscountedPrice, setQueryDiscountedPrice] = useState<
    number | undefined
  >(undefined);

  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let orderStatus = urlParams.get("order_status")
      ? urlParams.get("order_status")
      : "";
    let startDate = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : "";
    let endDate = urlParams.get("end_date") ? urlParams.get("end_date") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    if (orderStatus !== "") map.set("order_status", orderStatus);
    if (startDate !== "") map.set("start_date", startDate);
    if (endDate !== "") map.set("end_date", endDate);
    return map;
  };

  const calculatePageTotal = (orders: DeliveryOrder[]): number => {
    const total = orders
      .filter((order) => order.paymentStatus !== "reverted")
      .reduce((acc, order) => {
        const price = order.totalPrice ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const calculatePageCost = (orders: DeliveryOrder[]): number => {
    const total = orders
      .filter((order) => order.paymentStatus !== "reverted")
      .reduce((acc, order) => {
        const price = order.saleProductCosts ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const calculatePageProfit = (orders: DeliveryOrder[]): number => {
    const total = orders
      .filter((order) => order.paymentStatus !== "reverted")
      .reduce((acc, order) => {
        const price = order.saleProfit ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const calculatePagePaid = (orders: DeliveryOrder[]): number => {
    const total = orders
      .filter((order) => order.paymentStatus !== "reverted")
      .reduce((acc, order) => {
        const price = order.paidPrice ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const calculatePagePending = (orders: DeliveryOrder[]): number => {
    const total = orders
      .filter(
        (order) =>
          order.paymentStatus === "pending" || order.paymentStatus === "partial"
      )
      .reduce((acc, order) => {
        const price = (order.totalPrice ?? 0) - (order.paidPrice ?? 0);
        return acc + price;
      }, 0);

    return total;
  };
  const calculatePageDiscounted = (orders: DeliveryOrder[]): number => {
    const total = orders
      .filter((order) => order.paymentStatus === "paid")
      .reduce((acc, order) => {
        const price = (order.totalPrice ?? 0) - (order.paidPrice ?? 0);
        return acc + price;
      }, 0);

    return total;
  };

  const fetchTotals = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    urlParams.set("limit", limit);
    urlParams.set("offset", offset);
    const totalsStatus = await getItem<{
      profitTotal?: number;
      costTotal?: number;
      fullTotal?: number;
      paidTotal?: number;
      pendingTotal?: number;
      discountedTotal?: number;
    }>("/delivery_orders/totals/", urlParams);
    if (totalsStatus.status === SUCCESS) {
      if (totalsStatus.data !== undefined) {
        setQueryTotalPrice(totalsStatus.data.fullTotal);
        setQueryProfitPrice(totalsStatus.data.profitTotal);
        setQueryCostPrice(totalsStatus.data.costTotal);
        setQueryPaidPrice(totalsStatus.data.paidTotal);
        setQueryPendingPrice(totalsStatus.data.pendingTotal);
        setQueryDiscountedPrice(totalsStatus.data.discountedTotal);
      }
    } else {
      const message = totalsStatus.detail
        ? totalsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const fetchDeliveryOrders = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const deliveryOrdersStatus = await getList<DeliveryOrder>(
      "/delivery_orders/",
      limit,
      offset,
      urlParams
    );
    if (deliveryOrdersStatus.status === SUCCESS) {
      if (deliveryOrdersStatus.data !== undefined) {
        const count = deliveryOrdersStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setDeliveryOrders(deliveryOrdersStatus.data.items);
        setPageTotalPrice(calculatePageTotal(deliveryOrdersStatus.data.items));
        setPageCostPrice(calculatePageCost(deliveryOrdersStatus.data.items));
        setPageProfitPrice(
          calculatePageProfit(deliveryOrdersStatus.data.items)
        );
        setPagePaidPrice(calculatePagePaid(deliveryOrdersStatus.data.items));
        setPagePendingPrice(
          calculatePagePending(deliveryOrdersStatus.data.items)
        );
        setPageDiscountedPrice(
          calculatePageDiscounted(deliveryOrdersStatus.data.items)
        );
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = deliveryOrdersStatus.detail
        ? deliveryOrdersStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    if (previousParams.get("order_status")) {
      urlParams.set("order_status", previousParams.get("order_status"));
    }
    if (previousParams.get("start_date")) {
      urlParams.set("start_date", previousParams.get("start_date"));
    }
    if (previousParams.get("end_date")) {
      urlParams.set("end_date", previousParams.get("end_date"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onStartDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setStartDate("");
      return;
    }
    setStartDate(value);
  };

  const onEndDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setEndDate("");
      return;
    }
    setEndDate(value);
  };

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setStatuses(selectedOptions);
  };

  const onUpdateClick = (deliveryOrder: DeliveryOrder) => {
    const path = getRoutePath("deliveryOrderDetail");
    const modifiedWithId = path.replace(":id", String(deliveryOrder.id));
    history.push(modifiedWithId);
  };

  const onRevertClick = (deliveryOrder: DeliveryOrder) => {
    setDeliveryOrder(deliveryOrder);
    setShowDeliveryOrderRevertModal(true);
  };

  const onDeliveryOrderRevertCancel = () => {
    setDeliveryOrder(undefined);
    setShowDeliveryOrderRevertModal(false);
  };

  const onDeliveryOrderRevertSuccess = () => {
    successAlert("Registro revertido con éxito!");
    setShowDeliveryOrderRevertModal(false);
    setDeliveryOrders([]);
    setLoading(true);
    fetchDeliveryOrders();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    if (search !== "") {
      urlParams.set("search", search);
    } else {
      urlParams.delete("search");
    }
    if (statuses.length > 0) {
      urlParams.set("order_status", statuses.join(","));
    } else {
      urlParams.delete("order_status");
    }
    if (moment.isMoment(startDate)) {
      urlParams.set("start_date", startDate.toISOString());
    } else if (startDate !== undefined && startDate !== "") {
      urlParams.set("start_date", startDate);
    } else {
      urlParams.delete("start_date");
    }
    if (moment.isMoment(endDate)) {
      urlParams.set("end_date", endDate.toISOString());
    } else if (endDate !== undefined && endDate !== "") {
      urlParams.set("end_date", endDate);
    } else {
      urlParams.delete("end_date");
    }
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const onPDFClick = async (order: DeliveryOrder) => {
    const pdfState = await getItem<{ url: string }>(
      `/delivery_orders/${order.id}/pdf/`
    );

    if (pdfState.status !== SUCCESS || pdfState.data === undefined) {
      errorAlert("Error al tratar de obtener el PDF");
      return;
    }
    window.open(getObjectMinioUrl(pdfState.data.url), "_blank");
  };

  useEffect(() => {
    fetchDeliveryOrders();
    fetchTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("deliveryOrders")) {
        return;
      }
      fetchDeliveryOrders();
      fetchTotals();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Órdenes de Envío</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent
                  permissionName={DELIVERY_ORDERS_CREATE}
                >
                  <CLink to={getRoutePath("deliveryOrderFormCreate")}>
                    <CButton color="primary" className="float-right">
                      <BsFillPlusCircleFill />
                      &nbsp; Agregar Nuevo
                    </CButton>
                  </CLink>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow>
                <CCol></CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Desde:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onStartDatehange}
                    value={startDate}
                    locale="es/PY"
                    utc
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Hasta:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onEndDatehange}
                    value={endDate}
                    locale="es/PY"
                    utc
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Estado:</CLabel>
                </CCol>
                <CCol>
                  <CSelect
                    type="text"
                    multiple
                    defaultValue={statuses.length > 0 ? statuses : []}
                    onChange={onStatusChange}
                  >
                    <option value={""}>-----</option>
                    {Object.entries(DELIVERYORDERPAYMENTSTATUSES).map(
                      ([key, entry], ix) => {
                        return (
                          <option key={ix} value={key}>
                            {entry.name}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el cliente"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={deliveryOrders}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    id: (item: DeliveryOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.id ? `#${item.id}` : "-"}
                          </span>
                        </td>
                      );
                    },
                    paymentStatus: (item: DeliveryOrder) => {
                      return (
                        <td className="text-center">
                          <CBadge
                            color={
                              item.paymentStatus
                                ? getStatusClass(item.paymentStatus)
                                : undefined
                            }
                          >
                            {item.paymentStatus
                              ? getStatusLabel(item.paymentStatus)
                              : undefined}
                          </CBadge>
                        </td>
                      );
                    },
                    totalPriceNoDiscount: (item: DeliveryOrder) => {
                      const valueToShow =
                        item.totalPriceNoDiscount &&
                        item.totalPriceNoDiscount !== item.totalPrice
                          ? item.totalPriceNoDiscount
                          : item.totalPrice;
                      return (
                        <td className="text-center">
                          <span className="">
                            {valueToShow
                              ? formatToCurrency(valueToShow ?? item, PYG)
                              : "-"}
                          </span>
                        </td>
                      );
                    },
                    totalPrice: (item: DeliveryOrder) => {
                      const valueToShow =
                        item.totalPriceNoDiscount &&
                        item.totalPriceNoDiscount !== item.totalPrice
                          ? item.totalPrice
                          : undefined;
                      return (
                        <td className="text-center">
                          <span className="">
                            {valueToShow
                              ? formatToCurrency(valueToShow, PYG)
                              : "-"}
                          </span>
                        </td>
                      );
                    },
                    paidPrice: (item: DeliveryOrder) => {
                      const paidPrice = item.paidPrice ? item.paidPrice : 0;
                      return (
                        <td className="text-center">
                          <span className="">
                            {formatToCurrency(paidPrice, PYG)}
                          </span>
                        </td>
                      );
                    },
                    saleOrderId: (item: DeliveryOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.saleOrderId ? `#${item.saleOrderId}` : "-"}
                          </span>
                        </td>
                      );
                    },
                    orderDate: (item: DeliveryOrder) => {
                      return (
                        <td className="text-center">
                          {item.orderDate
                            ? formatDate(new Date(item.orderDate), DATEDDMMYYY)
                            : "-"}
                        </td>
                      );
                    },
                    estimatedDate: (item: DeliveryOrder) => {
                      const isPastDue =
                        item.orderEstimatedPayment &&
                        new Date() > new Date(item.orderEstimatedPayment);
                      const isPendingOrPartial =
                        item.paymentStatus === "pending" ||
                        item.paymentStatus === "partial";

                      return (
                        <td
                          className={`text-center ${
                            isPastDue && isPendingOrPartial ? "text-danger" : ""
                          }`}
                        >
                          {item.orderEstimatedPayment
                            ? formatDate(
                                new Date(item.orderEstimatedPayment),
                                DATEDDMMYYY
                              )
                            : "-"}
                        </td>
                      );
                    },
                    obs: (item: DeliveryOrder) => {
                      return (
                        <td className="text-center">
                          {item.obs ? item.obs : "-"}
                        </td>
                      );
                    },
                    actions: (item: DeliveryOrder) => {
                      const detailButton = (
                        <CTooltip content="Ver/Editar(Por devoluciones)">
                          <CButton
                            className="text-white"
                            color="primary"
                            onClick={() => {
                              onUpdateClick(item);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </CButton>
                        </CTooltip>
                      );
                      let pdfButton = null;
                      if (item.reportUrl !== undefined) {
                        pdfButton = (
                          <CTooltip content="PDF">
                            <CButton
                              className="text-white"
                              color="danger"
                              onClick={() => {
                                onPDFClick(item);
                              }}
                            >
                              <i className="fa fa-file-pdf"></i>
                            </CButton>
                          </CTooltip>
                        );
                      }
                      const revertButton = (
                        <CTooltip content="Revertir/Anular">
                          <CButton
                            className="text-white"
                            color="warning"
                            onClick={() => {
                              onRevertClick(item);
                            }}
                          >
                            <i className="fa fa-clock-rotate-left"></i>
                          </CButton>
                        </CTooltip>
                      );

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {pdfButton}
                            {detailButton}
                            {deliveryOrderIsReversible(item) ? (
                              revertButton
                            ) : (
                              <></>
                            )}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
              <CRow className={"mt-2"}>
                <CCol className={"border border-dark"}>
                  <CRow className={"mt-2"}>
                    <CCol>
                      <h5>Valores Totales:</h5>
                    </CCol>
                  </CRow>
                  <CRow className={"mt-2"}>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Total de la pagina visualizada:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {pageTotalPrice
                          ? formatToCurrency(pageTotalPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Total todas las páginas:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {queryTotalPrice
                          ? formatToCurrency(queryTotalPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                  </CRow>
                  <CRow className={"mt-2"}>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Costo de la pagina visualizada:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {pageCostPrice
                          ? formatToCurrency(pageCostPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Costo todas las páginas:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {queryCostPrice
                          ? formatToCurrency(queryCostPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                  </CRow>
                  <CRow className={"mt-2"}>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Ganancia de la pagina visualizada:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {pageProfitPrice
                          ? formatToCurrency(pageProfitPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Ganancia todas las páginas:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {queryProfitPrice
                          ? formatToCurrency(queryProfitPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                  </CRow>
                  <CRow className={"mt-2"}>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Pagado de la pagina visualizada:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {pagePaidPrice
                          ? formatToCurrency(pagePaidPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Pagado todas las páginas:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {queryPaidPrice
                          ? formatToCurrency(queryPaidPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                  </CRow>
                  <CRow className={"mt-2"}>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Pendientes de la pagina visualizada:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {pagePendingPrice
                          ? formatToCurrency(pagePendingPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Pendientes todas las páginas:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {queryPendingPrice
                          ? formatToCurrency(queryPendingPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                  </CRow>
                  <CRow className={"mt-2"}>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Descuentos en Pagos de la pagina visualizada:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {pageDiscountedPrice
                          ? formatToCurrency(pageDiscountedPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Descuentos en Pagos todas las páginas:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {queryDiscountedPrice
                          ? formatToCurrency(queryDiscountedPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <DeliveryOrderRevertModal
        show={showDeliveryOrderRevertModal}
        onCancel={onDeliveryOrderRevertCancel}
        onSuccess={onDeliveryOrderRevertSuccess}
        deliveryOrder={deliveryOrder}
      />
    </>
  );
};

export default DeliveryOrderList;
