import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { successAlert, warningAlert } from "../utils/messages";
import SaleOrderForm from "./SaleOrderForm";
import SaleOrder from "../../models/sale-order";
import Errors from "../../models/errors";
import { getItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { getRoutePath } from "../../routes";
import { Spinner } from "react-bootstrap";

import { BsFillPlusCircleFill } from "react-icons/bs";
import { SUPERWHOLESALE_PRICES_USE } from "../../auth/permissions";
import AllowWithPermissionModal from "../permissions/AllowWithPermissionModal";

const SaleOrderFormView = () => {
  const [saleOrder, setSaleOrder] = useState<SaleOrder | undefined>(undefined);
  const [showWholesaleModal, setShowWholesaleModal] = useState(false);
  const [allowSuperWholesale, setAllowSuperWholesale] = useState(false);

  const [errors] = useState<Errors>({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const onSuperWholesaleClick = () => {
    setShowWholesaleModal(true);
  };

  const onSuperWholeSaleCancel = () => {
    setShowWholesaleModal(false);
  };

  const onSuperWholeSaleSuccess = () => {
    setShowWholesaleModal(false);
    setAllowSuperWholesale(true);
  };

  const getInitialValues = async () => {
    setLoading(true);
    const saleOrderPromise = fetchSaleOrder(Number(id));
    await saleOrderPromise;
    setLoading(false);
  };

  const fetchSaleOrder = async (id: number) => {
    const saleOrderStatus = await getItem<SaleOrder>(`/sale_orders/${id}/`);
    if (saleOrderStatus.status === SUCCESS) {
      if (saleOrderStatus.data !== undefined) {
        setSaleOrder(saleOrderStatus.data);
      }
    } else {
      const message = saleOrderStatus.detail
        ? saleOrderStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  useEffect(() => {
    if (id === undefined || isNaN(Number(id))) {
      setLoading(false);

      return;
    }
    setAllowSuperWholesale(false);
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaleOrderEditCancel = () => {
    setSaleOrder(undefined);
  };

  const onSaleOrderEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    history.push(getRoutePath("saleOrders"));
  };

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol lg="12">
            <CCard>
              <CCardHeader className="d-flex flex-row mb-3">
                <div className="p-2 ">
                  <h3>
                    {saleOrder
                      ? `Modificar Orden de venta # ${saleOrder.id}`
                      : "Crear nueva orden de venta"}
                  </h3>
                </div>
                {!allowSuperWholesale ? (
                  <div className="p-2 ">
                    <CButton
                      color="primary"
                      className="float-right"
                      onClick={onSuperWholesaleClick}
                    >
                      <BsFillPlusCircleFill />
                      &nbsp; Habilitar supermayoristas
                    </CButton>
                  </div>
                ) : (
                  <></>
                )}
              </CCardHeader>
              <CCardBody>
                {loading ? (
                  <Spinner animation="grow" />
                ) : (
                  <SaleOrderForm
                    initialSaleOrder={saleOrder}
                    initialErrors={errors}
                    onCancel={onSaleOrderEditCancel}
                    onSuccess={onSaleOrderEditSuccess}
                    allowUsingSuperWholesale={allowSuperWholesale}
                  />
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <AllowWithPermissionModal
          permission={SUPERWHOLESALE_PRICES_USE}
          title={"Habilitar Precios Supermayoristas"}
          show={showWholesaleModal}
          onCancel={onSuperWholeSaleCancel}
          onSuccess={onSuperWholeSaleSuccess}
        />
      </CContainer>
    </>
  );
};

export default SaleOrderFormView;
