import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem, updateItem, ItemRequestStatus } from "../../api/generics";
import Client, { newClient } from "../../models/client";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

interface InputMarterialFormProps {
  initialClient?: Client;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const SupplierForm: React.FC<InputMarterialFormProps> = ({
  initialClient,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingClient, setEditingClient] = useState<Client>(
    initialClient ? initialClient : newClient()
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingClient({
      ...editingClient,
      name: e.target.value,
    });
  };

  const onRucChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingClient({
      ...editingClient,
      ruc: e.target.value,
    });
  };

  const onDocumentNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingClient({
      ...editingClient,
      documentNumber: e.target.value,
    });
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingClient({
      ...editingClient,
      email: e.target.value,
    });
  };

  const onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingClient({
      ...editingClient,
      phone: e.target.value,
    });
  };

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingClient({
      ...editingClient,
      address: e.target.value,
    });
  };

  const onSave = async () => {
    setSubmitting(true);
    let toSendClient = {
      ...editingClient,
      companyId: company.id,
    };
    let requestPromise: Promise<ItemRequestStatus<Client>>;
    if (editingClient.id === undefined) {
      requestPromise = createItem<Client>("/clients/create/", toSendClient);
    } else {
      requestPromise = updateItem<Client>(
        `/clients/${toSendClient.id}/`,
        toSendClient
      );
    }

    const clientStatus = await requestPromise;

    if (clientStatus.status !== SUCCESS) {
      if (clientStatus.errors !== undefined) {
        setErrors(clientStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (clientStatus.detail !== undefined) {
        message = clientStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingClient(newClient());
  };

  useEffect(() => {
    setEditingClient(initialClient ? initialClient : newClient());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialClient]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingClient.name)}
                onChange={onNameChange}
                placeholder="Nombre de cliente"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("name", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>

        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>RUC:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingClient.ruc)}
                onChange={onRucChange}
                placeholder="RUC de cliente"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("ruc", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Cédula Identidad:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingClient.documentNumber)}
                onChange={onDocumentNumberChange}
                placeholder="Cédula Identidad"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("documentNumber", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Email:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="email"
                value={emptyValueOnUndefined(editingClient.email)}
                onChange={onEmailChange}
                placeholder="Email (Para factura electrónica)"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("email", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Teléfono:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingClient.phone)}
                onChange={onPhoneNumberChange}
                placeholder="Telefono"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("phone", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Dirección:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingClient.address)}
                onChange={onAddressChange}
                placeholder="Dirección"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("address", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>

        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default SupplierForm;
