import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Color from "../../models/color";

const RECORS_PER_SEARCH = 20;

type ColorSelectProps = {
  productId?: number;
  value: Color | null;
  disabled?: boolean; 
  onChange: (_: Color | null) => void;
};

const SingleColorSelect: React.FC<ColorSelectProps> = ({ productId, value, disabled, onChange }) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );
  const loadOptions = async (
    s: string,
    currentOpts: readonly (Color | { options: Color[]; label?: string })[]
  ): Promise<
    Response<Color, { options: Color[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }


    if (productId!== undefined){
      additionalParams.set("clothing_product_id", productId.toString())
    }
    
    const dataPaginated = await getList<Color>(
      "/colors/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: Color[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Color"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: Color) => `${option.color}`}
      getOptionValue={(option: Color) => `${option.id}`}
      value={value}
      isDisabled={disabled}
    />
  );
};

export default SingleColorSelect;
