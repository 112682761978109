import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { singlePost } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import InventoryLocation from "../../models/inventory-location";

interface RecalculateModalProps {
  show: boolean;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const RecalculateModal: React.FC<RecalculateModalProps> = ({
  show,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = `Está seguro de que quiere recalcular los valores de inventarios? Esta acción no se puede deshacer `;

  const onConfirmClick = async () => {
    setSubmitting(true);
    const confirmStatus = await singlePost<InventoryLocation>(
      `/inventory_locations/recalculate_values/`,
      { inventoryValue: 0 }
    );
    if (confirmStatus.status !== SUCCESS) {
      let message = "Ha ocurrido un error!!";
      if (confirmStatus.detail !== undefined) {
        message = confirmStatus.detail;
      }
      errorAlert(message);
    } else {
      onSuccess();
    }

    setSubmitting(false);
  };

  return (
    <CModal show={show} className="modal-primary" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Recalcular Valores de Inventario</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onConfirmClick} color="primary">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Calculando..." : "Confirmar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default RecalculateModal;
