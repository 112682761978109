import StoreDebtList from "../components/store-debt/StoreDebtList";

const StoreDebtListPage = () => {
  return (
    <>
      <StoreDebtList />
    </>
  );
};

export default StoreDebtListPage;
