export const SUPPLIERS_GROUP_LABEL = "Proveedores";
export const SUPPLIERS_RETRIEVE = "suppliers.retrieve";
export const SUPPLIERS_RETRIEVE_LABEL = "Leer";
export const SUPPLIERS_CREATE = "suppliers.create";
export const SUPPLIERS_CREATE_LABEL = "Crear";
export const SUPPLIERS_UPDATE = "suppliers.update";
export const SUPPLIERS_UPDATE_LABEL = "Editar";
export const SUPPLIERS_DESTROY = "suppliers.destroy";
export const SUPPLIERS_DESTROY_LABEL = "Eliminar";

export const CLIENTS_GROUP_LABEL = "Clientes";
export const CLIENTS_RETRIEVE = "clients.retrieve";
export const CLIENTS_RETRIEVE_LABEL = "Leer";
export const CLIENTS_CREATE = "clients.create";
export const CLIENTS_CREATE_LABEL = "Crear";
export const CLIENTS_UPDATE = "clients.update";
export const CLIENTS_UPDATE_LABEL = "Editar";
export const CLIENTS_DESTROY = "clients.destroy";
export const CLIENTS_DESTROY_LABEL = "Eliminar";

export const PRODUCTION_GROUP_LABEL = "Producción";
export const PRODUCTION_RETRIEVE = "production.retrieve";
export const PRODUCTION_RETRIEVE_LABEL = "Leer";
export const PRODUCTION_CREATE = "production.create";
export const PRODUCTION_CREATE_LABEL = "Crear";
export const PRODUCTION_UPDATE = "production.update";
export const PRODUCTION_UPDATE_LABEL = "Editar";
export const PRODUCTION_DESTROY = "production.destroy";
export const PRODUCTION_DESTROY_LABEL = "Eliminar";

export const CLOTHING_MODELS_GROUP_LABEL = "Modelos de Prendas";
export const CLOTHING_MODELS_RETRIEVE = "clothing_models.retrieve";
export const CLOTHING_MODELS_RETRIEVE_LABEL = "Leer";
export const CLOTHING_MODELS_CREATE = "clothing_models.create";
export const CLOTHING_MODELS_CREATE_LABEL = "Crear";
export const CLOTHING_MODELS_UPDATE = "clothing_models.update";
export const CLOTHING_MODELS_UPDATE_LABEL = "Editar";
export const CLOTHING_MODELS_DESTROY = "clothing_models.destroy";
export const CLOTHING_MODELS_DESTROY_LABEL = "Eliminar";

export const COLORS_GROUP_LABEL = "Colores";
export const COLORS_RETRIEVE = "colors.retrieve";
export const COLORS_RETRIEVE_LABEL = "Leer";
export const COLORS_CREATE = "colors.create";
export const COLORS_CREATE_LABEL = "Crear";
export const COLORS_UPDATE = "colors.update";
export const COLORS_UPDATE_LABEL = "Editar";
export const COLORS_DESTROY = "colors.destroy";
export const COLORS_DESTROY_LABEL = "Eliminar";

export const SIZES_GROUP_LABEL = "Tamaños";
export const SIZES_RETRIEVE = "sizes.retrieve";
export const SIZES_RETRIEVE_LABEL = "Leer";
export const SIZES_CREATE = "sizes.create";
export const SIZES_CREATE_LABEL = "Crear";
export const SIZES_UPDATE = "sizes.update";
export const SIZES_UPDATE_LABEL = "Editar";
export const SIZES_DESTROY = "sizes.destroy";
export const SIZES_DESTROY_LABEL = "Eliminar";

export const INVENTORY_PRODUCTS_RETRIEVE_GROUP_LABEL = "Inventario Productos";
export const INVENTORY_PRODUCTS_RETRIEVE = "inventory_products.retrieve";
export const INVENTORY_PRODUCTS_RETRIEVE_LABEL = "Leer";

export const INPUT_MATERIALS_GROUP_LABEL = "Materias Primas";
export const INPUT_MATERIALS_RETRIEVE = "input_materials.retrieve";
export const INPUT_MATERIALS_RETRIEVE_LABEL = "Leer";
export const INPUT_MATERIALS_CREATE = "input_materials.create";
export const INPUT_MATERIALS_CREATE_LABEL = "Crear";
export const INPUT_MATERIALS_UPDATE = "input_materials.update";
export const INPUT_MATERIALS_UPDATE_LABEL = "Editar";
export const INPUT_MATERIALS_DESTROY = "input_materials.destroy";
export const INPUT_MATERIALS_DESTROY_LABEL = "Eliminar";

export const INVENTORY_LOCATIONS_GROUP_LABEL = "Ubicaciones de Inventario";
export const INVENTORY_LOCATIONS_RETRIEVE = "inventory_locations.retrieve";
export const INVENTORY_LOCATIONS_RETRIEVE_LABEL = "Leer";
export const INVENTORY_LOCATIONS_CREATE = "inventory_locations.create";
export const INVENTORY_LOCATIONS_CREATE_LABEL = "Crear";
export const INVENTORY_LOCATIONS_UPDATE = "inventory_locations.update";
export const INVENTORY_LOCATIONS_UPDATE_LABEL = "Editar";
export const INVENTORY_LOCATIONS_DESTROY = "inventory_locations.destroy";
export const INVENTORY_LOCATIONS_DESTROY_LABEL = "Eliminar";

export const INVENTORY_INPUT_BATCHES_GROUP_LABEL = "Tandas de Materias Primas";
export const INVENTORY_INPUT_BATCHES_RETRIEVE =
  "inventory_input_batches.retrieve";
export const INVENTORY_INPUT_BATCHES_RETRIEVE_LABEL = "Leer";
export const INVENTORY_INPUT_BATCHES_CREATE = "inventory_input_batches.create";
export const INVENTORY_INPUT_BATCHES_CREATE_LABEL = "Crear";
export const INVENTORY_INPUT_BATCHES_UPDATE = "inventory_input_batches.update";
export const INVENTORY_INPUT_BATCHES_UPDATE_LABEL = "Editar";
export const INVENTORY_INPUT_BATCHES_DESTROY =
  "inventory_input_batches.destroy";
export const INVENTORY_INPUT_BATCHES_DESTROY_LABEL = "Eliminar";

export const BATCH_AMOUNTS_LABEL = "Rendimiento Materia Prima";
export const BATCH_AMOUNTS_RETRIEVE = "batch_amounts.retrieve";
export const BATCH_AMOUNTS_RETRIEVE_LABEL = "Leer";
export const BATCH_AMOUNTS_UPDATE = "batch_amounts.update";
export const BATCH_AMOUNTS_UPDATE_LABEL = "Editar";

export const SALE_ORDERS_GROUP_LABEL = "Órdenes de Venta";
export const SALE_ORDERS_RETRIEVE = "sale_orders.retrieve";
export const SALE_ORDERS_RETRIEVE_LABEL = "Leer";
export const SALE_ORDERS_CREATE = "sale_orders.create";
export const SALE_ORDERS_CREATE_LABEL = "Crear";
export const SALE_ORDERS_UPDATE = "sale_orders.update";
export const SALE_ORDERS_UPDATE_LABEL = "Editar";
export const SALE_ORDERS_DESTROY = "sale_orders.destroy";
export const SALE_ORDERS_DESTROY_LABEL = "Eliminar";

export const INVENTORY_TRANSFERS_GROUP_LABEL = "Envío a tiendas";
export const INVENTORY_TRANSFERS_RETRIEVE = "inventory_transfers.retrieve";
export const INVENTORY_TRANSFERS_RETRIEVE_LABEL = "Leer";
export const INVENTORY_TRANSFERS_CREATE = "inventory_transfers.create";
export const INVENTORY_TRANSFERS_CREATE_LABEL = "Crear";
export const INVENTORY_TRANSFERS_UPDATE = "inventory_transfers.update";
export const INVENTORY_TRANSFERS_UPDATE_LABEL = "Editar";
export const INVENTORY_TRANSFERS_DESTROY = "inventory_transfers.destroy";
export const INVENTORY_TRANSFERS_DESTROY_LABEL = "Eliminar";

export const DELIVERY_ORDERS_GROUP_LABEL = "Órdenes de Envío";
export const DELIVERY_ORDERS_RETRIEVE = "delivery_orders.retrieve";
export const DELIVERY_ORDERS_RETRIEVE_LABEL = "Leer";
export const DELIVERY_ORDERS_CREATE = "delivery_orders.create";
export const DELIVERY_ORDERS_CREATE_LABEL = "Crear";
export const DELIVERY_ORDERS_UPDATE = "delivery_orders.update";
export const DELIVERY_ORDERS_UPDATE_LABEL = "Editar";
export const DELIVERY_ORDERS_DESTROY = "delivery_orders.destroy";
export const DELIVERY_ORDERS_DESTROY_LABEL = "Eliminar";

export const PAYMENTS_GROUP_LABEL = "Pagos";
export const PAYMENTS_RETRIEVE = "payments.retrieve";
export const PAYMENTS_RETRIEVE_LABEL = "Leer";
export const PAYMENTS_CREATE = "payments.create";
export const PAYMENTS_CREATE_LABEL = "Crear";
export const PAYMENTS_UPDATE = "payments.update";
export const PAYMENTS_UPDATE_LABEL = "Editar";

export const USERS_GROUP_LABEL = "Usuarios";
export const USERS_RETRIEVE = "users.retrieve";
export const USERS_RETRIEVE_LABEL = "Leer";
export const USERS_CREATE = "users.create";
export const USERS_CREATE_LABEL = "Crear";
export const USERS_UPDATE = "users.update";
export const USERS_UPDATE_LABEL = "Editar";
export const USERS_DESTROY = "users.destroy";
export const USERS_DESTROY_LABEL = "Eliminar";

export const DISCOUNTS_GROUP_LABEL = "Gestión de Descuentos";
export const DISCOUNTS_RETRIEVE = "discounts.retrieve";
export const DISCOUNTS_RETRIEVE_LABEL = "Leer";
export const DISCOUNTS_CREATE = "discounts.create";
export const DISCOUNTS_CREATE_LABEL = "Crear";
export const DISCOUNTS_UPDATE = "discounts.update";
export const DISCOUNTS_UPDATE_LABEL = "Editar";
export const DISCOUNTS_DESTROY = "discounts.destroy";
export const DISCOUNTS_DESTROY_LABEL = "Eliminar";

export const SUPERWHOLESALE_PRICES_LABEL = "Precios Super Mayoristas";
export const SUPERWHOLESALE_PRICES_USE = "superwholesale.use";
export const SUPERWHOLESALE_PRICES_USE_LABEL = "Leer";

export const INVENTORY_VALUES_LABEL = "Recalcular valor de inventarios";
export const INVENTORY_VALUES_USE = "inventory_values.use";
export const INVENTORY_VALUES_USE_LABEL = "Leer";

export const STORE_SALES_LABEL = "Descuentos Manuales Tienda";
export const STORE_SALES_RETRIEVE = "store_sales.retrieve";
export const STORE_SALES_RETRIEVE_LABEL = "Leer";

export const MANUAL_DISCOUNT_LABEL = "Historial ventas Sucursal";
export const MANUAL_DISCOUNT_USE = "manualdiscounts.use";
export const MANUAL_DISCOUNT_USE_LABEL = "Leer";

export const PRODUCT_HISTORY_LABEL = "Historial Movimiento de Prendas";
export const PRODUCT_HISTORY_RETRIEVE = "inventory_product_logs.retrieve";
export const PRODUCT_HISTORY_RETRIEVE_LABEL = "Leer";

export const INPUT_MATERIALS_HISTORY_LABEL =
  "Historial Movimiento de Mat. Primas";
export const INPUT_MATERIALS_HISTORY_RETRIEVE =
  "input_material_movement_logs.retrieve";
export const INPUT_MATERIALS_HISTORY_RETRIEVE_LABEL = "Leer";

export const PERMISSION_GROUPS: Map<
  string,
  { value: string; label: string }[]
> = new Map([
  [
    SUPPLIERS_GROUP_LABEL,
    [
      {
        value: SUPPLIERS_RETRIEVE,
        label: SUPPLIERS_RETRIEVE_LABEL,
      },
      {
        value: SUPPLIERS_CREATE,
        label: SUPPLIERS_CREATE_LABEL,
      },
      {
        value: SUPPLIERS_UPDATE,
        label: SUPPLIERS_UPDATE_LABEL,
      },
      {
        value: SUPPLIERS_DESTROY,
        label: SUPPLIERS_DESTROY_LABEL,
      },
    ],
  ],
  [
    CLIENTS_GROUP_LABEL,
    [
      {
        value: CLIENTS_RETRIEVE,
        label: CLIENTS_RETRIEVE_LABEL,
      },
      {
        value: CLIENTS_CREATE,
        label: CLIENTS_CREATE_LABEL,
      },
      {
        value: CLIENTS_UPDATE,
        label: CLIENTS_UPDATE_LABEL,
      },
      {
        value: CLIENTS_DESTROY,
        label: CLIENTS_DESTROY_LABEL,
      },
    ],
  ],
  [
    PRODUCTION_GROUP_LABEL,
    [
      {
        value: PRODUCTION_RETRIEVE,
        label: PRODUCTION_RETRIEVE_LABEL,
      },
      {
        value: PRODUCTION_CREATE,
        label: PRODUCTION_CREATE_LABEL,
      },
      {
        value: PRODUCTION_UPDATE,
        label: PRODUCTION_UPDATE_LABEL,
      },
      {
        value: PRODUCTION_DESTROY,
        label: PRODUCTION_DESTROY_LABEL,
      },
    ],
  ],
  [
    CLOTHING_MODELS_GROUP_LABEL,
    [
      {
        value: CLOTHING_MODELS_RETRIEVE,
        label: CLOTHING_MODELS_RETRIEVE_LABEL,
      },
      {
        value: CLOTHING_MODELS_CREATE,
        label: CLOTHING_MODELS_CREATE_LABEL,
      },
      {
        value: CLOTHING_MODELS_UPDATE,
        label: CLOTHING_MODELS_UPDATE_LABEL,
      },
      {
        value: CLOTHING_MODELS_DESTROY,
        label: CLOTHING_MODELS_DESTROY_LABEL,
      },
    ],
  ],
  [
    COLORS_GROUP_LABEL,
    [
      {
        value: COLORS_RETRIEVE,
        label: COLORS_RETRIEVE_LABEL,
      },
      {
        value: COLORS_CREATE,
        label: COLORS_CREATE_LABEL,
      },
      {
        value: COLORS_UPDATE,
        label: COLORS_UPDATE_LABEL,
      },
      {
        value: COLORS_DESTROY,
        label: COLORS_DESTROY_LABEL,
      },
    ],
  ],
  [
    SIZES_GROUP_LABEL,
    [
      {
        value: SIZES_RETRIEVE,
        label: SIZES_RETRIEVE_LABEL,
      },
      {
        value: SIZES_CREATE,
        label: SIZES_CREATE_LABEL,
      },
      {
        value: SIZES_UPDATE,
        label: SIZES_UPDATE_LABEL,
      },
      {
        value: SIZES_DESTROY,
        label: SIZES_DESTROY_LABEL,
      },
    ],
  ],
  [
    INVENTORY_PRODUCTS_RETRIEVE_GROUP_LABEL,
    [
      {
        value: INVENTORY_PRODUCTS_RETRIEVE,
        label: INVENTORY_PRODUCTS_RETRIEVE_LABEL,
      },
    ],
  ],
  [
    INPUT_MATERIALS_GROUP_LABEL,
    [
      {
        value: INPUT_MATERIALS_RETRIEVE,
        label: INPUT_MATERIALS_RETRIEVE_LABEL,
      },
      {
        value: INPUT_MATERIALS_CREATE,
        label: INPUT_MATERIALS_CREATE_LABEL,
      },
      {
        value: INPUT_MATERIALS_UPDATE,
        label: INPUT_MATERIALS_UPDATE_LABEL,
      },
      {
        value: INPUT_MATERIALS_DESTROY,
        label: INPUT_MATERIALS_DESTROY_LABEL,
      },
    ],
  ],
  [
    INVENTORY_LOCATIONS_GROUP_LABEL,
    [
      {
        value: INVENTORY_LOCATIONS_RETRIEVE,
        label: INVENTORY_LOCATIONS_RETRIEVE_LABEL,
      },
      {
        value: INVENTORY_LOCATIONS_CREATE,
        label: INVENTORY_LOCATIONS_CREATE_LABEL,
      },
      {
        value: INVENTORY_LOCATIONS_UPDATE,
        label: INVENTORY_LOCATIONS_UPDATE_LABEL,
      },
      {
        value: INVENTORY_LOCATIONS_DESTROY,
        label: INVENTORY_LOCATIONS_DESTROY_LABEL,
      },
    ],
  ],
  [
    INVENTORY_INPUT_BATCHES_GROUP_LABEL,
    [
      {
        value: INVENTORY_INPUT_BATCHES_RETRIEVE,
        label: INVENTORY_INPUT_BATCHES_RETRIEVE_LABEL,
      },
      {
        value: INVENTORY_INPUT_BATCHES_CREATE,
        label: INVENTORY_INPUT_BATCHES_CREATE_LABEL,
      },
      {
        value: INVENTORY_INPUT_BATCHES_UPDATE,
        label: INVENTORY_INPUT_BATCHES_UPDATE_LABEL,
      },
      {
        value: INVENTORY_INPUT_BATCHES_DESTROY,
        label: INVENTORY_INPUT_BATCHES_DESTROY_LABEL,
      },
    ],
  ],
  [
    SALE_ORDERS_GROUP_LABEL,
    [
      {
        value: SALE_ORDERS_RETRIEVE,
        label: SALE_ORDERS_RETRIEVE_LABEL,
      },
      {
        value: SALE_ORDERS_CREATE,
        label: SALE_ORDERS_CREATE_LABEL,
      },
      {
        value: SALE_ORDERS_UPDATE,
        label: SALE_ORDERS_UPDATE_LABEL,
      },
      {
        value: SALE_ORDERS_DESTROY,
        label: SALE_ORDERS_DESTROY_LABEL,
      },
    ],
  ],
  [
    INVENTORY_TRANSFERS_GROUP_LABEL,
    [
      {
        value: INVENTORY_TRANSFERS_RETRIEVE,
        label: INVENTORY_TRANSFERS_RETRIEVE_LABEL,
      },
      {
        value: INVENTORY_TRANSFERS_CREATE,
        label: INVENTORY_TRANSFERS_CREATE_LABEL,
      },
      {
        value: INVENTORY_TRANSFERS_UPDATE,
        label: INVENTORY_TRANSFERS_UPDATE_LABEL,
      },
      {
        value: INVENTORY_TRANSFERS_DESTROY,
        label: INVENTORY_TRANSFERS_DESTROY_LABEL,
      },
    ],
  ],
  [
    DELIVERY_ORDERS_GROUP_LABEL,
    [
      {
        value: DELIVERY_ORDERS_RETRIEVE,
        label: DELIVERY_ORDERS_RETRIEVE_LABEL,
      },
      {
        value: DELIVERY_ORDERS_CREATE,
        label: DELIVERY_ORDERS_CREATE_LABEL,
      },
      {
        value: DELIVERY_ORDERS_UPDATE,
        label: DELIVERY_ORDERS_UPDATE_LABEL,
      },
      {
        value: DELIVERY_ORDERS_DESTROY,
        label: DELIVERY_ORDERS_DESTROY_LABEL,
      },
    ],
  ],
  [
    DISCOUNTS_GROUP_LABEL,
    [
      {
        value: DISCOUNTS_RETRIEVE,
        label: DISCOUNTS_RETRIEVE_LABEL,
      },
      {
        value: DISCOUNTS_CREATE,
        label: DISCOUNTS_CREATE_LABEL,
      },
      {
        value: DISCOUNTS_UPDATE,
        label: DISCOUNTS_UPDATE_LABEL,
      },
      {
        value: DISCOUNTS_DESTROY,
        label: DISCOUNTS_DESTROY_LABEL,
      },
    ],
  ],
  [
    PAYMENTS_GROUP_LABEL,
    [
      {
        value: PAYMENTS_RETRIEVE,
        label: PAYMENTS_RETRIEVE_LABEL,
      },
      {
        value: PAYMENTS_CREATE,
        label: PAYMENTS_CREATE_LABEL,
      },
      {
        value: PAYMENTS_UPDATE,
        label: PAYMENTS_UPDATE_LABEL,
      },
    ],
  ],
  [
    USERS_GROUP_LABEL,
    [
      {
        value: USERS_RETRIEVE,
        label: USERS_RETRIEVE_LABEL,
      },
      {
        value: USERS_CREATE,
        label: USERS_CREATE_LABEL,
      },
      {
        value: USERS_UPDATE,
        label: USERS_UPDATE_LABEL,
      },
      {
        value: USERS_DESTROY,
        label: USERS_DESTROY_LABEL,
      },
    ],
  ],
  [
    BATCH_AMOUNTS_LABEL,
    [
      {
        value: BATCH_AMOUNTS_RETRIEVE,
        label: BATCH_AMOUNTS_RETRIEVE_LABEL,
      },
      {
        value: BATCH_AMOUNTS_UPDATE,
        label: BATCH_AMOUNTS_UPDATE_LABEL,
      },
    ],
  ],
  [
    STORE_SALES_LABEL,
    [
      {
        value: STORE_SALES_RETRIEVE,
        label: STORE_SALES_RETRIEVE_LABEL,
      },
    ],
  ],
  [
    SUPERWHOLESALE_PRICES_LABEL,
    [
      {
        value: SUPERWHOLESALE_PRICES_USE,
        label: SUPERWHOLESALE_PRICES_USE_LABEL,
      },
    ],
  ],
  [
    INVENTORY_VALUES_LABEL,
    [
      {
        value: INVENTORY_VALUES_USE,
        label: INVENTORY_VALUES_USE_LABEL,
      },
    ],
  ],
  [
    MANUAL_DISCOUNT_LABEL,
    [
      {
        value: MANUAL_DISCOUNT_USE,
        label: MANUAL_DISCOUNT_USE_LABEL,
      },
    ],
  ],
  [
    PRODUCT_HISTORY_LABEL,
    [
      {
        value: PRODUCT_HISTORY_RETRIEVE,
        label: PRODUCT_HISTORY_RETRIEVE_LABEL,
      },
    ],
  ],
  [
    INPUT_MATERIALS_HISTORY_LABEL,
    [
      {
        value: INPUT_MATERIALS_HISTORY_RETRIEVE,
        label: INPUT_MATERIALS_HISTORY_RETRIEVE_LABEL,
      },
    ],
  ],
]);
