import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import InputMaterial, { newInputMaterial } from "../../models/input-material";
import InputMaterialForm from "./InputMaterialForm";

interface InputMaterialUpdateModalProps {
  show: boolean;
  inputMaterial?: InputMaterial;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const InputMaterialUpdateModal: React.FC<InputMaterialUpdateModalProps> = ({
  show,
  inputMaterial,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialInputMaterial, setInitialInputMaterial] = useState<InputMaterial>(inputMaterial ? inputMaterial : newInputMaterial());

  const modalTitle = inputMaterial
    ? `Editar materia prima: ${inputMaterial.identifier}`
    : `Crear material prima nueva`;

  const onClose = () => {
    setInitialErrors({});
    setInitialInputMaterial(newInputMaterial());
    onCancel();
  };
  useEffect(() => {
    setInitialInputMaterial(inputMaterial ? inputMaterial : newInputMaterial());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputMaterial]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <InputMaterialForm
            initialInputMaterial={initialInputMaterial}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default InputMaterialUpdateModal;
