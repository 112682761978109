import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CSelect
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem, updateItem, ItemRequestStatus } from "../../api/generics";
import InputMaterial, { INPUT_MATERIAL_TYPE_CHOICES, newInputMaterial } from "../../models/input-material";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

interface InputMarterialFormProps {
  initialInputMaterial?: InputMaterial;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const SupplierForm: React.FC<InputMarterialFormProps> = ({
  initialInputMaterial,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingInputMaterial, setEditingInputMaterial] = useState<InputMaterial>(
    initialInputMaterial ? initialInputMaterial : newInputMaterial()
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);


  const onIdentifierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingInputMaterial({
      ...editingInputMaterial,
      identifier: e.target.value,
    });
  };

  const onTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingInputMaterial({
      ...editingInputMaterial,
      type: e.target.value,
    });
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingInputMaterial({
      ...editingInputMaterial,
      description: e.target.value,
    });
  };

  const onMeasurementUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingInputMaterial({
      ...editingInputMaterial,
      measurementUnit: e.target.value,
    });
  };
  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingInputMaterial((editingInputMaterial) => {
      let amount: number | undefined = undefined;

      if (!isNaN(parseFloat(e.target.value))) {
        amount = parseFloat(e.target.value);
      }

      return { ...editingInputMaterial, minimunToReport: amount };
    });
  };

  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingInputMaterial({
      ...editingInputMaterial,
      obs: e.target.value,
    });
  };


  const onSave = async () => {
    setSubmitting(true);
    let toSendInputMaterial = {
      ...editingInputMaterial,
      companyId: company.id,
    };
    let requestPromise: Promise<ItemRequestStatus<InputMaterial>>;
    if (editingInputMaterial.id === undefined) {
      requestPromise = createItem<InputMaterial>("/input_materials/create/", toSendInputMaterial);
    } else {
      requestPromise = updateItem<InputMaterial>(`/input_materials/${toSendInputMaterial.id}/`, toSendInputMaterial);
    }

    const inputMaterialStatus = await requestPromise;

    if (inputMaterialStatus.status !== SUCCESS) {
      if (inputMaterialStatus.errors !== undefined) {
        setErrors(inputMaterialStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (inputMaterialStatus.detail !== undefined) {
        message = inputMaterialStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingInputMaterial(newInputMaterial());
  };

  useEffect(() => {
    setEditingInputMaterial(initialInputMaterial ? initialInputMaterial : newInputMaterial());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInputMaterial]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Identificador:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingInputMaterial.identifier)}
                onChange={onIdentifierChange}
                placeholder="Identificador"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("identifier", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Tipo:</CLabel>
            </CCol>
            <CCol>
            <CSelect
                type="text"
                defaultValue={editingInputMaterial.type ? editingInputMaterial.type : ""}
                onChange={onTypeChange}
              >
                <option value={""} disabled>
                  -----
                </option>
                {Array.from(INPUT_MATERIAL_TYPE_CHOICES.entries()).map((entry) => {
                  return (
                    <option key={entry[0]} value={entry[0]}>
                      {entry[1]}
                    </option>
                  );
                })}
              </CSelect>
              <FieldErrors
                errors={getFieldErrors("type", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Unidad de Medida:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingInputMaterial.measurementUnit)}
                onChange={onMeasurementUnitChange}
                placeholder="Unidad de Medida"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("measurementUnit", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Mínimo para reportar reponer:</CLabel>
            </CCol>
            <CCol >
              <CInput
                type="number"
                placeholder="Mínimo para reponer."
                value={
                  emptyValueOnUndefined(editingInputMaterial.minimunToReport)
                }
                onChange={onAmountChange}
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("amount", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Descripción:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingInputMaterial.description)}
                onChange={onDescriptionChange}
                placeholder="Descripción"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("description", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Observación:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingInputMaterial.obs)}
                onChange={onObsChange}
                placeholder="Observación"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("obs", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default SupplierForm;
