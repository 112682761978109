import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { warningAlert } from "../utils/messages";
import { defaultValueOnUndefined } from "../../utils/fields";
import CashRegisterOperation from "../../models/cash-register-operation";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
import { formatDateTime } from "../../utils/dates";
import StoreSale from "../../models/store-sale";
import generateSalesReportPDF from "../../utils/storeSalesPdfGenerator";
import Store from "../../models/store";
import SingleStoreSelect from "../store/SingleStoreSelect";

const ITEMS_PER_PAGE = 20;

const CashRegisterList = () => {
  const fields = [
    {
      key: "storeName",
      _classes: ["text-center"],
      label: "Sucursal",
    },
    {
      key: "username",
      _classes: ["text-center"],
      label: "Usuario",
    },
    {
      key: "openingDatetime",
      _classes: ["text-center"],
      label: "Fecha/Hora apertura",
    },
    {
      key: "openingMoney",
      _classes: ["text-center"],
      label: "Monto Apertura",
    },
    {
      key: "openingObs",
      _classes: ["text-center"],
      label: "Observacion en apertura",
    },
    {
      key: "closingDatetime",
      _classes: ["text-center"],
      label: "Fecha/Hora cierre",
    },
    {
      key: "totalAmount",
      _classes: ["text-center"],
      label: "Monto Cierre",
    },
    {
      key: "closingMoney",
      _classes: ["text-center"],
      label: "Efectivo en caja",
    },
    {
      key: "closingObs",
      _classes: ["text-center"],
      label: "Observacion en cierre",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [filterStore, setFilterStore] = useState<Store | undefined>(undefined);

  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [operations, setOperations] = useState<CashRegisterOperation[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let storeId = urlParams.get("store_id") ? urlParams.get("store_id") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    if (storeId !== "") map.set("store_id", storeId);
    return map;
  };

  const fetchOperations = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const requestStatus = await getList<CashRegisterOperation>(
      "/cash_register_operations/",
      limit,
      offset,
      urlParams
    );
    if (requestStatus.status === SUCCESS) {
      if (requestStatus.data !== undefined) {
        const count = requestStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setOperations(requestStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = requestStatus.detail
        ? requestStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    if (previousParams.get("store_id")) {
      urlParams.set("store_id", previousParams.get("store_id"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onFilterStoreChange = (newStore: Store | null) => {
    setFilterStore(newStore !== null ? newStore : undefined);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("search", search);
    if (filterStore !== undefined && filterStore.id !== undefined) {
      urlParams.set("store_id", filterStore.id.toString());
    } else {
      urlParams.delete("store_id");
    }
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const fetchStoreSalesForCashRegister = async (
    operation: CashRegisterOperation
  ) => {
    if (operation.id === undefined) {
      return;
    }
    const filterMap = new Map();
    filterMap.set("cash_register_operation_id", operation.id.toString());
    const limit = -1;
    const offset = 0;
    const storeSalesStatus = await getList<StoreSale>(
      "/store_sales/",
      limit,
      offset,
      filterMap
    );
    if (storeSalesStatus.status === SUCCESS) {
      if (storeSalesStatus.data !== undefined) {
        if (storeSalesStatus.data.items.length <= 0) {
          warningAlert(
            "No se genero reporte de cierre porque no se encontraron registros de venta"
          );
          return;
        }
        const salesData = storeSalesStatus.data.items;
        const titlesRight: string[] = generateTitlesRight(filterMap);
        const filteredTitlesRight = titlesRight.filter((title) => title !== "");
        await generateSalesReportPDF(
          salesData,
          [
            "Reporte de Ventas",
            operation.storeName || "",
            "Reporte generado el:",
            formatDateTime(new Date()),
          ],
          filteredTitlesRight,
          operation
        );
      }
    } else {
      warningAlert("No se pudo generar reporte de cierre");
    }
  };

  const generateTitlesRight = (map: Map<any, any>): string[] => {
    const titlesRight: string[] = ["Filtros Utilizados: "];

    map.forEach((value, key) => {
      const stringKey = typeof key === "string" ? key : "";
      const stringValue = typeof value === "string" ? value : "";
      const description = getFilterDescription(stringKey, stringValue);
      titlesRight.push(description);
    });

    return titlesRight;
  };

  const getFilterDescription = (key: string, value: string) => {
    switch (key) {
      case "search":
        if (value !== "") {
          return `Búsqueda: ${value}`;
        } else {
          return "";
        }
      case "is_returnal":
        if (value === "true") {
          return "Tipo de Operación: Devolución";
        } else if (value === "false") {
          return "Tipo de Operación: Devolución";
        } else {
          return "";
        }
      case "cash_register_operation_id":
        return `Corresponde a ciclo de caja: Si`;
      case "start_date":
        return `Fecha de inicio: ${formatDateTime(new Date(value))}`;
      case "end_date":
        return `Fecha de fin: ${formatDateTime(new Date(value))}`;
      default:
        return "";
    }
  };

  const onReportClick = (operation: CashRegisterOperation) => {
    fetchStoreSalesForCashRegister(operation);
  };

  useEffect(() => {
    fetchOperations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("cashRegisterHistory")) {
        return;
      }
      fetchOperations();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Registro de caja</h3>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Sucursal:</CLabel>
                </CCol>
                <CCol>
                  <SingleStoreSelect
                    value={filterStore ? filterStore : null}
                    onChange={onFilterStoreChange}
                  ></SingleStoreSelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={operations}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    openingDatetime: (item: CashRegisterOperation) => {
                      return (
                        <td className="text-center">
                          {item.openingDatetime
                            ? formatDateTime(new Date(item.openingDatetime))
                            : "-"}
                        </td>
                      );
                    },
                    closingDatetime: (item: CashRegisterOperation) => {
                      return (
                        <td className="text-center">
                          {item.closingDatetime
                            ? formatDateTime(new Date(item.closingDatetime))
                            : "-"}
                        </td>
                      );
                    },
                    openingMoney: (item: CashRegisterOperation) => {
                      return (
                        <td className="text-center">
                          {item.openingMoney
                            ? formatToCurrency(item.openingMoney, PYG)
                            : "-"}
                        </td>
                      );
                    },
                    closingMoney: (item: CashRegisterOperation) => {
                      return (
                        <td className="text-center">
                          {item.closingMoney
                            ? formatToCurrency(item.closingMoney, PYG)
                            : "-"}
                        </td>
                      );
                    },

                    totalAmount: (item: CashRegisterOperation) => {
                      return (
                        <td className="text-center">
                          <b>
                            {item.totalAmount
                              ? formatToCurrency(item.totalAmount, PYG)
                              : "-"}
                          </b>
                        </td>
                      );
                    },

                    openingObs: (item: CashRegisterOperation) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.openingObs)}
                        </td>
                      );
                    },
                    closingObs: (item: CashRegisterOperation) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.closingObs)}
                        </td>
                      );
                    },
                    actions: (item: CashRegisterOperation) => {
                      const reportButton = (
                        <CButton
                          className="text-white"
                          color="primary"
                          onClick={() => {
                            onReportClick(item);
                          }}
                        >
                          <i className="fa fa-file-pdf"></i>
                        </CButton>
                      );

                      return (
                        <td className="text-right">
                          <CButtonGroup>{reportButton}</CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default CashRegisterList;
