import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getItem, getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { warningAlert } from "../utils/messages";
import InventoryProduct, {
  MINIMUNAMOUNTPERSIZESTORE,
} from "../../models/inventory-product";
import { getAbsoluteUrl } from "../../api/urls";

import { INVENTORY_PRODUCTS_RETRIEVE } from "../../auth/permissions";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import Store from "../../models/store";
import InventoryLocation from "../../models/inventory-location";
import { PYG } from "../../currency/available-currencies";
import { formatToCurrency } from "../../currency/format";

const ITEMS_PER_PAGE = 20;

interface FieldEntry {
  key: string;
  _classes: string[];
  label: string;
  filter?: boolean;
}

const StoreInventoryProductsList = () => {
  const { id } = useParams<{ id: string }>();

  const [fields, setFields] = useState<FieldEntry[]>([
    {
      key: "codeIdentifier",
      _classes: ["text-center"],
      label: "Código",
    },
    {
      key: "name",
      _classes: ["text-center"],
      label: "Nombre",
    },
    {
      key: "color",
      _classes: ["text-center"],
      label: "Color",
    },
    {
      key: "totalAmount",
      _classes: ["text-center"],
      label: "Cantidad total",
    },
  ]);

  const [store, setStore] = useState<Store | undefined>({});
  const [invLocation, setInvLocation] = useState<InventoryLocation | undefined>(
    undefined
  );

  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [inventoryProducts, setInventoryProducts] = useState<
    InventoryProduct[]
  >([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    return map;
  };

  const fetchStore = async (id: number) => {
    const storeStatus = await getItem<Store>(`/stores/${id}/`);
    if (storeStatus.status === SUCCESS) {
      if (storeStatus.data !== undefined) {
        setStore(storeStatus.data);
      }
    } else {
      const message = storeStatus.detail
        ? storeStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const fetchStoreLocation = async (storeId: number) => {
    const locationStatus = await getItem<InventoryLocation>(
      `/inventory_locations/from_store/${storeId}/`
    );
    if (locationStatus.status === SUCCESS) {
      if (locationStatus.data !== undefined) {
        setInvLocation(locationStatus.data);
      }
    } else {
      const message = locationStatus.detail
        ? locationStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const fetchInventoryProducts = async (id: number) => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    urlParams.set("store_id", id.toString());
    const inventoryLocationsStatus = await getList<InventoryProduct>(
      "/inventory_products/",
      limit,
      offset,
      urlParams
    );
    if (inventoryLocationsStatus.status === SUCCESS) {
      if (inventoryLocationsStatus.data !== undefined) {
        if (
          inventoryLocationsStatus.data.count === 0 &&
          inventoryLocationsStatus.data.items.length === 0
        ) {
          setTotalPages(1);
          setCurrentPage(1);
          setInventoryProducts([]);
          setLoading(false);
          return;
        }
        const count = inventoryLocationsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);

        setCurrentPage(urlParams.get("page"));

        const newSizesFields: FieldEntry[] = [];
        const encounteredSizes = new Set();

        inventoryLocationsStatus.data.items.forEach((entry) => {
          entry.sizes?.forEach((size) => {
            if (
              typeof size.size === "string" &&
              !encounteredSizes.has(size.size)
            ) {
              const newFieldEntry = {
                key: size.size,
                _classes: ["text-center"],
                label: size.size,
                filter: false,
              };

              newSizesFields.push(newFieldEntry);
              encounteredSizes.add(size.size);
            }
          });
        });
        newSizesFields.sort((a, b) => {
          const keyLengthDiff = a.key.length - b.key.length;
          if (keyLengthDiff !== 0) {
            return keyLengthDiff;
          }
          return parseInt(a.key) - parseInt(b.key);
        });

        setFields((prevFields) => [
          ...prevFields.slice(0, 4),
          ...newSizesFields,
        ]);
        const flattenedInventoryProducts =
          inventoryLocationsStatus.data.items.map((entry) => {
            const flattenedProduct: { [key: string]: any } = { ...entry };
            newSizesFields?.forEach((size) => {
              if (typeof size.key === "string") {
                const sizeInfo = entry.sizes?.find(
                  (sizeinfo) => sizeinfo.size === size.key
                );
                if (sizeInfo) {
                  const difference =
                    sizeInfo.allocatedAmount !== undefined
                      ? sizeInfo.amount - sizeInfo.allocatedAmount
                      : sizeInfo.amount;
                  if (
                    sizeInfo.allocatedAmount &&
                    sizeInfo.allocatedAmount !== 0
                  ) {
                    flattenedProduct[
                      size.key
                    ] = `${sizeInfo.amount}(${sizeInfo.allocatedAmount})`;
                  } else {
                    flattenedProduct[size.key] = `${sizeInfo.amount}` || "0";
                  }
                  if (difference < MINIMUNAMOUNTPERSIZESTORE) {
                    flattenedProduct[size.key] =
                      flattenedProduct[size.key] + "*";
                    flattenedProduct["hasMissingProducts"] = true;
                  }
                } else {
                  flattenedProduct[size.key] = "-";
                }
              }
            });
            return flattenedProduct;
          });
        setInventoryProducts(flattenedInventoryProducts);
      }
    } else {
      const message = inventoryLocationsStatus.detail
        ? inventoryLocationsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("search", search);
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const onReportClick = async () => {
    const storeParam = `?store_id=${id}`;
    const searchParam = search !== "" ? `&search=${search}` : "";

    const pdfEndpoint =
      getAbsoluteUrl("/inventory_products/pdf/") + storeParam + searchParam;

    window.open(pdfEndpoint, "_blank");
  };

  const getInitialValues = async () => {
    setLoading(true);
    const storePromise = fetchStore(Number(id));
    const productsPromise = fetchInventoryProducts(Number(id));
    const locationPromise = fetchStoreLocation(Number(id));
    await storePromise;
    await productsPromise;
    await locationPromise;

    setLoading(false);
  };

  useEffect(() => {
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      fetchInventoryProducts(Number(id));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>
                  {" "}
                  <b>{`${store?.name}  ->`}</b>Inventario de Productos en
                  Sucursal
                </h3>
              </div>
            </CCardHeader>
            <CCardBody>
              <PermissionRequiredComponent
                permissionName={INVENTORY_PRODUCTS_RETRIEVE}
              >
                <CRow>
                  <CCol>
                    <CButton
                      color="danger"
                      className="fa-solid fa-file-pdf"
                      onClick={onReportClick}
                      disabled={loading}
                    ></CButton>
                  </CCol>
                </CRow>
              </PermissionRequiredComponent>
              <CRow>
                <CCol>
                  <p>
                    <b>Referencias:</b>
                  </p>
                  <ul>
                    <li>
                      Las cantidades fuera del parentesis indican la existencia
                      total dentro del inventario.
                    </li>
                    <li>
                      Las cantidades dentro del paréntesis indican la cantidad
                      de producto ya tiene n en una orden de transferencia desde
                      la fabrica pero que aun no se ha recepcionado en el
                      inventario de la Sucursal .
                    </li>
                    <li>
                      Las filas en amarillo indican que al menos un talle de esa
                      prenda tiene una disponibilidad menor al mínimo aceptable{" "}
                      <b> {MINIMUNAMOUNTPERSIZESTORE} Unidades</b>. Las
                      cantidades marcadas con un <b>"*"</b> indican que el
                      producto en dicho color y talle esta por debajo del mínimo
                    </li>
                    <li>
                      Un <b>"-"</b> indica que la prenda no está disponible en
                      ese talle
                    </li>
                  </ul>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                      disabled={loading}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={inventoryProducts}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    totalAmount: (item: InventoryProduct) => {
                      return (
                        <td className="text-center">
                          <b>{item.totalAmount}</b>
                        </td>
                      );
                    },
                    name: (item: InventoryProduct) => {
                      const nameClass: string =
                        item.hasMissingProducts !== undefined &&
                        item.hasMissingProducts === true
                          ? "text-warning"
                          : "";
                      return (
                        <td className="text-center">
                          <b className={nameClass}>{item.name}</b>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  {!loading ? (
                    <CPagination
                      pages={totalPages}
                      activePage={currentPage}
                      onActivePageChange={(i: number) => onPageChange(i)}
                      className={totalPages < 2 ? "d-none" : ""}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {!loading ? (
                <CRow className={"mt-2"}>
                  <CCol className={"border border-dark"}>
                    <CRow className={"mt-2"}>
                      <CCol>
                        <h5>Valores Totales de Inventario de tienda:</h5>
                      </CCol>
                    </CRow>
                    <CRow className={"mt-2"}>
                      <CCol md={3}>
                        <CLabel className={"mt-2 font-weight-bold"}>
                          Valor de inventario(Costo):
                        </CLabel>
                      </CCol>
                      <CCol md={3}>
                        <CLabel className={"mt-2 font-weight-bold"}>
                          {invLocation?.inventoryValue
                            ? formatToCurrency(invLocation.inventoryValue, PYG)
                            : "-"}
                        </CLabel>
                      </CCol>
                      <CCol md={3}>
                        <CLabel className={"mt-2 font-weight-bold"}>
                          Valor de Inventario(Venta):
                        </CLabel>
                      </CCol>
                      <CCol md={3}>
                        <CLabel className={"mt-2 font-weight-bold"}>
                          {invLocation?.inventorySellValue
                            ? formatToCurrency(
                                invLocation.inventorySellValue,
                                PYG
                              )
                            : "-"}
                        </CLabel>
                      </CCol>
                      <CCol md={3}>
                        <CLabel className={"mt-2 font-weight-bold"}>
                          Valor de Inventario(Venta mayorista):
                        </CLabel>
                      </CCol>
                      <CCol md={3}>
                        <CLabel className={"mt-2 font-weight-bold"}>
                          {invLocation?.inventorySellValueWholesale
                            ? formatToCurrency(
                                invLocation.inventorySellValueWholesale,
                                PYG
                              )
                            : "-"}
                        </CLabel>
                      </CCol>
                    </CRow>

                    <CRow className={"mt-2"}>
                      <CCol md={12}>
                        <CLabel className={"mt-2 font-weight-bold"}>
                          Obs: El valor de inventario de venta es calculado
                          teniendo en cuenta la venta con el precio de lista
                          configurado.
                        </CLabel>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              ) : (
                <></>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default StoreInventoryProductsList;
