import Color from "../models/color";
import DeliveryOrder, { DeliveryOrderItem } from "../models/delivery-order";
import Product from "../models/product";
import { SaleOrderItem } from "../models/sale-order";
import Size from "../models/size";
import Variant from "../models/variant";

export interface DeliveryOrderFormItemSize {
  savedId?: number;
  saleOrderItemId?: number;
  saleOrderItem?: SaleOrderItem;
  sizeName?: string;
  size?: Size;
  sizeId?: number;
  variantId?: number;
  variant?: Variant;
  amountDelivered?: number;
  maxAmountAllowed?: number;
}

export interface DeliveryOrderFormItem {
  id?: number;
  companyId?: number;
  deliveryOrderId?: number;
  clothingProductId?: number;
  clothingProduct?: Product;
  productCodeIdentifier?: string;
  productName?: string;
  colorName?: string;
  colorId?: number;
  color?: Color;
  sizes?: DeliveryOrderFormItemSize[];
  unitPrice?: number;
  unitPriceNoDiscount?: number;
  totalPriceNoDiscount?: number;
  totalPrice?: number;
}

export const deliveryOrderTableFormatToAPI = (
  items: DeliveryOrderFormItem[]
): DeliveryOrderItem[] => {
  const result: DeliveryOrderItem[] = [];

  items.forEach((item) => {
    item.sizes?.forEach((size) => {
      if (size.amountDelivered !== undefined && size.amountDelivered !== 0) {
        const deliveryOrderItem: DeliveryOrderItem = {
          id: size.savedId,
          companyId: item.companyId,
          saleOrderItemId: size.saleOrderItemId,
          saleOrderItem: size.saleOrderItem,
          clothingProductId: item.clothingProductId,
          clothingProduct: item.clothingProduct,
          productVariantId: size.variantId,
          productVariant: size.variant,
          productCodeIdentifier: item.productCodeIdentifier,
          productName: item.productName,
          colorName: item.colorName,
          colorId: item.colorId,
          color: item.color,
          sizeName: size.sizeName,
          size: size.size,
          sizeId: size.sizeId,
          amountDelivered: size.amountDelivered,
          unitPrice: item.unitPrice,
          totalPrice: item.unitPrice
            ? item.unitPrice * size.amountDelivered
            : undefined,
          unitPriceNoDiscount: item.unitPriceNoDiscount,
          totalPriceNoDiscount: item.unitPriceNoDiscount
            ? item.unitPriceNoDiscount * size.amountDelivered
            : undefined,
        };
        result.push(deliveryOrderItem);
      }
    });
  });
  return result;
};

export const deliveryOrderAPIReceivedToTableFormat = (
  items: DeliveryOrderItem[]
): DeliveryOrderFormItem[] => {
  const groupedItems: Map<string, DeliveryOrderItem[]> = new Map();

  items.forEach((item) => {
    const key = `${item.productCodeIdentifier}-${item.colorName}`;

    if (!groupedItems.has(key)) {
      groupedItems.set(key, []);
    }

    groupedItems.get(key)?.push(item);
  });
  const result: DeliveryOrderFormItem[] = Array.from(groupedItems.values()).map(
    (group) => {
      const firstItem = group[0];

      const saleOrderFormItem: DeliveryOrderFormItem = {
        companyId: firstItem.companyId,
        deliveryOrderId: firstItem.deliveryOrderId,
        clothingProductId: firstItem.clothingProductId,
        clothingProduct: firstItem.clothingProduct,
        productCodeIdentifier: firstItem.productCodeIdentifier,
        productName: firstItem.productName,
        colorName: firstItem.colorName,
        color: firstItem.color,
        colorId: firstItem.colorId,
        sizes: group.map((item) => ({
          savedId: item.id,
          saleOrderItemId: item.saleOrderItemId,
          saleOrderItem: item.saleOrderItem,
          sizeName: item.sizeName,
          size: item.size,
          sizeId: item.sizeId,
          variantId: item.productVariantId,
          variant: item.productVariant,
          amountDelivered: item.amountDelivered,
          maxAmountAllowed:
            item.saleOrderItem?.amountRequired! -
            item.saleOrderItem?.amountDelivered! +
            item.amountDelivered!,
        })),
        unitPrice: firstItem.unitPrice,
        totalPrice: group.reduce(
          (sum, item) => sum + (item.totalPrice ?? 0),
          0
        ),
        unitPriceNoDiscount: firstItem.unitPriceNoDiscount,
        totalPriceNoDiscount: group.reduce(
          (sum, item) => sum + (item.totalPriceNoDiscount ?? 0),
          0
        ),
      };

      return saleOrderFormItem;
    }
  );

  return result;
};

export const saleOrderAPIReceivedToDeliveryTableFormat = (
  items: SaleOrderItem[]
): DeliveryOrderFormItem[] => {
  const groupedItems: Map<string, SaleOrderItem[]> = new Map();

  items.forEach((item) => {
    const key = `${item.productCodeIdentifier}-${item.colorName}`;

    if (!groupedItems.has(key)) {
      groupedItems.set(key, []);
    }

    groupedItems.get(key)?.push(item);
  });
  const result: DeliveryOrderFormItem[] = Array.from(groupedItems.values()).map(
    (group) => {
      const firstItem = group[0];
      const sizevalues = group.map((item) => {
        const newSizeEntry: DeliveryOrderFormItemSize = {
          saleOrderItemId: item.id,
          saleOrderItem: item,
          sizeName: item.sizeName,
          size: item.size,
          sizeId: item.sizeId,
          variantId: item.productVariantId,
          variant: item.productVariant,
          amountDelivered: 0,
          maxAmountAllowed: item.amountRequired! - item.amountDelivered!,
        };
        return newSizeEntry;
      });
      const saleOrderFormItem: DeliveryOrderFormItem = {
        companyId: firstItem.companyId,
        clothingProductId: firstItem.clothingProductId,
        clothingProduct: firstItem.clothingProduct,
        productCodeIdentifier: firstItem.productCodeIdentifier,
        productName: firstItem.productName,
        colorName: firstItem.colorName,
        color: firstItem.color,
        colorId: firstItem.colorId,
        sizes: sizevalues,
        unitPrice: firstItem.unitPrice,
        totalPrice: 0,
        unitPriceNoDiscount: firstItem.unitPrice,
        totalPriceNoDiscount: 0,
      };
      return saleOrderFormItem;
    }
  );
  return result;
};

export const deliveryOrderIsReversible = (order: DeliveryOrder): boolean => {
  if (order.paymentStatus === undefined) {
    return false;
  }
  return order.paymentStatus !== "reverted";
};
