import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import Size, { newSize } from "../../models/size";
import SizeForm from "./SizeForm";

interface SizeUpdateModalProps {
  show: boolean;
  size?: Size;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const SizeUpdateModal: React.FC<SizeUpdateModalProps> = ({
  show,
  size,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialSize, setInitialSize] = useState<Size>(size ? size : newSize());

  const modalTitle = size
    ? `Editar talle: ${size.size}`
    : `Crear talle nuevo`;

  const onClose = () => {
    setInitialErrors({});
    setInitialSize(newSize());
    onCancel();
  };
  useEffect(() => {
    setInitialSize(size ? size : newSize());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <SizeForm
            initialSize={initialSize}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default SizeUpdateModal;
