import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CTextarea,
} from "@coreui/react";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

import { createItem, updateItem, ItemRequestStatus } from "../../api/generics";
import InventoryLocation from "../../models/inventory-location";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert, warningAlert } from "../utils/messages";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import InventoryInputBatch, {
  BatchItemSellLogs,
} from "../../models/inventory-input-batch";

interface InventoryInputBatchFormProps {
  initialInventoryInputBatch?: InventoryInputBatch;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const InventoryInputBatchForm: React.FC<InventoryInputBatchFormProps> = ({
  initialInventoryInputBatch,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingItem, setEditingItem] = useState<BatchItemSellLogs>({});

  const [batch, setBatch] = useState<InventoryInputBatch | undefined>(
    undefined
  );
  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const onLogDateChange = (value: string | moment.Moment) => {
    setEditingItem((editingItem) => {
      const newDate = value as moment.Moment;

      if (newDate === undefined || !moment(value, true).isValid()) {
        return { ...editingItem, logDate: undefined };
      }

      const purchaseDate = new Date((value as moment.Moment).toISOString());

      return { ...editingItem, logDate: purchaseDate.toISOString() };
    });
  };

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingItem((editingItem) => {
      let amount: number | undefined = undefined;

      if (!isNaN(parseInt(e.target.value))) {
        amount = parseInt(e.target.value);
      }

      return { ...editingItem, amount: amount };
    });
  };

  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingItem({
      ...editingItem,
      obs: e.target.value,
    });
  };

  const onSave = useCallback(async () => {
    setSubmitting(true);
    let toSendInventoryLocation = {
      ...editingItem,
      companyId: company.id,
      registerType: "Manual",
    };
    if (!toSendInventoryLocation.batchId) {
      toSendInventoryLocation.batchId = batch?.id;
    }

    let requestPromise: Promise<ItemRequestStatus<BatchItemSellLogs>>;
    if (toSendInventoryLocation.id === undefined) {
      requestPromise = createItem<InventoryLocation>(
        "/batches_amounts_logs/create/",
        toSendInventoryLocation
      );
    } else {
      warningAlert("Algo falló. intente de nuevo luego");
      setSubmitting(false);
      return;
    }

    const requestStatus = await requestPromise;

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.errors !== undefined) {
        setErrors(requestStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (requestStatus.detail !== undefined) {
        message = requestStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  const onClose = () => {
    clearForm();
    setEditingItem({});
    onCancel();
  };

  const clearForm = () => {
    setEditingItem({});
  };

  useEffect(() => {
    let newValue: BatchItemSellLogs = {};
    if (initialInventoryInputBatch?.id) {
      newValue.batchId = initialInventoryInputBatch.id;
    }
    setEditingItem(newValue);
    setBatch(initialInventoryInputBatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInventoryInputBatch]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Fecha:</CLabel>
            </CCol>
            <CCol>
              <Datetime
                className="pl-0"
                onChange={onLogDateChange}
                value={
                  editingItem.logDate !== undefined
                    ? new Date(editingItem.logDate)
                    : undefined
                }
                locale="es/PY"
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                closeOnSelect={true}
              ></Datetime>
              <FieldErrors
                errors={getFieldErrors("logDate", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Cantidad:</CLabel>
            </CCol>
            <CCol md={4}>
              <CInput
                type="number"
                placeholder="Cant."
                value={
                  editingItem.amount !== undefined ? editingItem.amount : 0
                }
                onChange={onAmountChange}
                step="1"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("amount", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Observación:</CLabel>
            </CCol>
            <CCol>
              <CTextarea
                placeholder="Observación"
                value={editingItem.obs ? editingItem.obs : ""}
                onChange={onObsChange}
              ></CTextarea>
              <FieldErrors
                errors={getFieldErrors("obs", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="warning" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default InventoryInputBatchForm;
