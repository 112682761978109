import { CCol, CFormGroup, CRow } from "@coreui/react";
import React, { useCallback, useState } from "react";

import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";
import Product from "../../models/product";

interface ProductionLaborPriceFormProps {
  value: Product;
  initialPrice?: number;
  onChange: (_: Product) => void;
  disabled?: boolean;
}

const DeliveryOrderDiscountItemForm: React.FC<
  ProductionLaborPriceFormProps
> = ({ value, initialPrice, onChange, disabled }) => {
  const [editingItem, setEditingItem] = useState<Product>(value);
  const [price, setPrice] = useState<number | undefined>(initialPrice);

  const onPriceChange = useCallback(
    (newPrice: number | undefined) => {
      setPrice(newPrice);
      const newItem = {
        ...editingItem,
        price: newPrice,
      };
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem, onChange]
  );

  return (
    <div className="section border rounded mb-2 p-2">
      <CFormGroup>
        <CRow>
          <CCol md={2} className={"mt-2"}>
            Producto:
          </CCol>
          <CCol md={3}>
            <p className={"mt-2"}>
              {value.codeIdentifier} {value.name}
            </p>
          </CCol>
          <CCol md={1} className={"mt-2"}>
            Descuento por unidad:
          </CCol>
          <CCol md={2}>
            <CurrencyField
              currency={PYG}
              value={price}
              onChange={onPriceChange}
              placeholder="Descuento por unidad"
              disabled={disabled}
            ></CurrencyField>
          </CCol>
        </CRow>
      </CFormGroup>
    </div>
  );
};

export default DeliveryOrderDiscountItemForm;
