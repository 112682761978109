import { useCallback, useEffect, useState } from "react";
import Color from "../../models/color";
import Errors from "../../models/errors";
import Product from "../../models/product";
import ProductSelect from "../product/ProductSelect";
import SingleColorSelect from "../color/SingleColorSelect";
import { CButton, CButtonGroup, CInput, CTooltip } from "@coreui/react";
import { getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { emptyValueOnUndefined } from "../../utils/fields";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Variant from "../../models/variant";
import {
  InventoryTransferFormItem,
  InventoryTransferFormItemSize,
} from "../../utils/inventory-transfers";

interface InventoryTransferItemFormProps {
  value?: InventoryTransferFormItem;
  initialValue?: InventoryTransferFormItem;
  initialErrors?: Errors;
  sizesColumns: string[];
  errors: Errors;
  disabled?: boolean;
  onChange: (saleOrderItem: InventoryTransferFormItem) => void | Promise<void>;
  onDelete: (saleOrderItem: InventoryTransferFormItem) => void | Promise<void>;
}

const FIXED_COLUMNS = [
  "Prenda",
  "Color",
  "Precio Unit.",
  "Subtotal",
  "Cant. Total",
  "Acciones",
];

const InventoryTransferItemForm: React.FC<InventoryTransferItemFormProps> = ({
  value,
  initialErrors,
  sizesColumns,
  initialValue,
  onChange,
  onDelete,
  errors,
  disabled,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);

  const [editingItem, setEditingItem] = useState<InventoryTransferFormItem>(
    value ? value : {}
  );
  const [product, setProduct] = useState<Product | undefined>(
    initialValue?.clothingProduct ? initialValue?.clothingProduct : undefined
  );
  const [color, setColor] = useState<Color | undefined>(
    initialValue?.color ? initialValue.color : undefined
  );
  const [sizeItems, setSizeItems] = useState<InventoryTransferFormItemSize[]>(
    initialValue?.sizes ? initialValue.sizes : []
  );

  const [totalRequiredClothesAmount, setTotalRequiredClothesAmount] =
    useState<number>(0);

  const onProductChange = useCallback(
    (newProduct: Product | null) => {
      const newItem = {
        ...editingItem,
        companyId: company.id,
        clothingProductId: newProduct?.id,
        clothingProduct: newProduct !== null ? newProduct : undefined,
        productCodeIdentifier: newProduct?.codeIdentifier,
        productName: newProduct?.name,
        colorName: undefined,
        color: undefined,
        colorId: undefined,
      };
      setEditingItem(newItem);
      setProduct(newProduct !== null ? newProduct : undefined);
      setColor(undefined);
      setSizeItems([]);
      setTotalRequiredClothesAmount(0);
      onChange(newItem);
    },
    [editingItem, company.id, onChange]
  );

  const onColorChange = useCallback(
    (newColor: Color | null) => {
      const newItem = {
        ...editingItem,
        companyId: company.id,
        colorName: newColor !== null ? newColor.color : undefined,
        color: newColor !== null ? newColor : undefined,
        colorId: newColor !== null ? newColor.id : undefined,
      };
      setEditingItem(newItem);
      setColor(newColor !== null ? newColor : undefined);
      if (product === undefined) {
        setSizeItems([]);
        setTotalRequiredClothesAmount(0);
        onChange(newItem);
        return;
      }
      if (newColor === null) {
        setSizeItems([]);
        setTotalRequiredClothesAmount(0);
        onChange(newItem);
        return;
      }
      fetchAvailableVariants(product, newColor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem, company.id, onChange, product]
  );

  const onSizeAmountChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      sizeItem: InventoryTransferFormItemSize | undefined
    ) => {
      if (sizeItem === undefined) {
        onChange(editingItem);
        return;
      }
      const newAmountNumber = Number(e.target.value);
      const newSizeItems = [...sizeItems];
      const index = newSizeItems.findIndex((item) => {
        return item.sizeId === sizeItem.sizeId;
      });
      if (index === -1) {
        setSizeItems(newSizeItems);
      }
      const itemCopy: InventoryTransferFormItemSize = newSizeItems[index];
      itemCopy.amount = newAmountNumber;
      newSizeItems[index] = itemCopy;
      setSizeItems(newSizeItems);

      const newTotalRequiredClothesAmount = newSizeItems.reduce(
        (total, item) => total + (item.amount || 0),
        0
      );
      setTotalRequiredClothesAmount(newTotalRequiredClothesAmount);

      const newEditingItem = {
        ...editingItem,
        companyId: company.id,
        sizes: newSizeItems,
      };
      setEditingItem(newEditingItem);
      onChange(newEditingItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem, sizeItems, company.id, onChange]
  );

  const fetchAvailableVariants = useCallback(
    async (product: Product, color: Color) => {
      const productIdStr = product.id?.toString();
      const colorIdStr = color.id?.toString();
      const limit = 200;
      const offset = 0;
      const additional = new Map();
      additional.set("clothing_product_id", productIdStr);
      additional.set("color_id", colorIdStr);

      try {
        const sizesStatus = await getList<Variant>(
          "/product_variants/with_related/",
          limit,
          offset,
          additional
        );

        if (sizesStatus.status === SUCCESS && sizesStatus.data !== undefined) {
          const newSizes = sizesStatus.data.items.map((variant) => ({
            size: variant.sizeObject,
            sizeId: variant.sizeId,
            sizeName: variant.size,
            variant: variant,
            variantId: variant.id,
            amountRequired: undefined,
          }));

          setSizeItems(newSizes);
          const newEditingItem = {
            ...editingItem,
            sizes: newSizes,
            colorName: color ? color.color : undefined,
            color: color,
            colorId: color.id,
            totalPrice: undefined,
          };
          setEditingItem(newEditingItem);
          onChange(newEditingItem);
        } else {
          setSizeItems([]);
        }
      } catch (error) {
        console.error("Error fetching sizes:", error);
        setSizeItems([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  useEffect(() => {
    if (initialValue?.clothingProduct === undefined) {
      return;
    }
    if (initialValue?.color === undefined) {
      return;
    }
    const initialSizes = initialValue.sizes ? initialValue.sizes : [];

    addAvailableSizes(
      initialValue?.clothingProduct,
      initialValue?.color,
      initialSizes
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteClick = useCallback(() => {
    onDelete(editingItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem, onDelete]);

  useEffect(() => {
    if (initialValue?.clothingProduct === undefined) {
      return;
    }
    if (initialValue?.color === undefined) {
      return;
    }
    const initialSizes = initialValue.sizes ? initialValue.sizes : [];

    addAvailableSizes(
      initialValue?.clothingProduct,
      initialValue?.color,
      initialSizes
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addAvailableSizes = async (
    product: Product,
    color: Color,
    initialSizes: InventoryTransferFormItemSize[]
  ) => {
    const productIdStr = product.id?.toString();
    const colorIdStr = color.id?.toString();
    const limit = 100;
    const offset = 0;
    const additional = new Map();
    additional.set("clothing_product_id", productIdStr);
    additional.set("color_id", colorIdStr);

    try {
      const sizesStatus = await getList<Variant>(
        "/product_variants/with_related/",
        limit,
        offset,
        additional
      );

      if (sizesStatus.status === SUCCESS && sizesStatus.data !== undefined) {
        const newSizes: InventoryTransferFormItemSize[] =
          sizesStatus.data.items.map((variant) => {
            const existingSize = initialSizes.find(
              (size) => size.sizeId === variant.sizeId
            );

            if (existingSize) {
              return existingSize;
            } else {
              return {
                size: variant.sizeObject,
                sizeId: variant.sizeId,
                sizeName: variant.size,
                variant: variant,
                variantId: variant.id,
                amount: undefined,
              };
            }
          });

        setSizeItems(newSizes);
        const newEditingItem = {
          ...editingItem,
          sizes: newSizes,
        };
        setEditingItem(newEditingItem);
        onChange(newEditingItem);
      } else {
        setSizeItems([]);
      }
    } catch (error) {
      console.error("Error fetching sizes:", error);
      setSizeItems([]);
    }
  };

  return (
    <tr style={{ verticalAlign: "middle", overflow: "hidden" }}>
      <td style={{ padding: 0, minWidth: "200px" }}>
        <ProductSelect
          value={product ? product : null}
          onChange={onProductChange}
          disabled={disabled}
        ></ProductSelect>
      </td>
      <td style={{ padding: 0, width: "120px" }}>
        <SingleColorSelect
          value={color ? color : null}
          onChange={onColorChange}
          productId={product?.id}
          key={product ? product.id : 0}
          disabled={disabled}
        ></SingleColorSelect>
      </td>
      <td style={{ padding: 0, width: "70px" }}>
        <CInput disabled value={totalRequiredClothesAmount}></CInput>
      </td>
      {sizesColumns.map((column, ix) => {
        if (FIXED_COLUMNS.includes(column)) {
          return <></>;
        }
        const matchSize = sizeItems.find((item) => item.sizeName === column);

        return (
          <td key={ix} style={{ padding: 0, width: "70px" }}>
            <CInput
              type="number"
              min={0}
              value={matchSize ? emptyValueOnUndefined(matchSize.amount) : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onSizeAmountChange(e, matchSize)
              }
              disabled={matchSize === undefined}
            ></CInput>
          </td>
        );
      })}
      <td className="text-center" style={{ padding: 0, width: "60px" }}>
        <CButtonGroup>
          <CTooltip content="Borrar">
            <CButton
              className="text-white"
              color="danger"
              onClick={() => {
                onDeleteClick();
              }}
              disabled={disabled}
            >
              <i className="fa fa-trash"></i>
            </CButton>
          </CTooltip>
        </CButtonGroup>
      </td>
    </tr>
  );
};

export default InventoryTransferItemForm;
