import {
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { updateItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import StoreSale from "../../models/store-sale";
import { formatDateTime } from "../../utils/dates";
import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";

interface SaleNullifyModalProps {
  show: boolean;
  storeSale?: StoreSale;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const SaleNullifyModal: React.FC<SaleNullifyModalProps> = ({
  show,
  storeSale,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [returnToInventory, setReturnToInventory] = useState(false);

  const message = storeSale?.documentNumber
    ? `Está seguro de que quiere anular la venta: ${storeSale.establishment}-${storeSale.dispatchPoint}-${storeSale.documentNumber}`
    : `Está seguro de que quiere anular la venta: ${formatDateTime(
        new Date(storeSale?.saleDate!)
      )}`;

  const onReturnToInvChange = (returnToInventory: boolean) => {
    setReturnToInventory(returnToInventory);
  };

  const onConfirmClick = async () => {
    if (storeSale !== undefined) {
      setSubmitting(true);
      let toSendStoreSale: StoreSale = {
        ...storeSale,
        cancelationReturnToInventory: returnToInventory
          ? returnToInventory
          : undefined,
      };
      const supplierStatus = await updateItem<StoreSale>(
        `/store_sales/${storeSale.id}/cancel/`,
        toSendStoreSale
      );

      if (supplierStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (supplierStatus.detail !== undefined) {
          message = supplierStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
        setReturnToInventory(false);
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Anular Venta</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <h4>
                Si la venta tiene devoluciones las devoluciones tambien se
                anularán.
              </h4>
              {storeSale?.cdcInvoice !== undefined ? (
                <h4>La factura también se anulará.</h4>
              ) : (
                <></>
              )}
              <h4>Esta acción no se puede deshacer.</h4>
            </CCol>
          </CRow>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Devolver Prendas al inventario:</CLabel>
              </CCol>
              <CCol md={2}>
                <CRow>
                  <AnimatedCheckbox
                    value={returnToInventory}
                    onChange={onReturnToInvChange}
                  ></AnimatedCheckbox>
                </CRow>
              </CCol>
            </CRow>
          </CFormGroup>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onConfirmClick} color="danger">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Anulando..." : "Confirmar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default SaleNullifyModal;
