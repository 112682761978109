import StoreCrud from "../components/store/StoreCrud";

const StoreCrudPage = () => {
  return (
    <>
      <StoreCrud />
    </>
  );
};

export default StoreCrudPage;
