import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Category from "../../models/category";

const RECORS_PER_SEARCH = 20;

type CategoriesSelectProps = {
  value: readonly Category[];
  onChange: React.Dispatch<React.SetStateAction<readonly Category[]>>;
  isDisabled?: boolean;
};

const CategoriesSelect: React.FC<CategoriesSelectProps> = ({
  value,
  onChange,
  isDisabled,
}) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );
  const loadOptions = async (
    s: string,
    currentOpts: readonly (Category | { options: Category[]; label?: string })[]
  ): Promise<
    Response<Category, { options: Category[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    const dataPaginated = await getList<Category>(
      "/categories/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: Category[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder="Selecciona las categorias para este descuento"
      onChange={onChange}
      isMulti={true}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: Category) => `${option.name}`}
      getOptionValue={(option: Category) => `${option.id}`}
      value={value}
      isDisabled={isDisabled}
    />
  );
};

export default CategoriesSelect;
