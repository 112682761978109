import BatchAmountList from "../components/batches-amount/BatchAmountList";

const BatchAmountListPage = () => {
  return (
    <>
      <BatchAmountList />
    </>
  );
};

export default BatchAmountListPage;
