import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import Supplier from "../../models/supplier";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { warningAlert } from "../utils/messages";
import { defaultValueOnUndefined } from "../../utils/fields";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { InventoryProductLog } from "../../models/inventory-product";
import { formatDateTime } from "../../utils/dates";
import InventoryLocation, {
  INVENTORY_LOCATION_TYPE_PRODUCTS,
} from "../../models/inventory-location";
import InventoryLocationSelect from "../inventory-location/InventoryLocationSelect";

const ITEMS_PER_PAGE = 50;

const ProductHistoryList = () => {
  const fields = [
    {
      key: "createdAt",
      _classes: ["text-center"],
      label: "Fecha",
    },
    {
      key: "inventoryName",
      _classes: ["text-center"],
      label: "Inventario",
    },
    {
      key: "productCodeIdentifier",
      _classes: ["text-center"],
      label: "Código de producto",
    },
    {
      key: "productName",
      _classes: ["text-center"],
      label: "Nombre",
    },
    {
      key: "colorName",
      _classes: ["text-center"],
      label: "Color",
    },
    {
      key: "sizeName",
      _classes: ["text-center"],
      label: "Talle",
    },
    {
      key: "amount",
      _classes: ["text-center"],
      label: "Cantidad",
    },
    {
      key: "subject",
      _classes: ["text-center"],
      label: "Motivo",
    },
    {
      key: "obs",
      _classes: ["text-center"],
      label: "Observación",
    },
  ];

  const [search, setSearch] = useState<string>("");
  const [colorFilter, setColorFilter] = useState<string>("");
  const [sizeFilter, setSizeFilter] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState<Supplier[]>([]);
  const [startDate, setStartDate] = useState<string | moment.Moment>("");
  const [endDate, setEndDate] = useState<string | moment.Moment>("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [filterLocation, setFilterLocation] = useState<
    InventoryLocation | undefined
  >(undefined);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let colorSearch = urlParams.get("color_search")
      ? urlParams.get("color_search")
      : "";
    let sizeSearch = urlParams.get("size_search")
      ? urlParams.get("size_search")
      : "";
    let locationId = urlParams.get("location_id")
      ? urlParams.get("location_id")
      : "";
    let startDate = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : "";
    let endDate = urlParams.get("end_date") ? urlParams.get("end_date") : "";
    let map = new Map();
    map.set("search", search);
    if (colorSearch !== "") map.set("color_search", colorSearch);
    if (sizeSearch !== "") map.set("size_search", sizeSearch);
    if (startDate !== "") map.set("start_date", startDate);
    if (endDate !== "") map.set("end_date", endDate);
    if (locationId !== "") map.set("location_id", locationId);
    map.set("page", page);
    return map;
  };

  const fetchLogs = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const requestStatus = await getList<InventoryProductLog>(
      "/inventory_products/logs/",
      limit,
      offset,
      urlParams
    );
    if (requestStatus.status === SUCCESS) {
      if (requestStatus.data !== undefined) {
        const count = requestStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setLogs(requestStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = requestStatus.detail
        ? requestStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    if (previousParams.get("color_search")) {
      urlParams.set("color_search", previousParams.get("color_search"));
    }
    if (previousParams.get("size_search")) {
      urlParams.set("size_search", previousParams.get("size_search"));
    }
    if (previousParams.get("start_date")) {
      urlParams.set("start_date", previousParams.get("start_date"));
    }
    if (previousParams.get("end_date")) {
      urlParams.set("end_date", previousParams.get("end_date"));
    }
    if (previousParams.get("location_id")) {
      urlParams.set("location_id", previousParams.get("location_id"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onStartDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setStartDate("");
      return;
    }
    setStartDate(value);
  };

  const onEndDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setEndDate("");
      return;
    }
    setEndDate(value);
  };

  const onFilterLocationChange = (newLocation: InventoryLocation | null) => {
    setFilterLocation(newLocation !== null ? newLocation : undefined);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColorFilter(e.target.value);
  };

  const onSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSizeFilter(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    if (search !== "") {
      urlParams.set("search", search);
    } else {
      urlParams.delete("search");
    }
    if (colorFilter !== "") {
      urlParams.set("color_search", colorFilter);
    } else {
      urlParams.delete("color_search");
    }
    if (sizeFilter !== "") {
      urlParams.set("size_search", sizeFilter);
    } else {
      urlParams.delete("size_search");
    }
    if (moment.isMoment(startDate)) {
      urlParams.set("start_date", startDate.toISOString());
    } else if (startDate !== undefined && startDate !== "") {
      urlParams.set("start_date", startDate);
    } else {
      urlParams.delete("start_date");
    }
    if (moment.isMoment(endDate)) {
      urlParams.set("end_date", endDate.toISOString());
    } else if (endDate !== undefined && endDate !== "") {
      urlParams.set("end_date", endDate);
    } else {
      urlParams.delete("end_date");
    }
    if (filterLocation !== undefined && filterLocation.id !== undefined) {
      urlParams.set("location_id", filterLocation.id.toString());
    } else {
      urlParams.delete("location_id");
    }
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("inventoryProductLogs")) {
        return;
      }
      fetchLogs();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Historial de Movimiento de productos</h3>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Sucursal:</CLabel>
                </CCol>
                <CCol>
                  <InventoryLocationSelect
                    value={filterLocation ? filterLocation : null}
                    onChange={onFilterLocationChange}
                    inventoryType={INVENTORY_LOCATION_TYPE_PRODUCTS}
                  ></InventoryLocationSelect>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Desde:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onStartDatehange}
                    value={startDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Hasta:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onEndDatehange}
                    value={endDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Color:</CLabel>
                </CCol>
                <CCol>
                  <CInput
                    type="text"
                    placeholder="Introduzca el Color"
                    onChange={onColorChange}
                    value={colorFilter}
                  />
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Talle:</CLabel>
                </CCol>
                <CCol>
                  <CInput
                    type="text"
                    placeholder="Introduzca el Talle"
                    onChange={onSizeChange}
                    value={sizeFilter}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el nombre o código de producto"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={logs}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    createdAt: (item: InventoryProductLog) => {
                      return (
                        <td className="text-center">
                          {formatDateTime(new Date(item.createdAt!))}
                        </td>
                      );
                    },
                    obs: (item: InventoryProductLog) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.obs)}
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default ProductHistoryList;
