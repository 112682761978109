import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface TransferState {
  pendingTransfers: number | undefined;
  pendingRequests: number | undefined;
}

const initialState: TransferState = {
  pendingTransfers: undefined,
  pendingRequests: undefined,
};

export const transfersSlice = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    setPendingTransfersCount: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.pendingTransfers = action.payload;
    },
    setPendingRequestsCount: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.pendingRequests = action.payload;
    },
  },
});

export const { setPendingTransfersCount, setPendingRequestsCount } =
  transfersSlice.actions;

export default transfersSlice.reducer;
