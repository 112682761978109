import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import InputMaterial from "../../models/input-material";

const RECORS_PER_SEARCH = 20;

type InputMaterialSelectProps = {
  value: InputMaterial | null;
  disabled?:boolean;
  onChange: (_: InputMaterial | null) => void;
};

const InputMaterialSelect: React.FC<InputMaterialSelectProps> = ({
  value,
  disabled,
  onChange,
}) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );
  const loadOptions = async (
    s: string,
    currentOpts: readonly (
      | InputMaterial
      | { options: InputMaterial[]; label?: string }
    )[]
  ): Promise<
    Response<InputMaterial, { options: InputMaterial[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    const dataPaginated = await getList<InputMaterial>(
      "/input_materials/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: InputMaterial[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione una Materia Prima"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: InputMaterial) => `${option.identifier}`}
      getOptionValue={(option: InputMaterial) => `${option.id}`}
      value={value}
      isDisabled={disabled}
    />
  );
};

export default InputMaterialSelect;
