import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { successAlert } from "../utils/messages";
import BarcodeForm from "./BarcodeForm";
import Errors from "../../models/errors";
import { getRoutePath } from "../../routes";
import { Spinner } from "react-bootstrap";

const BarcodeFormView = () => {
  const [errors] = useState<Errors>({});
  const [loading] = useState(false);
  const history = useHistory();

  const onInventoryTransferEditCancel = () => {
    console.log("cancelled");
  };

  const onInventoryTransferEditSuccess = () => {
    successAlert("Acción realizada con éxito!");
    history.push(getRoutePath("barcodePrint"));
  };

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol lg="12">
            <CCard>
              <CCardHeader className="d-flex flex-row mb-3">
                <div className="p-2 ">
                  <h3>Impresión de códigos de barra</h3>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading ? (
                  <Spinner animation="grow" />
                ) : (
                  <BarcodeForm
                    initialErrors={errors}
                    onCancel={onInventoryTransferEditCancel}
                    onSuccess={onInventoryTransferEditSuccess}
                  />
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default BarcodeFormView;
