import { CButton, CCol, CFormGroup, CInput, CRow } from "@coreui/react";
import React, { useCallback, useState } from "react";
import { FieldErrors } from "../form/FieldErrors";
import Errors, { errorsAreSame, getFieldErrors } from "../../models/errors";
import { emptyValueOnUndefined } from "../../utils/fields";
import InputMaterial from "../../models/input-material";
import InputMaterialSelect from "../input-material/InputMaterialSelect";
import InputBatchSelect from "../inventory-input-batches/InputBatchSelect";
import InventoryInputBatch from "../../models/inventory-input-batch";
import ProductionOrderInputMaterial from "../../models/production-input-material";

interface ProductionInputMaterialFormProps {
  value: ProductionOrderInputMaterial;
  initialInputMaterial?: InputMaterial;
  initialBatch?: InventoryInputBatch;
  initialAmount?: number;
  disabled?: boolean;
  onDelete: (_: ProductionOrderInputMaterial) => void;
  onChange: (_: ProductionOrderInputMaterial) => void;
  errors: Errors;
}

const ProductionInputMaterialForm: React.FC<
  ProductionInputMaterialFormProps
> = ({
  value,
  initialInputMaterial,
  initialBatch,
  initialAmount,
  disabled,
  onDelete,
  onChange,
  errors,
}) => {
  const [editingItem, setEditingItem] =
    useState<ProductionOrderInputMaterial>(value);
  const [inputMaterial, setInputMaterial] = useState<InputMaterial | null>(
    initialInputMaterial !== undefined ? initialInputMaterial : null
  );
  const [batch, setBatch] = useState<InventoryInputBatch | null>(
    initialBatch !== undefined ? initialBatch : null
  );
  const [amount, setAmount] = useState<number | null>(
    initialAmount !== undefined ? initialAmount : null
  );

  const onDeleteClick = useCallback(() => {
    onDelete(editingItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  const onInputMaterialChange = useCallback(
    (newInputMaterial: InputMaterial | null) => {
      setInputMaterial(newInputMaterial);
      setAmount(null);
      setBatch(null);
      const newItem = {
        ...editingItem,
        amount: undefined,
        price: undefined,
        inventoryInputBatch: undefined,
        inventoryInputBatchId: undefined,
      };
      newItem.inputMaterialIdentifier =
        newInputMaterial?.identifier !== null
          ? newInputMaterial?.identifier
          : undefined;
      newItem.inputMaterialId =
        newInputMaterial?.id !== null ? newInputMaterial?.id : undefined;
      newItem.inputMaterial =
        newInputMaterial !== null ? newInputMaterial : undefined;
      newItem.isFabric =
        newInputMaterial !== null ? newInputMaterial.type === "fabric" : false;
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onBatchChange = useCallback(
    (newBatch: InventoryInputBatch | null) => {
      setBatch(newBatch);
      const newItem = {
        ...editingItem,
        inventoryInputBatch: newBatch !== null ? newBatch : undefined,
        inventoryInputBatchId: newBatch !== null ? newBatch.id : undefined,
      };
      if (editingItem.amount !== undefined) {
        newItem.price = newBatch?.unitPrice! * editingItem.amount;
      }
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(e.target.value);
      setAmount(newValue);
      const newItem = {
        ...editingItem,
        amount: newValue !== null ? newValue : undefined,
      };
      if (editingItem.inventoryInputBatch !== undefined) {
        newItem.price = editingItem.inventoryInputBatch.unitPrice! * newValue;
      }
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  return (
    <div className="section border rounded mb-2 p-2">
      <CFormGroup>
        <CRow>
          <CCol md={2} className={"mt-2"}>
            Materia Prima:
          </CCol>
          <CCol md={3}>
            <InputMaterialSelect
              value={inputMaterial}
              onChange={onInputMaterialChange}
              disabled={disabled}
            ></InputMaterialSelect>
            <FieldErrors
              errors={getFieldErrors("inputMaterial", errors) as string[]}
            ></FieldErrors>
          </CCol>
          <CCol md={1} className={"mt-2"}>
            Cantidad:
          </CCol>
          <CCol md={2}>
            <CInput
              type="number"
              value={emptyValueOnUndefined(editingItem.amount)}
              onChange={onAmountChange}
              placeholder="Cant."
              step="0.01"
            ></CInput>
            <FieldErrors
              errors={getFieldErrors("identifier", errors) as string[]}
            ></FieldErrors>
          </CCol>
          <CCol md={1} className={"mt-2"}>
            Lote:
          </CCol>
          <CCol md={3}>
            <InputBatchSelect
              value={batch}
              key={inputMaterial !== null ? inputMaterial.id : 0}
              minAmount={amount ? amount : undefined}
              inputMaterialId={
                inputMaterial !== null ? inputMaterial.id : undefined
              }
              onChange={onBatchChange}
              disabled={disabled}
            ></InputBatchSelect>
            <FieldErrors
              errors={getFieldErrors("inputMaterial", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>

      <CFormGroup>
        <CRow>
          <CCol>
            <CButton
              className="btn btn-danger float-right"
              onClick={onDeleteClick}
              disabled={disabled}
            >
              <i className="fa fa-trash"></i>
            </CButton>
          </CCol>
        </CRow>
      </CFormGroup>
    </div>
  );
};

const propsAreEqual = (
  prevItemProps: ProductionInputMaterialFormProps,
  nextItemProps: ProductionInputMaterialFormProps
): boolean => {
  const areEqual =
    prevItemProps.value.id === nextItemProps.value.id &&
    prevItemProps.value.inventoryInputBatch ===
      nextItemProps.value.inventoryInputBatch &&
    prevItemProps.value.amount === nextItemProps.value.amount &&
    prevItemProps.value.inputMaterial === nextItemProps.value.inputMaterial &&
    prevItemProps.value.price === nextItemProps.value.price &&
    prevItemProps.value.inputMaterialId ===
      nextItemProps.value.inputMaterialId &&
    prevItemProps.value.inventoryInputBatchId ===
      nextItemProps.value.inventoryInputBatchId &&
    errorsAreSame(prevItemProps.errors, nextItemProps.errors);

  return areEqual;
};

export default React.memo(ProductionInputMaterialForm, propsAreEqual);
