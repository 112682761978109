export const calculateSoldPercentage = (item: {
  clothesProduced?: number;
  clothesSold?: number;
}): string | undefined => {
  const { clothesProduced, clothesSold } = item;

  if (clothesProduced === undefined) {
    return undefined;
  }

  if (clothesSold === undefined) {
    return "0%";
  }

  const percentage = (clothesSold / clothesProduced) * 100;
  return `${percentage.toFixed(2)}%`;
};
