import SupplierCrud from "../components/supplier/SupplierCrud";

const SupplierCrudPage = () => {
  return (
    <>
      <SupplierCrud />
    </>
  );
};

export default SupplierCrudPage;
