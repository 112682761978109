import AmountsSummary from "../components/amounts-summary/AmountsSummary";

const AmountsSummaryPage = () => {
  return (
    <>
      <AmountsSummary />
    </>
  );
};

export default AmountsSummaryPage;
