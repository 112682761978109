import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import Client, { ClientPayment, newClientPayment } from "../../models/client";
import PaymentForm from "./PaymentForm";

interface ClientPaymentModalProps {
  show: boolean;
  client?: Client;
  payment?:ClientPayment;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ClientPaymentModal: React.FC<ClientPaymentModalProps> = ({
  show,
  client,
  payment,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialPayment, setInitialPayment] = useState<ClientPayment>(
    payment ? payment : newClientPayment()
  );

  const modalTitle = client
    ? `Realizar pago - cliente: ${client.name}`
    : `Realizar pago`;

  const onClose = () => {
    setInitialErrors({});
    setInitialPayment(newClientPayment());
    onCancel();
  };
  useEffect(() => {
    setInitialPayment(payment ? payment : newClientPayment());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  return (
    <CModal show={show} onClose={onClose} size="xl" closeOnBackdrop={false}>
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <PaymentForm
            renderContent={show}
            initialPayment={initialPayment}
            initialClient={client}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default ClientPaymentModal;
