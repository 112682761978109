import StoreRequestList from "../components/store-request/StoreRequestCrud";

const StoreRequestListPage = () => {
  return (
    <>
      <StoreRequestList />
    </>
  );
};

export default StoreRequestListPage;
