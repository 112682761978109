import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
  CTooltip,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import DiscountDeleteModal from "./DiscountDeleteModal";
import DiscountUpdateModal from "./DiscountUpdateModal";
import Discount, { getDiscountTypeLabel } from "../../models/discount";
import DiscountActivenessModal from "./DiscountActivenessModal";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import {
  DISCOUNTS_CREATE,
  DISCOUNTS_DESTROY,
  DISCOUNTS_UPDATE,
} from "../../auth/permissions";

const ITEMS_PER_PAGE = 20;

const DiscountList = () => {
  const fields = [
    {
      key: "name",
      _classes: ["text-center", "align-middle"],
      label: "Nombre",
    },
    {
      key: "discountType",
      _classes: ["text-center", "align-middle"],
      label: "Tipo de Descuento",
    },
    {
      key: "discountPercentage",
      _classes: ["text-center", "align-middle"],
      label: "Monto de Descuento",
    },
    {
      key: "actions",
      _classes: ["text-center", "align-middle"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActivenessModal, setShowActivenessModal] = useState(false);

  const [discount, setDiscount] = useState<Discount | undefined>(undefined);
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    return map;
  };

  const fetchDiscounts = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const discountsStatus = await getList<Discount>(
      "/discounts/",
      limit,
      offset,
      urlParams
    );
    if (discountsStatus.status === SUCCESS) {
      if (discountsStatus.data !== undefined) {
        const count = discountsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setDiscounts(discountsStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = discountsStatus.detail
        ? discountsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setShowEditModal(true);
  };

  const onUpdateClick = (discount: Discount) => {
    setDiscount(discount);
    setShowEditModal(true);
  };

  const onDeleteClick = (discount: Discount) => {
    setDiscount(discount);
    setShowDeleteModal(true);
  };

  const onActivenessClick = (discount: Discount) => {
    setDiscount(discount);
    setShowActivenessModal(true);
  };

  const onEditCancel = () => {
    setDiscount(undefined);
    setShowEditModal(false);
  };

  const onEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowEditModal(false);
    setDiscounts([]);
    setLoading(true);
    fetchDiscounts();
  };

  const onDeleteCancel = () => {
    setDiscount(undefined);
    setShowDeleteModal(false);
  };

  const onDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowDeleteModal(false);
    setDiscounts([]);
    setLoading(true);
    fetchDiscounts();
  };

  const onActivenessCancel = () => {
    setDiscount(undefined);
    setShowActivenessModal(false);
  };

  const onActivenessSuccess = () => {
    successAlert("Registro modificado con éxito!");
    setShowActivenessModal(false);
    setDiscounts([]);
    setLoading(true);
    fetchDiscounts();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("search", search);
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchDiscounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("discounts")) {
        return;
      }
      fetchDiscounts();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Descuentos/Promociones</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent permissionName={DISCOUNTS_CREATE}>
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={discounts}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    discountType: (item: Discount) => {
                      return (
                        <td className="text-center">
                          {item.discountType
                            ? getDiscountTypeLabel(item.discountType)
                            : "-"}
                        </td>
                      );
                    },
                    discountPercentage: (item: Discount) => {
                      let valueToShow: string = "-";
                      if (item.discountType === "percentage") {
                        valueToShow = item.discountPercentage
                          ? `${item.discountPercentage}%`
                          : "-";
                      } else if (item.discountType === "two_levels") {
                        valueToShow = item.discountPercentage
                          ? `${formatToCurrency(
                              item.discountPercentage,
                              PYG
                            )} -> ${formatToCurrency(
                              item.secondDiscountValue ?? 0,
                              PYG
                            )}`
                          : "-";
                      } else {
                        valueToShow = item.discountPercentage
                          ? formatToCurrency(item.discountPercentage, PYG)
                          : "-";
                      }

                      return <td className="text-center">{valueToShow}</td>;
                    },
                    actions: (item: Discount) => {
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={DISCOUNTS_UPDATE}
                        >
                          <CTooltip content={"Editar"}>
                            <CButton
                              className="text-white"
                              color="warning"
                              onClick={() => {
                                onUpdateClick(item);
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const iconClass =
                        item.isActive === true
                          ? "fa fa-arrow-down"
                          : "fa fa-arrow-up";

                      const ButtonText =
                        item.isActive === true ? "Desactivar" : "Activar";

                      const activenessButton = (
                        <PermissionRequiredComponent
                          permissionName={DISCOUNTS_UPDATE}
                        >
                          <CTooltip content={"Activar/Desactivar Promoción"}>
                            <CButton
                              className="text-white"
                              color="success"
                              onClick={() => {
                                onActivenessClick(item);
                              }}
                            >
                              <i className={iconClass}></i>
                              {ButtonText}
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const deleteButton = (
                        <PermissionRequiredComponent
                          permissionName={DISCOUNTS_DESTROY}
                        >
                          <CTooltip content={"Borrar"}>
                            <CButton
                              className="text-white"
                              color="danger"
                              onClick={() => {
                                onDeleteClick(item);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );

                      return (
                        <td className="text-right align-middle">
                          <CButtonGroup>
                            {activenessButton}
                            {editButton}
                            {deleteButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <DiscountUpdateModal
        show={showEditModal}
        onCancel={onEditCancel}
        onSuccess={onEditSuccess}
        discount={discount}
      />
      <DiscountDeleteModal
        show={showDeleteModal}
        onCancel={onDeleteCancel}
        onSuccess={onDeleteSuccess}
        discount={discount}
      />
      <DiscountActivenessModal
        show={showActivenessModal}
        onCancel={onActivenessCancel}
        onSuccess={onActivenessSuccess}
        discount={discount}
      />
    </>
  );
};

export default DiscountList;
