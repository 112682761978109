import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import Supplier from "../../models/supplier";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import SupplierDeleteModal from "./SupplierDeleteModal";
import SupplierUpdateModal from "./SupplierUpdateModal";
import { defaultValueOnUndefined } from "../../utils/fields";
import {
  SUPPLIERS_CREATE,
  SUPPLIERS_DESTROY,
  SUPPLIERS_UPDATE,
} from "../../auth/permissions";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";

const ITEMS_PER_PAGE = 10;

const SupplierList = () => {
  const fields = [
    {
      key: "name",
      _classes: ["text-center"],
      label: "Nombre",
    },
    {
      key: "legalName",
      _classes: ["text-center"],
      label: "Nombre Legal",
    },
    {
      key: "ruc",
      _classes: ["text-center"],
      label: "RUC",
    },
    {
      key: "phone",
      _classes: ["text-center"],
      label: "Teléfono",
    },
    {
      key: "email",
      _classes: ["text-center"],
      label: "Email",
    },
    {
      key: "contactName",
      _classes: ["text-center"],
      label: "Nombre de Contacto",
    },
    {
      key: "contactPhone",
      _classes: ["text-center"],
      label: "Teléfono de contacto",
    },
    {
      key: "contactEmail",
      _classes: ["text-center"],
      label: "Email de contacto",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showSupplierEditModal, setShowSupplierEditModal] = useState(false);
  const [showSupplierDeleteModal, setShowSupplierDeleteModal] = useState(false);

  const [supplier, setSupplier] = useState<Supplier | undefined>(undefined);
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    return map;
  };

  const fetchSuppliers = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const requestStatus = await getList<Supplier>(
      "/suppliers/",
      limit,
      offset,
      urlParams
    );
    if (requestStatus.status === SUCCESS) {
      if (requestStatus.data !== undefined) {
        const count = requestStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setSuppliers(requestStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = requestStatus.detail
        ? requestStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setShowSupplierEditModal(true);
  };

  const onUpdateClick = (supplier: Supplier) => {
    setSupplier(supplier);
    setShowSupplierEditModal(true);
  };

  const onDeleteClick = (supplier: Supplier) => {
    setSupplier(supplier);
    setShowSupplierDeleteModal(true);
  };

  const onSupplierEditCancel = () => {
    setSupplier(undefined);
    setShowSupplierEditModal(false);
  };

  const onSupplierEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowSupplierEditModal(false);
    setSuppliers([]);
    setLoading(true);
    fetchSuppliers();
  };

  const onSupplierDeleteCancel = () => {
    setSupplier(undefined);
    setShowSupplierDeleteModal(false);
  };

  const onSupplierDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowSupplierDeleteModal(false);
    setSuppliers([]);
    setLoading(true);
    fetchSuppliers();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("search", search);
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("suppliers")) {
        return;
      }
      fetchSuppliers();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Proveedores</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent permissionName={SUPPLIERS_CREATE}>
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={suppliers}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    phone: (item: Supplier) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.phone)}
                        </td>
                      );
                    },
                    legalName: (item: Supplier) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.legalName)}
                        </td>
                      );
                    },
                    ruc: (item: Supplier) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.ruc)}
                        </td>
                      );
                    },
                    email: (item: Supplier) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.email)}
                        </td>
                      );
                    },
                    contactName: (item: Supplier) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.contactName)}
                        </td>
                      );
                    },
                    contactPhone: (item: Supplier) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.contactPhone)}
                        </td>
                      );
                    },
                    contactEmail: (item: Supplier) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.contactEmail)}
                        </td>
                      );
                    },
                    actions: (item: Supplier) => {
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={SUPPLIERS_UPDATE}
                        >
                          <CButton
                            className="text-white"
                            color="warning"
                            onClick={() => {
                              onUpdateClick(item);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );
                      const deleteButton = (
                        <PermissionRequiredComponent
                          permissionName={SUPPLIERS_DESTROY}
                        >
                          <CButton
                            className="text-white"
                            color="danger"
                            onClick={() => {
                              onDeleteClick(item);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {editButton}
                            {deleteButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <SupplierUpdateModal
        show={showSupplierEditModal}
        onCancel={onSupplierEditCancel}
        onSuccess={onSupplierEditSuccess}
        supplier={supplier}
      />
      <SupplierDeleteModal
        show={showSupplierDeleteModal}
        onCancel={onSupplierDeleteCancel}
        onSuccess={onSupplierDeleteSuccess}
        supplier={supplier}
      />
    </>
  );
};

export default SupplierList;
