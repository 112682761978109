import ProductionOrder from "../models/production-order";

export const orderIsEditable = (order: ProductionOrder): boolean => {
  if (order.orderStatus === undefined) {
    return true;
  }
  return order.startDate === undefined && order.orderStatus === "pending";
};

export const orderCanAdvance = (order: ProductionOrder): boolean => {
  if (order.orderStatus === undefined) {
    return false;
  }
  return order.orderStatus === "ongoing";
};

export const orderCanEnd = (order: ProductionOrder): boolean => {
  if (orderCanAdvance(order) === false) {
    return false;
  }
  return order.reportUrl !== undefined;
};

export const orderHasFinishedProducts = (order: ProductionOrder): boolean => {
  return order.finishedAmount !== undefined && order.finishedAmount !== 0;
};
