import PaymentList from "../components/payments/PaymentList";

const ClientPaymentListPage = () => {
  return (
    <>
      <PaymentList />
    </>
  );
};

export default ClientPaymentListPage;
