import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { updateItem, ItemRequestStatus } from "../../api/generics";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import StoreRequest, { newStoreRequest } from "../../models/store-requests";

interface StoreRequestFormProps {
  initialStoreRequest?: StoreRequest;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const StoreRequestForm: React.FC<StoreRequestFormProps> = ({
  initialStoreRequest,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingStoreRquest, setEditingStoreRequest] = useState<StoreRequest>(
    initialStoreRequest ? initialStoreRequest : newStoreRequest()
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingStoreRequest({
      ...editingStoreRquest,
      amount: Number(e.target.value),
      totalValue:
        (editingStoreRquest.variantPrice || 0) * Number(e.target.value),
    });
  };

  const onSave = async () => {
    setSubmitting(true);

    let toSendStoreRequest = {
      ...editingStoreRquest,
      companyId: company.id,
    };
    let requestPromise: Promise<ItemRequestStatus<StoreRequest>>;
    if (editingStoreRquest.id === undefined) {
      errorAlert("Ocurrio un error");
      setSubmitting(false);
      onCancel();
      return;
    } else {
      requestPromise = updateItem<StoreRequest>(
        `/store_requests/${toSendStoreRequest.id}/`,
        toSendStoreRequest
      );
    }

    const storeRequestStatus = await requestPromise;

    if (storeRequestStatus.status !== SUCCESS) {
      if (storeRequestStatus.errors !== undefined) {
        setErrors(storeRequestStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (storeRequestStatus.detail !== undefined) {
        message = storeRequestStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingStoreRequest(newStoreRequest());
  };

  useEffect(() => {
    setEditingStoreRequest(
      initialStoreRequest ? initialStoreRequest : newStoreRequest()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStoreRequest]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>Producto Solicitado:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingStoreRquest.variantName)}
                disabled
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("variantName", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={3}>
              <CLabel>Cantidad a solicitar:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="number"
                value={emptyValueOnUndefined(editingStoreRquest.amount)}
                onChange={onAmountChange}
                placeholder="Cantidad"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("total_value", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default StoreRequestForm;
