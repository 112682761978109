import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem, updateItem, ItemRequestStatus } from "../../api/generics";
import Size, { newSize } from "../../models/size";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";

interface SizeFormProps {
  initialSize?: Size;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const SizeForm: React.FC<SizeFormProps> = ({
  initialSize,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingSize, setEditingSize] = useState<Size>(
    initialSize ? initialSize : newSize()
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingSize({
      ...editingSize,
      size: e.target.value,
    });
  };

  const onKidsSizeChange = (isKidsSize: boolean) => {
    setEditingSize({
      ...editingSize,
      isKidSize: isKidsSize,
    });
  };


  const onSave = async () => {
    setSubmitting(true);
    let toSendSize = {
      ...editingSize,
      companyId: company.id,
    };
    let requestPromise: Promise<ItemRequestStatus<Size>>;
    if (editingSize.id === undefined) {
      requestPromise = createItem<Size>("/sizes/create/", toSendSize);
    } else {
      requestPromise = updateItem<Size>(
        `/sizes/${toSendSize.id}/`,
        toSendSize
      );
    }

    const sizeStatus = await requestPromise;

    if (sizeStatus.status !== SUCCESS) {
      if (sizeStatus.errors !== undefined) {
        setErrors(sizeStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (sizeStatus.detail !== undefined) {
        message = sizeStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingSize(newSize());
  };

  useEffect(() => {
    setEditingSize(initialSize ? initialSize : newSize());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSize]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingSize.size)}
                onChange={onNameChange}
                placeholder="Nombre de size"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("size", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Talle de niños:</CLabel>
            </CCol>
            <CCol md={2}>
              <CRow>
                <AnimatedCheckbox
                  value={
                    editingSize.isKidSize
                  }
                  defaultValue={editingSize.isKidSize}
                  onChange={onKidsSizeChange}
                ></AnimatedCheckbox>
              </CRow>

              <FieldErrors
                errors={getFieldErrors("applyDiscount", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" size="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" size="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default SizeForm;
