import SizeCrud from "../components/size/SizeCrud";

const SizeCrudPage = () => {
  return (
    <>
      <SizeCrud />
    </>
  );
};

export default SizeCrudPage;
