import {
  CButton,
  CCol,
  CContainer,
  CInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import ProductionOrder, {
  ProductionOrderItem,
} from "../../models/production-order";
import {
  VariantTableEntry,
  productionOrderItemAPIToTable,
} from "../../models/variant";
import { emptyValueOnUndefined } from "../../utils/fields";
import {
  BarcodeData,
  convertToBarcodeDataFromProduction,
} from "../../utils/barcode-print";
import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";

interface ProductionBarcodeModalProps {
  show: boolean;
  productionOrder?: ProductionOrder;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const FIXED_COLUMNS = ["Color"];

const ProductionBarcodeModal: React.FC<ProductionBarcodeModalProps> = ({
  show,
  productionOrder,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sizeErrors, setSizeErrors] = useState<string>("");

  const [tableColumns, setTableColumns] = useState<string[]>(FIXED_COLUMNS);

  const [tableEntries, setTableEntries] = useState<VariantTableEntry[]>([]);

  const message = productionOrder
    ? `Está seguro de que quiere Imprimir códigos de barra para el lote de producción ${productionOrder.id}, ${productionOrder.productName}`
    : "";

  const onSave = async () => {
    setSubmitting(true);

    const formattedItems = convertToBarcodeDataFromProduction(tableEntries);

    generateAndPrintPDF(formattedItems);

    clearForm();

    setSubmitting(false);
  };

  const generateAndPrintPDF = (data: BarcodeData[]) => {
    const customWidth = 56;
    const customHeight = 124;
    const barcodeHeight = 12;
    const margin = 5;
    const stickersPerPage = 4;
    const stickerHeight =
      (customHeight - (stickersPerPage + 1) * margin) / stickersPerPage;
    const offsetY = 2;
    const pdf = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: [customWidth, customHeight],
    });

    const truncateText = (text: string, maxWidth: number) => {
      let truncatedText = text;
      const ellipsis = "...";
      while (
        pdf.getTextWidth(truncatedText + ellipsis) > maxWidth &&
        truncatedText.length > 0
      ) {
        truncatedText = truncatedText.slice(0, -1);
      }

      return truncatedText + ellipsis;
    };

    let currentStickerCount = 0;
    let currentPage = 1;
    let y = margin;

    const companyName = "LUNGOR SRL";
    pdf.setFontSize(6);
    const textWidthTitle = pdf.getTextWidth(companyName);
    const textXTitle = (customWidth - textWidthTitle) / 2;
    pdf.setFontSize(16);
    data.forEach(({ code, quantity, label }) => {
      for (let i = 0; i < quantity; i++) {
        if (currentStickerCount === stickersPerPage) {
          pdf.addPage();
          currentStickerCount = 0;
          y = margin;
          currentPage = currentPage + 1;
        }

        const x = margin;

        const canvas = document.createElement("canvas");
        const isNumeric = /^\d+$/.test(code);

        const marginBarcode = isNumeric ? 50 : 50;

        JsBarcode(canvas, code, {
          format: "CODE128",
          displayValue: true,
          fontSize: 24,
          marginTop: 0,
          marginBottom: 0,
          marginLeft: marginBarcode,
          marginRight: marginBarcode,
        });

        const imgData = canvas.toDataURL("image/png");
        const textWidth = pdf.getTextWidth(code);
        const textX = x + (customWidth - 2 * margin - textWidth) / 2;

        pdf.addImage(
          imgData,
          "PNG",
          textX,
          y + offsetY + (stickerHeight - barcodeHeight) / 2,
          textWidth,
          barcodeHeight
        );
        pdf.setFontSize(6);

        pdf.text(
          companyName,
          textXTitle,
          y + offsetY + (stickerHeight - barcodeHeight) / 2 - 4
        );
        pdf.setFontSize(16);

        if (label) {
          pdf.setFontSize(6);
          const labelWidth = pdf.getTextWidth(label);
          if (labelWidth > textWidth) {
            const labelTruncated = truncateText(label, textWidth);
            pdf.text(
              labelTruncated,
              textX,
              y + offsetY + (stickerHeight - barcodeHeight) / 2 - 1
            );
          } else {
            pdf.text(
              label,
              textX + 2,
              y + offsetY + (stickerHeight - barcodeHeight) / 2 - 1
            );
          }

          pdf.setFontSize(16);
        }
        y += stickerHeight + margin;
        currentStickerCount++;

        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
      }
    });

    pdf.autoPrint();
    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const clearForm = () => {
    generateTableHeaders([]);
    setTableColumns([]);
    setTableEntries([]);
    onSuccess();
  };

  const generateTableHeaders = (entries: VariantTableEntry[]) => {
    const uniqueSizeNames: string[] = [];

    entries.forEach((item) => {
      if (item.sizes) {
        item.sizes
          .map((size) => size?.sizeName)
          .filter(
            (sizeName) =>
              sizeName !== undefined && !uniqueSizeNames.includes(sizeName)
          )
          .forEach((sizeName) => {
            if (sizeName) {
              uniqueSizeNames.push(sizeName);
            }
          });
      }
    });

    uniqueSizeNames.sort((a, b) => {
      const aFloat = parseFloat(a);
      const bFloat = parseFloat(b);
      return aFloat - bFloat;
    });

    setTableColumns([...FIXED_COLUMNS, ...uniqueSizeNames]);
  };

  const getOrderItems = async () => {
    if (productionOrder === undefined || productionOrder.id === undefined) {
      setTableEntries([]);
      return;
    }
    const productionOrderIdStr = productionOrder.id.toString();
    if (productionOrderIdStr === undefined) {
      setTableEntries([]);
      return;
    }
    const limit = 2000;
    const offset = 0;
    const orderSizesStatus = await getList<ProductionOrderItem>(
      `/production/${productionOrderIdStr}/order_items/`,
      limit,
      offset
    );
    if (orderSizesStatus.status === SUCCESS) {
      if (orderSizesStatus.data !== undefined) {
        const formattedTableEntries = productionOrderItemAPIToTable(
          orderSizesStatus.data.items
        );

        setTableEntries(formattedTableEntries);
        generateTableHeaders(formattedTableEntries);
      } else {
        setTableEntries([]);
        return;
      }
    } else {
      setTableEntries([]);
      return;
    }
  };

  const onSizeAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, variantId: number | undefined) => {
      const newAmountNumber = Number(e.target.value);

      if (variantId !== undefined) {
        const newTableEntries = tableEntries.map((entry) => {
          const updatedSizes = entry.sizes?.map((size) => {
            if (size.variantId === variantId) {
              return {
                ...size,
                amount: newAmountNumber,
              };
            }
            return size;
          });

          return {
            ...entry,
            sizes: updatedSizes,
          };
        });
        setTableEntries(newTableEntries);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableEntries]
  );

  const fetchTableEntries = async () => {
    setLoading(true);
    await getOrderItems();
    setLoading(false);
  };

  useEffect(() => {
    setSizeErrors("");
    fetchTableEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionOrder]);

  return (
    <CModal show={show} className="modal-primary" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>
          Imprimir códigos de barra para lote de producción
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <h5>{"Asegúrese que la impresora esta conectada."}</h5>
            </CCol>
          </CRow>
          <fieldset>
            <CRow>
              <CCol md={2}>
                <h5>Items disponibles</h5>
              </CCol>
            </CRow>
            {sizeErrors !== "" ? (
              <CRow>
                <CCol md={12}>
                  <p className="text-danger">{sizeErrors}</p>
                </CCol>
              </CRow>
            ) : (
              <></>
            )}

            {!loading ? (
              <CRow>
                <CCol md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered table-fixed">
                      <thead>
                        <tr>
                          {tableColumns.map((title, ix) => {
                            return (
                              <th
                                className="text-center"
                                key={ix}
                                style={{
                                  verticalAlign: "middle",
                                  overflow: "hidden",
                                }}
                              >
                                <div className="d-inline">{title}</div>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tableEntries.map((tableEntry, ix) => {
                          return (
                            <tr
                              style={{
                                verticalAlign: "middle",
                                overflow: "hidden",
                              }}
                              key={ix}
                            >
                              <td
                                className={"text-center"}
                                style={{ padding: 0, width: "120px" }}
                              >
                                <p>{tableEntry.colorName}</p>
                              </td>
                              {tableColumns.map((column, ix) => {
                                if (column === "Color") {
                                  return <></>;
                                }
                                const matchSize = tableEntry.sizes?.find(
                                  (item) => item.sizeName === column
                                );
                                const disabled = matchSize === undefined;
                                return (
                                  <td
                                    key={ix}
                                    style={{ padding: 0, width: "70px" }}
                                  >
                                    <CInput
                                      type="number"
                                      min={0}
                                      value={
                                        disabled
                                          ? ""
                                          : matchSize
                                          ? emptyValueOnUndefined(
                                              matchSize.amount
                                            )
                                          : ""
                                      }
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) =>
                                        onSizeAmountChange(
                                          e,
                                          matchSize!.variantId
                                        )
                                      }
                                      disabled={disabled}
                                    ></CInput>
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                        {tableEntries.length === 0 ? (
                          <tr>
                            <td colSpan={tableColumns.length}>
                              No hay tamaños disponibles para esta prenda.
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                    <br />
                    <br />
                  </div>
                </CCol>
              </CRow>
            ) : (
              <Spinner
                animation="grow"
                style={{
                  height: "17px",
                  width: "17px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
            )}
          </fieldset>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onSave} color="primary">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Confirmando..." : "Confirmar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ProductionBarcodeModal;
