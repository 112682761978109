import {
  CButton,
  CButtonGroup,
  CCol,
  CContainer,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { warningAlert } from "../utils/messages";

import VariantSelect from "../product/VariantSelect";
import Variant from "../../models/variant";
import SingleStoreSelect from "../store/SingleStoreSelect";
import Store from "../../models/store";

interface ManualAddProductModalProps {
  show: boolean;
  isRequest?: boolean;
  onCancel: () => void | Promise<void>;
  onSuccess: (
    storeSaleItem: Variant,
    storeLocation: Store,
    amount: number
  ) => void | Promise<void>;
}

const ManualAddProductModal: React.FC<ManualAddProductModalProps> = ({
  show,
  isRequest,
  onCancel,
  onSuccess,
}) => {
  const [variant, setVariant] = useState<Variant | undefined>();
  const [amount, setAmount] = useState<number>(0);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterStore, setFilterStore] = useState<Store | undefined>(undefined);

  const onVariantChange = async (newVariant: Variant | null) => {
    setVariant(newVariant !== null ? newVariant : undefined);
  };

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Number(e.target.value));
  };

  const onFilterStoreChange = (newStore: Store | null) => {
    setFilterStore(newStore !== null ? newStore : undefined);
  };

  const onSave = async () => {
    if (filterStore === undefined) {
      warningAlert("Debe seleccionar una sucursal");
      setSubmitting(false);
      return;
    }
    setSubmitting(true);
    if (variant === undefined) {
      warningAlert("Debe seleccionar un producto");
      setSubmitting(false);
      return;
    }
    if (amount <= 0) {
      warningAlert("La cantidad debe ser mayor a 0");
      setSubmitting(false);
      return;
    }
    if (amount > (variant.availableAmount || 0) && !isRequest) {
      warningAlert("La cantidad ingresada supera el stock actual del producto");
      setSubmitting(false);
      return;
    }
    onSuccess(variant, filterStore, amount);
    setSubmitting(false);
    clearForm();
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setAmount(0);
    setVariant(undefined);
    setFilterStore(undefined);
    onResetVariantSelect();
  };

  const onResetVariantSelect = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setVariant(undefined);
    }, 500);
  };

  return (
    <CModal show={show} className="modal-primary" onClosed={onCancel} size="lg">
      <CModalHeader closeButton>
        <CModalTitle>
          Seleccione el producto para agregar{" "}
          {isRequest ? "al pedido" : "a la venta"}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel className={"mt-2"}>Sucursal:</CLabel>
              </CCol>
              <CCol>
                <SingleStoreSelect
                  value={filterStore ? filterStore : null}
                  onChange={onFilterStoreChange}
                ></SingleStoreSelect>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Producto:</CLabel>
              </CCol>
              <CCol>
                {loading ? (
                  <Spinner animation="border" />
                ) : (
                  <VariantSelect
                    value={variant ? variant : null}
                    onChange={onVariantChange}
                  ></VariantSelect>
                )}
              </CCol>
              <CCol md={2}>
                <CButton onClick={onResetVariantSelect} color="primary">
                  Recargar
                </CButton>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Cantidad:</CLabel>
              </CCol>
              <CCol>
                <CInput
                  type="number"
                  value={amount}
                  min={0}
                  max={variant?.availableAmount ? variant.availableAmount : 0}
                  onChange={onAmountChange}
                  placeholder="Cantidad"
                ></CInput>
              </CCol>
            </CRow>
          </CFormGroup>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButtonGroup>
          <CButton type="button" color="secondary" onClick={onClose}>
            Atras
          </CButton>
          <CButton disabled={submitting} onClick={onSave} color="primary">
            {submitting ? (
              <Spinner
                animation="grow"
                style={{
                  height: "17px",
                  width: "17px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
            ) : (
              <></>
            )}
            {submitting ? "Enviando..." : "Enviar"}
          </CButton>
        </CButtonGroup>
      </CModalFooter>
    </CModal>
  );
};

export default ManualAddProductModal;
