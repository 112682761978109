import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import Product, { newProduct } from "../../models/product";
import ProductAmountForm from "./ProductAmountForm";

interface ProductAmountModalProps {
  show: boolean;
  product?: Product;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ProductAmountModal: React.FC<ProductAmountModalProps> = ({
  show,
  product,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialProduct, setInitialProduct] = useState<Product>(
    product ? product : newProduct()
  );

  const modalTitle = product
    ? `Agregar/disminuir Stock a producto: ${product.name}`
    : `Agregar/disminuir Stock a producto`;

  const onClose = () => {
    setInitialErrors({});
    setInitialProduct(newProduct());
    onCancel();
  };
  useEffect(() => {
    setInitialProduct(product ? product : newProduct());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <CModal show={show} onClose={onClose} size="xl" className="modal-primary">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <h3>Las cantidades seran agregadas al inventario actual</h3>
          <p>
            Puede usar números positivos para agregar o números negativos para
            disminuir
          </p>
          <ProductAmountForm
            initialProduct={initialProduct}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default ProductAmountModal;
