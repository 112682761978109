import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Color from "../../models/color";
import InventoryInputBatch from "../../models/inventory-input-batch";
import { DATEDDMMYYY, formatDate } from "../../utils/dates";
import { showNumberWithDecimalPoint } from "../../utils/fields";

const RECORS_PER_SEARCH = 20;

type InputBatchSelectProps = {
  inputMaterialId?: number;
  minAmount?: number;
  value: InventoryInputBatch | null;
  disabled?: boolean;
  onChange: (_: InventoryInputBatch | null) => void;
};

const InputBatchSelect: React.FC<InputBatchSelectProps> = ({
  inputMaterialId,
  minAmount,
  disabled,
  value,
  onChange,
}) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );
  const loadOptions = async (
    s: string,
    currentOpts: readonly (
      | InventoryInputBatch
      | { options: InventoryInputBatch[]; label?: string }
    )[]
  ): Promise<
    Response<Color, { options: InventoryInputBatch[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    if (inputMaterialId !== undefined) {
      additionalParams.set("input_material_id", inputMaterialId.toString());
    }

    if (minAmount !== undefined) {
      additionalParams.set("min_available", minAmount.toString());
    }

    const dataPaginated = await getList<InventoryInputBatch>(
      "/inventory_locations/batches/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: InventoryInputBatch[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un lote"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: InventoryInputBatch) =>
        `${
          option.purchaseDate
            ? formatDate(new Date(option.purchaseDate), DATEDDMMYYY)
            : ""
        } - Disponible -> ${showNumberWithDecimalPoint(
          option.amount! - option.amountUsed!,
          2
        )}`
      }
      getOptionValue={(option: InventoryInputBatch) => `${option.id}`}
      value={value}
      isDisabled={disabled}
    />
  );
};

export default InputBatchSelect;
