import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { deleteItem } from "../../api/generics";
import Product from "../../models/product";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";

interface ProductDeleteModalProps {
  show: boolean;
  product?: Product;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ProductDeleteModal: React.FC<ProductDeleteModalProps> = ({
  show,
  product,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = product
    ? `Está seguro de que quiere eliminar la prenda: ${product.name}`
    : "";

  const onDeleteClick = async () => {
    if (product !== undefined) {
      setSubmitting(true);
      const productStatus = await deleteItem<Product>(
        `/clothing_products/${product.id}/`,
        product
      );

      if (productStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (productStatus.detail !== undefined) {
          message = productStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Eliminar</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <h3 className="text-danger">Precaución</h3>
              <h5>
                No se recomienda borrar Modelos de prendas puesto que esto
                podria afectar a datos o registros anteriores en la cual fue
                ultilizado. por ejemplo órdenes de venta, órdenes de envío,
                órdenes de producción. Así como también el inventario de dicho
                modelo de prenda en todas las ubicaciones.
              </h5>
              <br />
              <h5>Podrían perderse datos.</h5>
              <br />
              <h5>
                Si ya no va utilizar no es necesario borrar. Puede quedar como
                un registro.
              </h5>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onDeleteClick} color="danger">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Eliminando..." : "Eliminar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ProductDeleteModal;
