interface Color {
  id?: number;
  companyId?: number,
  color?: string;
}

export const newColor = (): Color => {
  return {
  };
};

export default Color;
