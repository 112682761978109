interface Category {
  id?: number;
  name?: string;
  companyId?: number;
}

export const newCategory = (): Category => {
  return {};
};

export default Category;
