import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import Product from "../../models/product";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import ProductDeleteModal from "./ProductDeleteModal";
import ProductUpdateModal from "./ProductUpdateModal";
import ProductAmountModal from "./ProductAmountModal";
import ProductAsyncImage from "./ProductAsyncImage";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import {
  CLOTHING_MODELS_CREATE,
  CLOTHING_MODELS_DESTROY,
  CLOTHING_MODELS_RETRIEVE,
  CLOTHING_MODELS_UPDATE,
} from "../../auth/permissions";
import { defaultValueOnUndefined } from "../../utils/fields";
import CategorySelect from "../category/CategorySelect";
import Category from "../../models/category";

const ITEMS_PER_PAGE = 10;

const ProductList = () => {
  const fields = [
    {
      key: "imageUrl",
      _classes: ["text-center"],
      label: "Imagen",
    },
    {
      key: "name",
      _classes: ["text-center", "align-middle"],
      label: "Nombre",
    },
    {
      key: "codeIdentifier",
      _classes: ["text-center", "align-middle"],
      label: "Codigo de Producto",
    },
    {
      key: "description",
      _classes: ["text-center", "align-middle"],
      label: "Descripcion",
    },
    {
      key: "categoryName",
      _classes: ["text-center", "align-middle"],
      label: "Categoría",
    },
    {
      key: "actions",
      _classes: ["text-center", "align-middle"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showProductEditModal, setShowProductEditModal] = useState(false);
  const [showProductDeleteModal, setShowProductDeleteModal] = useState(false);
  const [showProductAmountModal, setShowProductAmountModal] = useState(false);
  const [category, setCategory] = useState<Category | undefined>(undefined);

  const [product, setProduct] = useState<Product | undefined>(undefined);
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let categoryId = urlParams.get("category_id")
      ? urlParams.get("category_id")
      : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    if (categoryId !== "") map.set("category_id", categoryId);
    return map;
  };

  const fetchProducts = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const productsStatus = await getList<Product>(
      "/clothing_products/",
      limit,
      offset,
      urlParams
    );
    if (productsStatus.status === SUCCESS) {
      if (productsStatus.data !== undefined) {
        const count = productsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setProducts(productsStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = productsStatus.detail
        ? productsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    if (previousParams.get("category_id")) {
      urlParams.set("category_id", previousParams.get("category_id"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setShowProductEditModal(true);
  };

  const onUpdateClick = (product: Product) => {
    setProduct(product);
    setShowProductEditModal(true);
  };

  const onDeleteClick = (product: Product) => {
    setProduct(product);
    setShowProductDeleteModal(true);
  };

  const onAmountClick = (product: Product) => {
    setProduct(product);
    setShowProductAmountModal(true);
  };

  const onProductEditCancel = () => {
    setProduct(undefined);
    setShowProductEditModal(false);
  };

  const onProductEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowProductEditModal(false);
    setProducts([]);
    setLoading(true);
    fetchProducts();
  };

  const onProductDeleteCancel = () => {
    setProduct(undefined);
    setShowProductDeleteModal(false);
  };

  const onProductDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowProductDeleteModal(false);
    setProducts([]);
    setLoading(true);
    fetchProducts();
  };

  const onProductAmountCancel = () => {
    setProduct(undefined);
    setShowProductAmountModal(false);
  };

  const onProductAmountSuccess = () => {
    successAlert("Stock de producto con éxito!");
    setShowProductAmountModal(false);
    setProducts([]);
    setLoading(true);
    fetchProducts();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    if (search !== "") {
      urlParams.set("search", search);
    } else {
      urlParams.delete("search");
    }

    if (category !== undefined) {
      urlParams.set("category_id", category.id!.toString());
    } else {
      urlParams.delete("category_id");
    }
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onCategoryChange = (newCategory: Category | null) => {
    setCategory(newCategory !== null ? newCategory : undefined);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("products")) {
        return;
      }
      fetchProducts();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Modelos</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent
                  permissionName={CLOTHING_MODELS_CREATE}
                >
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow>
                <CCol></CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={1}>
                  <CLabel className={"mt-2"}>Categoría:</CLabel>
                </CCol>
                <CCol md={11}>
                  <CategorySelect
                    value={category ? category : null}
                    onChange={onCategoryChange}
                  ></CategorySelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Filtrar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={products}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    description: (item: Product) => {
                      return (
                        <td className="text-center align-middle">
                          {defaultValueOnUndefined("-", item.description)}
                        </td>
                      );
                    },
                    categoryName: (item: Product) => {
                      return (
                        <td className="text-center align-middle">
                          {defaultValueOnUndefined("-", item.categoryName)}
                        </td>
                      );
                    },
                    imageUrl: (item: Product) => {
                      return (
                        <td className="text-center align-middle">
                          <ProductAsyncImage product={item} />
                        </td>
                      );
                    },
                    actions: (item: Product) => {
                      const stockButton = (
                        <PermissionRequiredComponent
                          permissionName={CLOTHING_MODELS_RETRIEVE}
                        >
                          <CButton
                            className="text-white"
                            color="primary"
                            onClick={() => {
                              onAmountClick(item);
                            }}
                          >
                            <i className="fa fa-truck-ramp-box"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={CLOTHING_MODELS_UPDATE}
                        >
                          <CButton
                            className="text-white"
                            color="warning"
                            onClick={() => {
                              onUpdateClick(item);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );
                      const deleteButton = (
                        <PermissionRequiredComponent
                          permissionName={CLOTHING_MODELS_DESTROY}
                        >
                          <CButton
                            className="text-white"
                            color="danger"
                            onClick={() => {
                              onDeleteClick(item);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );

                      return (
                        <td className="text-right align-middle">
                          <CButtonGroup>
                            {stockButton}
                            {editButton}
                            {deleteButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <ProductUpdateModal
        show={showProductEditModal}
        onCancel={onProductEditCancel}
        onSuccess={onProductEditSuccess}
        product={product}
      />
      <ProductDeleteModal
        show={showProductDeleteModal}
        onCancel={onProductDeleteCancel}
        onSuccess={onProductDeleteSuccess}
        product={product}
      />
      <ProductAmountModal
        show={showProductAmountModal}
        onCancel={onProductAmountCancel}
        onSuccess={onProductAmountSuccess}
        product={product}
      />
    </>
  );
};

export default ProductList;
