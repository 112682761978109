import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { errorAlert } from "../utils/messages";

interface BarcodeConfirmModalProps {
  show: boolean;
  totalAmount?: number;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const BarcodeConfirmModal: React.FC<BarcodeConfirmModalProps> = ({
  show,
  totalAmount,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = totalAmount
    ? `Se imprimirán: ${totalAmount} códigos de barra. Confirmar acción.`
    : "Se imprimiran los codigos de Barra. Confirmar acción.";

  const onConfirmClick = async () => {
    setSubmitting(true);
    if (totalAmount === undefined) {
      errorAlert(
        "Ocurrió un error. No se encontró la cantidad total de Items."
      );
      setSubmitting(false);
      return;
    }
    if (totalAmount <= 0) {
      errorAlert(
        "Debe seleccionar al menos un item para imprimir su código de barras."
      );
      setSubmitting(false);
      return;
    }

    onSuccess();

    setSubmitting(false);
  };

  return (
    <CModal show={show} className="modal-primary" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Confirmar Acción de Impresión</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <h5>{"Asegúrese que la impresora esta conectada."}</h5>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onConfirmClick} color="primary">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Guardando..." : "CONFIRMAR"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default BarcodeConfirmModal;
