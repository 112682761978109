import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getList, updateItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import Discount from "../../models/discount";
import Category from "../../models/category";
import Store from "../../models/store";

interface DiscountActivenessModalProps {
  show: boolean;
  discount?: Discount;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const DiscountActivenessModal: React.FC<DiscountActivenessModalProps> = ({
  show,
  discount,
  onCancel,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [categories, setCategories] = useState<readonly Category[]>([]);
  const [stores, setStores] = useState<readonly Store[]>([]);

  const message =
    discount && discount.isActive === true
      ? "El descuento esta activo. ¿Desea desactivarlo?"
      : "El descuento esta inactivo. ¿Desea activarlo?";

  const fetchCategories = async () => {
    if (discount === undefined || discount.id === undefined) {
      setCategories([]);
      return [];
    }
    const discountIdStr = discount.id.toString();
    if (discountIdStr === undefined) {
      setCategories([]);
      return [];
    }
    const limit = 100;
    const offset = 0;
    const additional = new Map();
    additional.set("discount_id", discountIdStr);
    const categoriesStatus = await getList<Category>(
      "/categories/",
      limit,
      offset,
      additional
    );
    if (categoriesStatus.status === SUCCESS) {
      if (categoriesStatus.data !== undefined) {
        setCategories(categoriesStatus.data.items);
      }
    } else {
      setCategories([]);
    }
  };

  const fetchStores = async () => {
    if (discount === undefined || discount.id === undefined) {
      setStores([]);
      return [];
    }
    const discountIdStr = discount.id.toString();
    if (discountIdStr === undefined) {
      setStores([]);
      return [];
    }
    const limit = 100;
    const offset = 0;
    const additional = new Map();
    additional.set("discount_id", discountIdStr);
    const storesStatus = await getList<Store>(
      "/stores/",
      limit,
      offset,
      additional
    );
    if (storesStatus.status === SUCCESS) {
      if (storesStatus.data !== undefined) {
        setStores(storesStatus.data.items);
      }
    } else {
      setStores([]);
    }
  };

  const getInitialValues = async () => {
    setLoading(true);
    const categoriesPromise = fetchCategories();
    const storesPromise = fetchStores();
    await categoriesPromise;
    await storesPromise;
    setLoading(false);
  };

  useEffect(() => {
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount]);

  const onSubmitClick = async () => {
    if (discount !== undefined) {
      setSubmitting(true);

      const mutableCategories: Category[] = Array.from(categories);
      const mutableStores: Store[] = Array.from(stores);

      let toSendDiscount: Discount = {
        ...discount,
        isActive: discount.isActive !== undefined ? !discount.isActive : true,
        categories: mutableCategories,
        stores: mutableStores,
      };

      const discountStatus = await updateItem<Discount>(
        `/discounts/${discount.id}/`,
        toSendDiscount
      );

      if (discountStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (discountStatus.errors !== undefined) {
        }
        if (discountStatus.detail !== undefined) {
          message = discountStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
        setCategories([]);
        setStores([]);
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-success" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>
          Activar/Desactivar descuento/promoción {discount?.name}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onSubmitClick} color="success">
          {submitting || loading ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Guardando..." : "Aceptar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default DiscountActivenessModal;
