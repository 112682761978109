import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CTextarea,
} from "@coreui/react";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

import { createItem, updateItem, ItemRequestStatus } from "../../api/generics";
import InventoryLocation from "../../models/inventory-location";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import InventoryInputBatch from "../../models/inventory-input-batch";
import InventoryLocationSelect from "../inventory-location/InventoryLocationSelect";
import InputMaterial from "../../models/input-material";
import InputMaterialSelect from "../input-material/InputMaterialSelect";
import SupplierSelect from "../supplier/SupplierSelect";
import Supplier from "../../models/supplier";
import CurrencyField from "../currencies/CurrencyField";

interface InventoryInputBatchFormProps {
  initialInventoryInputBatch?: InventoryInputBatch;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const InventoryInputBatchForm: React.FC<InventoryInputBatchFormProps> = ({
  initialInventoryInputBatch,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingItem, setEditingItem] = useState<InventoryInputBatch>(
    initialInventoryInputBatch ? initialInventoryInputBatch : {}
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const onPurchaseDateChange = (value: string | moment.Moment) => {
    setEditingItem((editingItem) => {
      const newDate = value as moment.Moment;

      if (newDate === undefined || !moment(value, true).isValid()) {
        return { ...editingItem, purchaseDate: undefined };
      }

      const purchaseDate = new Date((value as moment.Moment).toISOString());

      return { ...editingItem, purchaseDate: purchaseDate.toISOString() };
    });
  };

  const onInventoryLocationChange = (
    newInventoryLocation: InventoryLocation | null
  ) => {
    setEditingItem((editingItem) => {
      return {
        ...editingItem,
        inventoryLocationId:
          newInventoryLocation !== null ? newInventoryLocation.id : undefined,
        inventoryLocation:
          newInventoryLocation !== null ? newInventoryLocation : undefined,
      };
    });
  };

  const onInputMaterialChange = (newInputMaterial: InputMaterial | null) => {
    setEditingItem((editingItem) => {
      return {
        ...editingItem,
        inputMaterialId:
          newInputMaterial !== null ? newInputMaterial.id : undefined,
        inputMaterialIdentifier:
          newInputMaterial !== null ? newInputMaterial.identifier : undefined,
        inputMaterial: newInputMaterial !== null ? newInputMaterial : undefined,
      };
    });
  };

  const onSupplierChange = (newSupplier: Supplier | null) => {
    setEditingItem((editingItem) => {
      return {
        ...editingItem,
        supplierId: newSupplier !== null ? newSupplier.id : undefined,
        supplierName: newSupplier !== null ? newSupplier.name : undefined,
        supplierLegalName:
          newSupplier !== null ? newSupplier.legalName : undefined,
        supplierRuc: newSupplier !== null ? newSupplier.ruc : undefined,
        supplier: newSupplier !== null ? newSupplier : undefined,
      };
    });
  };

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingItem((editingItem) => {
      let amount: number | undefined = undefined;

      if (!isNaN(parseFloat(e.target.value))) {
        amount = parseFloat(e.target.value);
      }

      return { ...editingItem, amount: amount };
    });
  };

  const onUnitPriceChange = (newUnitPrice: number | undefined) => {
    setEditingItem((editingItem) => {
      return { ...editingItem, unitPrice: newUnitPrice };
    });
  };

  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingItem((editingItem) => {
      return {
        ...editingItem,
      };
    });
  };

  const onSave = useCallback(async () => {
    setSubmitting(true);
    let toSendInventoryLocation = {
      ...editingItem,
      companyId: company.id,
    };
    let requestPromise: Promise<ItemRequestStatus<InventoryInputBatch>>;
    if (toSendInventoryLocation.id === undefined) {
      requestPromise = createItem<InventoryLocation>(
        "/inventory_locations/batches/create/",
        toSendInventoryLocation
      );
    } else {
      requestPromise = updateItem<InventoryInputBatch>(
        `/inventory_locations/batches/${toSendInventoryLocation.id}/`,
        toSendInventoryLocation
      );
    }

    const requestStatus = await requestPromise;

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.errors !== undefined) {
        setErrors(requestStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (requestStatus.detail !== undefined) {
        message = requestStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingItem({});
  };

  useEffect(() => {
    setEditingItem(
      initialInventoryInputBatch ? initialInventoryInputBatch : {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInventoryInputBatch]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Fecha de emisión:
              </CLabel>
            </CCol>
            <CCol>
              <Datetime
                className="pl-0"
                onChange={onPurchaseDateChange}
                value={
                  editingItem.purchaseDate !== undefined
                    ? new Date(editingItem.purchaseDate)
                    : undefined
                }
                locale="es/PY"
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                closeOnSelect={true}
              ></Datetime>
              <FieldErrors
                errors={getFieldErrors("purchaseDate", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Materia Prima:</CLabel>
            </CCol>
            <CCol md={4}>
              <InputMaterialSelect
                value={
                  editingItem.inputMaterial !== undefined
                    ? editingItem.inputMaterial
                    : null
                }
                onChange={onInputMaterialChange}
              ></InputMaterialSelect>
              <FieldErrors
                errors={getFieldErrors("inputMaterialId", errors) as string[]}
              ></FieldErrors>
            </CCol>
            <CCol md={2}>
              <CLabel>Inventario:</CLabel>
            </CCol>
            <CCol md={4}>
              <InventoryLocationSelect
                value={
                  editingItem.inventoryLocation !== undefined
                    ? editingItem.inventoryLocation
                    : null
                }
                onChange={onInventoryLocationChange}
                inventoryType="input_material"
              />
              <FieldErrors
                errors={
                  getFieldErrors("inventoryLocationId", errors) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Proveedor:</CLabel>
            </CCol>
            <CCol md={4}>
              <SupplierSelect
                value={
                  editingItem.supplier !== undefined
                    ? editingItem.supplier
                    : null
                }
                onChange={onSupplierChange}
              ></SupplierSelect>
              <FieldErrors
                errors={getFieldErrors("supplierId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Cantidad:</CLabel>
            </CCol>
            <CCol md={4}>
              <CInput
                type="number"
                placeholder="Cant."
                value={
                  editingItem.amount !== undefined ? editingItem.amount : 0
                }
                onChange={onAmountChange}
                step="0.1"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("amount", errors) as string[]}
              ></FieldErrors>
            </CCol>
            <CCol md={2}>
              <CLabel>Precio Unitario:</CLabel>
            </CCol>
            <CCol md={4}>
              <CurrencyField
                currency={"PYG"}
                placeholder="Introduzca el monto"
                onChange={onUnitPriceChange}
                value={editingItem.unitPrice}
                limit={1000000000}
              />
              <FieldErrors
                errors={getFieldErrors("unitPrice", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Observación:</CLabel>
            </CCol>
            <CCol>
              <CTextarea
                placeholder="Observación"
                value={editingItem.obs}
                onChange={onObsChange}
              ></CTextarea>
              <FieldErrors
                errors={getFieldErrors("obs", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default InventoryInputBatchForm;
