import { useEffect, useState } from "react";

import { getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { PendingAmounts } from "../../models/inventory-product";

import { CContainer, CRow, CTooltip } from "@coreui/react";
import { Spinner } from "react-bootstrap";

interface InventoryAsyncTooltipProps {
  productId?: number;
  colorId?: number;
  children: React.ReactNode;
}

const InventoryAsyncTooltip: React.FC<InventoryAsyncTooltipProps> = ({
  productId,
  colorId,
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [failed, setFailed] = useState<boolean>(false);
  const [pendingAmounts, setPendingAmounts] = useState<
    PendingAmounts[] | undefined
  >(undefined);

  const truncateString = (str: string, num: number): string => {
    return str.length > num ? str.slice(0, num) + "..." : str;
  };

  const fetchvalues = async (productId?: number, colorId?: number) => {
    if (colorId === undefined || productId === undefined) {
      setLoading(false);
      return;
    }
    const pendingStatus = await getList<PendingAmounts>(
      `/inventory_products/pending_clients/product/${productId}/color/${colorId}/`,
      10000,
      0
    );
    if (pendingStatus.status === SUCCESS) {
      if (pendingStatus.data !== undefined) {
        setPendingAmounts(pendingStatus.data.items);
      }
      setLoading(false);
    } else {
      setLoading(false);
      setFailed(true);
    }
  };

  useEffect(() => {
    fetchvalues(productId, colorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (failed) {
    return (
      <CTooltip content={"No se pudo encontrar información sobre pendientes"}>
        {children}
      </CTooltip>
    );
  }

  if (loading || pendingAmounts === undefined) {
    return (
      <CTooltip
        content={
          <Spinner
            animation="grow"
            style={{
              height: "17px",
              width: "17px",
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: "10px",
            }}
          />
        }
      >
        {children}
      </CTooltip>
    );
  }

  if (pendingAmounts.length === 0) {
    return (
      <CTooltip content={"No hay pendientes de entrega"}>{children}</CTooltip>
    );
  }

  return (
    <CTooltip
      content={
        <div>
          <h5>Pendientes</h5>
          {pendingAmounts.map((entry, ix) => {
            return (
              <CContainer key={ix}>
                <CRow>
                  <p>Talle:{entry.size}</p>
                </CRow>
                {entry.clients?.map((clientEntry, index) => {
                  return (
                    <CRow key={index}>
                      {`${truncateString(clientEntry.clientName, 20)} -> ${
                        clientEntry.pendingAmount
                      }`}
                    </CRow>
                  );
                })}
                <br />
              </CContainer>
            );
          })}
        </div>
      }
    >
      {children}
    </CTooltip>
  );
};

export default InventoryAsyncTooltip;
