import ProductCrud from "../components/product/ProductCrud";

const ProductCrudPage = () => {
  return (
    <>
      <ProductCrud />
    </>
  );
};

export default ProductCrudPage;
