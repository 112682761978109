import UserAudit from "../components/user/UserAudit";

const UserAuditPage = () => {
  return (
    <>
      <UserAudit />
    </>
  );
};

export default UserAuditPage;
