import Color from "../models/color";
import InventoryTransfer, {
  InventoryTransferItem,
} from "../models/inventory-transfer";
import Product from "../models/product";
import Size from "../models/size";
import Variant from "../models/variant";

export const inventoryTransferIsEditable = (
  transfer: InventoryTransfer
): boolean => {
  if (transfer.status === undefined) {
    return true;
  }
  return transfer.status === "pending" || transfer.status === "ongoing";
};

export const inventoryTransferIsAcceptable = (
  transfer: InventoryTransfer,
  currentStoreLocationId: number | undefined
): boolean => {
  if (!currentStoreLocationId) {
    return false;
  }
  if (currentStoreLocationId !== transfer.destinationInventoryId) {
    if (transfer.destinationName !== "Prendas Devueltas") {
      return false;
    }
  }
  if (transfer.status === undefined) {
    return false;
  }
  return transfer.status === "ongoing";
};

export const inventoryTransferIsPending = (
  transfer: InventoryTransfer
): boolean => {
  if (transfer.status === undefined) {
    return false;
  }
  return transfer.status === "pending";
};

export const inventoryTransferIsReversible = (
  transfer: InventoryTransfer
): boolean => {
  if (transfer.status === undefined) {
    return false;
  }
  return transfer.status !== "pending" && transfer.status !== "cancelled";
};

export interface InventoryTransferFormItemSize {
  savedId?: number;
  sizeName?: string;
  size?: Size;
  sizeId?: number;
  variantId?: number;
  variant?: Variant;
  amount?: number;
}

export interface InventoryTransferFormItem {
  id?: number;
  companyId?: number;
  inventoryLocationTransferId?: number;
  clothingProductId?: number;
  clothingProduct?: Product;
  productCodeIdentifier?: string;
  productName?: string;
  colorName?: string;
  colorId?: number;
  color?: Color;
  unitValue?: number;
  allSizesAmount?: number;
  sizes?: InventoryTransferFormItemSize[];
}

export const inventoryTransferTableFormatToAPIReceived = (
  items: InventoryTransferFormItem[]
): InventoryTransferItem[] => {
  const result: InventoryTransferItem[] = [];

  items.forEach((item) => {
    item.sizes?.forEach((size) => {
      if (size.amount !== undefined && size.amount !== 0) {
        const inventoryTransferItem: InventoryTransferItem = {
          id: size.savedId,
          companyId: item.companyId,
          inventoryLocationTransferId: item.inventoryLocationTransferId,
          clothingProductId: item.clothingProductId,
          clothingProduct: item.clothingProduct,
          productVariantId: size.variantId,
          productVariant: size.variant,
          productCodeIdentifier: item.productCodeIdentifier,
          productName: item.productName,
          colorName: item.colorName,
          colorId: item.colorId,
          color: item.color,
          sizeName: size.sizeName,
          size: size.size,
          sizeId: size.sizeId,
          amount: size.amount,
          unitValue: item.unitValue,
          totalValue: (item.unitValue || 0) * (item.allSizesAmount || 0),
        };
        result.push(inventoryTransferItem);
      }
    });
  });

  return result;
};

export const inventoryTransferAPIReceivedToTableFormat = (
  items: InventoryTransferItem[]
): InventoryTransferFormItem[] => {
  const groupedItems: Map<string, InventoryTransferItem[]> = new Map();

  items.forEach((item) => {
    const key = `${item.productCodeIdentifier}-${item.colorName}`;

    if (!groupedItems.has(key)) {
      groupedItems.set(key, []);
    }

    groupedItems.get(key)?.push(item);
  });
  const result: InventoryTransferFormItem[] = Array.from(
    groupedItems.values()
  ).map((group) => {
    const firstItem = group[0];

    const sizes = group.map((item) => ({
      savedId: item.id,
      sizeName: item.sizeName,
      size: item.size,
      sizeId: item.sizeId,
      variantId: item.productVariantId,
      variant: item.productVariant,
      amount: item.amount,
    }));

    const allSizesAmount = sizes.reduce(
      (sum, size) => sum + (size.amount || 0),
      0
    );

    const inventoryTransferFormItem: InventoryTransferFormItem = {
      companyId: firstItem.companyId,
      inventoryLocationTransferId: firstItem.inventoryLocationTransferId,
      clothingProductId: firstItem.clothingProductId,
      clothingProduct: firstItem.clothingProduct,
      productCodeIdentifier: firstItem.productCodeIdentifier,
      productName: firstItem.productName,
      colorName: firstItem.colorName,
      color: firstItem.color,
      colorId: firstItem.colorId,
      unitValue: firstItem.unitValue,
      allSizesAmount: allSizesAmount,
      sizes: sizes,
    };

    return inventoryTransferFormItem;
  });

  return result;
};
