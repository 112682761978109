interface Supplier {
  id?: number;
  companyId?: number;
  name?: string;
  legalName?: string;
  ruc?: string;
  phone?: string;
  email?: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
}

export const newSupplier = (): Supplier => {
  return {};
};

export default Supplier;
