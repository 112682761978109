import InventoryTransferFormView from "../components/inventory-transfers/InventoryTransferFormView";

const InventoryTransferFormPage = () => {
  return (
    <>
      <InventoryTransferFormView />
    </>
  );
};

export default InventoryTransferFormPage;
