import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import Client, { newClient } from "../../models/client";
import ClientForm from "./ClientForm";

interface ClientUpdateModalProps {
  show: boolean;
  client?: Client;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ClientUpdateModal: React.FC<ClientUpdateModalProps> = ({
  show,
  client,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialClient, setInitialClient] = useState<Client>(client ? client : newClient());

  const modalTitle = client
    ? `Editar cliente: ${client.name}`
    : `Crear cliente nuevo`;

  const onClose = () => {
    setInitialErrors({});
    setInitialClient(newClient());
    onCancel();
  };
  useEffect(() => {
    setInitialClient(client ? client : newClient());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <ClientForm
            initialClient={initialClient}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default ClientUpdateModal;
