import DeliveryOrderFormView from "../components/delivery-order/DeliveryOrderFormView";

const DeliveryOrderCrudPage = () => {
  return (
    <>
      <DeliveryOrderFormView />
    </>
  );
};

export default DeliveryOrderCrudPage;
