import ProductionStage from "./production-stage";

interface ProductionOrderLaborPrice {
  id?: number;
  orderId?:number;
  productionStage?: ProductionStage;
  stageId?:number;
  isActive?: boolean;
  subject?:string;
  price?:number;
}

export const newProductionOrderLaborPrice = (): ProductionOrderLaborPrice => {
  return {};
};

export default ProductionOrderLaborPrice;
