import { CButton, CCol, CFormGroup, CInput, CRow } from "@coreui/react";
import React, { useCallback, useState } from "react";
import { FieldErrors } from "../form/FieldErrors";
import Errors, {  errorsAreSame, getFieldErrors } from "../../models/errors";
import { emptyValueOnUndefined } from "../../utils/fields";

import { ProductionOrderSize } from "../../models/production-order";
import Size from "../../models/size";
import SingleSizeSelect from "../size/SingleSizeSelect";

interface ProductionSizeItemFormProps {
  productId?: number;
  value: ProductionOrderSize;
  onDelete: (_: ProductionOrderSize) => void;
  onChange: (_: ProductionOrderSize) => void;
  errors: Errors;
}

const ProductionSizeEntryForm: React.FC<ProductionSizeItemFormProps> = ({
  productId,
  value,
  onDelete,
  onChange,
  errors,
}) => {
  const [editingItem, setEditingItem] = useState<ProductionOrderSize>(value);
  const [size, setSize] = useState<Size|null>(null);

  const onDeleteClick = useCallback(() => {
    onDelete(editingItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  const onSizeChange = useCallback(
    (newSize: Size | null) => {
      setSize(newSize)
      const newItem = editingItem;
      newItem.size = newSize !== null ? newSize : undefined;
      newItem.sizeId = newSize?.id !== null ? newSize?.id : undefined;
      newItem.sizeName = newSize?.size !== null ? newSize?.size : undefined;
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );
  
  const onAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(e.target.value);
      const newItem = {
        ...editingItem,
        amount: newValue !== null ? newValue : undefined,
      };
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );
  return (
    <div className="section border rounded mb-2 p-2">
      <CFormGroup>
        <CRow>
          <CCol md={2} className={"mt-2"}>Talle:</CCol>
          <CCol md={3}>
            <SingleSizeSelect
              key={productId?productId:0}
              value={size}
              onChange={onSizeChange}
              productId={productId}
            ></SingleSizeSelect>
            <FieldErrors
              errors={getFieldErrors("sizeId", errors) as string[]}
            ></FieldErrors>
          </CCol>
          <CCol md={1} className={"mt-2"}>Cantidad:</CCol>
          <CCol md={2}>
            <CInput
              type="number"
              value={emptyValueOnUndefined(editingItem.amount)}
              onChange={onAmountChange}
              placeholder="Cant."
              step="1"
            ></CInput>
            <FieldErrors
              errors={getFieldErrors("amount", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup>
        <CRow>
          <CCol>
            <CButton
              className="btn btn-danger float-right"
              onClick={onDeleteClick}
            >
              <i className="fa fa-trash"></i>
            </CButton>
          </CCol>
        </CRow>
      </CFormGroup>
    </div>
  );
};

const propsAreEqual = (
  prevItemProps: ProductionSizeItemFormProps,
  nextItemProps: ProductionSizeItemFormProps
): boolean => {
  const areEqual =
    prevItemProps.productId === nextItemProps.productId &&
    prevItemProps.value.id === nextItemProps.value.id &&
    prevItemProps.value.sizeId ===
      nextItemProps.value.sizeId &&
    prevItemProps.value.amount === nextItemProps.value.amount &&
    prevItemProps.value.sizeName === nextItemProps.value.sizeName &&
    prevItemProps.value.size === nextItemProps.value.size
    errorsAreSame(prevItemProps.errors, nextItemProps.errors);
  return areEqual;
};

export default React.memo(ProductionSizeEntryForm, propsAreEqual);
