import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getItem, updateItem } from "../../api/generics";
import moment from "moment";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import InventoryTransfer from "../../models/inventory-transfer";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import InventoryLocation from "../../models/inventory-location";

interface InventoryTransferAcceptModalProps {
  show: boolean;
  inventoryTransfer?: InventoryTransfer;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const InventoryTransferAcceptModal: React.FC<
  InventoryTransferAcceptModalProps
> = ({ show, inventoryTransfer, onCancel, onSuccess }) => {
  const [submitting, setSubmitting] = useState(false);
  const userEmail = useSelector((state: RootState) => state.user.email);
  const userId = useSelector((state: RootState) => state.user.id);
  const [originInventory, setOriginInventory] = useState<
    InventoryLocation | undefined
  >(undefined);
  const [destinationInventory, setDestinationInventory] = useState<
    InventoryLocation | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const message = inventoryTransfer
    ? `Se recepcionaran los articulos de la orden de transferencia: ${inventoryTransfer.id}`
    : "";

  const onSubmitClick = async () => {
    if (inventoryTransfer !== undefined) {
      setSubmitting(true);

      const finishDate = moment().toISOString();

      let toSendInventoryTransfer: InventoryTransfer = {
        ...inventoryTransfer,
        status: "completed",
        receiveDate: finishDate,
        receiverUserId: userId,
        receiverUserName: userEmail,
      };

      if (destinationInventory) {
        toSendInventoryTransfer.destinationInventory = destinationInventory;
      }
      if (originInventory) {
        toSendInventoryTransfer.originInventory = originInventory;
      }

      const orderStatus = await updateItem<InventoryTransfer>(
        `/inventory_transfers/${inventoryTransfer.id}/finish/`,
        toSendInventoryTransfer
      );

      if (orderStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (orderStatus.errors !== undefined) {
        }
        if (orderStatus.detail !== undefined) {
          message = orderStatus.detail;
        }
        errorAlert(message);
      } else {
        setDestinationInventory(undefined);
        setOriginInventory(undefined);
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  const getInitialValues = async (originId: number, destinationId: number) => {
    setLoading(true);
    const originLocationPromise = fetchOriginLocation(originId);
    const destinationLocationPromise = fetchDestinationLocation(destinationId);
    await originLocationPromise;
    await destinationLocationPromise;
    setLoading(false);
  };

  const fetchOriginLocation = async (id: number) => {
    const deliveryOrderStatus = await getItem<InventoryLocation>(
      `/inventory_locations/${id}/`
    );
    if (deliveryOrderStatus.status === SUCCESS) {
      if (deliveryOrderStatus.data !== undefined) {
        setOriginInventory(deliveryOrderStatus.data);
      }
    }
  };

  const fetchDestinationLocation = async (id: number) => {
    const deliveryOrderStatus = await getItem<InventoryLocation>(
      `/inventory_locations/${id}/`
    );
    if (deliveryOrderStatus.status === SUCCESS) {
      if (deliveryOrderStatus.data !== undefined) {
        setDestinationInventory(deliveryOrderStatus.data);
      }
    }
  };

  useEffect(() => {
    if (
      inventoryTransfer &&
      inventoryTransfer.originInventoryId &&
      inventoryTransfer.destinationInventoryId
    ) {
      getInitialValues(
        inventoryTransfer.originInventoryId,
        inventoryTransfer.destinationInventoryId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryTransfer]);

  return (
    <CModal show={show} className="modal-primary" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Recepcionar</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <h5>Los articulos se agregaran al inventario.</h5>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={submitting || loading}
          onClick={onSubmitClick}
          color="primary"
        >
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Recepcionando..." : "Recepcionar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default InventoryTransferAcceptModal;
