import {
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import StoreDebtForm from "./StoreDebtForm";
import InventoryLocation, {
  InventoryLocationValueLog,
} from "../../models/inventory-location";
import Store from "../../models/store";

interface StoreDebtLogUpdateModalProps {
  show: boolean;
  initialStore?: Store;
  initialLocation?: InventoryLocation;
  storeDebtLog?: InventoryLocationValueLog;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const StoreDebtLogUpdateModal: React.FC<StoreDebtLogUpdateModalProps> = ({
  show,
  initialStore,
  initialLocation,
  storeDebtLog,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialDebtLog, setInitialDebtLog] =
    useState<InventoryLocationValueLog>(storeDebtLog ? storeDebtLog : {});

  const modalTitle = initialStore
    ? `Editar Registro de deuda de tienda: ${initialStore.name}`
    : `Crear registro de deuda de tienda`;

  const onClose = () => {
    setInitialErrors({});
    setInitialDebtLog({});
    onCancel();
  };
  useEffect(() => {
    setInitialDebtLog(storeDebtLog ? storeDebtLog : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeDebtLog]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol>
              <p>
                <span className="text-danger">IMPORTANTE: </span>Los valores en
                el campo de Monto se restarán de la deuda. Si quiere aumentar la
                deuda use valores negativos.
              </p>
              <p>
                Las modificaciones realizadas solo tienen efecto sobre la
                entrada de la tabla modificada
              </p>
            </CCol>
          </CRow>
          <StoreDebtForm
            initialDebtLog={initialDebtLog}
            initialStore={initialStore}
            initialInventoryLocation={initialLocation}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default StoreDebtLogUpdateModal;
