import PaymentCrud from "../components/payments/PaymentCrud";

const PaymentCrudPage = () => {
  return (
    <>
      <PaymentCrud />
    </>
  );
};

export default PaymentCrudPage;
