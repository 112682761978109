import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
  CBadge,
  CSelect,
  CLink,
  CTooltip,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getItem, getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, successAlert, warningAlert } from "../utils/messages";
import SaleOrderDeleteModal from "./SaleOrderDeleteModal";
import SaleOrder, {
  SALEORDERSTATUSES,
  getDeliveryStatusLabel,
  getPaymentStatusLabel,
  getStatusClass,
  getStatusLabel,
} from "../../models/sale-order";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
import { DATEDDMMYYY, formatDate } from "../../utils/dates";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import {
  saleOrderCanBeMarkedAsReady,
  saleOrderIsEditable,
  saleOrderIsReversible,
} from "../../utils/sale-orders";
import { getObjectMinioUrl } from "../../minio/urls";
import {
  SALE_ORDERS_CREATE,
  SALE_ORDERS_DESTROY,
  SALE_ORDERS_RETRIEVE,
  SALE_ORDERS_UPDATE,
} from "../../auth/permissions";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import SaleOrderRevertModal from "./SaleOrderRevertModal";
import SaleOrderMarkAsReadyModal from "./SaleOrderMarkAsReadyModal";

const ITEMS_PER_PAGE = 10;

const SaleOrderList = () => {
  const fields = [
    {
      key: "id",
      _classes: ["text-center"],
      label: "N° de Orden",
    },
    {
      key: "orderDate",
      _classes: ["text-center"],
      label: "Fecha de Orden",
    },
    {
      key: "clientName",
      _classes: ["text-center"],
      label: "Cliente",
    },
    {
      key: "status",
      _classes: ["text-center"],
      label: "Estado",
    },
    {
      key: "deliveryStatus",
      _classes: ["text-center"],
      label: "Estado de delivery",
    },
    {
      key: "paymentStatus",
      _classes: ["text-center"],
      label: "Estado de pago",
    },
    {
      key: "lastDeliveryDate",
      _classes: ["text-center"],
      label: "Ultima fecha de entrega",
    },
    {
      key: "lastPaymentDate",
      _classes: ["text-center"],
      label: "Ultima fecha de pago",
    },
    {
      key: "totalPrice",
      _classes: ["text-center"],
      label: "Total de orden",
    },
    {
      key: "pendingPrice",
      _classes: ["text-center"],
      label: "Total Enviado",
    },
    {
      key: "paidPrice",
      _classes: ["text-center"],
      label: "Monto Pagado",
    },
    {
      key: "debtPrice",
      _classes: ["text-center"],
      label: "Monto Adeudado",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showSaleOrderDeleteModal, setShowSaleOrderDeleteModal] =
    useState(false);

  const [showSaleOrderRevertModal, setShowSaleOrderRevertModal] =
    useState(false);

  const [showSaleOrderReadyModal, setShowSaleOrderReadyModal] = useState(false);

  const [saleOrder, setSaleOrder] = useState<SaleOrder | undefined>(undefined);
  const [startDate, setStartDate] = useState<string | moment.Moment>("");
  const [endDate, setEndDate] = useState<string | moment.Moment>("");
  const [status, setStatus] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [saleOrders, setSaleOrders] = useState<SaleOrder[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let orderStatus = urlParams.get("order_status")
      ? urlParams.get("order_status")
      : "";
    let startDate = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : "";
    let endDate = urlParams.get("end_date") ? urlParams.get("end_date") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    if (orderStatus !== "") map.set("order_status", orderStatus);
    if (startDate !== "") map.set("start_date", startDate);
    if (endDate !== "") map.set("end_date", endDate);
    return map;
  };

  const fetchSaleOrders = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const saleOrdersStatus = await getList<SaleOrder>(
      "/sale_orders/",
      limit,
      offset,
      urlParams
    );
    if (saleOrdersStatus.status === SUCCESS) {
      if (saleOrdersStatus.data !== undefined) {
        const count = saleOrdersStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setSaleOrders(saleOrdersStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = saleOrdersStatus.detail
        ? saleOrdersStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    if (previousParams.get("order_status")) {
      urlParams.set("order_status", previousParams.get("order_status"));
    }
    if (previousParams.get("start_date")) {
      urlParams.set("start_date", previousParams.get("start_date"));
    }
    if (previousParams.get("end_date")) {
      urlParams.set("end_date", previousParams.get("end_date"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onStartDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setStartDate("");
      return;
    }
    setStartDate(value);
  };

  const onEndDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setEndDate("");
      return;
    }
    setEndDate(value);
  };

  const onStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.value);
  };

  const onUpdateClick = (saleOrder: SaleOrder) => {
    const path = getRoutePath("saleOrderForm");
    const modifiedWithId = path.replace(":id", String(saleOrder.id));
    history.push(modifiedWithId);
  };

  const onDeleteClick = (saleOrder: SaleOrder) => {
    setSaleOrder(saleOrder);
    setShowSaleOrderDeleteModal(true);
  };

  const onRevertClick = (saleOrder: SaleOrder) => {
    setSaleOrder(saleOrder);
    setShowSaleOrderRevertModal(true);
  };

  const onReadyClick = (saleOrder: SaleOrder) => {
    setSaleOrder(saleOrder);
    setShowSaleOrderReadyModal(true);
  };

  const onSaleOrderDeleteCancel = () => {
    setSaleOrder(undefined);
    setShowSaleOrderDeleteModal(false);
  };

  const onSaleOrderRevertCancel = () => {
    setSaleOrder(undefined);
    setShowSaleOrderRevertModal(false);
  };

  const onSaleOrderReadyCancel = () => {
    setSaleOrder(undefined);
    setShowSaleOrderReadyModal(false);
  };

  const onSaleOrderDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowSaleOrderDeleteModal(false);
    setSaleOrders([]);
    setLoading(true);
    fetchSaleOrders();
  };

  const onSaleOrderRevertSuccess = () => {
    successAlert("Registro revertido con éxito!");
    setShowSaleOrderRevertModal(false);
    setSaleOrders([]);
    setLoading(true);
    fetchSaleOrders();
  };

  const onSaleOrderReadySuccess = () => {
    successAlert("Registro finalizado con éxito!");
    setShowSaleOrderReadyModal(false);
    setSaleOrders([]);
    setLoading(true);
    fetchSaleOrders();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    if (search !== "") {
      urlParams.set("search", search);
    } else {
      urlParams.delete("search");
    }
    if (status !== "") {
      urlParams.set("order_status", status);
    } else {
      urlParams.delete("order_status");
    }
    if (moment.isMoment(startDate)) {
      urlParams.set("start_date", startDate.toISOString());
    } else if (startDate !== undefined && startDate !== "") {
      urlParams.set("start_date", startDate);
    } else {
      urlParams.delete("start_date");
    }
    if (moment.isMoment(endDate)) {
      urlParams.set("end_date", endDate.toISOString());
    } else if (endDate !== undefined && endDate !== "") {
      urlParams.set("end_date", endDate);
    } else {
      urlParams.delete("end_date");
    }
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const onPDFClick = async (order: SaleOrder) => {
    const pdfState = await getItem<{ url: string }>(
      `/sale_orders/${order.id}/pdf/`
    );

    if (pdfState.status !== SUCCESS || pdfState.data === undefined) {
      errorAlert("Error al tratar de obtener el PDF");
      return;
    }
    window.open(getObjectMinioUrl(pdfState.data.url), "_blank");
  };

  useEffect(() => {
    fetchSaleOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("saleOrders")) {
        return;
      }
      fetchSaleOrders();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Órdenes de Venta</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent
                  permissionName={SALE_ORDERS_CREATE}
                >
                  <CLink to={getRoutePath("saleOrderFormCreate")}>
                    <CButton color="primary" className="float-right">
                      <BsFillPlusCircleFill />
                      &nbsp; Agregar Nuevo
                    </CButton>
                  </CLink>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow>
                <CCol></CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Desde:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onStartDatehange}
                    value={startDate}
                    locale="es/PY"
                    utc
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Hasta:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onEndDatehange}
                    value={endDate}
                    locale="es/PY"
                    utc
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Estado:</CLabel>
                </CCol>
                <CCol>
                  <CSelect
                    type="text"
                    defaultValue={status !== null ? status : ""}
                    onChange={onStatusChange}
                  >
                    <option value={""}>-----</option>
                    {Object.entries(SALEORDERSTATUSES).map(
                      ([key, entry], ix) => {
                        return (
                          <option key={ix} value={key}>
                            {entry.name}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el cliente"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={saleOrders}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    id: (item: SaleOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.id ? `#${item.id}` : "-"}
                          </span>
                        </td>
                      );
                    },
                    status: (item: SaleOrder) => {
                      return (
                        <td className="text-center">
                          <CBadge
                            color={
                              item.status
                                ? getStatusClass(item.status)
                                : undefined
                            }
                          >
                            {item.status
                              ? getStatusLabel(item.status)
                              : undefined}
                          </CBadge>
                        </td>
                      );
                    },
                    deliveryStatus: (item: SaleOrder) => {
                      return (
                        <td className="text-center">
                          {item.deliveryStatus
                            ? getDeliveryStatusLabel(item.deliveryStatus)
                            : undefined}
                        </td>
                      );
                    },
                    paymentStatus: (item: SaleOrder) => {
                      return (
                        <td className="text-center">
                          {item.paymentStatus
                            ? getPaymentStatusLabel(item.paymentStatus)
                            : undefined}
                        </td>
                      );
                    },
                    totalPrice: (item: SaleOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.totalPrice
                              ? formatToCurrency(item.totalPrice, PYG)
                              : "-"}
                          </span>
                        </td>
                      );
                    },
                    pendingPrice: (item: SaleOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.pendingPrice
                              ? formatToCurrency(item.pendingPrice, PYG)
                              : "-"}
                          </span>
                        </td>
                      );
                    },
                    paidPrice: (item: SaleOrder) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.paidPrice
                              ? formatToCurrency(item.paidPrice, PYG)
                              : "-"}
                          </span>
                        </td>
                      );
                    },
                    debtPrice: (item: SaleOrder) => {
                      const paidPrice = item.paidPrice ? item.paidPrice : 0;
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.pendingPrice
                              ? formatToCurrency(
                                  item.pendingPrice - paidPrice,
                                  PYG
                                )
                              : "-"}
                          </span>
                        </td>
                      );
                    },
                    orderDate: (item: SaleOrder) => {
                      return (
                        <td className="text-center">
                          {item.orderDate
                            ? formatDate(new Date(item.orderDate), DATEDDMMYYY)
                            : "-"}
                        </td>
                      );
                    },
                    lastDeliveryDate: (item: SaleOrder) => {
                      return (
                        <td className="text-center">
                          {item.lastDeliveryDate
                            ? formatDate(
                                new Date(item.lastDeliveryDate),
                                DATEDDMMYYY
                              )
                            : "-"}
                        </td>
                      );
                    },
                    lastPaymentDate: (item: SaleOrder) => {
                      return (
                        <td className="text-center">
                          {item.lastPaymentDate
                            ? formatDate(
                                new Date(item.lastPaymentDate),
                                DATEDDMMYYY
                              )
                            : "-"}
                        </td>
                      );
                    },
                    actions: (item: SaleOrder) => {
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={SALE_ORDERS_UPDATE}
                        >
                          <CTooltip content={"Modificar"}>
                            <CButton
                              className="text-white"
                              color="warning"
                              onClick={() => {
                                onUpdateClick(item);
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const revertButton = (
                        <PermissionRequiredComponent
                          permissionName={SALE_ORDERS_UPDATE}
                        >
                          <CTooltip content="Revertir/anular">
                            <CButton
                              className="text-white"
                              color="warning"
                              onClick={() => {
                                onRevertClick(item);
                              }}
                            >
                              <i className="fa fa-clock-rotate-left"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const detailButton = (
                        <PermissionRequiredComponent
                          permissionName={SALE_ORDERS_RETRIEVE}
                        >
                          <CTooltip content={"Ver detalles"}>
                            <CButton
                              className="text-white"
                              color="primary"
                              onClick={() => {
                                onUpdateClick(item);
                              }}
                            >
                              <i className="fa fa-eye"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const deleteButton = (
                        <PermissionRequiredComponent
                          permissionName={SALE_ORDERS_DESTROY}
                        >
                          <CTooltip content={"Eliminar"}>
                            <CButton
                              className="text-white"
                              color="danger"
                              onClick={() => {
                                onDeleteClick(item);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const readyButton = (
                        <PermissionRequiredComponent
                          permissionName={SALE_ORDERS_UPDATE}
                        >
                          <CTooltip content={"Finalizar Manualmente"}>
                            <CButton
                              className="text-white"
                              color="success"
                              onClick={() => {
                                onReadyClick(item);
                              }}
                            >
                              <i className="fa fa-flag-checkered"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      let pdfButton = null;
                      if (item.reportUrl !== undefined) {
                        pdfButton = (
                          <PermissionRequiredComponent
                            permissionName={SALE_ORDERS_RETRIEVE}
                          >
                            <CTooltip content="PDF">
                              <CButton
                                className="text-white"
                                color="danger"
                                onClick={() => {
                                  onPDFClick(item);
                                }}
                              >
                                <i className="fa fa-file-pdf"></i>
                              </CButton>
                            </CTooltip>
                          </PermissionRequiredComponent>
                        );
                      }

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {pdfButton}
                            {saleOrderIsEditable(item)
                              ? editButton
                              : detailButton}
                            {saleOrderIsEditable(item) ? deleteButton : <></>}
                            {saleOrderCanBeMarkedAsReady(item) ? (
                              readyButton
                            ) : (
                              <></>
                            )}
                            {saleOrderIsReversible(item) ? revertButton : <></>}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <SaleOrderDeleteModal
        show={showSaleOrderDeleteModal}
        onCancel={onSaleOrderDeleteCancel}
        onSuccess={onSaleOrderDeleteSuccess}
        saleOrder={saleOrder}
      />
      <SaleOrderRevertModal
        show={showSaleOrderRevertModal}
        onCancel={onSaleOrderRevertCancel}
        onSuccess={onSaleOrderRevertSuccess}
        saleOrder={saleOrder}
      />
      <SaleOrderMarkAsReadyModal
        show={showSaleOrderReadyModal}
        onCancel={onSaleOrderReadyCancel}
        onSuccess={onSaleOrderReadySuccess}
        saleOrder={saleOrder}
      />
    </>
  );
};

export default SaleOrderList;
