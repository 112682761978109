import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { successAlert, warningAlert } from "../utils/messages";
import InventoryTransferForm from "./InventoryTransferForm";
import SaleOrder from "../../models/sale-order";
import Errors from "../../models/errors";
import { getItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { getRoutePath } from "../../routes";
import { Spinner } from "react-bootstrap";

import InventoryTransfer from "../../models/inventory-transfer";

const SaleOrderFormView = () => {
  const [inventoryTransfer, setInventoryTransfer] = useState<
    InventoryTransfer | undefined
  >(undefined);

  const [errors] = useState<Errors>({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const getInitialValues = async () => {
    setLoading(true);
    const inventoryTransferPromise = fetchInventoryTransfer(Number(id));
    await inventoryTransferPromise;
    setLoading(false);
  };

  const fetchInventoryTransfer = async (id: number) => {
    const inventoryTransferStatus = await getItem<SaleOrder>(
      `/inventory_transfers/${id}/`
    );
    if (inventoryTransferStatus.status === SUCCESS) {
      if (inventoryTransferStatus.data !== undefined) {
        setInventoryTransfer(inventoryTransferStatus.data);
      }
    } else {
      const message = inventoryTransferStatus.detail
        ? inventoryTransferStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  useEffect(() => {
    if (id === undefined || isNaN(Number(id))) {
      setLoading(false);

      return;
    }
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInventoryTransferEditCancel = () => {
    setInventoryTransfer(undefined);
  };

  const onInventoryTransferEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    history.push(getRoutePath("inventoryTransfers"));
  };

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol lg="12">
            <CCard>
              <CCardHeader className="d-flex flex-row mb-3">
                <div className="p-2 ">
                  <h3>
                    {inventoryTransfer
                      ? `Modificar Envío a tienda # ${inventoryTransfer.id}`
                      : "Crear nuevo envío a tienda"}
                  </h3>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading ? (
                  <Spinner animation="grow" />
                ) : (
                  <InventoryTransferForm
                    initialInventoryTransfer={inventoryTransfer}
                    initialErrors={errors}
                    onCancel={onInventoryTransferEditCancel}
                    onSuccess={onInventoryTransferEditSuccess}
                  />
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default SaleOrderFormView;
