import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getItem, getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { warningAlert } from "../utils/messages";
import Client, {
  ClientPayment,
  getPaymentStatusClass,
  getPaymentStatusLabel,
  getPaymentTypeLabel,
  newClient,
} from "../../models/client";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
import { DATEDDMMYYY, formatDate } from "../../utils/dates";
import { emptyValueOnUndefined } from "../../utils/fields";

const ITEMS_PER_PAGE = 10;

const PaymentList = () => {
  const fields = [
    {
      key: "date",
      _classes: ["text-center", "align-middle"],
      label: "Fecha",
    },
    {
      key: "amountPaid",
      _classes: ["text-center", "align-middle"],
      label: "Monto Gs",
    },
    {
      key: "discountInDebt",
      _classes: ["text-center", "align-middle"],
      label: "Descuento a deuda de cliente Gs",
    },
    {
      key: "status",
      _classes: ["text-center", "align-middle"],
      label: "Estado",
    },
    {
      key: "paymentType",
      _classes: ["text-center", "align-middle"],
      label: "Medio de pago",
    },
    {
      key: "bankName",
      _classes: ["text-center", "align-middle"],
      label: "Banco",
    },
    {
      key: "checkNumber",
      _classes: ["text-center", "align-middle"],
      label: "Nro Cheque",
    },
    {
      key: "checkDate",
      _classes: ["text-center", "align-middle"],
      label: "Fecha cheque",
    },
    {
      key: "relatedOrder",
      _classes: ["text-center", "align-middle"],
      label: "Orden de envío",
      filter: false,
    },
    {
      key: "obs",
      _classes: ["text-center", "align-middle"],
      label: "Observación",
      filter: false,
    },
  ];

  const { id } = useParams<{ id: string }>();

  const [client, setClient] = useState<Client | undefined>(newClient());
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState<ClientPayment[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const fetchClient = async (id: number) => {
    const clientStatus = await getItem<Client>(`/clients/${id}/`);
    if (clientStatus.status === SUCCESS) {
      if (clientStatus.data !== undefined) {
        setClient(clientStatus.data);
      }
    } else {
      const message = clientStatus.detail
        ? clientStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }

    let map = new Map();

    map.set("page", page);
    return map;
  };

  const fetchPayments = async (id: number) => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    urlParams.set("client_id", id.toString());
    const paymentsStatus = await getList<ClientPayment>(
      "/payments/",
      limit,
      offset,
      urlParams
    );
    if (paymentsStatus.status === SUCCESS) {
      if (paymentsStatus.data !== undefined) {
        const count = paymentsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setPayments(paymentsStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = paymentsStatus.detail
        ? paymentsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const getInitialValues = async () => {
    setLoading(true);
    const clientPromise = fetchClient(Number(id));
    const paymentsPromise = fetchPayments(Number(id));
    await clientPromise;
    await paymentsPromise;
    setLoading(false);
  };

  useEffect(() => {
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      fetchPayments(Number(id));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Pagos realizados por Cliente: {client?.name}</h3>
              </div>
            </CCardHeader>
            <CCardBody>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={payments}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    amountPaid: (item: ClientPayment) => {
                      return (
                        <td className="text-center">
                          <b>{formatToCurrency(item.amountPaid!, PYG)}</b>
                        </td>
                      );
                    },
                    discountInDebt: (item: ClientPayment) => {
                      return (
                        <td className="text-center">
                          <b>
                            {item.discountInDebt
                              ? formatToCurrency(item.discountInDebt, PYG)
                              : "-"}
                          </b>
                        </td>
                      );
                    },
                    date: (item: ClientPayment) => {
                      return (
                        <td className="text-center">
                          <b>
                            {item.date
                              ? formatDate(new Date(item.date), DATEDDMMYYY)
                              : "-"}
                          </b>
                        </td>
                      );
                    },
                    paymentType: (item: ClientPayment) => {
                      return (
                        <td className="text-center">
                          {getPaymentTypeLabel(item)}
                        </td>
                      );
                    },
                    bankName: (item: ClientPayment) => {
                      return (
                        <td className="text-center">
                          {emptyValueOnUndefined(item.bankName)}
                        </td>
                      );
                    },
                    status: (item: ClientPayment) => {
                      return (
                        <td className="text-center">
                          <CBadge
                            color={
                              item.status
                                ? getPaymentStatusClass(item.status)
                                : undefined
                            }
                          >
                            {item.status
                              ? getPaymentStatusLabel(item.status)
                              : undefined}
                          </CBadge>
                        </td>
                      );
                    },
                    checkNumber: (item: ClientPayment) => {
                      return (
                        <td className="text-center">
                          {emptyValueOnUndefined(item.checkNumber)}
                        </td>
                      );
                    },
                    checkDate: (item: ClientPayment) => {
                      return (
                        <td className="text-center">
                          {item.checkDate
                            ? formatDate(new Date(item.checkDate), DATEDDMMYYY)
                            : ""}
                        </td>
                      );
                    },
                    relatedOrder: (item: ClientPayment) => {
                      return (
                        <td className="text-center">
                          {item.deliveryOrderId
                            ? `#${item.deliveryOrderId}`
                            : "-"}
                        </td>
                      );
                    },
                    obs: (item: ClientPayment) => {
                      return (
                        <td className="text-center">
                          {item.obs ? item.obs : "-"}
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default PaymentList;
