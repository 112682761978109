import {
  CButton,
  CButtonGroup,
  CCol,
  CContainer,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, warningAlert } from "../utils/messages";

import { ItemRequestStatus, checkPermission } from "../../api/generics";

interface AllowWithPermissionModalProps {
  title: string;
  permission: string;
  show: boolean;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const AllowWithPermissionModal: React.FC<AllowWithPermissionModalProps> = ({
  title,
  show,
  permission,
  onCancel,
  onSuccess,
}) => {
  const [email, setEmail] = useState("");
  const [phrase, setPrase] = useState<string>("");
  const [submitting, setSubmitting] = useState(false);

  const onPhraseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrase(e.target.value);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onSave = async () => {
    setSubmitting(true);
    if (phrase === "") {
      warningAlert("Debe introducir una frase");
      setSubmitting(false);
      return;
    }
    if (email === "") {
      warningAlert("Debe introducir un email");
      setSubmitting(false);
      return;
    }
    let toSendPhrase = {
      email: email,
      phrase: phrase,
      permission: permission,
    };
    let requestPromise: Promise<ItemRequestStatus<{ status: string }>>;

    requestPromise = checkPermission<{
      email: string;
      phrase: string;
      permission: string;
    }>(`/auth/check_user_for_permission/`, toSendPhrase);

    const phraseStatus = await requestPromise;

    if (phraseStatus.status !== SUCCESS) {
      console.log(phraseStatus);
      errorAlert(
        phraseStatus.detail ? phraseStatus.detail : "Ocurrio un error"
      );
    } else {
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setPrase("");
  };

  return (
    <CModal show={show} className="modal-primary" onClosed={onCancel} size="lg">
      <CModalHeader closeButton>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{`Ingrese las credenciales para ${title}`}</h2>
            </CCol>
          </CRow>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Usuario:</CLabel>
              </CCol>
              <CCol>
                <CInput
                  type="text"
                  placeholder="Correo Electrónico"
                  autoComplete="username"
                  onChange={handleEmailChange}
                  value={email}
                />
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Contraseña:</CLabel>
              </CCol>
              <CCol>
                <CInput
                  type="password"
                  value={phrase}
                  onChange={onPhraseChange}
                  placeholder="Contraseña"
                ></CInput>
              </CCol>
            </CRow>
          </CFormGroup>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButtonGroup>
          <CButton type="button" color="secondary" onClick={onClose}>
            Atras
          </CButton>
          <CButton disabled={submitting} onClick={onSave} color="primary">
            {submitting ? (
              <Spinner
                animation="grow"
                style={{
                  height: "17px",
                  width: "17px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
            ) : (
              <></>
            )}
            {submitting ? "Enviando..." : "Enviar"}
          </CButton>
        </CButtonGroup>
      </CModalFooter>
    </CModal>
  );
};

export default AllowWithPermissionModal;
