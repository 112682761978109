import {
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getList, updateItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, warningAlert } from "../utils/messages";
import ProductionOrder, {
  ProductionOrderItem,
} from "../../models/production-order";
import {
  VariantTableEntry,
  productionOrderItemAPIToTable,
} from "../../models/variant";
import { emptyValueOnUndefined } from "../../utils/fields";

interface ProductionOrderEndModalProps {
  show: boolean;
  productionOrder?: ProductionOrder;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const FIXED_COLUMNS = ["Color"];

const ProductionOrderEndModal: React.FC<ProductionOrderEndModalProps> = ({
  show,
  productionOrder,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState<number>(0);
  const [obs, setObs] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [tableColumns, setTableColumns] = useState<string[]>(FIXED_COLUMNS);

  const [tableEntries, setTableEntries] = useState<VariantTableEntry[]>([]);

  const message = productionOrder
    ? `Está seguro de que quiere finalizar la produccion ${productionOrder.id}, ${productionOrder.productName}`
    : "";

  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setObs(e.target.value);
  };

  const onSubmitClick = async () => {
    if (productionOrder !== undefined) {
      setSubmitting(true);

      const amountLost = amount;

      if (amountLost > productionOrder.amountProduced!) {
        warningAlert("No perder mas unidades que las cortadas");
      }

      const achievedProfit = productionOrder.expectedProfit;
      let toSendOrder: ProductionOrder = {
        ...productionOrder,
        currentStage: undefined,
        currentStageId: undefined,
        orderStatus: "finished",
        currentStageAllowRelatedAmounts: false,
        currentStageIsCut: false,
        currentStageIsConfection: false,
        currentStageIsLaundry: false,
        amountLost: amountLost,
        achievedProfit: achievedProfit,
      };

      if (obs !== "") {
        toSendOrder.finalObs = obs;
      }

      const orderStatus = await updateItem<ProductionOrder>(
        `/production/${productionOrder.id}/finish/`,
        toSendOrder
      );

      if (orderStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (orderStatus.detail !== undefined) {
          message = orderStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
        setAmount(0);
        setObs("");
      }

      setSubmitting(false);
    }
  };

  const generateTableHeaders = (entries: VariantTableEntry[]) => {
    const uniqueSizeNames: string[] = [];

    entries.forEach((item) => {
      if (item.sizes) {
        item.sizes
          .map((size) => size?.sizeName)
          .filter(
            (sizeName) =>
              sizeName !== undefined && !uniqueSizeNames.includes(sizeName)
          )
          .forEach((sizeName) => {
            if (sizeName) {
              uniqueSizeNames.push(sizeName);
            }
          });
      }
    });

    uniqueSizeNames.sort((a, b) => {
      const aFloat = parseFloat(a);
      const bFloat = parseFloat(b);
      return aFloat - bFloat;
    });

    setTableColumns([...FIXED_COLUMNS, ...uniqueSizeNames]);
  };

  const getOrderItems = async () => {
    if (productionOrder === undefined || productionOrder.id === undefined) {
      setTableEntries([]);
      return;
    }
    const productionOrderIdStr = productionOrder.id.toString();
    if (productionOrderIdStr === undefined) {
      setTableEntries([]);
      return;
    }
    const limit = 100;
    const offset = 0;
    const orderSizesStatus = await getList<ProductionOrderItem>(
      `/production/${productionOrderIdStr}/order_items/`,
      limit,
      offset
    );
    if (orderSizesStatus.status === SUCCESS) {
      if (orderSizesStatus.data !== undefined) {
        const formattedTableEntries = productionOrderItemAPIToTable(
          orderSizesStatus.data.items
        );

        setTableEntries(formattedTableEntries);
        generateTableHeaders(formattedTableEntries);
      } else {
        setTableEntries([]);
        return;
      }
    } else {
      setTableEntries([]);
      return;
    }
  };

  const fetchTableEntries = async () => {
    setLoading(true);
    await getOrderItems();
    setLoading(false);
  };

  useEffect(() => {
    if (productionOrder?.amountProduced) {
      setAmount(
        productionOrder.amountProduced -
          (productionOrder.finishedAmount || 0) +
          (productionOrder.amountLost || 0)
      );
    } else {
      setAmount(0);
    }
    setObs("");
    fetchTableEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionOrder]);
  return (
    <CModal show={show} className="modal-primary" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Finalizar Producción</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <p>
                Los productos que no fueron agregados al inventarios seran
                tomados como perdidos. puede dejar una Observación de porque no
                se terminaron.
              </p>
            </CCol>
          </CRow>
          <fieldset>
            <CFormGroup>
              <CRow>
                <CCol md={5}>
                  <CLabel>Cantidad Total Cortada:</CLabel>
                </CCol>
                <CCol md={7}>
                  <CInput
                    type="number"
                    placeholder="Cantidad"
                    disabled
                    value={emptyValueOnUndefined(
                      productionOrder?.amountProduced
                    )}
                  ></CInput>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={5}>
                  <CLabel>Cantidad Perdida:</CLabel>
                </CCol>
                <CCol md={7}>
                  <CInput
                    type="number"
                    placeholder="Cantidad"
                    disabled
                    value={emptyValueOnUndefined(amount)}
                  ></CInput>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={5}>
                  <CLabel>Observación:</CLabel>
                </CCol>
                <CCol md={7}>
                  <CInput
                    type="text"
                    value={obs}
                    onChange={onObsChange}
                    placeholder="Observación"
                  ></CInput>
                </CCol>
              </CRow>
            </CFormGroup>
            <CRow>
              <CCol md={12}>
                <h3>Cantidades Finales producidas</h3>
                <p>
                  Las cantidades producidas se indican de la siguiente manera
                  (cantidad terminada/cantidad total cortada)
                </p>
              </CCol>
            </CRow>
            {!loading ? (
              <CRow>
                <CCol md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered table-fixed">
                      <thead>
                        <tr>
                          {tableColumns.map((title, ix) => {
                            return (
                              <th
                                className="text-center"
                                key={ix}
                                style={{
                                  verticalAlign: "middle",
                                  overflow: "hidden",
                                }}
                              >
                                <div className="d-inline">{title}</div>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tableEntries.map((tableEntry, ix) => {
                          return (
                            <tr
                              style={{
                                verticalAlign: "middle",
                                overflow: "hidden",
                              }}
                              key={ix}
                            >
                              <td
                                className={"text-center"}
                                style={{ padding: 0, width: "120px" }}
                              >
                                <p>{tableEntry.colorName}</p>
                              </td>
                              {tableColumns.map((column, ix) => {
                                if (column === "Color") {
                                  return <></>;
                                }
                                const matchSize = tableEntry.sizes?.find(
                                  (item) => item.sizeName === column
                                );
                                const disabled = matchSize === undefined;
                                let showValue = "";
                                if (matchSize !== undefined) {
                                  if (matchSize.finishedAmount !== undefined) {
                                    showValue = `${matchSize.finishedAmount}/${matchSize.totalAmount}`;
                                  } else {
                                    showValue = `${matchSize.totalAmount}`;
                                  }
                                }
                                return (
                                  <td
                                    key={ix}
                                    style={{ padding: 0, width: "70px" }}
                                  >
                                    <CInput
                                      type="text"
                                      value={
                                        disabled
                                          ? ""
                                          : matchSize
                                          ? emptyValueOnUndefined(showValue)
                                          : ""
                                      }
                                      disabled={true}
                                    ></CInput>
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                        {tableEntries.length === 0 ? (
                          <tr>
                            <td colSpan={tableColumns.length}>
                              No hay tamaños disponibles para esta prenda.
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                    <br />
                    <br />
                  </div>
                </CCol>
              </CRow>
            ) : (
              <Spinner
                animation="grow"
                style={{
                  height: "17px",
                  width: "17px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
            )}
          </fieldset>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onSubmitClick} color="primary">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Finalizando..." : "Finalizar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ProductionOrderEndModal;
