import Color from "./color";
import InventoryLocation from "./inventory-location";
import Product from "./product";
import Size from "./size";
import Variant from "./variant";

interface InventoryTransfer {
  id?: number;
  companyId?: number;
  originInventoryId?: number;
  originInventory?: InventoryLocation;
  originName?: string;
  destinationInventoryId?: number;
  destinationInventory?: InventoryLocation;
  destinationName?: string;
  status?: string;
  senderUserId?: number;
  senderUserName?: string;
  receiverUserId?: number;
  receiverUserName?: string;
  sendDate?: string;
  receiveDate?: string;
  senderObs?: string;
  receiverObs?: string;
  reportUrl?: string;
  items?: InventoryTransferItem[];
  totalValue?: number;
}

export interface InventoryTransferItem {
  id?: number;
  companyId?: number;
  inventoryLocationTransferId?: number;
  clothingProductId?: number;
  clothingProduct?: Product;
  productVariantId?: number;
  productVariant?: Variant;
  productCodeIdentifier?: string;
  productName?: string;
  colorId?: number;
  color?: Color;
  colorName?: string;
  sizeId?: number;
  size?: Size;
  sizeName?: string;
  amount?: number;
  unitValue?: number;
  totalValue?: number;
}

interface StatusData {
  class: string;
  name: string;
}

interface StatusesMap {
  [index: string]: StatusData;
}

export const INVENTORYTRANSFERSTATUSES: StatusesMap = {
  pending: {
    class: "info",
    name: "Pendiente de envío",
  },
  ongoing: {
    class: "primary",
    name: "Enviado",
  },
  completed: {
    class: "success",
    name: "Recepcionado/completado",
  },
  cancelled: {
    class: "danger",
    name: "Cancelado/revertido",
  },
};

export const getStatusLabel = (key: string) => {
  return INVENTORYTRANSFERSTATUSES[key].name;
};

export const getStatusClass = (key: string) => {
  return INVENTORYTRANSFERSTATUSES[key].class;
};

export default InventoryTransfer;
