import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CLink,
  CInputFile,
} from "@coreui/react";
import { useEffect, useState, useRef } from "react";
import { Spinner } from "react-bootstrap";
import {
  createItem,
  updateItem,
  ItemRequestStatus,
  getItemPresignedUrl,
  getList,
  getItem,
} from "../../api/generics";
import Product, { newProduct } from "../../models/product";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert, warningAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import Color from "../../models/color";
import ColorsSelect from "../color/ColorsSelect";
import Size from "../../models/size";
import SizesSelect from "../size/SizesSelect";
import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";
import CategorySelect from "../category/CategorySelect";
import Category from "../../models/category";

interface InputMarterialFormProps {
  initialProduct?: Product;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const SupplierForm: React.FC<InputMarterialFormProps> = ({
  initialProduct,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingProduct, setEditingProduct] = useState<Product>(
    initialProduct ? initialProduct : newProduct()
  );

  const [availableSizes, setAvailableSizes] = useState<Size[]>([]);
  const [colors, setColors] = useState<readonly Color[]>([]);
  const [sizes, setSizes] = useState<readonly Size[]>([]);
  const [loading, setLoading] = useState(true);
  const [isKidSize, setIsKidSize] = useState(false);
  const [category, setCategory] = useState<Category | undefined>(undefined);
  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const [loadedLogoPath, setLoadedLogoPath] = useState<string | undefined>(
    initialProduct?.imageUrl
  );
  const logo = useRef<HTMLInputElement>(null);
  const logoFileName = useRef<string>("");

  const logoModified = useRef<boolean>(false);

  const onIdentifierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingProduct({
      ...editingProduct,
      name: e.target.value,
    });
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingProduct({
      ...editingProduct,
      description: e.target.value,
    });
  };

  const onCodeIdentifierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingProduct({
      ...editingProduct,
      codeIdentifier: e.target.value,
    });
  };

  const onPriceChange = (newAmount: number | undefined) => {
    setEditingProduct({
      ...editingProduct,
      price: newAmount,
    });
  };
  const onProductionCostChange = (newAmount: number | undefined) => {
    setEditingProduct({
      ...editingProduct,
      productionCost: newAmount,
    });
  };

  const onWholesalePriceChange = (newAmount: number | undefined) => {
    setEditingProduct({
      ...editingProduct,
      wholesalePrice: newAmount,
    });
  };

  const onSuperWholesalePriceChange = (newAmount: number | undefined) => {
    setEditingProduct({
      ...editingProduct,
      superWholesalePrice: newAmount,
    });
  };

  const onThresholdAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingProduct((editingItem) => {
      let amount: number | undefined = undefined;

      if (!isNaN(parseInt(e.target.value))) {
        amount = parseInt(e.target.value);
      }

      return { ...editingItem, wholesaleThreshold: amount };
    });
  };

  const onAverageFabricChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingProduct((prevEditingProduct) => {
      let amount: number | undefined = undefined;

      if (!isNaN(parseFloat(e.target.value))) {
        amount = parseFloat(e.target.value);
      }

      return { ...prevEditingProduct, averageFabricUsage: amount };
    });
  };

  const onLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    logoModified.current = true;
    let fileParts = e.target.value.split("\\");
    logoFileName.current = fileParts[fileParts.length - 1];
    setEditingProduct({
      ...editingProduct,
      imageUrl: logoFileName.current,
    });
  };

  const onKidsSizeChange = (isKidsSize: boolean) => {
    setIsKidSize(isKidSize);
    const newSizes: readonly Size[] = availableSizes
      .filter((size) => size.isKidSize === isKidsSize)
      .map((size) => ({ ...size }));
    setSizes(newSizes);
  };

  const handleLogoUpload = async () => {
    if (!logoModified.current) {
      return true;
    }

    const mutableColors: Color[] = Array.from(colors);
    const mutableSizes: Size[] = Array.from(sizes);

    setSubmitting(true);
    let toSendProduct = {
      ...editingProduct,
      companyId: company.id,
      colors: mutableColors,
      sizes: mutableSizes,
      categoryName: category ? category.name : undefined,
      categoryId: category ? category.id : undefined,
    };

    let presignedUrlPromise: Promise<ItemRequestStatus<{ url: string }>>;

    presignedUrlPromise = getItemPresignedUrl<Product>(
      `/clothing_products/put_logo_url/`,
      toSendProduct
    );

    const logoUrlStatus = await presignedUrlPromise;

    if (logoUrlStatus.status !== SUCCESS) {
      if (logoUrlStatus.errors !== undefined) {
        setErrors(logoUrlStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (logoUrlStatus.detail !== undefined) {
        message = logoUrlStatus.detail;
      }
      errorAlert(message);
      return false;
    }

    if (logoUrlStatus.data === undefined) {
      return false;
    }

    const urlData = logoUrlStatus.data as { url: string };

    if (!logo.current || !logo.current.files) {
      return false;
    }
    const logoFile = logo.current.files[0];
    if (!logoFile) {
      return false;
    }

    const uploadStatus = await fetch(urlData.url!, {
      method: "PUT",
      body: logoFile,
      headers: {
        "Content-type": logoFile.type,
      },
    });

    return uploadStatus.status === 200;
  };

  const onSave = async () => {
    setSubmitting(true);
    const hasLogo = editingProduct.imageUrl !== undefined;

    const imageUploaded = hasLogo ? await handleLogoUpload() : true;
    if (!imageUploaded) {
      setSubmitting(false);
      return;
    }

    const mutableColors: Color[] = Array.from(colors);
    const mutableSizes: Color[] = Array.from(sizes);

    let toSendProduct = {
      ...editingProduct,
      companyId: company.id,
      colorsIds: colors,
      colors: mutableColors,
      sizes: mutableSizes,
      categoryName: category ? category.name : undefined,
      categoryId: category ? category.id : undefined,
    };
    if (
      toSendProduct.id === undefined &&
      toSendProduct.wholesaleThreshold === undefined
    ) {
      toSendProduct.wholesaleThreshold = 3;
    }

    let requestPromise: Promise<ItemRequestStatus<Product>>;
    if (editingProduct.id === undefined) {
      requestPromise = createItem<Product>(
        "/clothing_products/create/",
        toSendProduct
      );
    } else {
      requestPromise = updateItem<Product>(
        `/clothing_products/${toSendProduct.id}/`,
        toSendProduct
      );
    }

    const productStatus = await requestPromise;

    if (productStatus.status !== SUCCESS) {
      if (productStatus.errors !== undefined) {
        setErrors(productStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (productStatus.detail !== undefined) {
        message = productStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      setAvailableSizes([]);
      setLoading(true);
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingProduct(newProduct());
    setColors([]);
    setSizes([]);
    setCategory(undefined);
  };

  const getColors = async () => {
    if (initialProduct === undefined || initialProduct.id === undefined) {
      setColors([]);
      return [];
    }
    const productIdStr = initialProduct.id.toString();
    if (productIdStr === undefined) {
      setColors([]);
      return [];
    }
    const limit = 100;
    const offset = 0;
    const additional = new Map();
    additional.set("clothing_product_id", productIdStr);
    const colorsStatus = await getList<Color>(
      "/colors/",
      limit,
      offset,
      additional
    );
    if (colorsStatus.status === SUCCESS) {
      if (colorsStatus.data !== undefined) {
        setColors(colorsStatus.data.items);
      }
    } else {
      setColors([]);
    }
  };

  const getSizes = async () => {
    if (initialProduct === undefined || initialProduct.id === undefined) {
      setSizes([]);
      setIsKidSize(false);
      return [];
    }
    const productIdStr = initialProduct.id.toString();
    if (productIdStr === undefined) {
      setSizes([]);
      setIsKidSize(false);
      return [];
    }
    const limit = 100;
    const offset = 0;
    const additional = new Map();
    additional.set("clothing_product_id", productIdStr);
    const sizesStatus = await getList<Size>(
      "/sizes/",
      limit,
      offset,
      additional
    );
    if (sizesStatus.status === SUCCESS) {
      if (sizesStatus.data !== undefined) {
        setSizes(sizesStatus.data.items);
        setIsKidSize(
          sizesStatus.data.items.some((size) => size.isKidSize === true)
        );
      }
    } else {
      setSizes([]);
      setIsKidSize(false);
    }
  };

  const onCategoryChange = (newCategory: Category | null) => {
    setEditingProduct((editingProduct) => {
      return {
        ...editingProduct,
        categoryId: newCategory !== null ? newCategory.id : undefined,
        categoryName: newCategory !== null ? newCategory.name : undefined,
      };
    });
    setCategory(newCategory !== null ? newCategory : undefined);
  };

  const fetchAvailableSizes = async () => {
    const limit = 100;
    const offset = 0;
    const sizesStatus = await getList<Size>(
      "/sizes/",
      limit,
      offset,
      undefined
    );
    if (sizesStatus.status === SUCCESS) {
      if (sizesStatus.data !== undefined) {
        setAvailableSizes(sizesStatus.data.items);
      }
    } else {
      setAvailableSizes([]);
    }
  };

  const fetchCategory = async (id: number) => {
    const categoriesStatus = await getItem<Category>(`/categories/${id}/`);
    if (categoriesStatus.status === SUCCESS) {
      if (categoriesStatus.data !== undefined) {
        setCategory(categoriesStatus.data);
      }
    } else {
      const message = categoriesStatus.detail
        ? categoriesStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  useEffect(() => {
    setLoading(true);
    setEditingProduct(initialProduct ? initialProduct : newProduct());
    setLoadedLogoPath(initialProduct?.imageUrl);
    getColors();
    getSizes();
    if (initialProduct?.categoryId) {
      fetchCategory(initialProduct.categoryId);
    } else {
      setCategory(undefined);
    }
    fetchAvailableSizes();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProduct]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);
  const buttonText1 = loading ? "Cargando..." : "Guardar";
  const buttonText = submitting ? "Guardando..." : buttonText1;

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Código de prenda:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingProduct.codeIdentifier)}
                onChange={onCodeIdentifierChange}
                placeholder="Código de prenda"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("code_identifier", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Identificador:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingProduct.name)}
                onChange={onIdentifierChange}
                placeholder="Nombre de Producto"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("name", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Descripción:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingProduct.description)}
                onChange={onDescriptionChange}
                placeholder="Descripción"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("description", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Categoría:</CLabel>
            </CCol>

            <CCol>
              <CategorySelect
                value={category ? category : null}
                onChange={onCategoryChange}
              ></CategorySelect>
              <FieldErrors
                errors={getFieldErrors("categoryName", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Precio de Lista:</CLabel>
            </CCol>
            <CCol md={4}>
              <CurrencyField
                currency={PYG}
                value={editingProduct.price}
                onChange={onPriceChange}
              ></CurrencyField>
              <FieldErrors
                errors={getFieldErrors("price", errors) as string[]}
              ></FieldErrors>
            </CCol>
            <CCol md={2}>
              <CLabel>Precio de Costo:</CLabel>
            </CCol>
            <CCol md={4}>
              <CurrencyField
                currency={PYG}
                value={editingProduct.productionCost}
                onChange={onProductionCostChange}
              ></CurrencyField>
              <FieldErrors
                errors={getFieldErrors("productionCost", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Precio Mayorista:</CLabel>
            </CCol>
            <CCol md={4}>
              <CurrencyField
                currency={PYG}
                value={editingProduct.wholesalePrice}
                onChange={onWholesalePriceChange}
              ></CurrencyField>
              <FieldErrors
                errors={getFieldErrors("wholesalePrice", errors) as string[]}
              ></FieldErrors>
            </CCol>
            <CCol md={2}>
              <CLabel>Unidades para precio Mayorista:</CLabel>
            </CCol>
            <CCol md={4}>
              <CInput
                type="number"
                placeholder="Cantidad"
                value={
                  editingProduct.wholesaleThreshold !== undefined
                    ? editingProduct.wholesaleThreshold
                    : 3
                }
                onChange={onThresholdAmountChange}
              ></CInput>
              <FieldErrors
                errors={
                  getFieldErrors("wholesaleThreshold", errors) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Precio Super Mayorista:</CLabel>
            </CCol>
            <CCol>
              <CurrencyField
                currency={PYG}
                value={editingProduct.superWholesalePrice}
                onChange={onSuperWholesalePriceChange}
              ></CurrencyField>
              <FieldErrors
                errors={
                  getFieldErrors("superWholesalePrice", errors) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Promedio de Tela:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="number"
                placeholder="Promedio."
                value={
                  editingProduct.averageFabricUsage !== undefined
                    ? editingProduct.averageFabricUsage
                    : 0
                }
                onChange={onAverageFabricChange}
                step="0.01"
              ></CInput>
              <FieldErrors
                errors={
                  getFieldErrors("averageFabricUsage", errors) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Imagen:</CLabel>
            </CCol>
            <CCol>
              <CInputFile
                accept=".png, .jpg"
                type="file"
                innerRef={logo}
                onChange={onLogoChange}
              ></CInputFile>
              {loadedLogoPath ? (
                <small>
                  Seleccionado actualmente:
                  <CLink href={`#`} target="_blank">
                    {loadedLogoPath}
                  </CLink>
                </small>
              ) : (
                <></>
              )}

              <FieldErrors
                errors={getFieldErrors("imageUrl", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Colores:</CLabel>
            </CCol>
            <CCol>
              <ColorsSelect
                value={colors}
                onChange={setColors}
                isDisabled={loading || submitting}
              ></ColorsSelect>
              <FieldErrors
                errors={getFieldErrors("colors", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Prenda para niños:</CLabel>
            </CCol>
            <CCol md={2}>
              <CRow>
                <AnimatedCheckbox
                  value={isKidSize}
                  onChange={onKidsSizeChange}
                ></AnimatedCheckbox>
              </CRow>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Talles:</CLabel>
            </CCol>
            <CCol>
              <SizesSelect
                value={sizes}
                onChange={setSizes}
                isDisabled={loading || submitting}
              ></SizesSelect>
              <FieldErrors
                errors={getFieldErrors("sizes", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting || loading ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {buttonText}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default SupplierForm;
