import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import InventoryLocation from "../../models/inventory-location";

const RECORS_PER_SEARCH = 20;

type InventoryLocationSelectProps = {
  value: InventoryLocation | null;
  onChange: (_: InventoryLocation | null) => void;
  inventoryType?: string;
  locationType?: string;
  disabled?: boolean;
};

const InventoryLocationSelect: React.FC<InventoryLocationSelectProps> = ({
  value,
  onChange,
  inventoryType,
  locationType,
  disabled,
}) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );
  const loadOptions = async (
    s: string,
    currentOpts: readonly (
      | InventoryLocation
      | { options: InventoryLocation[]; label?: string }
    )[]
  ): Promise<
    Response<
      InventoryLocation,
      { options: InventoryLocation[]; label?: string },
      any
    >
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    if (inventoryType !== undefined) {
      additionalParams.set("inventory_type", inventoryType);
    }

    if (locationType !== undefined) {
      additionalParams.set("location_type", locationType);
    }

    const dataPaginated = await getList<InventoryLocation>(
      "/inventory_locations/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: InventoryLocation[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      newOpts = dataPaginated.data.items;
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un Inventario"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: InventoryLocation) => `${option.identifier}`}
      getOptionValue={(option: InventoryLocation) => `${option.id}`}
      value={value}
      isDisabled={disabled}
      isClearable
    />
  );
};

export default InventoryLocationSelect;
