import CategoryCrud from "../components/category/CategoryCrud";

const CategoryCrudPage = () => {
  return (
    <>
      <CategoryCrud />
    </>
  );
};

export default CategoryCrudPage;
