import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CSelect,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  createItem,
  updateItem,
  ItemRequestStatus,
  getList,
} from "../../api/generics";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import Category from "../../models/category";
import Discount, { newDiscount } from "../../models/discount";
import Store from "../../models/store";
import CategoriesSelect from "../category/CategoriesSelect";
import StoresSelect from "../store/StoresSelect";

interface DiscountFormProps {
  initialDiscount?: Discount;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const DiscountForm: React.FC<DiscountFormProps> = ({
  initialDiscount,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingDiscount, setEditingDiscount] = useState<Discount>(
    initialDiscount ? initialDiscount : newDiscount()
  );

  const [categories, setCategories] = useState<readonly Category[]>([]);
  const [stores, setStores] = useState<readonly Store[]>([]);
  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);
  const [showSpecialFiels, setShowSpecialFields] = useState(false);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingDiscount({
      ...editingDiscount,
      name: e.target.value,
    });
  };

  const onDiscountTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "two_levels") {
      setShowSpecialFields(true);
    } else {
      setShowSpecialFields(false);
    }
    setEditingDiscount((editingDiscount) => {
      const newDiscountType =
        e.target.value !== "" ? e.target.value : undefined;
      return {
        ...editingDiscount,
        discountType: newDiscountType,
      };
    });
  };

  const onPercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmountNumber = Number(e.target.value);
    setEditingDiscount({
      ...editingDiscount,
      discountPercentage: newAmountNumber,
    });
  };

  const onFirstThresholdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmountNumber = Number(e.target.value);
    setEditingDiscount({
      ...editingDiscount,
      firstThreshold: newAmountNumber,
    });
  };

  const onSecondThresholdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmountNumber = Number(e.target.value);
    setEditingDiscount({
      ...editingDiscount,
      secondThreshold: newAmountNumber,
    });
  };

  const onSecondAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmountNumber = Number(e.target.value);
    setEditingDiscount({
      ...editingDiscount,
      secondDiscountValue: newAmountNumber,
    });
  };

  const onSave = async () => {
    setSubmitting(true);

    const mutableCategories: Category[] = Array.from(categories);
    const mutableStores: Store[] = Array.from(stores);

    let toSendDiscount: Discount = {
      ...editingDiscount,
      companyId: company.id,
      categories: mutableCategories,
      stores: mutableStores,
    };

    if (toSendDiscount.firstThreshold === undefined) {
      toSendDiscount.firstThreshold = null;
    }
    if (toSendDiscount.secondThreshold === undefined) {
      toSendDiscount.secondThreshold = null;
    }
    if (toSendDiscount.secondDiscountValue === undefined) {
      toSendDiscount.secondDiscountValue = null;
    }
    let requestPromise: Promise<ItemRequestStatus<Discount>>;
    if (editingDiscount.id === undefined) {
      toSendDiscount.isActive = true;
      requestPromise = createItem<Discount>(
        "/discounts/create/",
        toSendDiscount
      );
    } else {
      requestPromise = updateItem<Discount>(
        `/discounts/${toSendDiscount.id}/`,
        toSendDiscount
      );
    }

    const discountStatus = await requestPromise;

    if (discountStatus.status !== SUCCESS) {
      if (discountStatus.errors !== undefined) {
        setErrors(discountStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (discountStatus.detail !== undefined) {
        message = discountStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      setLoading(false);
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingDiscount(newDiscount());
    setCategories([]);
    setStores([]);
    setShowSpecialFields(false);
  };

  const fetchCategories = async () => {
    if (initialDiscount === undefined || initialDiscount.id === undefined) {
      setCategories([]);
      return [];
    }
    const discountIdStr = initialDiscount.id.toString();
    if (discountIdStr === undefined) {
      setCategories([]);
      return [];
    }
    const limit = 100;
    const offset = 0;
    const additional = new Map();
    additional.set("discount_id", discountIdStr);
    const categoriesStatus = await getList<Category>(
      "/categories/",
      limit,
      offset,
      additional
    );
    if (categoriesStatus.status === SUCCESS) {
      if (categoriesStatus.data !== undefined) {
        setCategories(categoriesStatus.data.items);
      }
    } else {
      setCategories([]);
    }
  };

  const fetchStores = async () => {
    if (initialDiscount === undefined || initialDiscount.id === undefined) {
      setStores([]);
      return [];
    }
    const discountIdStr = initialDiscount.id.toString();
    if (discountIdStr === undefined) {
      setStores([]);
      return [];
    }
    const limit = 100;
    const offset = 0;
    const additional = new Map();
    additional.set("discount_id", discountIdStr);
    const storesStatus = await getList<Store>(
      "/stores/",
      limit,
      offset,
      additional
    );
    if (storesStatus.status === SUCCESS) {
      if (storesStatus.data !== undefined) {
        setStores(storesStatus.data.items);
      }
    } else {
      setStores([]);
    }
  };

  useEffect(() => {
    setLoading(true);
    setEditingDiscount(initialDiscount ? initialDiscount : newDiscount());
    if (initialDiscount?.discountType === "two_levels") {
      setShowSpecialFields(true);
    }
    fetchCategories();
    fetchStores();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDiscount]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);
  const buttonText1 = loading ? "Cargando..." : "Guardar";
  const buttonText = submitting ? "Guardando..." : buttonText1;

  return (
    <>
      <fieldset disabled={submitting}>
        <CRow>
          <CCol>
            <p>
              <b>Observaciones:</b>
            </p>
            <ul>
              <li>
                El monto del descuento depende del tipo de descuento que se
                elija.
              </li>
              <li>
                Porcentaje: En este caso el monto de descuento sera el % de
                descuento que se hará sobre el precio del producto Ej: Si
                introduce 20 se hará un 20% de descuento sobre el precio.
              </li>
              <li>
                Monto fijo: En este caso el monto de descuento sera un valor en
                Gs que se descontará al precio del producto Ej: Si introduce
                5000 se hará un descuento 5000 Gs sobre el precio.
              </li>
              <li>
                Fijar Precio: En este caso el monto de descuento sera un valor
                en Gs que directamente reemplazará el precio del producto Ej: Si
                introduce 30000 todos los productos afectados por el descuento
                se venderan por 30000Gs
              </li>
              <li>
                Dos Niveles: En este caso el monto de descuento sera un valor en
                Gs que se descontará al precio del producto si se alcanza un
                valor predeterminado y luego un descuento mayor en caso que se
                alcance un segundo valor predeterminado en productos de las
                categorias Ej: 2500 Gs de descuento en cada unidad si se alcanza
                un valor total de venta en las categorias por 5000000 Gs y luego
                5000 por cada unidad si se alcanzan 10000000 Gs
              </li>
            </ul>
          </CCol>
        </CRow>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingDiscount.name)}
                onChange={onNameChange}
                placeholder="Nombre"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("name", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Tipo de Descuento:</CLabel>
            </CCol>
            <CCol>
              <CSelect
                type="text"
                onChange={onDiscountTypeChange}
                value={
                  editingDiscount.discountType
                    ? editingDiscount.discountType
                    : ""
                }
              >
                <option value={""}>-----</option>
                <option value={"percentage"}>Porcentaje</option>
                <option value={"fixed_amount"}>Descuento de Monto Fijo</option>
                <option value={"set_price"}>Fijar Precio </option>
                <option value={"two_levels"}>Dos Niveles </option>
              </CSelect>
              <FieldErrors
                errors={getFieldErrors("discountType", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                {showSpecialFiels ? "Primer descuento" : "Monto del descuento:"}
              </CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(
                  editingDiscount.discountPercentage
                )}
                onChange={onPercentageChange}
                placeholder="Monto de descuento"
              ></CInput>
              <FieldErrors
                errors={
                  getFieldErrors("discountPercentage", errors) as string[]
                }
              ></FieldErrors>
            </CCol>
            {showSpecialFiels ? (
              <>
                <CCol md={2}>
                  <CLabel>Valor a alcanzar 1:</CLabel>
                </CCol>
                <CCol>
                  <CInput
                    type="text"
                    value={emptyValueOnUndefined(
                      editingDiscount.firstThreshold === null
                        ? undefined
                        : editingDiscount.firstThreshold
                    )}
                    onChange={onFirstThresholdChange}
                    placeholder="Valor a alcanzar 1"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors("firstThreshold", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </>
            ) : (
              <></>
            )}
          </CRow>
        </CFormGroup>
        {showSpecialFiels ? (
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Segundo Descuento:</CLabel>
              </CCol>
              <CCol>
                <CInput
                  type="text"
                  value={emptyValueOnUndefined(
                    editingDiscount.secondDiscountValue === null
                      ? undefined
                      : editingDiscount.secondDiscountValue
                  )}
                  onChange={onSecondAmountChange}
                  placeholder="Monto de descuento"
                ></CInput>
                <FieldErrors
                  errors={
                    getFieldErrors("secondDiscountValue", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>Valor a alcanzar 2:</CLabel>
              </CCol>
              <CCol>
                <CInput
                  type="text"
                  value={emptyValueOnUndefined(
                    editingDiscount.secondThreshold === null
                      ? undefined
                      : editingDiscount.secondThreshold
                  )}
                  onChange={onSecondThresholdChange}
                  placeholder="Valor a alcanzar 2"
                ></CInput>
                <FieldErrors
                  errors={getFieldErrors("secondThreshold", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
        ) : (
          <></>
        )}
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Categorías:</CLabel>
            </CCol>
            <CCol>
              <CategoriesSelect
                value={categories}
                onChange={setCategories}
                isDisabled={loading || submitting}
              ></CategoriesSelect>
              <FieldErrors
                errors={getFieldErrors("categories", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Tiendas:</CLabel>
            </CCol>
            <CCol>
              <StoresSelect
                value={stores}
                onChange={setStores}
                isDisabled={loading || submitting}
              ></StoresSelect>
              <FieldErrors
                errors={getFieldErrors("sizes", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting || loading ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {buttonText}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default DiscountForm;
