import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTextarea,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  createItem,
  updateItem,
  ItemRequestStatus,
  getList,
  getItem,
} from "../../api/generics";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import ProductSelect from "../product/ProductSelect";
import ProductionOrder, {
  ProductionOrderItem,
  ProductionOrderSize,
  newProductionOrder,
} from "../../models/production-order";
import Product from "../../models/product";
import ProductionInputMaterialForm from "./ProductionMaterialInputForm";
import ProductionLaborPriceForm from "./ProductionLaborPriceForm";
import {
  UniqueListItem,
  listToUniqueListItems,
  newUniqueListItem,
  uniqueListToListItems,
} from "../../models/unique-list-item";
import ProductionOrderLaborPrice, {
  newProductionOrderLaborPrice,
} from "../../models/production-labor-price";
import ProductionOrderInputMaterial from "../../models/production-input-material";
import ProductionStage from "../../models/production-stage";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";
import { orderIsEditable } from "../../utils/production-orders";
import {
  VariantTableEntry,
  productionOrderItemAPIToTable,
} from "../../models/variant";
import { emptyValueOnUndefined } from "../../utils/fields";

interface ProductionFormProps {
  initialProductionOrder?: ProductionOrder;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const FIXED_COLUMNS = ["Color"];

const ProductionForm: React.FC<ProductionFormProps> = ({
  initialProductionOrder,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingOrder, setEditingOrder] = useState<ProductionOrder>(
    initialProductionOrder ? initialProductionOrder : {}
  );
  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);
  const [, setLoading] = useState(true);
  const [editable, setEditable] = useState(true);
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const [totalCostPrice, setTotalCostPrice] = useState<number>(0);
  const [expectedProfit, setExpectedProfit] = useState<number>(0);
  const [expectedProfitWholesale, setExpectedProfitWholesale] =
    useState<number>(0);
  const [expectedProfitSuperWholesale, setExpectedProfitSuperWholesale] =
    useState<number>(0);

  const [tableColumns, setTableColumns] = useState<string[]>(FIXED_COLUMNS);

  const [tableEntries, setTableEntries] = useState<VariantTableEntry[]>([]);

  const [laborPrices, setLaborPrices] = useState<
    UniqueListItem<ProductionOrderLaborPrice>[]
  >([]);

  const [productionOrderSizes, setProductionOrderSizes] = useState<
    ProductionOrderSize[]
  >([]);

  const [inputMaterials, setInputMaterials] = useState<
    UniqueListItem<ProductionOrderInputMaterial>[]
  >([]);

  const onProductChange = (newProduct: Product | null) => {
    setEditingOrder((editingItem) => {
      return {
        ...editingItem,
        productId: newProduct?.id,
        productCodeIdentifier: newProduct?.codeIdentifier,
        productName: newProduct?.name,
        productReferencePrice: newProduct?.price,
        productReferencePriceWholesale: newProduct?.wholesalePrice,
        productReferencePriceSuperWholesale: newProduct?.superWholesalePrice,
      };
    });
    setProduct(newProduct ? newProduct : undefined);
  };

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingOrder((editingItem) => {
      let amount: number | undefined = undefined;

      if (!isNaN(parseInt(e.target.value))) {
        amount = parseInt(e.target.value);
      }

      return { ...editingItem, amountProduced: amount };
    });
  };

  const onAddInputMaterialClick = () => {
    setInputMaterials((inputMaterials) => {
      const newInputMaterials = [
        ...inputMaterials,
        newUniqueListItem<ProductionOrderInputMaterial>({}),
      ];
      return newInputMaterials;
    });
  };

  const onInputMaterialEntryChange = (
    inputMaterial: UniqueListItem<ProductionOrderInputMaterial>
  ) => {
    setInputMaterials((inputMaterials) => {
      const newInputMaterials = [...inputMaterials];
      const index = newInputMaterials.findIndex((item) => {
        return item.uuid === inputMaterial.uuid;
      });

      if (index === -1) {
        return newInputMaterials;
      }

      newInputMaterials[index] = inputMaterial;

      return newInputMaterials;
    });
  };

  const onInputMaterialEntryDelete = (
    entry: UniqueListItem<ProductionOrderInputMaterial>
  ) => {
    setInputMaterials((inputMaterials) => {
      const newInputMaterials = [...inputMaterials];

      const index = newInputMaterials.findIndex((item) => {
        return item.uuid === entry.uuid;
      });

      if (index === -1) {
        return newInputMaterials;
      }

      newInputMaterials.splice(index, 1);

      return newInputMaterials;
    });
  };

  const onLaborPriceEntryChange = (
    laborPrice: UniqueListItem<ProductionOrderLaborPrice>
  ) => {
    setLaborPrices((laborPrices) => {
      const newLaborPrices = [...laborPrices];
      const index = newLaborPrices.findIndex((item) => {
        return item.uuid === laborPrice.uuid;
      });

      if (index === -1) {
        return newLaborPrices;
      }

      newLaborPrices[index] = laborPrice;

      return newLaborPrices;
    });
  };

  const calculateLaborCost = (
    laborPricesEnrties: ProductionOrderLaborPrice[]
  ) => {
    const totalLaborPriceCalculation: number = laborPricesEnrties.reduce(
      (sum: number, entry: ProductionOrderLaborPrice) => {
        if (entry.price !== undefined) {
          return sum + entry.price;
        }
        return sum;
      },
      0
    );
    return totalLaborPriceCalculation;
  };

  const calculateMaterialCost = (
    materialEntries: ProductionOrderInputMaterial[]
  ) => {
    const totalMaterialPriceCalculation: number = materialEntries.reduce(
      (sum: number, entry: ProductionOrderInputMaterial) => {
        if (entry.price !== undefined) {
          return sum + entry.price;
        }
        return sum;
      },
      0
    );

    return totalMaterialPriceCalculation;
  };

  const onSave = async () => {
    setSubmitting(true);

    const totalLaborPrice = 0;
    const totalMaterialPrice = 0;
    let toSendOrder: ProductionOrder = {
      ...editingOrder,
      companyId: company.id,
      orderStatus: "pending",
      expectedProfit: expectedProfit,
      expectedProfitSuperWholesale: expectedProfitSuperWholesale,
      expectedProfitWholesale: expectedProfitWholesale,
      totalCostPrice: totalCostPrice,
      totalLaborPrice: totalLaborPrice,
      totalMaterialPrice: totalMaterialPrice,
      productionMaterials: uniqueListToListItems(inputMaterials),
      productionLaborPrices: uniqueListToListItems(laborPrices),
    };

    let requestPromise: Promise<ItemRequestStatus<ProductionOrder>>;
    if (editingOrder.id === undefined) {
      requestPromise = createItem<ProductionOrder>(
        "/production/create/",
        toSendOrder
      );
    } else {
      requestPromise = updateItem<ProductionOrder>(
        `/production/${toSendOrder.id}/`,
        toSendOrder
      );
    }

    const requestStatus = await requestPromise;

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.errors !== undefined) {
        setErrors(requestStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (requestStatus.detail !== undefined) {
        message = requestStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const fetchProduct = async (productId: number) => {
    const productStatus = await getItem<Product>(
      `/clothing_products/${productId}/`
    );
    if (productStatus.status === SUCCESS) {
      if (productStatus.data !== undefined) {
        setProduct(productStatus.data);
      }
    }
  };

  const getLaborPrices = async () => {
    if (
      initialProductionOrder === undefined ||
      initialProductionOrder.id === undefined
    ) {
      setLaborPrices(await generateLaborPrices());
      return [];
    }
    const productionOrderIdStr = initialProductionOrder.id.toString();
    if (productionOrderIdStr === undefined) {
      setLaborPrices(await generateLaborPrices());
      return [];
    }
    const limit = 100;
    const offset = 0;
    const laborPricesStatus = await getList<ProductionOrderLaborPrice>(
      `/production/${productionOrderIdStr}/labor_prices/`,
      limit,
      offset
    );
    if (laborPricesStatus.status === SUCCESS) {
      if (laborPricesStatus.data !== undefined) {
        setLaborPrices(listToUniqueListItems(laborPricesStatus.data.items));
      }
    } else {
      setLaborPrices(await generateLaborPrices());
    }
  };

  const generateLaborPrices = async () => {
    const limit = 100;
    const offset = 0;
    const stagesStatus = await getList<ProductionStage>(
      `/production_stages/`,
      limit,
      offset
    );
    if (stagesStatus.status === SUCCESS) {
      if (stagesStatus.data !== undefined) {
        const items = stagesStatus.data.items.map((item, ix) => {
          const newItem = newProductionOrderLaborPrice();
          newItem.productionStage = item;
          newItem.stageId = item.id;
          newItem.isActive = false;
          newItem.subject = item.name;
          newItem.price = 0;
          return newItem;
        });
        return listToUniqueListItems(items);
      }
    }
    return [];
  };

  const getInputMaterials = async () => {
    if (
      initialProductionOrder === undefined ||
      initialProductionOrder.id === undefined
    ) {
      setInputMaterials([]);
      return [];
    }
    const productionOrderIdStr = initialProductionOrder.id.toString();
    if (productionOrderIdStr === undefined) {
      setInputMaterials([]);
      return [];
    }
    const limit = 100;
    const offset = 0;
    const laborPricesStatus = await getList<ProductionOrderInputMaterial>(
      `/production/${productionOrderIdStr}/input_materials/`,
      limit,
      offset
    );
    if (laborPricesStatus.status === SUCCESS) {
      if (laborPricesStatus.data !== undefined) {
        setInputMaterials(listToUniqueListItems(laborPricesStatus.data.items));
      }
    } else {
      setInputMaterials([]);
    }
  };

  const getOrderSizes = async () => {
    if (
      initialProductionOrder === undefined ||
      initialProductionOrder.id === undefined
    ) {
      setProductionOrderSizes([]);
      return [];
    }
    const productionOrderIdStr = initialProductionOrder.id.toString();
    if (productionOrderIdStr === undefined) {
      setProductionOrderSizes([]);
      return [];
    }
    const limit = 100;
    const offset = 0;
    const orderSizesStatus = await getList<ProductionOrderSize>(
      `/production/${productionOrderIdStr}/order_sizes/`,
      limit,
      offset
    );
    if (orderSizesStatus.status === SUCCESS) {
      if (orderSizesStatus.data !== undefined) {
        setProductionOrderSizes(orderSizesStatus.data.items);
      }
    } else {
      setProductionOrderSizes([]);
    }
  };

  const generateTableHeaders = (entries: VariantTableEntry[]) => {
    const uniqueSizeNames: string[] = [];

    entries.forEach((item) => {
      if (item.sizes) {
        item.sizes
          .map((size) => size?.sizeName)
          .filter(
            (sizeName) =>
              sizeName !== undefined && !uniqueSizeNames.includes(sizeName)
          )
          .forEach((sizeName) => {
            if (sizeName) {
              uniqueSizeNames.push(sizeName);
            }
          });
      }
    });

    uniqueSizeNames.sort((a, b) => {
      const aFloat = parseFloat(a);
      const bFloat = parseFloat(b);
      return aFloat - bFloat;
    });

    setTableColumns([...FIXED_COLUMNS, ...uniqueSizeNames]);
  };

  const getOrderItems = async () => {
    if (
      initialProductionOrder === undefined ||
      initialProductionOrder.id === undefined
    ) {
      setTableEntries([]);
      return;
    }
    const productionOrderIdStr = initialProductionOrder.id.toString();
    if (productionOrderIdStr === undefined) {
      setTableEntries([]);
      return;
    }
    const limit = 5000;
    const offset = 0;
    const orderSizesStatus = await getList<ProductionOrderItem>(
      `/production/${productionOrderIdStr}/order_items/`,
      limit,
      offset
    );
    if (orderSizesStatus.status === SUCCESS) {
      if (orderSizesStatus.data !== undefined) {
        const formattedTableEntries = productionOrderItemAPIToTable(
          orderSizesStatus.data.items
        );

        setTableEntries(formattedTableEntries);
        generateTableHeaders(formattedTableEntries);
      } else {
        setTableEntries([]);
        return;
      }
    } else {
      setTableEntries([]);
      return;
    }
  };

  const inputMaterialErrors = getFieldErrors(
    "productionMaterials",
    errors
  ) as Errors[];
  const laborPricesErrors = getFieldErrors("laborPrices", errors) as Errors[];

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingOrder(newProductionOrder());
    setProduct(undefined);
    setEditable(true);
    setInputMaterials([]);
    setLaborPrices([]);
    setProductionOrderSizes([]);
    setTableEntries([]);
    setTableColumns(FIXED_COLUMNS);
  };

  useEffect(() => {
    const priceitems = calculateMaterialCost(
      uniqueListToListItems(inputMaterials)
    );
    const priceLabor = calculateLaborCost(uniqueListToListItems(laborPrices));
    const priceSum = priceLabor + priceitems;
    const productPrice = product ? product.price : undefined;
    const priceExpectedProfit = productPrice
      ? productPrice - priceSum
      : undefined;
    setTotalCostPrice(priceitems + priceLabor);
    setExpectedProfit(priceExpectedProfit ? priceExpectedProfit : 0);

    const productPriceWholesale = product ? product.wholesalePrice : undefined;
    const priceExpectedProfitWholesale = productPriceWholesale
      ? productPriceWholesale - priceSum
      : undefined;
    setExpectedProfitWholesale(
      priceExpectedProfitWholesale ? priceExpectedProfitWholesale : 0
    );

    const productPriceSuperWholesale = product
      ? product.superWholesalePrice
      : undefined;
    const priceExpectedProfitSuperWholesale = productPriceSuperWholesale
      ? productPriceSuperWholesale - priceSum
      : undefined;
    setExpectedProfitSuperWholesale(
      priceExpectedProfitSuperWholesale ? priceExpectedProfitSuperWholesale : 0
    );

    const newEditingOrderValue = {
      ...editingOrder,
      totalLaborPrice: priceLabor,
      totalMaterialPrice: priceitems,
      totalCostPrice: priceSum,
      expectedProfit: priceExpectedProfit,
      expectedProfitWholesale: priceExpectedProfitWholesale,
      expectedProfitSuperWholesale: priceExpectedProfitSuperWholesale,
    };
    setEditingOrder(newEditingOrderValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laborPrices, inputMaterials, product]);

  useEffect(() => {
    setLoading(true);
    setEditingOrder(
      initialProductionOrder ? initialProductionOrder : newProductionOrder()
    );
    if (initialProductionOrder?.productId !== undefined) {
      fetchProduct(initialProductionOrder?.productId);
    } else {
      setProduct(undefined);
    }
    getInputMaterials();
    getLaborPrices();
    getOrderSizes();
    setEditable(
      orderIsEditable(
        initialProductionOrder ? initialProductionOrder : newProductionOrder()
      )
    );
    setLoading(false);
    getOrderItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProductionOrder]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  const editableMsg = editable ? (
    <></>
  ) : (
    <small className="mr-2 text-danger">
      La producción ya ha iniciado. Ya no se pueden hacer cambios
    </small>
  );
  const differencelost = editingOrder.amountLost ? editingOrder.amountLost : 0;
  return (
    <>
      <fieldset disabled={submitting || !editable}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Modelo de prenda:</CLabel>
            </CCol>
            <CCol>
              {editable ? (
                <>
                  <ProductSelect
                    value={product ? product : null}
                    onChange={onProductChange}
                  ></ProductSelect>
                  <FieldErrors
                    errors={getFieldErrors("productId", errors) as string[]}
                  ></FieldErrors>
                </>
              ) : (
                <p>{`${editingOrder.productCodeIdentifier} - ${editingOrder.productName}`}</p>
              )}
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Cantidad estimada:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="number"
                placeholder="Cantidad"
                value={
                  editingOrder.amountProduced !== undefined
                    ? editingOrder.amountProduced
                    : 0
                }
                onChange={onAmountChange}
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("amountProduced", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        {editingOrder.orderStatus === "finished" ? (
          <>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Cantidad defectuosa:</CLabel>
                </CCol>
                <CCol md={3}>
                  <CInput
                    type="number"
                    value={
                      editingOrder.amountLost ? editingOrder.amountLost : 0
                    }
                    disabled={true}
                  ></CInput>
                </CCol>
                <CCol md={1}></CCol>
                <CCol md={3}>
                  <CLabel>Cantidad resultante:</CLabel>
                </CCol>
                <CCol md={3}>
                  <CInput
                    type="number"
                    value={editingOrder.amountProduced! - differencelost}
                    disabled={true}
                  ></CInput>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Observación final:</CLabel>
                </CCol>
                <CCol md={10}>
                  <CTextarea
                    placeholder="-"
                    value={editingOrder.finalObs}
                    disabled={true}
                  ></CTextarea>
                </CCol>
              </CRow>
            </CFormGroup>
          </>
        ) : (
          <></>
        )}
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Costo Unitario:</CLabel>
            </CCol>
            <CCol md={2}>
              <CurrencyField
                currency={PYG}
                disabled={true}
                value={
                  editingOrder.totalCostPrice !== undefined
                    ? editingOrder.totalCostPrice
                    : 0
                }
              ></CurrencyField>
            </CCol>
            <CCol md={2}>
              <CLabel>Precio Unitario:</CLabel>
            </CCol>
            <CCol md={2}>
              <CurrencyField
                currency={PYG}
                disabled={true}
                value={product?.price !== undefined ? product?.price : 0}
              ></CurrencyField>
            </CCol>
            <CCol md={2}>
              <CLabel>Ganancia unitaria:</CLabel>
            </CCol>
            <CCol md={2}>
              <CurrencyField
                currency={PYG}
                disabled={true}
                value={
                  editingOrder.expectedProfit !== undefined
                    ? editingOrder.expectedProfit
                    : 0
                }
              ></CurrencyField>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Promedio de Tela:</CLabel>
            </CCol>
            <CCol md={2}>
              <CInput
                type="number"
                placeholder="-"
                value={
                  product?.averageFabricUsage !== undefined
                    ? product.averageFabricUsage
                    : undefined
                }
                disabled
              ></CInput>
            </CCol>
            <CCol md={2}>
              <CLabel>Precio Mayorista:</CLabel>
            </CCol>
            <CCol md={2}>
              <CurrencyField
                currency={PYG}
                disabled={true}
                value={
                  product?.wholesalePrice !== undefined
                    ? product?.wholesalePrice
                    : 0
                }
              ></CurrencyField>
            </CCol>
            <CCol md={2}>
              <CLabel>Ganancia Mayorista:</CLabel>
            </CCol>
            <CCol md={2}>
              <CurrencyField
                currency={PYG}
                disabled={true}
                value={
                  editingOrder.expectedProfitWholesale !== undefined
                    ? editingOrder.expectedProfitWholesale
                    : 0
                }
              ></CurrencyField>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2} className={"offset-md-4"}>
              <CLabel>Precio Supermayorista:</CLabel>
            </CCol>
            <CCol md={2}>
              <CurrencyField
                currency={PYG}
                disabled={true}
                value={
                  product?.superWholesalePrice !== undefined
                    ? product?.superWholesalePrice
                    : 0
                }
              ></CurrencyField>
            </CCol>
            <CCol md={2}>
              <CLabel>Ganancia Supermayorista:</CLabel>
            </CCol>
            <CCol md={2}>
              <CurrencyField
                currency={PYG}
                disabled={true}
                value={
                  editingOrder.expectedProfitSuperWholesale !== undefined
                    ? editingOrder.expectedProfitSuperWholesale
                    : 0
                }
              ></CurrencyField>
            </CCol>
          </CRow>
        </CFormGroup>
        <CRow>
          <CCol>
            <CTabs activeTab="laborPrices">
              <CNav variant="tabs">
                <CNavItem>
                  <CNavLink data-tab="laborPrices">MANO DE OBRA</CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink data-tab="inputMaterials">MATERIAS PRIMAS</CNavLink>
                </CNavItem>
                {editingOrder.currentStageIsConfection ? (
                  <CNavItem>
                    <CNavLink data-tab="sizes">TALLES CORTADOS</CNavLink>
                  </CNavItem>
                ) : (
                  <></>
                )}
                {tableEntries.length > 0 ? (
                  <CNavItem>
                    <CNavLink data-tab="items">
                      COLORES Y TALLES PRODUCIDOS
                    </CNavLink>
                  </CNavItem>
                ) : (
                  <></>
                )}
              </CNav>
              <CTabContent>
                <CTabPane data-tab="laborPrices">
                  {laborPrices.map((laborPrice, ix) => {
                    return (
                      <CRow key={laborPrice.uuid}>
                        <CCol>
                          <ProductionLaborPriceForm
                            value={laborPrice.item}
                            initialStage={laborPrice.item.productionStage}
                            initialPrice={laborPrice.item.price}
                            onChange={(item) =>
                              onLaborPriceEntryChange({
                                uuid: laborPrice.uuid,
                                item: item,
                              })
                            }
                            errors={
                              ix < laborPricesErrors.length
                                ? laborPricesErrors[ix]
                                : {}
                            }
                          />
                        </CCol>
                      </CRow>
                    );
                  })}
                </CTabPane>
                <CTabPane data-tab="inputMaterials">
                  {inputMaterials.map((inputMaterial, ix) => {
                    return (
                      <CRow key={inputMaterial.uuid}>
                        <CCol>
                          <ProductionInputMaterialForm
                            value={inputMaterial.item}
                            onDelete={() =>
                              onInputMaterialEntryDelete(inputMaterial)
                            }
                            initialInputMaterial={
                              inputMaterial.item.inputMaterial
                            }
                            initialAmount={inputMaterial.item.amount}
                            initialBatch={
                              inputMaterial.item.inventoryInputBatch
                            }
                            disabled={!editable}
                            onChange={(item) =>
                              onInputMaterialEntryChange({
                                uuid: inputMaterial.uuid,
                                item: item,
                              })
                            }
                            errors={
                              ix < inputMaterialErrors.length
                                ? inputMaterialErrors[ix]
                                : {}
                            }
                          />
                        </CCol>
                      </CRow>
                    );
                  })}
                  {inputMaterials.length === 0 ? (
                    <CRow>
                      <CCol>
                        Agregue nuevas materias primas con el boton + de la
                        derecha
                      </CCol>
                    </CRow>
                  ) : (
                    <></>
                  )}
                  <CRow className="mb-1">
                    <CCol>
                      <CButton
                        className="btn btn-primary float-right"
                        onClick={onAddInputMaterialClick}
                        disabled={!editable}
                      >
                        <i className="fa fa-plus" />
                      </CButton>
                    </CCol>
                  </CRow>
                </CTabPane>
                {editingOrder.currentStageIsConfection ? (
                  <CTabPane data-tab="sizes">
                    {productionOrderSizes.map((orderSize, ix) => {
                      return (
                        <CRow key={ix}>
                          <CCol md={1} className={"offset-md-1"}>
                            <CLabel>Talle:</CLabel>
                          </CCol>
                          <CCol md={2}>
                            <p>{orderSize.sizeName}</p>
                          </CCol>
                          <CCol md={1}>
                            <CLabel>Cantidad:</CLabel>
                          </CCol>
                          <CCol md={2}>
                            <p>{orderSize.amount}</p>
                          </CCol>
                        </CRow>
                      );
                    })}
                  </CTabPane>
                ) : (
                  <></>
                )}
                {tableEntries.length > 0 ? (
                  <CTabPane data-tab="items">
                    <CRow>
                      <CCol md={12}>
                        <div className="table-responsive">
                          <table className="table table-striped table-bordered table-fixed">
                            <thead>
                              <tr>
                                {tableColumns.map((title, ix) => {
                                  return (
                                    <th
                                      className="text-center"
                                      key={ix}
                                      style={{
                                        verticalAlign: "middle",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <div className="d-inline">{title}</div>
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {tableEntries.map((tableEntry, ix) => {
                                return (
                                  <tr
                                    style={{
                                      verticalAlign: "middle",
                                      overflow: "hidden",
                                    }}
                                    key={ix}
                                  >
                                    <td
                                      className={"text-center"}
                                      style={{ padding: 0, width: "120px" }}
                                    >
                                      <p>{tableEntry.colorName}</p>
                                    </td>
                                    {tableColumns.map((column, ix) => {
                                      if (column === "Color") {
                                        return <></>;
                                      }
                                      const matchSize = tableEntry.sizes?.find(
                                        (item) => item.sizeName === column
                                      );
                                      const disabled = matchSize === undefined;
                                      let showValue = "";
                                      if (matchSize !== undefined) {
                                        if (
                                          matchSize.finishedAmount !== undefined
                                        ) {
                                          showValue = `${matchSize.finishedAmount}/${matchSize.totalAmount}`;
                                        } else {
                                          showValue = `${matchSize.totalAmount}`;
                                        }
                                      }
                                      return (
                                        <td
                                          key={ix}
                                          style={{ padding: 0, width: "70px" }}
                                        >
                                          <CInput
                                            type="text"
                                            value={
                                              disabled
                                                ? ""
                                                : matchSize
                                                ? emptyValueOnUndefined(
                                                    showValue
                                                  )
                                                : ""
                                            }
                                            disabled={true}
                                          ></CInput>
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                              {tableEntries.length === 0 ? (
                                <tr>
                                  <td colSpan={tableColumns.length}>
                                    No hay tamaños disponibles para esta prenda.
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                          <br />
                          <br />
                        </div>
                      </CCol>
                    </CRow>
                  </CTabPane>
                ) : (
                  <></>
                )}
              </CTabContent>
            </CTabs>
          </CCol>
        </CRow>
        <CFormGroup className="float-right">
          {editableMsg}
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default ProductionForm;
