import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import Color from "../../models/color";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import ColorDeleteModal from "./ColorDeleteModal";
import ColorUpdateModal from "./ColorUpdateModal";
import {
  COLORS_CREATE,
  COLORS_DESTROY,
  COLORS_UPDATE,
} from "../../auth/permissions";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";

const ITEMS_PER_PAGE = 10;

const ColorList = () => {
  const fields = [
    {
      key: "color",
      _classes: ["text-center"],
      label: "Nombre",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showColorEditModal, setShowColorEditModal] = useState(false);
  const [showColorDeleteModal, setShowColorDeleteModal] = useState(false);

  const [color, setColor] = useState<Color | undefined>(undefined);
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [colors, setColors] = useState<Color[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    return map;
  };

  const fetchColors = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const colorsStatus = await getList<Color>(
      "/colors/",
      limit,
      offset,
      urlParams
    );
    if (colorsStatus.status === SUCCESS) {
      if (colorsStatus.data !== undefined) {
        const count = colorsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setColors(colorsStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = colorsStatus.detail
        ? colorsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setShowColorEditModal(true);
  };

  const onUpdateClick = (color: Color) => {
    setColor(color);
    setShowColorEditModal(true);
  };

  const onDeleteClick = (color: Color) => {
    setColor(color);
    setShowColorDeleteModal(true);
  };

  const onColorEditCancel = () => {
    setColor(undefined);
    setShowColorEditModal(false);
  };

  const onColorEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowColorEditModal(false);
    setColors([]);
    setLoading(true);
    fetchColors();
  };

  const onColorDeleteCancel = () => {
    setColor(undefined);
    setShowColorDeleteModal(false);
  };

  const onColorDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowColorDeleteModal(false);
    setColors([]);
    setLoading(true);
    fetchColors();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("search", search);
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("colors")) {
        return;
      }
      fetchColors();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Colores</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent permissionName={COLORS_CREATE}>
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={colors}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    actions: (item: Color) => {
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={COLORS_UPDATE}
                        >
                          <CButton
                            className="text-white"
                            color="warning"
                            onClick={() => {
                              onUpdateClick(item);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );
                      const deleteButton = (
                        <PermissionRequiredComponent
                          permissionName={COLORS_DESTROY}
                        >
                          <CButton
                            className="text-white"
                            color="danger"
                            onClick={() => {
                              onDeleteClick(item);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {editButton}
                            {deleteButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <ColorUpdateModal
        show={showColorEditModal}
        onCancel={onColorEditCancel}
        onSuccess={onColorEditSuccess}
        color={color}
      />
      <ColorDeleteModal
        show={showColorDeleteModal}
        onCancel={onColorDeleteCancel}
        onSuccess={onColorDeleteSuccess}
        color={color}
      />
    </>
  );
};

export default ColorList;
