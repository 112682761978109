import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem, updateItem, ItemRequestStatus } from "../../api/generics";
import Supplier, { newSupplier } from "../../models/supplier";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

interface UserFormProps {
  initialSupplier?: Supplier;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const SupplierForm: React.FC<UserFormProps> = ({
  initialSupplier,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingSupplier, setEditingSupplier] = useState<Supplier>(
    initialSupplier ? initialSupplier : newSupplier()
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingSupplier({
      ...editingSupplier,
      name: e.target.value,
    });
  };

  const onLegalNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingSupplier({
      ...editingSupplier,
      legalName: e.target.value,
    });
  };

  const onRucChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingSupplier({
      ...editingSupplier,
      ruc: e.target.value,
    });
  };

  const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingSupplier({
      ...editingSupplier,
      phone: e.target.value,
    });
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingSupplier({
      ...editingSupplier,
      email: e.target.value,
    });
  };

  const onContactNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingSupplier({
      ...editingSupplier,
      contactName: e.target.value,
    });
  };

  const onContactPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingSupplier({
      ...editingSupplier,
      contactPhone: e.target.value,
    });
  };

  const onContactEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingSupplier({
      ...editingSupplier,
      contactEmail: e.target.value,
    });
  };

  const onSave = async () => {
    setSubmitting(true);
    let toSendSupplier = {
      ...editingSupplier,
      companyId: company.id,
    };
    let requestPromise: Promise<ItemRequestStatus<Supplier>>;
    if (editingSupplier.id === undefined) {
      requestPromise = createItem<Supplier>(
        "/suppliers/create/",
        toSendSupplier
      );
    } else {
      requestPromise = updateItem<Supplier>(
        `/suppliers/${toSendSupplier.id}/`,
        toSendSupplier
      );
    }

    const requestStatus = await requestPromise;

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.errors !== undefined) {
        setErrors(requestStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (requestStatus.detail !== undefined) {
        message = requestStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingSupplier(newSupplier());
  };

  useEffect(() => {
    setEditingSupplier(initialSupplier ? initialSupplier : newSupplier());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSupplier]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingSupplier.name)}
                onChange={onNameChange}
                placeholder="Nombre"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("name", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre legal:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingSupplier.legalName)}
                onChange={onLegalNameChange}
                placeholder="Nombre legal"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("legalName", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>RUC:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingSupplier.ruc)}
                onChange={onRucChange}
                placeholder="RUC"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("ruc", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Teléfono:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingSupplier.phone)}
                onChange={onPhoneChange}
                placeholder="Teléfono"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("phone", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Email:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="email"
                value={emptyValueOnUndefined(editingSupplier.email)}
                onChange={onEmailChange}
                placeholder="Email"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("email", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre de contacto:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingSupplier.contactName)}
                onChange={onContactNameChange}
                placeholder="Nombre de contacto"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("contactName", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Teléfono de contacto:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingSupplier.contactPhone)}
                onChange={onContactPhoneChange}
                placeholder="Teléfono de contacto"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("contactPhone", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Email de contacto:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="email"
                value={emptyValueOnUndefined(editingSupplier.contactEmail)}
                onChange={onContactEmailChange}
                placeholder="Email de contacto"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("contactEmail", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default SupplierForm;
