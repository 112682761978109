import DeliveryOrder from "./delivery-order";
import StoreSale from "./store-sale";

interface clientPaymentAccount {
  id?: number;
  totalSpent?: number;
  totalPaid?: number;
  totalPending?: number;
  discountsMade?: number;
}

export interface ClientPayment {
  id?: number;
  companyId?: number;
  clientId?: number;
  clientAccountId?: number;
  clientName?: string;
  clientRuc?: string;
  amountPaid?: number;
  discountInDebt?: number;
  date?: string;
  paymentType?: string;
  bankName?: string;
  checkNumber?: string;
  checkDate?: string;
  obs?: string;
  saleOrderId?: number;
  deliveryOrderId?: number;
  deliveryOrder?: DeliveryOrder;
  status?: string;
  orders?: DeliveryOrder[];
  markOrdersAsFinished?: boolean;
}

export interface PaymentRelatedOrder {
  id?: number;
  companyId?: number;
  paymentId?: number;
  orderId?: number;
  amountPaid?: number;
  discount?: number;
  totalPaymentAmount?: number;
  totalDebtDiscount?: number;
}

export const newClientPayment = (): ClientPayment => {
  return {};
};

interface Client {
  id?: number;
  companyId?: number;
  name?: string;
  ruc?: string;
  legalName?: string;
  documentNumber?: string;
  email?: string;
  phone?: string;
  address?: string;
  clientPaymentAccount?: clientPaymentAccount;
}

export const newClient = (): Client => {
  return {};
};

// Client types
export const PAYMENT_TYPE_CASH = "cash";
export const PAYMENT_TYPE_CASH_LABEL = "Efectivo";
export const PAYMENT_TYPE_CREDIT_CARD = "credit_card";
export const PAYMENT_TYPE_CREDIT_CARD_LABEL = "Tarjeta de crédito";
export const PAYMENT_TYPE_DEBIT_CARD = "debit_card";
export const PAYMENT_TYPE_DEBIT_CARD_LABEL = "Tarjeta de débito";
export const PAYMENT_TYPE_TRANSFER = "bank_transfer";
export const PAYMENT_TYPE_TRANSFER2 = "transfer";
export const PAYMENT_TYPE_TRANSFER_LABEL = "Transferencia bancaria";
export const PAYMENT_TYPE_QR = "qr_code";
export const PAYMENT_TYPE_QR2 = "qr_payment";
export const PAYMENT_TYPE_QR_LABEL = "Pago QR";
export const PAYMENT_TYPE_CHECK = "check";
export const PAYMENT_TYPE_CHECK_LABEL = "Cheque";

export const PAYMENT_TYPE_CHOICES: Map<string, string> = new Map([
  [PAYMENT_TYPE_CASH, PAYMENT_TYPE_CASH_LABEL],
  [PAYMENT_TYPE_CREDIT_CARD, PAYMENT_TYPE_CREDIT_CARD_LABEL],
  [PAYMENT_TYPE_DEBIT_CARD, PAYMENT_TYPE_DEBIT_CARD_LABEL],
  [PAYMENT_TYPE_TRANSFER, PAYMENT_TYPE_TRANSFER_LABEL],
  [PAYMENT_TYPE_QR, PAYMENT_TYPE_QR_LABEL],
  [PAYMENT_TYPE_CHECK, PAYMENT_TYPE_CHECK_LABEL],
]);

export const getPaymentTypeLabel = (
  clientPayment: ClientPayment | StoreSale
) => {
  const getLabel = (paymentType: string) => {
    switch (paymentType) {
      case PAYMENT_TYPE_CASH:
        return PAYMENT_TYPE_CASH_LABEL;
      case PAYMENT_TYPE_CREDIT_CARD:
        return PAYMENT_TYPE_CREDIT_CARD_LABEL;
      case PAYMENT_TYPE_DEBIT_CARD:
        return PAYMENT_TYPE_DEBIT_CARD_LABEL;
      case PAYMENT_TYPE_TRANSFER:
      case PAYMENT_TYPE_TRANSFER2:
        return PAYMENT_TYPE_TRANSFER_LABEL;
      case PAYMENT_TYPE_QR:
      case PAYMENT_TYPE_QR2:
        return PAYMENT_TYPE_QR_LABEL;
      case PAYMENT_TYPE_CHECK:
        return PAYMENT_TYPE_CHECK_LABEL;
      default:
        return "-";
    }
  };

  const label1 = getLabel(
    clientPayment.paymentType ? clientPayment.paymentType : ""
  );
  let label2 = "";

  if ("paymentType2" in clientPayment && clientPayment.paymentType2) {
    label2 = getLabel(clientPayment.paymentType2);
  }

  return label2 ? `${label1}, ${label2}` : label1;
};

export interface StatusData {
  class: string;
  name: string;
}

interface StatusesMap {
  [index: string]: StatusData;
}

export const PAYMENTSTATUSES: StatusesMap = {
  paid: {
    class: "success",
    name: "Pagado",
  },
  reverted: {
    class: "danger",
    name: "Revertido",
  },
};

export const getPaymentStatusLabel = (key: string) => {
  return PAYMENTSTATUSES[key].name;
};

export const getPaymentStatusClass = (key: string) => {
  return PAYMENTSTATUSES[key].class;
};

export default Client;
