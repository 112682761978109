import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CLabel,
  CRow,
} from "@coreui/react";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import Errors, { getFieldErrors } from "../../models/errors";

import {
  UniqueListItem,
  newUniqueListItem,
  uniqueListToListItems,
} from "../../models/unique-list-item";
import InventoryTransferItemForm from "./BarcodeItemForm";

import {
  BarcodeData,
  BarcodeFormItem,
  convertToBarcodeData,
} from "../../utils/barcode-print";
import BarcodeConfirmModal from "./BarcodeConfirmModal";
import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";
//import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";

interface BarcodeFormProps {
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const FIXED_COLUMNS = ["Prenda", "Color", "Cant. Total"];

const BarcodeForm: React.FC<BarcodeFormProps> = ({
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);
  const [tableColumns, setTableColumns] = useState<string[]>(FIXED_COLUMNS);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [totalAmount, setTotalAmount] = useState<number>(0);
  //const [onlyNumericCode, setOnlyNumericCode] = useState(false);
  const [items, setItems] = useState<UniqueListItem<BarcodeFormItem>[]>([]);

  const onAddItemClick = useCallback(() => {
    const newItems = [...items, newUniqueListItem<BarcodeFormItem>({})];

    setItems(newItems);
    generateTableHeaders(newItems);
  }, [items]);

  const onItemEntryChange = useCallback(
    (formItem: UniqueListItem<BarcodeFormItem>) => {
      let newItems = [...items];
      const index = newItems.findIndex((item) => {
        return item.uuid === formItem.uuid;
      });

      if (index === -1) {
        setItems(newItems);
        return;
      }

      newItems[index] = formItem;

      setTotalAmount(calculateTotalAmount(newItems));
      setItems(newItems);

      generateTableHeaders(newItems);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [items]
  );

  const onEntryDelete = useCallback(
    (entry: UniqueListItem<BarcodeFormItem>) => {
      let newItems = [...items];
      const index = newItems.findIndex((item) => {
        return item.uuid === entry.uuid;
      });

      if (index === -1) {
        setItems(newItems);
      }

      newItems.splice(index, 1);
      setTotalAmount(calculateTotalAmount(newItems));
      setItems(newItems);

      generateTableHeaders(newItems);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [items]
  );

  const generateTableHeaders = (
    transferItems: UniqueListItem<BarcodeFormItem>[]
  ) => {
    const uniqueSizeNames: string[] = [];

    transferItems.forEach((item) => {
      if (item.item.sizes) {
        item.item.sizes
          .map((size) => size?.sizeName)
          .filter(
            (sizeName) =>
              sizeName !== undefined && !uniqueSizeNames.includes(sizeName)
          )
          .forEach((sizeName) => {
            if (sizeName) {
              uniqueSizeNames.push(sizeName);
            }
          });
      }
    });

    uniqueSizeNames.sort((a, b) => {
      const aFloat = parseFloat(a);
      const bFloat = parseFloat(b);
      return aFloat - bFloat;
    });

    setTableColumns([...FIXED_COLUMNS, ...uniqueSizeNames, "Acciones"]);
  };

  const calculateTotalAmount = (
    tableItems: UniqueListItem<BarcodeFormItem>[]
  ): number => {
    return tableItems.reduce((totalAmount, item) => {
      if (item.item.sizes) {
        return item.item.sizes.reduce((subtotal, size) => {
          return subtotal + (size.amount || 0);
        }, totalAmount);
      }
      return totalAmount;
    }, 0);
  };

  const onSave = async () => {
    setSubmitting(true);

    const formattedItems = convertToBarcodeData(
      uniqueListToListItems(items),
      false
    );

    generateAndPrintPDF(formattedItems);

    clearForm();

    setSubmitting(false);
  };

  const clearForm = () => {
    setItems([]);
    //setOnlyNumericCode(false);
    generateTableHeaders([]);
  };

  const onConfirmSuccess = async () => {
    setSubmitting(true);
    await onSave();
    setShowConfirmModal(false);
    onSuccess();
  };

  const onConfirmCancel = () => {
    setShowConfirmModal(false);
  };

  const onConfirmClick = () => {
    setShowConfirmModal(true);
  };

  const generateAndPrintPDF = (data: BarcodeData[]) => {
    const customWidth = 56;
    const customHeight = 124;
    const barcodeHeight = 12;
    const margin = 5;
    const stickersPerPage = 4;
    const stickerHeight =
      (customHeight - (stickersPerPage + 1) * margin) / stickersPerPage;
    const offsetY = 2;
    const pdf = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: [customWidth, customHeight],
    });

    const truncateText = (text: string, maxWidth: number) => {
      let truncatedText = text;
      const ellipsis = "...";
      while (
        pdf.getTextWidth(truncatedText + ellipsis) > maxWidth &&
        truncatedText.length > 0
      ) {
        truncatedText = truncatedText.slice(0, -1);
      }

      return truncatedText + ellipsis;
    };

    let currentStickerCount = 0;
    let currentPage = 1;
    let y = margin;

    const companyName = "LUNGOR SRL";
    pdf.setFontSize(6);
    const textWidthTitle = pdf.getTextWidth(companyName);
    const textXTitle = (customWidth - textWidthTitle) / 2;
    pdf.setFontSize(16);
    data.forEach(({ code, quantity, label }) => {
      for (let i = 0; i < quantity; i++) {
        if (currentStickerCount === stickersPerPage) {
          pdf.addPage();
          currentStickerCount = 0;
          y = margin;
          currentPage = currentPage + 1;
        }

        const x = margin;

        const canvas = document.createElement("canvas");
        const isNumeric = /^\d+$/.test(code);

        const marginBarcode = isNumeric ? 50 : 5;

        JsBarcode(canvas, code, {
          format: "CODE128",
          displayValue: true,
          fontSize: 24,
          marginTop: 0,
          marginBottom: 0,
          marginLeft: marginBarcode,
          marginRight: marginBarcode,
        });

        const imgData = canvas.toDataURL("image/png");
        const textWidth = pdf.getTextWidth(code);
        const textX = x + (customWidth - 2 * margin - textWidth) / 2;

        pdf.addImage(
          imgData,
          "PNG",
          textX,
          y + offsetY + (stickerHeight - barcodeHeight) / 2,
          textWidth,
          barcodeHeight
        );
        pdf.setFontSize(6);

        pdf.text(
          companyName,
          textXTitle,
          y + offsetY + (stickerHeight - barcodeHeight) / 2 - 4
        );
        pdf.setFontSize(16);

        if (label) {
          pdf.setFontSize(6);
          const labelWidth = pdf.getTextWidth(label);
          if (labelWidth > textWidth) {
            const labelTruncated = truncateText(label, textWidth);
            pdf.text(
              labelTruncated,
              textX,
              y + offsetY + (stickerHeight - barcodeHeight) / 2 - 1
            );
          } else {
            pdf.text(
              label,
              textX,
              y + offsetY + (stickerHeight - barcodeHeight) / 2 - 1
            );
          }

          pdf.setFontSize(16);
        }
        y += stickerHeight + margin;
        currentStickerCount++;

        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
      }
    });

    pdf.autoPrint();
    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  /*const onlyNumericCodeChange = (onlyNumeric: boolean) => {
    setOnlyNumericCode(onlyNumeric);
  };*/

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  const inventoryTransferItemsErrors = getFieldErrors(
    "items",
    errors
  ) as Errors[];

  return (
    <>
      <fieldset disabled={submitting}>
        {/*<CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Código solo numérico:</CLabel>
            </CCol>
            <CCol md={2}>
              <CRow>
                <AnimatedCheckbox
                  value={onlyNumericCode}
                  onChange={onlyNumericCodeChange}
                ></AnimatedCheckbox>
              </CRow>
            </CCol>
          </CRow>
        </CFormGroup>*/}
        <CFormGroup>
          <CRow>
            <CCol md={12}>
              <h5>
                Agregue los productos y la cantidad de Codigos de barra que
                quiere generar para cada tipo
              </h5>
            </CCol>
          </CRow>
          <CRow>
            <CCol md={12}>
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-fixed">
                  <thead>
                    <tr>
                      {tableColumns.map((title, ix) => {
                        return (
                          <th
                            className="text-center"
                            key={ix}
                            style={{
                              verticalAlign: "middle",
                              overflow: "hidden",
                            }}
                          >
                            <div className="d-inline">{title}</div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((inventoryTransferFormItem, ix) => {
                      return (
                        <InventoryTransferItemForm
                          disabled={submitting}
                          key={inventoryTransferFormItem.uuid}
                          value={inventoryTransferFormItem.item}
                          sizesColumns={tableColumns}
                          initialValue={inventoryTransferFormItem.item}
                          onDelete={() =>
                            onEntryDelete(inventoryTransferFormItem)
                          }
                          onChange={(item) =>
                            onItemEntryChange({
                              uuid: inventoryTransferFormItem.uuid,
                              item: item,
                            })
                          }
                          errors={
                            ix < inventoryTransferItemsErrors.length
                              ? inventoryTransferItemsErrors[ix]
                              : {}
                          }
                        />
                      );
                    })}
                    {items.length === 0 ? (
                      <tr>
                        <td colSpan={tableColumns.length}>
                          Agregue nuevas entradas con el boton + de la derecha
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                <br />
                <br />
              </div>
            </CCol>
          </CRow>
          <CRow className="mb-1">
            <CCol>
              <CButton
                className="btn btn-primary float-right"
                onClick={onAddItemClick}
                disabled={submitting}
              >
                <i className="fa fa-plus" />
              </CButton>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            {/*<CButton type="button" color="secondary" onClick={onClose}>
              Atras
                  </CButton>*/}
            <CButton type="submit" color="primary" onClick={onConfirmClick}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Cargando..." : "Imprimir"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
      <BarcodeConfirmModal
        show={showConfirmModal}
        totalAmount={totalAmount}
        onCancel={onConfirmCancel}
        onSuccess={onConfirmSuccess}
      />
    </>
  );
};

export default BarcodeForm;
