import ProductHistoryList from "../components/product-history/ProductHistoryList";

const ProductHistoryPage = () => {
  return (
    <>
      <ProductHistoryList />
    </>
  );
};

export default ProductHistoryPage;
