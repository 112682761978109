import SaleOrderFormView from "../components/sale-orders/SaleOrderFormView";

const SaleOrderCrudPage = () => {
  return (
    <>
      <SaleOrderFormView />
    </>
  );
};

export default SaleOrderCrudPage;
