import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Variant from "../../models/variant";

const RECORS_PER_SEARCH = 50;

type VariantSelectProps = {
  value: Variant | null;
  disabled?: boolean;
  onChange: (_: Variant | null) => void;
};

const VariantSelect: React.FC<VariantSelectProps> = ({
  value,
  disabled,
  onChange,
}) => {
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );

  const loadOptions = async (
    s: string,
    currentOpts: readonly (Variant | { options: Variant[]; label?: string })[]
  ): Promise<
    Response<Variant, { options: Variant[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();
    additionalParams.set("company_id", companyId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    const dataPaginated = await getList<Variant>(
      "/product_variants/with_stock_factory/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: Variant[] = [];
    let count = 0;

    if (dataPaginated.data !== undefined) {
      for (const item of dataPaginated.data.items) {
        const newOpt = { ...item };
        delete newOpt.options;
        newOpts.push(newOpt);
      }
      count = dataPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };

  return (
    <AsyncPaginateBootstrap
      placeholder={"Prenda"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadOptions}
      getOptionLabel={(option: Variant) =>
        `${option.productName} - ${option.name} - Cod barras:${
          option.barcode2
        } Disponible:${option.availableAmount ? option.availableAmount : 0}`
      }
      getOptionValue={(option: Variant) => `${option.id}`}
      value={value}
      isDisabled={disabled}
    />
  );
};

export default VariantSelect;
