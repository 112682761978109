export const AVAILABLE_MONTHS: Map<number, string> = new Map([
  [1, "Enero"],
  [2, "Febrero"],
  [3, "Marzo"],
  [4, "Abril"],
  [5, "Mayo"],
  [6, "Junio"],
  [7, "Julio"],
  [8, "Agosto"],
  [9, "Septiembre"],
  [10, "Octubre"],
  [11, "Noviembre"],
  [12, "Diciembre"],
]);

export const formatDate = (
  date: Date,
  format: string = "YYYY-MM-DD"
): string => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const dayStr = day < 10 ? `0${day}` : `${day}`;
  const monthStr = month < 10 ? `0${month}` : `${month}`;

  if (format === "DD/MM/YYYY") {
    return `${dayStr}/${monthStr}/${year}`;
  }

  return `${year}-${monthStr}-${dayStr}`;
};

export const DATEDDMMYYY = "DD/MM/YYYY";

export const formatUTCDateTime = (date: Date): string => {
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = date.getUTCMonth().toString().padStart(2, "0");
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const formatDateTime = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const getYearsAvailableFromYear = (startingYear: number) => {
  let years: number[] = [];

  const currentYear = new Date().getFullYear();

  for (let year = startingYear; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
};

export const changeTZToUtc = (date: Date): Date => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
};
