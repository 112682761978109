import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import StoreRequestForm from "./StoreRequestForm";
import StoreRequest, { newStoreRequest } from "../../models/store-requests";

interface StoreRequestUpdateModalProps {
  show: boolean;
  storeRequest?: StoreRequest;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const StoreRequestUpdateModal: React.FC<StoreRequestUpdateModalProps> = ({
  show,
  storeRequest,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialStoreRequest, setInitialStoreRequest] = useState<StoreRequest>(
    storeRequest ? storeRequest : newStoreRequest()
  );

  const modalTitle = storeRequest
    ? `Editar pedido: ${storeRequest.variantName}`
    : `Crear pedido nuevo`;

  const onClose = () => {
    setInitialErrors({});
    setInitialStoreRequest(newStoreRequest());
    onCancel();
  };

  useEffect(() => {
    setInitialStoreRequest(storeRequest ? storeRequest : newStoreRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeRequest]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <StoreRequestForm
            initialStoreRequest={initialStoreRequest}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default StoreRequestUpdateModal;
