import { useEffect, useState } from "react";

import Product from "../../models/product";
import { getItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";

interface ProductAsyncImageProps {
  product: Product;
}

const PLACEHOLDER_URL = "/image_not_found.jpg";
const ProductAsyncImage: React.FC<ProductAsyncImageProps> = ({ product }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  const loadImage = async (product: Product) => {
    const presignedUrlState = await getItem<{ url: string }>(
      `/clothing_products/${product.id}/image_url/`
    );

    if (
      presignedUrlState.status !== SUCCESS ||
      presignedUrlState.data === undefined
    ) {
      setLoading(false);
      return;
    }
    setImageUrl(presignedUrlState.data.url);
    setLoading(false);
  };

  useEffect(() => {
    loadImage(product);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || imageUrl === undefined) {
    return (
      <img
        src={PLACEHOLDER_URL}
        alt="logo"
        style={{ maxHeight: "100px", width: "auto" }}
      />
    );
  }

  return (
    <img
      src={imageUrl}
      alt="logo"
      style={{ maxHeight: "100px", width: "auto" }}
    />
  );
};

export default ProductAsyncImage;
