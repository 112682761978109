interface User {
  id: number;
  username?: string;
  email?: string;
  firstName?: string;
  lastName: string;
  password1?: string;
  password2?: string;
  isSuperUser?: boolean;
  permissions?: string[];
}

export const newUser = (): User => {
  return {
    id: 0,
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    password1: "",
    password2: "",
    isSuperUser: undefined,
    permissions: [],
  };
};

export default User;
