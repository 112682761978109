import {
  CBadge,
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { updateItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import SaleOrder from "../../models/sale-order";

interface SaleOrderMarkAsReadyModalProps {
  show: boolean;
  saleOrder?: SaleOrder;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const SaleOrderMarkAsReadyModal: React.FC<SaleOrderMarkAsReadyModalProps> = ({
  show,
  saleOrder,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = saleOrder
    ? `Está seguro de que quiere Marcar como Finalizada la orden de venta: ${saleOrder.id}`
    : "";

  const onDeleteClick = async () => {
    if (saleOrder !== undefined) {
      setSubmitting(true);
      const saleOrderStatus = await updateItem<SaleOrder>(
        `/sale_orders/${saleOrder.id}/ready/`,
        saleOrder
      );

      if (saleOrderStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (saleOrderStatus.detail !== undefined) {
          message = saleOrderStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-success" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Finalizar Manualmente</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <h3 className="text-danger">Precaución</h3>
              <h5>
                Esta acción no se puede deshacer. Marcar como finalizado pondrá
                el estado de la orden de envío en{" "}
                <CBadge color="success">Finalizado</CBadge> sin realizar
                modificaciones en los envíos o pagos.
              </h5>
              <h5>
                El objetivo de la función de finalización manual de órden de
                venta es el de poder mantener organizados los pedidos activos
                (Finalizar pedidos que hayan sido abandonados sin enviar o en
                los que al momento de registrar el pago no se vinculó
                correctamente con el pedido y por lo tanto no se finalizó
                automáticamente).
              </h5>
              <h5>
                Además, esta función garantiza que las cantidades pendientes de
                envío en el inventario se mantengan actualizadas de manera
                precisa.
              </h5>
              <h5>
                Si hubieran articulos pendientes de envío ya no se podrán
                generar órdenes de envío para esta venta.
              </h5>
              <h5>
                Si hubieran cantidades pendientes de envío, estas se descontaran
                de los paréntesis de la sección de Inventario de Productos.
              </h5>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onDeleteClick} color="success">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Guardando..." : "Confirmar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default SaleOrderMarkAsReadyModal;
