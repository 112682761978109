import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem, ItemRequestStatus } from "../../api/generics";
import { newProduct } from "../../models/product";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";

import { errorAlert, warningAlert } from "../utils/messages";

import { RootState } from "../../store";
import { useSelector } from "react-redux";

import InputMaterial, {
  InputMaterialAmount,
  InputMaterialAmountData,
  newInputMaterial,
} from "../../models/input-material";
import InputMaterialAmountFormItem from "./InputMaterialAmountFormItem";
import {
  UniqueListItem,
  newUniqueListItem,
  uniqueListToListItems,
} from "../../models/unique-list-item";

interface InputMaterialAmountFormProps {
  initialInputMaterial?: InputMaterial;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const InputMaterialAmountForm: React.FC<InputMaterialAmountFormProps> = ({
  initialInputMaterial,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);

  const [editingInputMaterial, setEditingInputMaterial] =
    useState<InputMaterial>(
      initialInputMaterial ? initialInputMaterial : newProduct()
    );

  const [items, setItems] = useState<UniqueListItem<InputMaterialAmount>[]>([]);

  const [obs, setObs] = useState<string>("");

  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setObs(e.target.value);
  };

  const onAddInputMaterialClick = () => {
    setItems((items) => {
      const newItems = [
        ...items,
        newUniqueListItem<InputMaterialAmount>({
          inputMaterial: editingInputMaterial,
          inputMaterialId: editingInputMaterial?.id,
          inputMaterialIdentifier: editingInputMaterial?.identifier,
        }),
      ];
      return newItems;
    });
  };

  const onItemEntryChange = (
    modifiedItem: UniqueListItem<InputMaterialAmount>
  ) => {
    setItems((items) => {
      const newItems = [...items];
      const index = newItems.findIndex((item) => {
        return item.uuid === modifiedItem.uuid;
      });

      if (index === -1) {
        return newItems;
      }

      newItems[index] = modifiedItem;

      return newItems;
    });
  };

  const onItemEntryDelete = (
    deletedEntry: UniqueListItem<InputMaterialAmount>
  ) => {
    setItems((items) => {
      const newItems = [...items];

      const index = newItems.findIndex((item) => {
        return item.uuid === deletedEntry.uuid;
      });

      if (index === -1) {
        return newItems;
      }

      newItems.splice(index, 1);

      return newItems;
    });
  };

  const onSave = async () => {
    setSubmitting(true);

    if (items.length === 0) {
      warningAlert("Debes introducir al menos algun valor");
      setSubmitting(false);
      return;
    }

    let toSendData: InputMaterialAmountData = {
      companyId: company.id,
      items: uniqueListToListItems(items),
    };

    if (obs !== "") toSendData.obs = obs;

    let requestPromise: Promise<ItemRequestStatus<InputMaterialAmountData>>;

    requestPromise = createItem<InputMaterialAmountData>(
      "/input_materials/modify_inventory/",
      toSendData
    );

    const amountsStatus = await requestPromise;

    if (amountsStatus.status !== SUCCESS) {
      if (amountsStatus.errors !== undefined) {
        setErrors(amountsStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (amountsStatus.detail !== undefined) {
        message = amountsStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      setLoading(true);
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingInputMaterial(newInputMaterial());
    setObs("");
    setItems([]);
  };

  useEffect(() => {
    setLoading(true);
    setEditingInputMaterial(
      initialInputMaterial ? initialInputMaterial : newInputMaterial()
    );
    setObs("");
    setLoading(false);
    setItems([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInputMaterial]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  const buttonText1 = loading ? "Cargando..." : "Guardar";
  const buttonText = submitting ? "Guardando..." : buttonText1;

  const inputMaterialErrors = getFieldErrors("items", errors) as Errors[];

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Observación:</CLabel>
            </CCol>
            <CCol md={10}>
              <CInput
                type="text"
                value={obs}
                onChange={onObsChange}
                placeholder="Complete aquí el motivo por el que se hace este ajuste de inventario"
              ></CInput>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <h5>Cantidades</h5>
            </CCol>
          </CRow>
          {items.map((entry, ix) => {
            return (
              <CRow key={entry.uuid}>
                <CCol>
                  <InputMaterialAmountFormItem
                    value={entry.item}
                    onDelete={() => onItemEntryDelete(entry)}
                    initialInputMaterial={entry.item.inputMaterial}
                    onChange={(item) =>
                      onItemEntryChange({
                        uuid: entry.uuid,
                        item: item,
                      })
                    }
                    errors={
                      ix < inputMaterialErrors.length
                        ? inputMaterialErrors[ix]
                        : {}
                    }
                  />
                </CCol>
              </CRow>
            );
          })}
          {items.length === 0 ? (
            <CRow>
              <CCol>
                Agregue nuevas materias primas con el boton + de la derecha
              </CCol>
            </CRow>
          ) : (
            <></>
          )}
          <CRow className="mb-1">
            <CCol>
              <CButton
                className="btn btn-primary float-right"
                onClick={onAddInputMaterialClick}
              >
                <i className="fa fa-plus" />
              </CButton>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting || loading ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {buttonText}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default InputMaterialAmountForm;
