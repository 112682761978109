import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Company from "../../models/company";

export interface CompanyState {
  loaded: boolean;
  loading: Boolean;
  error: boolean;
  errorDetails: string[];
  data: {
    company: Company;
  };
}

const initialState: CompanyState = {
  loaded: false,
  loading: false,
  error: false,
  errorDetails: [],
  data: {
    company: {},
  },
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCompany: (state, action: PayloadAction<Company>) => {
      state.loaded = true;
      state.loading = false;
      state.data.company = action.payload;
    },
    setCompanyErrorLoading: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
  },
});

export const { setCompanyLoading, setCompany, setCompanyErrorLoading } =
companySlice.actions;

export default companySlice.reducer;
