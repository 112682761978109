import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { deleteItem } from "../../api/generics";
import InputMaterial from "../../models/input-material";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";

interface InputMaterialDeleteModalProps {
  show: boolean;
  inputMaterial?: InputMaterial;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const InputMaterialDeleteModal: React.FC<InputMaterialDeleteModalProps> = ({
  show,
  inputMaterial,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = inputMaterial
    ? `Está seguro de que quiere eliminar la materia prima: ${inputMaterial.identifier}`
    : "";

  const onDeleteClick = async () => {
    if (inputMaterial !== undefined) {
      setSubmitting(true);
      const inputMaterialStatus = await deleteItem<InputMaterial>(
        `/input_materials/${inputMaterial.id}/`,
        inputMaterial
      );

      if (inputMaterialStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (inputMaterialStatus.detail !== undefined) {
          message = inputMaterialStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Eliminar</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <h3 className="text-danger">Precaución</h3>
              <h5>
                No se recomienda borrar entradas de materia prima puesto que
                esto podria afectar a datos o registros anteriores en la cual
                fue ultilizada. por ejemplo órdenes de producción o tandas de
                materia prima.
              </h5>
              <br />
              <h5>Podrían perderse datos.</h5>
              <br />
              <h5>
                Si ya no va utilizar no es necesario borrar. Puede quedar como
                un registro.
              </h5>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onDeleteClick} color="danger">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Eliminando..." : "Eliminar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default InputMaterialDeleteModal;
