import Client from "./client";
import Color from "./color";
import { BatchItemSellLogs } from "./inventory-input-batch";
import InventoryLocation from "./inventory-location";
import Product from "./product";
import SaleOrder, { SaleOrderItem } from "./sale-order";
import Size from "./size";
import Variant from "./variant";

export interface DeliveryOrderItem {
  id?: number;
  companyId?: number;
  deliveryOrderId?: number;
  saleOrderItemId?: number;
  saleOrderItem?: SaleOrderItem;
  clothingProductId?: number;
  clothingProduct?: Product;
  productVariantId?: number;
  productVariant?: Variant;
  productCodeIdentifier?: string;
  productName?: string;
  colorId?: number;
  color?: Color;
  colorName?: string;
  sizeId?: number;
  size?: Size;
  sizeName?: string;
  amountDelivered?: number;
  unitPrice?: number;
  totalPrice?: number;
  unitPriceNoDiscount?: number;
  totalPriceNoDiscount?: number;
}

interface DeliveryOrder {
  id?: number;
  companyId?: number;
  clientId?: number;
  saleOrderId?: number;
  clientName?: string;
  paymentStatus?: string;
  orderDate?: string;
  orderEstimatedPayment?: string;
  hasDiscount?: boolean;
  discountPerUnit?: number;
  totalPriceNoDiscount?: number;
  totalPrice?: number;
  paidPrice?: number;
  obs?: string;
  deliveryOrderItems?: DeliveryOrderItem[];
  client?: Client;
  saleOrder?: SaleOrder;
  inventoryLocation?: InventoryLocation;
  inventoryLocationId?: number;
  inventoryLocationName?: string;
  reportUrl?: string;
  saleProfit?: number;
  saleProductCosts?: number;
  batchLogs?: BatchItemSellLogs[];
}

export interface StatusData {
  class: string;
  name: string;
}

interface StatusesMap {
  [index: string]: StatusData;
}

export const DELIVERYORDERPAYMENTSTATUSES: StatusesMap = {
  pending: {
    class: "info",
    name: "Pendiente",
  },
  partial: {
    class: "warning",
    name: "Pagado parcialmente",
  },
  paid: {
    class: "success",
    name: "Pagado",
  },
  reverted: {
    class: "danger",
    name: "Revertido",
  },
};

export const getStatusLabel = (key: string) => {
  return DELIVERYORDERPAYMENTSTATUSES[key].name;
};

export const getStatusClass = (key: string) => {
  return DELIVERYORDERPAYMENTSTATUSES[key].class;
};

export const newDeliveryOrder = (): DeliveryOrder => {
  return {};
};

export default DeliveryOrder;
