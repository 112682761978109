import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../store";
import {
  setPendingRequestsCount,
  setPendingTransfersCount,
} from "../redux/inventory-transfer/inventory-transfer-slice";
import { getList } from "../api/generics";
import InventoryTransfer from "../models/inventory-transfer";
import { SUCCESS } from "../utils/constants/tags";
import StoreRequest from "../models/store-requests";

interface AuthProps {
  children: React.ReactNode;
}

const TransferProvider: React.FunctionComponent<AuthProps> = (
  props: AuthProps
) => {
  const isLoggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const dispatch = useDispatch();

  const getPendingTransfersAndStoreRequests = async () => {
    let map = new Map();
    map.set("order_status", "ongoing");

    const pendingTransfersStatus = await getList<InventoryTransfer>(
      "/inventory_transfers/",
      10000,
      0,
      map
    );

    if (
      pendingTransfersStatus.status === SUCCESS &&
      pendingTransfersStatus.data !== undefined
    ) {
      let receivedItems = pendingTransfersStatus.data.items;

      const count = receivedItems.length;

      dispatch(setPendingTransfersCount(count));
    }
    let map2 = new Map();
    map2.set("request_status", "pending");

    const pendingStoreRequests = await getList<StoreRequest>(
      "/store_requests/",
      10000,
      0,
      map2
    );
    if (
      pendingStoreRequests.status === SUCCESS &&
      pendingStoreRequests.data !== undefined
    ) {
      let receivedRequestStatus = pendingStoreRequests.data.items;

      const requestsCount = receivedRequestStatus.length;

      dispatch(setPendingRequestsCount(requestsCount));
    }
  };

  // Initialize login
  useEffect(() => {
    if (isLoggedIn) {
      getPendingTransfersAndStoreRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Refresh token if user is logged in
  useEffect(() => {
    let refreshInterval: number | null = null;
    if (isLoggedIn) {
      refreshInterval = window.setInterval(async () => {
        await getPendingTransfersAndStoreRequests();
      }, 15000);
    }

    // Will be called at the re render or unmount of the component
    return () => {
      if (refreshInterval != null) {
        clearInterval(refreshInterval);
      }
    };
  });

  return <>{props.children}</>;
};

export default TransferProvider;
