import SaleOrder from "../models/sale-order";

export const saleOrderIsEditable = (order: SaleOrder): boolean => {
  if (order.status === undefined) {
    return true;
  }
  return order.lastDeliveryDate === undefined && order.status === "pending";
};

export const saleOrderIsReversible = (order: SaleOrder): boolean => {
  if (order.status === undefined) {
    return false;
  }
  return order.status === "ongoing" || order.status === "completed";
};

export const saleOrderCanBeMarkedAsReady = (order: SaleOrder): boolean => {
  if (order.status === undefined) {
    return false;
  }
  return order.status === "ongoing";
};
