import ProductionCrud from "../components/production/ProductionCrud";

const ProductionCrudPage = () => {
  return (
    <>
      <ProductionCrud />
    </>
  );
};

export default ProductionCrudPage;
