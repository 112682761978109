import CashRegisterList from "../components/cash-register/CashRegisterList";

const CashRegisterHistoryPage = () => {
  return (
    <>
      <CashRegisterList />
    </>
  );
};

export default CashRegisterHistoryPage;
