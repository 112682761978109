import InventoryInputBatch from "./inventory-input-batch";

interface InputMaterial {
  id?: number;
  companyId?: number;
  identifier?: string;
  type?: string;
  description?: string;
  obs?: string;
  currentTotal?: number;
  measurementUnit?: string;
  minimunToReport?: number;
}

export interface InputMaterialMovementLog {
  id?: number;
  companyId?: number;
  inputMaterialId?: number;
  batchId?: number;
  inputMaterialIdentifier?: string;
  batchIdentifier?: string;
  amount?: number;
  subject?: string;
  obs?: string;
  inventoryName?: string;
  createdAt?: string;
}

export interface InputMaterialAmountData {
  companyId?: number;
  obs?: string;
  items?: InputMaterialAmount[];
}

export interface InputMaterialAmount {
  inputMaterial?: InputMaterial;
  inputMaterialId?: number;
  inputMaterialIdentifier?: string;
  inventoryInputBatch?: InventoryInputBatch;
  inventoryInputBatchId?: number;
  batchIdentifier?: string;
  amount?: number;
}

// Client types
export const INPUT_MATERIAL_TYPE_FABRIC = "fabric";
export const INPUT_MATERIAL_TYPE_FABRIC_LABEL = "Tela";
export const INPUT_MATERIAL_TYPE_BUTTON = "button";
export const INPUT_MATERIAL_TYPE_BUTTON_LABEL = "Botón";
export const INPUT_MATERIAL_TYPE_ZIPPER = "zipper";
export const INPUT_MATERIAL_TYPE_ZIPPER_LABEL = "Cierre";
export const INPUT_MATERIAL_TYPE_TAG = "tag";
export const INPUT_MATERIAL_TYPE_TAG_LABEL = "Etiqueta";
export const INPUT_MATERIAL_TYPE_THREAD = "thread";
export const INPUT_MATERIAL_TYPE_THREAD_LABEL = "Hilo";
export const INPUT_MATERIAL_TYPE_OTHERS = "others";
export const INPUT_MATERIAL_TYPE_OTHERS_LABEL = "Otros";

export const INPUT_MATERIAL_TYPE_CHOICES: Map<string, string> = new Map([
  [INPUT_MATERIAL_TYPE_FABRIC, INPUT_MATERIAL_TYPE_FABRIC_LABEL],
  [INPUT_MATERIAL_TYPE_BUTTON, INPUT_MATERIAL_TYPE_BUTTON_LABEL],
  [INPUT_MATERIAL_TYPE_ZIPPER, INPUT_MATERIAL_TYPE_ZIPPER_LABEL],
  [INPUT_MATERIAL_TYPE_ZIPPER, INPUT_MATERIAL_TYPE_ZIPPER_LABEL],
  [INPUT_MATERIAL_TYPE_TAG, INPUT_MATERIAL_TYPE_TAG_LABEL],
  [INPUT_MATERIAL_TYPE_THREAD, INPUT_MATERIAL_TYPE_THREAD_LABEL],
  [INPUT_MATERIAL_TYPE_OTHERS, INPUT_MATERIAL_TYPE_OTHERS_LABEL],
]);

export const newInputMaterial = (): InputMaterial => {
  return {};
};

export const inputMaterialNeedsReStock = (inputMaterial: InputMaterial) => {
  if (inputMaterial.minimunToReport === undefined) {
    return false;
  }
  if (inputMaterial.currentTotal === undefined) {
    return !(inputMaterial.minimunToReport === 0);
  }
  if (inputMaterial.currentTotal > inputMaterial.minimunToReport) {
    return false;
  }
  return true;
};

export const getInputMaterialTypeLabel = (inputMaterial: InputMaterial) => {
  switch (inputMaterial.type) {
    case INPUT_MATERIAL_TYPE_FABRIC:
      return INPUT_MATERIAL_TYPE_FABRIC_LABEL;
    case INPUT_MATERIAL_TYPE_BUTTON:
      return INPUT_MATERIAL_TYPE_BUTTON_LABEL;
    case INPUT_MATERIAL_TYPE_ZIPPER:
      return INPUT_MATERIAL_TYPE_ZIPPER_LABEL;
    case INPUT_MATERIAL_TYPE_TAG:
      return INPUT_MATERIAL_TYPE_TAG_LABEL;
    case INPUT_MATERIAL_TYPE_THREAD:
      return INPUT_MATERIAL_TYPE_THREAD_LABEL;
    case INPUT_MATERIAL_TYPE_OTHERS:
      return INPUT_MATERIAL_TYPE_OTHERS_LABEL;
    default:
      return "-";
  }
};

export default InputMaterial;
