import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CInput,
  CInputGroup,
  CInputGroupText,
  CLabel,
  CPagination,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getItem, getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { warningAlert } from "../utils/messages";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { DATEDDMMYYY, formatDate } from "../../utils/dates";
import { defaultValueOnUndefined } from "../../utils/fields";
import InventoryInputBatch, {
  BatchItemSellLogs,
} from "../../models/inventory-input-batch";

const ITEMS_PER_PAGE = 50;

const BatchAmountLogList = () => {
  const fields = [
    {
      key: "logDate",
      _classes: ["text-center", "align-middle"],
      label: "Fecha",
    },
    {
      key: "barcode",
      _classes: ["text-center", "align-middle"],
      label: "Código Escaneado",
    },
    {
      key: "productCodeIdentifier",
      _classes: ["text-center", "align-middle"],
      label: "Código Producto",
    },
    {
      key: "amount",
      _classes: ["text-center", "align-middle"],
      label: "Cantidad",
    },
    {
      key: "registerType",
      _classes: ["text-center", "align-middle"],
      label: "Tipo de Operación",
    },
    {
      key: "obs",
      _classes: ["text-center", "align-middle"],
      label: "Observación",
    },
  ];

  const { id } = useParams<{ id: string }>();
  const [search, setSearch] = useState<string>("");
  const [startDate, setStartDate] = useState<string | moment.Moment>("");
  const [endDate, setEndDate] = useState<string | moment.Moment>("");
  const [batch, setBatch] = useState<InventoryInputBatch | undefined>({});
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState<BatchItemSellLogs[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const fetchBatch = async (id: number) => {
    const clientStatus = await getItem<InventoryInputBatch>(
      `/inventory_locations/batches/${id}/`
    );
    if (clientStatus.status === SUCCESS) {
      if (clientStatus.data !== undefined) {
        setBatch(clientStatus.data);
      }
    } else {
      const message = clientStatus.detail
        ? clientStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let startDate = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : "";
    let endDate = urlParams.get("end_date") ? urlParams.get("end_date") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    if (startDate !== "") map.set("start_date", startDate);
    if (endDate !== "") map.set("end_date", endDate);
    return map;
  };

  const fetchLogs = async (id: number) => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    urlParams.set("batch_id", id.toString());
    const paymentsStatus = await getList<BatchItemSellLogs>(
      "/batches_amounts_logs/",
      limit,
      offset,
      urlParams
    );
    if (paymentsStatus.status === SUCCESS) {
      if (paymentsStatus.data !== undefined) {
        const count = paymentsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setLogs(paymentsStatus.data.items);
        console.log(paymentsStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = paymentsStatus.detail
        ? paymentsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    if (previousParams.get("start_date")) {
      urlParams.set("start_date", previousParams.get("start_date"));
    }
    if (previousParams.get("end_date")) {
      urlParams.set("end_date", previousParams.get("end_date"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onStartDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setStartDate("");
      return;
    }
    setStartDate(value);
  };

  const onEndDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setEndDate("");
      return;
    }
    setEndDate(value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    if (search !== "") {
      urlParams.set("search", search);
    } else {
      urlParams.delete("search");
    }
    if (moment.isMoment(startDate)) {
      urlParams.set("start_date", startDate.toISOString());
    } else if (startDate !== undefined && startDate !== "") {
      urlParams.set("start_date", startDate);
    } else {
      urlParams.delete("start_date");
    }
    if (moment.isMoment(endDate)) {
      urlParams.set("end_date", endDate.toISOString());
    } else if (endDate !== undefined && endDate !== "") {
      urlParams.set("end_date", endDate);
    } else {
      urlParams.delete("end_date");
    }
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const getInitialValues = async () => {
    setLoading(true);
    const batchPromise = fetchBatch(Number(id));
    const logsPromise = fetchLogs(Number(id));
    await batchPromise;
    await logsPromise;
    setLoading(false);
  };

  useEffect(() => {
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      fetchLogs(Number(id));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>
                  Movimientos de items del lote de Tela:{" "}
                  {batch?.inputMaterialIdentifier} {batch?.supplierName}
                </h3>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow>
                <CCol></CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Desde:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onStartDatehange}
                    value={startDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Hasta:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onEndDatehange}
                    value={endDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el término de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={logs}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    logDate: (item: BatchItemSellLogs) => {
                      return (
                        <td className="text-center">
                          <b>
                            {item.logDate
                              ? formatDate(new Date(item.logDate), DATEDDMMYYY)
                              : "-"}
                          </b>
                        </td>
                      );
                    },
                    productCodeIdentifier: (item: BatchItemSellLogs) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined(
                            "-",
                            item.productCodeIdentifier
                          )}
                        </td>
                      );
                    },
                    barcode: (item: BatchItemSellLogs) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.barcode)}
                        </td>
                      );
                    },
                    registerType: (item: BatchItemSellLogs) => {
                      const valueToShow =
                        item.registerType === "automatic"
                          ? "Generado por el sistema"
                          : "Registro Manual";
                      return <td className="text-center">{valueToShow}</td>;
                    },
                    obs: (item: BatchItemSellLogs) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.obs)}
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default BatchAmountLogList;
