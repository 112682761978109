import BarcodeFormView from "../components/barcode-print/BarcodeFormView";

const BarcodeFormPage = () => {
  return (
    <>
      <BarcodeFormView />
    </>
  );
};

export default BarcodeFormPage;
