import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem, updateItem, ItemRequestStatus } from "../../api/generics";
import Store, { newStore } from "../../models/store";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";
import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";

interface UserFormProps {
  initialStore?: Store;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const StoreForm: React.FC<UserFormProps> = ({
  initialStore,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingStore, setEditingStore] = useState<Store>(
    initialStore ? initialStore : newStore()
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const onPercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingStore((prevEditingStore) => {
      let amount: number | undefined = undefined;

      if (!isNaN(parseFloat(e.target.value))) {
        amount = parseFloat(e.target.value);
      }

      return { ...prevEditingStore, storeRequestCreditPercentage: amount };
    });
  };

  const onAmountChange = (newAmount: number | undefined) => {
    setEditingStore({
      ...editingStore,
      storeRequestsCredit: newAmount !== undefined ? newAmount : 0,
    });
  };

  const onEnableLimitChange = (enableLimit: boolean) => {
    setEditingStore({
      ...editingStore,
      storeRequestLimitEnabled: enableLimit,
    });
  };

  const onSave = async () => {
    setSubmitting(true);
    let toSendStore = {
      ...editingStore,
      companyId: company.id,
    };
    let requestPromise: Promise<ItemRequestStatus<Store>>;
    if (editingStore.id === undefined) {
      requestPromise = createItem<Store>("/stores/create/", toSendStore);
    } else {
      requestPromise = updateItem<Store>(
        `/stores/${toSendStore.id}/`,
        toSendStore
      );
    }

    const storeStatus = await requestPromise;

    if (storeStatus.status !== SUCCESS) {
      if (storeStatus.errors !== undefined) {
        setErrors(storeStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (storeStatus.detail !== undefined) {
        message = storeStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingStore(newStore());
  };

  useEffect(() => {
    setEditingStore(initialStore ? initialStore : newStore());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStore]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={3} className={"mt-2"}>
              Crédito de pedidos:
            </CCol>
            <CCol md={9}>
              <CurrencyField
                currency={PYG}
                value={editingStore.storeRequestsCredit}
                onChange={onAmountChange}
              ></CurrencyField>
              <FieldErrors
                errors={
                  getFieldErrors("storeRequestsCredit", errors) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={3} className={"mt-2"}>
              Porcentaje de ventas a credito de pedidos:
            </CCol>
            <CCol md={9}>
              <CInput
                type="number"
                value={emptyValueOnUndefined(
                  editingStore.storeRequestCreditPercentage
                )}
                onChange={onPercentageChange}
                placeholder="Porcentaje %"
                step="0.01"
              ></CInput>
              <FieldErrors
                errors={
                  getFieldErrors(
                    "storeRequestCreditPercentage",
                    errors
                  ) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Bloquear pedidos al quedarse sin crédito:</CLabel>
            </CCol>
            <CCol md={2}>
              <CRow>
                <AnimatedCheckbox
                  value={editingStore.storeRequestLimitEnabled}
                  defaultValue={editingStore.storeRequestLimitEnabled}
                  onChange={onEnableLimitChange}
                ></AnimatedCheckbox>
              </CRow>

              <FieldErrors
                errors={
                  getFieldErrors("storeRequestLimitEnabled", errors) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default StoreForm;
